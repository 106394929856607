import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import TitoloBlocco from '../../shared/titoloBlocco/TitoloBlocco';
import CustomButton from '../../shared/customButton/CustomButton';
import MagazineItem from '../../shared/magazineItem/MagazineItem';

const BloccoMagazineHome = () => {
	const magazinesHome = useSelector((state) => state.magazinesHome);
	const paginestaticheTranslation = useSelector(
		(state) => state.translations.paginestatiche
	);
	const homepageTranslation = useSelector(
		(state) => state.translations.homepage
	);
	const navigationItems = useSelector(
		(state) => state.translations.mainNavigation
	);

	const elencoMagazines = magazinesHome.map((magazine) => (
		<MagazineItem key={_uniqueId('magazine_')} {...magazine} />
	));

	return (
		<div className='containerFluido container mx-auto'>
			<div className='grid grid-cols-60 lg:pt-24'>
				<div className='col-start-5 col-end-56 lg:col-end-57 flex flex-col justify-center xl:items-center'>
					<div className='border border-ribola-alto hidden xl:flex w-full relative'></div>
					<TitoloBlocco
						titoloStyle='titoloBlocco text-3xl sm:text-4xl lg:text-5xl 4xl:text-6xl text-ribola-alto bg-white pt-12 lg:py-4 xl:px-20 lg:absolute'
						titolo={paginestaticheTranslation.titolomagazine.itemName}
					/>
				</div>
			</div>
			<div className='grid grid-cols-60 pt-8 pb-4 sm:pb-12 lg:pt-16 lg:pb-20'>
				<div className='col-start-5 4xl:col-start-11 col-end-56 4xl:col-end-51 flex xl:justify-center items-center xl:text-center relative'>
					<span
						className='text-sm sm:text-base 4xl:text-lg text-ribola-dove-gray xl:px-48'
						dangerouslySetInnerHTML={{
							__html: paginestaticheTranslation.descrizionemagazine.itemName,
						}}
					/>
					<CustomButton
						buttonLink={navigationItems.magazine.itemLink}
						buttonRouteName={navigationItems.magazine.itemRouteName}
						buttonName={homepageTranslation.veditutti.itemName.toUpperCase()}
						buttonStyle='buttonBordered bg-ribola-alto -mt-20 xl:mt-0 absolute top-0 xl:top-auto right-0'
					/>
				</div>
			</div>
			<div className='grid grid-cols-60 pb-0 sm:pb-16'>
				<div className='col-start-1 sm:col-start-3 2xl:col-start-7 4xl:col-start-11 col-end-61 sm:col-end-58 2xl:col-end-55 4xl:col-end-51'>
					<div className='grid grid-cols-60 sm:gap-5 overflow-x-auto xl:overflow-x-visible py-8 pl-3 pr-3 sm:pr-0 sm:py-0'>
						{elencoMagazines}
					</div>
				</div>
			</div>
		</div>
	);
};

export default BloccoMagazineHome;
