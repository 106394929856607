import { Fragment } from 'react';
import { CardElement } from '@stripe/react-stripe-js';

const StripeElement = () => {
	return (
		<Fragment>
			<CardElement
				options={{
					style: {
						base: {
							fontSize: '18px',
							color: '#424770',
							'::placeholder': {
								color: '#aab7c4',
							},
						},
						invalid: {
							color: '#9e2146',
						},
					},
				}}
			/>
		</Fragment>
	);
};

export default StripeElement;
