import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import InfoTooltipElement from '../../../shared/infoTooltipElement/InfoTooltipElement';

const TabellaDatiOutlet = ({
	giacenza,
	venduti,
	codice,
	pesocollo,
	larghezza,
	lunghezza,
	profondita,
	metrilineari,
	colore,
	unitamisura,
}) => {
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	useEffect(() => {}, []);

	const disponibilita = parseInt(giacenza) - parseInt(venduti);

	const lunghezzaMisura =
		(unitamisura === 1 ||
			unitamisura === 2 ||
			unitamisura === 5 ||
			unitamisura === 6) &&
		lunghezza > 0 ? (
			<div className='flex justify-between items-center border-b border-gray-300 py-2 text-sm text-white'>
				<span>{genericheTranslation.lunghezza.itemName.toUpperCase()}</span>
				<span>
					{parseFloat(lunghezza).toFixed(2).toString().replace('.', ',')}
					{genericheTranslation.metri.itemName}
				</span>
			</div>
		) : (
			''
		);

	const larghezzaMisura =
		(unitamisura === 1 ||
			unitamisura === 2 ||
			unitamisura === 5 ||
			unitamisura === 6) &&
		larghezza > 0 ? (
			<div className='flex justify-between items-center border-b border-gray-300 py-2 text-sm text-white'>
				<span>{genericheTranslation.larghezza.itemName.toUpperCase()}</span>
				<span>
					{parseFloat(larghezza).toFixed(2).toString().replace('.', ',')}
					{genericheTranslation.metri.itemName}
				</span>
			</div>
		) : (
			''
		);

	const profonditaMisura =
		unitamisura === 6 && profondita > 0 ? (
			<div className='flex justify-between items-center border-b border-gray-300 py-2 text-sm text-white'>
				<span>{genericheTranslation.profondita.itemName.toUpperCase()}</span>
				<span>
					{parseFloat(profondita).toFixed(2).toString().replace('.', ',')}
					{genericheTranslation.metri.itemName}
				</span>
			</div>
		) : (
			''
		);

	const metrilineariMisura =
		unitamisura === 3 && metrilineari > 0 ? (
			<div className='flex justify-between items-center border-b border-gray-300 py-2 text-sm text-white'>
				<span>{genericheTranslation.metrilineari.itemName.toUpperCase()}</span>
				<span>
					{parseFloat(metrilineari).toFixed(2).toString().replace('.', ',')}
					{genericheTranslation.metrilineari.itemName}
				</span>
			</div>
		) : (
			''
		);

	const dimensioneMetriQuadri =
		lunghezzaMisura !== '' && larghezzaMisura !== '' ? (
			<div className='flex justify-between items-center border-b border-gray-300 py-2 text-sm text-white'>
				<span>{genericheTranslation.dimensione.itemName.toUpperCase()}</span>
				<span>
					{parseFloat(lunghezza * lunghezza)
						.toFixed(2)
						.toString()
						.replace('.', ',')}
					&nbsp;{genericheTranslation.mq.itemName}
				</span>
			</div>
		) : (
			''
		);

	const dimensioneMetriCubi =
		profonditaMisura !== '' ? (
			<div className='flex justify-between items-center border-b border-gray-300 py-2 text-sm text-white'>
				<span>{genericheTranslation.dimensione.itemName.toUpperCase()}</span>
				<span>
					{parseFloat(lunghezza * lunghezza * profondita)
						.toFixed(2)
						.toString()
						.replace('.', ',')}
					&nbsp;{genericheTranslation.mc.itemName}
				</span>
			</div>
		) : (
			''
		);

	return (
		<div className='flex flex-col mb-4 py-10'>
			<div className='flex justify-between items-center border-b border-gray-300 py-2 text-sm text-ribola-heavy-metal px-8'>
				<span>
					{genericheTranslation.disponibilita.itemName.toUpperCase()}
					<small className='ml-3'>
						<em>{genericheTranslation.finoaesaurimentoscorte.itemName}</em>
					</small>
				</span>
				<span className='font-opensanssemibold'>
					{disponibilita.toString()}
				</span>
			</div>
			<div className='flex justify-between items-center border-b border-gray-300 py-2 text-sm text-ribola-heavy-metal px-8'>
				<span>{genericheTranslation.codice.itemName.toUpperCase()}</span>
				<span className='font-opensanssemibold'>{codice}</span>
			</div>
			<div className='flex justify-between items-center border-b border-gray-300 py-2 text-sm text-ribola-heavy-metal px-8'>
				<span>{genericheTranslation.pesocollo.itemName.toUpperCase()}</span>
				<span className='font-opensanssemibold'>
					{parseFloat(pesocollo).toFixed(2).toString().replace('.', ',')}&nbsp;
					{genericheTranslation.kg.itemName}
				</span>
			</div>
			<div
				className={`flex flex-col mt-8 w-full bg-${colore} text-white px-8 py-6`}>
				<span className='flex justify-between items-center text-xl font-opensanssemibold mb-2'>
					{genericheTranslation.dimensioni.itemName.toUpperCase()}
					<InfoTooltipElement
						iconColor='text-white'
						fontSize='text-3xl'
						tooltipColor='black'
						tooltipText={genericheTranslation.dimensioniprodottomisura.itemName}
					/>
				</span>
				{lunghezzaMisura}
				{larghezzaMisura}
				{profonditaMisura}
				{metrilineariMisura}
				{dimensioneMetriQuadri}
				{dimensioneMetriCubi}
			</div>
		</div>
	);
};

export default TabellaDatiOutlet;
