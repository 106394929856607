import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import AOS from 'aos';
import LazyLoadPicture from '../../shared/lazyLoadPicture/LazyLoadPicture';

const BloccoBannerHome = () => {
	const bannersHome = useSelector((state) => state.bannersHome);

	useEffect(() => {
		// inizializzo AOS
		AOS.init();
		AOS.refresh();
	}, []);

	const imgWidth = {
		md: '952',
		xxs: '952',
	};

	const imgHeight = {
		md: '240',
		xxs: '240',
	};

	if (bannersHome === undefined || bannersHome?.length === 0) {
		return <div></div>;
	}

	let banner1;
	if (bannersHome[0] !== undefined) {
		banner1 = bannersHome[0]['imgLink'] ? (
			<a href={bannersHome[0]['imgLink']}>
				<LazyLoadPicture
					imgName={bannersHome[0]['imgName']}
					imgPath={bannersHome[0]['imgPath']}
					imgWidth={imgWidth}
					imgHeight={imgHeight}
					imgStyle='flex-start'
					imgAlt={bannersHome[0]['imgAlt']}
				/>
			</a>
		) : (
			<LazyLoadPicture
				imgName={bannersHome[0]['imgName']}
				imgPath={bannersHome[0]['imgPath']}
				imgWidth={imgWidth}
				imgHeight={imgHeight}
				imgStyle='flex-start'
				imgAlt={bannersHome[0]['imgAlt']}
			/>
		);
	}

	let banner2;
	if (bannersHome[1] !== undefined) {
		banner2 = bannersHome[1]['imgLink'] ? (
			<a href={bannersHome[1]['imgLink']}>
				<LazyLoadPicture
					imgName={bannersHome[1]['imgName']}
					imgPath={bannersHome[1]['imgPath']}
					imgWidth={imgWidth}
					imgHeight={imgHeight}
					imgStyle='flex-start'
					imgAlt={bannersHome[1]['imgAlt']}
				/>
			</a>
		) : (
			<LazyLoadPicture
				imgName={bannersHome[1]['imgName']}
				imgPath={bannersHome[1]['imgPath']}
				imgWidth={imgWidth}
				imgHeight={imgHeight}
				imgStyle='flex-start'
				imgAlt={bannersHome[1]['imgAlt']}
			/>
		);
	}

	return (
		<div className='container mx-auto'>
			<div className='grid grid-cols-60 xl:gap-4 pt-0 sm:pt-4 pb-4 sm:pb-12'>
				{bannersHome[0] !== undefined && (
					<div
						className='col-span-60 md:col-span-30 mb-4 md:mb-0'
						data-aos='flip-left'
						data-aos-delay='100'
						data-aos-once={false}>
						{banner1}
					</div>
				)}

				{bannersHome[1] !== undefined && (
					<div
						className='col-span-60 md:col-span-30'
						data-aos='flip-right'
						data-aos-delay='100'
						data-aos-once={false}>
						{banner2}
					</div>
				)}
			</div>
		</div>
	);
};

export default BloccoBannerHome;
