import produce from 'immer';
import { combineReducers } from 'redux-immer';
import { currentPageReducer } from './currentPageReducer';
import { headerStaticReducer } from './headerStaticReducer';
import { staticPagesReducer } from './staticPagesReducer';
import { catalogueReducer } from './catalogueReducer';
import { bannersHomeReducer } from './bannersHomeReducer';
import { productsBestSoldHomeReducer } from './productsBestSoldHomeReducer';
import { inspirationHomeReducer } from './inspirationHomeReducer';
import { categoriesSlideHomeReducer } from './categoriesSlideHomeReducer';
import { magazineHomeReducer } from './magazineHomeReducer';
import { magazineReducer } from './magazineReducer';
import { siteParamsReducer } from './siteParamsReducer';
import { promotionReducer } from './promotionReducer';
import { cartReducer } from './cartReducer';
import { cartLastItemReducer } from './cartLastItemReducer';
import { orderReducer } from './orderReducer';
import { userReducer } from './userReducer';
import { checkoutReducer } from './checkoutReducer';
import { translationReducer } from './translationReducer';
import { menuHamburgerReducer } from './menuHamburgerReducer';

export default () =>
	combineReducers(produce, {
		currentPage: currentPageReducer,
		order: orderReducer,
		cart: cartReducer,
		cartLastItem: cartLastItemReducer,
		checkout: checkoutReducer,
		user: userReducer,
		promotion: promotionReducer,
		headerStatic: headerStaticReducer,
		staticPages: staticPagesReducer,
		catalogue: catalogueReducer,
		bannersHome: bannersHomeReducer,
		productsBestSoldHome: productsBestSoldHomeReducer,
		inspirationsHome: inspirationHomeReducer,
		categoriesSlideHome: categoriesSlideHomeReducer,
		magazinesHome: magazineHomeReducer,
		magazine: magazineReducer,
		siteParams: siteParamsReducer,
		translations: translationReducer,
		menuHamburger: menuHamburgerReducer,
	});
