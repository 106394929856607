import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Hamburger from '../hamburger/Hamburger';
import Logo from '../shared/logo/Logo';
import MainNavigation from '../shared/mainNavigation/MainNavigation';
import Socials from '../shared/socials/Socials';
import TopSearch from '../shared/topSearch/TopSearch';
import LoginRegistrationButton from '../shared/loginRegistrationButton/LoginRegistrationButton';
import CartButton from '../shared/cartButton/CartButton';
import FeedatyButton from '../shared/feedatyButton/FeedatyButton';

const Header = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const contattiNavigation = useSelector(
		(state) => state.translations.mainNavigation.contatti
	);

	// local state

	useEffect(() => {
		console.log('render Header');
	}, []);

	const onClickInfo = (event) => {
		event.preventDefault();

		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(
				'',
				'contattiDetail',
				contattiNavigation.itemLink,
				history
			)
		);
	};

	return (
		<div className='container mx-auto'>
			<header>
				<div className='grid grid-cols-60 xl:h-64 lg:mb-3'>
					<div className='col-start-4 sm:col-start-4 lg:col-start-2 col-end-23 sm:col-end-15 lg:col-end-14 2xl:col-start-3 2xl:col-end-16 4xl:col-start-5 4xl:col-end-21 flex items-center py-3 sm:py-6 lg:py-8'>
						<Logo />
					</div>
					<div className='col-start-25 col-end-38 hidden sm:flex lg:hidden'>
						<FeedatyButton />
					</div>
					<div className='col-start-40 col-end-59 flex justify-end lg:hidden'>
						<div
							className='flex flex-col justify-center items-center text-ribola-selective-yellow'
							onClick={onClickInfo}>
							<span className='text-2xl border-3 border-ribola-selective-yellow flex mb-1 px-4 py-2'>
								<span className='leading-3'>i</span>
							</span>
							<span className='font-opensansbold'>INFO</span>
						</div>
						<Hamburger />
					</div>
					<div className='hidden lg:flex col-start-17 col-end-60 2xl:col-start-16 2xl:col-end-59 4xl:col-start-21 4xl:col-end-57'>
						<div className='grid grid-cols-60 w-full h-full py-9 2xl:py-8'>
							<div className='col-span-60'>
								<div className='grid grid-cols-60 w-full'>
									<div className='col-start-1 col-end-17 3xl:col-end-21'>
										<TopSearch />
									</div>
									<div className='col-start-19 xl:col-start-23 3xl:col-start-25 col-end-33 xl:col-end-36'>
										<LoginRegistrationButton />
									</div>
									<div className='col-start-33 xl:col-start-36 col-end-51 xl:col-end-51'>
										<CartButton />
									</div>
									<div className='col-start-51 col-end-61'>
										<FeedatyButton />
									</div>
								</div>
							</div>
							<div className='col-span-60 flex items-end'>
								<div className='grid grid-cols-60 w-full'>
									<MainNavigation />
									<Socials />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='grid grid-cols-60 lg:hidden bg-ribola-congress-blue py-3 barraBluHeader'>
					<div className='col-start-4 col-end-57 sm:col-end-26'>
						<TopSearch />
					</div>
					<div className='col-start-4 sm:col-start-26 col-end-24 sm:col-end-40 flex justify-start sm:justify-end items-center mt-3 sm:mt-0'>
						<CartButton />
					</div>
					<div className='col-start-24 sm:col-start-40 col-end-57 mt-3 sm:mt-0'>
						<LoginRegistrationButton />
					</div>
				</div>
			</header>
		</div>
	);
};

export default Header;
