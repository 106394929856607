import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import OrderClosedDetail from '../../components/cart/orderClosedDetail/OrderClosedDetail';

const OrderClosed = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const staticPages = useSelector((state) => state.staticPages);
	const user = useSelector((state) => state.user);
	const order = useSelector((state) => state.order);
	const checkout = useSelector((state) => state.checkout);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// controllo nello store se ho già il dettaglio della pagina di ordine concluso, altrimenti eseguo action creator
		const orderClosedPage = staticPages.orderClosed;

		if (orderClosedPage === undefined || orderClosedPage.detail === undefined) {
			// recupero metodo di pagamento ordine concluso
			let targetPage = '';
			if (order.paymentId !== null && order.paymentId !== 0) {
				const paymentIndex = Object.keys(checkout.paymentMethods)
					.filter((key) => checkout.paymentMethods[key].id === order.paymentId)
					.toString();
				const paymentMethod = checkout.paymentMethods[paymentIndex].name;
				paymentMethod === 'Carta Credito'
					? (targetPage = 'stripe')
					: (targetPage = checkout.paymentMethods[paymentIndex].name);
			}

			// non ho i dati del prodotto nello store, chiamo action creator fetchOrderClosedDetail
			setTimeout(
				() =>
					dispatch(
						allActions.checkoutDetailActions.fetchOrderClosedDetail(
							currentPage.currentLang,
							user.token_id,
							user.userData.id,
							targetPage
						)
					),
				50
			);
		} else {
			// loading...
			dispatch(allActions.isFetchingAction.loadStoredData());

			// recupero dati da store
			dispatch(
				allActions.checkoutDetailActions.loadOrderClosedDetail(orderClosedPage)
			);
		}

		//console.log(orderClosedPage);
	}, [location]);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<OrderClosedDetail />
				</div>
			</div>
		</Layout>
	);
};

export default OrderClosed;
