import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import MenuLeft from '../../components/shared/menuLeft/MenuLeft';
import CategoryDetail from '../../components/categoryDetail/CategoryDetail';
import Error404Detail from '../../components/error404Detail/Error404Detail';

const Category = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const catalogue = useSelector((state) => state.catalogue);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// recupero struttura percorso da url (per via del back dal browser, devo sempre recuperare i dati via url)
		const pathNodes = location.pathname.split('/').filter((val) => val !== '');

		const reti_urlseo = pathNodes[0];
		const parent_product_category_urlseo = pathNodes[1];
		const parent_product_category_id = Object.keys(catalogue)
			.filter((key) => catalogue[key].urlSeo === parent_product_category_urlseo)
			.toString();
		const parent_product_category = catalogue[parent_product_category_id];

		const product_category_urlseo = pathNodes[2];
		//console.log(product_category_urlseo);

		let product_category_id = null;
		if (parent_product_category_id) {
			product_category_id = Object.keys(
				catalogue[parent_product_category_id]?.childCategories
			)
				.filter(
					(key) =>
						catalogue[parent_product_category_id]?.childCategories[key]
							.urlSeo === product_category_urlseo
				)
				.toString();
		}

		// controllo nello store se ho già l'elenco dei prodotti childProducts, altrimenti eseguo action creator
		let category = null;
		if (parent_product_category_id && product_category_id) {
			category =
				catalogue[parent_product_category_id]?.childCategories[
					product_category_id
				];
		}

		// ho la categoria, setto i metatags
		if (category) {
			// setto i metatags
			document.title = category.metaTitle;
			document.getElementsByTagName('META')[2].content = category.metaDesc;
		}

		// controllo per pagina 404, se currentPage.routeName === 'error404Detail' vuol dire che ho inserito un url sbagliato, restituisco la pagina 404
		if (
			currentPage.routeName === 'error404Detail' ||
			category === undefined ||
			category === null
		) {
			// recupero pagina 404
			const wrongPage404 =
				reti_urlseo +
				'/' +
				parent_product_category_urlseo +
				'/' +
				product_category_urlseo +
				'/';
			setTimeout(
				() =>
					dispatch(
						allActions.staticDetailActions.fetchError404Detail(
							currentPage.currentLang,
							user?.userData?.id,
							wrongPage404
						)
					),
				50
			);
		} else {
			// controllo dati categoria
			if (
				category === null ||
				category?.isFetched === undefined ||
				category?.isFetched === false
			) {
				// non ho i dati del prodotto nello store (isFetched = true), chiamo action creator fetchCategoryDetail (se sono in pronta consegna cambio fetch e passo id 2 (pronta Consegna))
				setTimeout(() => {
					// se non ho category.id (provengo da menu mobile e non ho fetchato la categoria padre) uso product_category_urlseo
					const categoryIdFix =
						category?.id === undefined ? product_category_urlseo : category.id;
					if (parent_product_category.id === '2') {
						dispatch(
							allActions.categoryDetailActions.fetchCategoryDetail(
								currentPage.currentLang,
								user?.userData?.id,
								categoryIdFix,
								2
							),
							50
						);
					} else {
						dispatch(
							allActions.categoryDetailActions.fetchCategoryDetail(
								currentPage.currentLang,
								user?.userData?.id,
								categoryIdFix
							),
							50
						);
					}
				});
			} else {
				// loading...
				dispatch(allActions.isFetchingAction.loadStoredData());

				// recupero dati da store
				dispatch(allActions.categoryDetailActions.loadCategoryDetail(category));
			}
		}
	}, [location, catalogue]);

	const isPage404 =
		currentPage.routeName === 'error404Detail' ? (
			<Error404Detail />
		) : (
			<CategoryDetail />
		);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<MenuLeft />
					{isPage404}
				</div>
			</div>
		</Layout>
	);
};

export default Category;
