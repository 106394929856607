import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../state/actions';
import LazyLoadImg from '../../shared/lazyLoadImg/LazyLoadImg';
import CustomButton from '../../shared/customButton/CustomButton';
import { BASENAME } from '../../../utils/basename';
import { URLASSOLUTO } from '../../../utils/urlassoluto';
import { BASESTORAGE } from '../../../utils/basestorage';

const CategoryBoxItem = ({
	categoryId,
	immagine,
	titolo,
	sottotitolo,
	prezzoapartireda,
	scontofinoa,
	scontoPromozione,
	tipologiaScontoPromozione,
	colore,
	is_macro_accessori,
	flags_macro_accessori,
	macro_padre_accessori,
	routeName,
	linkSeo,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const currentLang = useSelector((state) => state.currentPage.currentLang);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	const onCategoryClick = (event) => {
		event.preventDefault();

		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(
				categoryId,
				routeName,
				linkSeo,
				history
			)
		);
	};

	const immagineElenco =
		immagine === null || immagine?.file_name === undefined ? (
			<LazyLoadImg
				imgName='placeholder_elenco_prodotto.jpg'
				imgPath='statiche'
				imgWidth='300'
				imgHeight='232'
				imgStyle='flex-start mb-3 w-full object-fit cursor-pointer'
				imgAlt={titolo}
			/>
		) : (
			<LazyLoadImg
				imgName={`300x232-${immagine.file_name.replace('.jpg', '.webp')}`}
				imgPath={`${immagine.folder}/thumbs`}
				imgWidth='300'
				imgHeight='232'
				imgStyle='flex-start mb-3 w-full object-fit cursor-pointer'
				imgAlt={titolo}
			/>
		);

	const prezzoAPartireDaString = prezzoapartireda ? (
		<Fragment>
			{genericheTranslation.apartireda.itemName}&nbsp;
			<span className='text-2xl'>
				{parseFloat(prezzoapartireda).toFixed(2).toString().replace('.', ',')}€
			</span>
		</Fragment>
	) : (
		''
	);

	const scontoFasciaArancione =
		(scontofinoa && scontofinoa > 0) ||
		(scontoPromozione && scontoPromozione > 0) ? (
			<div
				className={`scontoFasciaArancione fascia_arancione${currentLang} small pointer-events-none z-20`}>
				<span className='scontoFasciaInterna small'>
					<span className='titoloFasciaInterna'>
						<span className='leading-none'>
							{scontofinoa | scontoPromozione}
							{tipologiaScontoPromozione === 'Percentuale' ? '%' : '€'}
						</span>
					</span>
					<span className='leading-none'>
						{genericheTranslation.sconto.itemName}
					</span>
				</span>
				<img
					src={`${BASENAME}/images/sconto_fascia_arancione.svg`}
					width='106'
					height='106'
					className='flex-start'
					alt='Sconto categoria'
				/>
			</div>
		) : (
			''
		);

	const titoloFormatted =
		(scontofinoa && scontofinoa > 0) ||
		(scontoPromozione && scontoPromozione > 0) ? (
			<span
				className={`titolo leading-tight block text-${colore} pt-4 pl-8 mb-3`}>
				{titolo}
			</span>
		) : (
			<span className={`titolo leading-tight block text-${colore} mb-3`}>
				{titolo}
			</span>
		);

	// filtri per macrocategorie accessori?
	const elencoTagMacroAccessori =
		is_macro_accessori === '1' &&
		flags_macro_accessori.map((item) => {
			if (macro_padre_accessori === null || macro_padre_accessori === undefined)
				return false;

			console.log(macro_padre_accessori);

			if (macro_padre_accessori.split(',').includes(item.id)) {
				return (
					<div className='mr-2 mb-1' key={item.id}>
						<span
							className={`iconaTag bg-${item.hoverColor} flex justify-center items-center px-2 py-1 my-1`}>
							<img
								src={
									BASESTORAGE +
									'/' +
									item.icona.folder +
									'/' +
									item.icona.file_name
								}
								width='25'
								height='25'
								className='flex-start filter brightness-0 invert'
								alt={item.name}
							/>
						</span>
					</div>
				);
			}

			return;
		});
	const showTagMacroAccessori = is_macro_accessori === '1' && (
		<div className='w-full flex flex-wrap cursor-pointer'>
			{elencoTagMacroAccessori}
		</div>
	);

	return (
		<div className='col-span-60 sm:col-span-30 md:col-span-20 xl:col-span-15 mb-10 flex justify-center'>
			<div className='productBoxItem border-t border-gray-200 max-w-xs sm:max-w-none mx-8 sm:mx-4 md:mx-2 lg:mx-3 xl:mx-2 4xl:mx-4'>
				{scontoFasciaArancione}
				<a
					href={URLASSOLUTO + linkSeo}
					onClick={onCategoryClick}
					className='text-lg md:text-sm 3xl:text-lg sm:h-20 block'>
					{titoloFormatted}
				</a>
				<a
					href={URLASSOLUTO + linkSeo}
					onClick={onCategoryClick}
					className='block h-52 overflow-hidden mb-2'>
					{immagineElenco}
				</a>
				{showTagMacroAccessori}
				<span
					className='sottotitolo text-sm block sm:h-16'
					dangerouslySetInnerHTML={{
						__html: sottotitolo,
					}}
				/>
				<span className='sottotitolo text-sm 3xl:text-xl text-ribola-congress-blue block font-opensansbold my-3'>
					{prezzoAPartireDaString}
				</span>
				<CustomButton
					buttonId={categoryId}
					buttonLink={linkSeo}
					buttonRouteName={routeName}
					buttonName={genericheTranslation.continua.itemName}
					buttonStyle='dettagliButton text-sm pl-4 pr-16 py-2 3xl:py-3 mt-2'
				/>
			</div>
		</div>
	);
};

export default CategoryBoxItem;
