import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import RegistrationDetail from '../../components/cart/registrationDetail/RegistrationDetail';

const Registration = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const cart = useSelector((state) => state.cart);
	const staticPages = useSelector((state) => state.staticPages);
	const tokenCarrello = useSelector((state) => state.user.token_id);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// controllo nello store se ho già il dettaglio di registrazione e cart altrimenti eseguo action creator
		const registrationPage = staticPages.registration;

		if (registrationPage) {
			// setto i metatags
			document.title = registrationPage.metaTitle;
			document.getElementsByTagName('META')[2].content =
				registrationPage.metaDesc;
		}

		if (
			registrationPage === undefined ||
			registrationPage.detail === undefined
		) {
			// non ho i dati nello store, chiamo action creator fetchRegistrationDetail
			setTimeout(
				() =>
					dispatch(
						allActions.registrationDetailActions.fetchRegistrationDetail(
							currentPage.currentLang,
							user?.userData?.id,
							tokenCarrello
						)
					),
				50
			);
		} else {
			// loading...
			dispatch(allActions.isFetchingAction.loadStoredData());

			// recupero dati da store
			dispatch(
				allActions.registrationDetailActions.loadRegistrationDetail(
					registrationPage
				)
			);
		}

		//console.log(registrationPage);
	}, [location, staticPages]);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<RegistrationDetail />
				</div>
			</div>
		</Layout>
	);
};

export default Registration;
