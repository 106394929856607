import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from '../../../../utils/axiosBase';
import InputField from '../../../shared/inputField/InputField';
import SelectField from '../../../shared/selectField/SelectField';
import debounce from '../../../../utils/debounce';

const filterSelect = (inputValue, response) => {
	return response.filter((i) =>
		i.label.toLowerCase().includes(inputValue.toLowerCase())
	);
};

// chiamo action creator dopo 1 secondo con debounce
const loadOptions = debounce(async (inputValue, callback) => {
	if (inputValue.length < 3) {
		return;
	}

	const payload = {
		municipality: inputValue,
	};

	// chiamo web service
	const response = await axios.post(`api/searchmunicipality/`, payload, {
		withCredentials: true,
	});

	//console.log(response.data);

	// popolo select
	callback(filterSelect(inputValue, response.data));
}, 1000);

const BoxFormIndirizzo = ({
	onChangeNazioneSFunction,
	idIndirizzo = '',
	isInputDisabledSpedizione = false,
	isBoxSpedizioneVisible,
	isUserOspite,
	isEdit = false,
	isSending = false,
	showSubmit = false,
	currentAddress,
	nazioneEsteraCampiVisibili,
	disableCampiProvinciaCitta,
	errors,
	register = () => {},
	Controller,
	control,
}) => {
	const checkout = useSelector((state) => state.checkout);
	const user = useSelector((state) => state.user);
	const campiFormTranslation = useSelector(
		(state) => state.translations.campiFormRegistrazione
	);
	const erroriFormTranslation = useSelector(
		(state) => state.translations.erroriForm
	);
	const gestioneIndirizziTranslation = useSelector(
		(state) => state.translations.gestioneIndirizzi
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	useEffect(() => {
		console.log(currentAddress, nazioneEsteraCampiVisibili);
	}, [currentAddress]);

	let defaultItaliaId;

	const elencoPaesiSpedizione = checkout.shippingCountries.map((item) => {
		if (item.shippingCountry === 'Italia') {
			defaultItaliaId = item.id;
		}
		return { id: item.id, value: item.shippingCountry };
	});

	const elencoProvince = checkout.provinces.map((item) => {
		return { id: item.id, value: item.province };
	});

	//// ERRORI VARI
	const errorFunction = ({ campoNome, messaggioErrore }) => {
		const erroreMsg = campoNome ? (
			<p className='text-sm text-red-600'>
				<i className='fal fa-exclamation-triangle mr-1'></i>
				{messaggioErrore}
			</p>
		) : (
			''
		);

		return erroreMsg;
	};
	/// FINE ERRORI

	const validateNazioneS =
		!isInputDisabledSpedizione && !isUserOspite
			? {
					...register('shipping_country_idS', {
						required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.nazione.itemName}`,
					}),
			  }
			: '';

	const validateProvinciaS =
		!isInputDisabledSpedizione && !isUserOspite && !nazioneEsteraCampiVisibili
			? {
					...register('provinciaS', {
						required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.provincia.itemName}`,
					}),
			  }
			: '';

	const disableCampiCittaProvincia =
		disableCampiProvinciaCitta && 'pointer-events-none opacity-50';

	// MOSTRO SELECT PER PROVINCIA ITALIANA O INPUT PER PROVINCIA ESTERA?
	const showProvinciaEsteraS = nazioneEsteraCampiVisibili ? (
		<div
			className={`col-span-60 sm:col-span-30 md:col-span-20 ${disableCampiCittaProvincia}`}>
			<InputField
				inputType='text'
				inputLabel={`${campiFormTranslation.provinciaestera.itemName}: *`}
				inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
				inputName='provinciaEsteraS'
				inputStyle='border border-ribola-dove-gray bg-white border-opacity-100 mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12'
				inputError={errorFunction({
					campoNome: errors?.provinciaEsteraS,
					messaggioErrore: errors?.provinciaEsteraS?.message,
				})}
				register={
					!isInputDisabledSpedizione &&
					!isUserOspite &&
					register('provinciaEsteraS', {
						required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.provinciaestera.itemName}`,
					})
				}
			/>
		</div>
	) : (
		<div
			className={`col-span-60 sm:col-span-30 md:col-span-20 ${disableCampiCittaProvincia}`}>
			<SelectField
				selectName='provinciaS'
				selectListValue={elencoProvince}
				selectLabel={`${campiFormTranslation.provincia.itemName}: *`}
				selectLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
				selectClassStyle='mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12 bg-white'
				selectError={errorFunction({
					campoNome: errors?.provinciaS,
					messaggioErrore: errors?.provinciaS?.message,
				})}
				{...validateProvinciaS}
			/>
		</div>
	);

	// MOSTRO SELECT PER COMUNI ITALIANI O INPUT PER COMUNI ESTERI?
	const showCittaEsteraS = nazioneEsteraCampiVisibili ? (
		<div
			className={`col-span-60 sm:col-span-30 md:col-span-20 ${disableCampiCittaProvincia}`}>
			<InputField
				inputType='text'
				inputLabel={`${campiFormTranslation.citta.itemName}: *`}
				inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
				inputName='cittaS'
				inputStyle='border mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12 bg-white placeholder-opacity-50'
				isDisabled={isInputDisabledSpedizione}
				inputError={errorFunction({
					campoNome: errors?.cittaS,
					messaggioErrore: errors?.cittaS?.message,
				})}
				register={
					!isInputDisabledSpedizione &&
					!isUserOspite &&
					register('cittaS', {
						required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.citta.itemName}`,
					})
				}
			/>
		</div>
	) : (
		<div
			className={`col-span-60 sm:col-span-30 md:col-span-20 ${disableCampiCittaProvincia}`}>
			<Controller
				control={control}
				name='cittaS'
				rules={{
					required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.citta.itemName}`,
				}}
				defaultValue={currentAddress?.meta_data?.cittas}
				render={({ field }) => (
					<InputField
						{...field}
						isSelectAsync={true}
						inputLabel={`${campiFormTranslation.citta.itemName}: *`}
						inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
						inputHandlerFunction={field.onChange}
						inputStyle='border border-ribola-dove-gray border-opacity-100 mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12'
						inputPlaceholder={genericheTranslation.selezionaselect.itemName}
						noOptionsMessageText={genericheTranslation.nessunaopzione.itemName}
						loadOptionsAsync={loadOptions}
						inputError={errorFunction({
							campoNome: errors?.cittaS,
							messaggioErrore: errors?.cittaS?.message,
						})}
						asyncSelectDefaultOption={{
							value: currentAddress?.meta_data.cittas,
							label: currentAddress?.meta_data.cittas,
						}}
					/>
				)}
			/>
		</div>
	);

	const isDisabledClick = isSending ? 'pointer-events-none' : '';
	const isButtonSending = isSending ? true : false;

	const pulsanteText = isEdit
		? gestioneIndirizziTranslation.modificaindirizzo.itemName.toUpperCase()
		: gestioneIndirizziTranslation.creaindirizzo.itemName.toUpperCase();

	const showSubmitButton = showSubmit ? (
		<div className='col-span-60 flex justify-center mt-6'>
			<InputField
				inputType='submit'
				inputValue={pulsanteText}
				inputStyle='w-full sm:w-max flex justify-center items-center text-white bg-ribola-congress-blue text-lg xl:text-xl font-opensanssemibold py-4 px-8 sm:px-24 border-r-2 border-ribola-gallery-gray cursor-pointer'
				showLabel={false}
				isSpinnerLoading={isButtonSending}
			/>
		</div>
	) : (
		''
	);

	let labelTitolo;
	if (showSubmit) {
		labelTitolo = isEdit
			? gestioneIndirizziTranslation.modificaindirizzo.itemName.toUpperCase()
			: gestioneIndirizziTranslation.aggiungiindirizzo.itemName.toUpperCase();
	} else {
		labelTitolo = campiFormTranslation.datispedizione.itemName.toUpperCase();
	}

	let labelSottoTitolo;
	if (showSubmit) {
		labelSottoTitolo = isEdit
			? gestioneIndirizziTranslation.sottotitolomodificaindirizzo.itemName
			: gestioneIndirizziTranslation.sottotitolonuovoindirizzoordine.itemName;
	} else {
		labelSottoTitolo =
			campiFormTranslation.inserisciindirizzospedizione.itemName;
	}

	return (
		<div
			className={`flex-col bg-ribola-gallery-gray text-ribola-heavy-metal py-8 px-8 mt-6 mb-4 sm:mb-12 ${isBoxSpedizioneVisible} ${isDisabledClick}`}>
			<InputField
				inputType='hidden'
				inputName='addressId'
				showLabel={false}
				register={register('addressId')}
			/>
			<input type='hidden' name='idIndirizzo' value={idIndirizzo} />
			<span
				className='text-3xl md:text-4xl text-ribola-congress-blue font-opensansbold w-full text-center'
				dangerouslySetInnerHTML={{
					__html: labelTitolo,
				}}
			/>
			<span
				className='sm:text-lg md:text-xl block mt-6'
				dangerouslySetInnerHTML={{
					__html: labelSottoTitolo,
				}}
			/>
			<div className='grid grid-cols-60 sm:gap-2 2xl:gap-4 mt-3'>
				<div className='col-span-60 sm:col-span-30 md:col-span-20'>
					<InputField
						inputType='text'
						inputLabel={`${campiFormTranslation.nome.itemName}: *`}
						inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
						inputName='nomeS'
						inputStyle='border mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12 bg-white placeholder-opacity-50'
						isDisabled={isInputDisabledSpedizione}
						inputError={errorFunction({
							campoNome: errors?.nomeS,
							messaggioErrore: errors?.nomeS?.message,
						})}
						register={
							!isInputDisabledSpedizione &&
							!isUserOspite &&
							register('nomeS', {
								required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.nome.itemName}`,
							})
						}
					/>
				</div>
				<div className='col-span-60 sm:col-span-30 md:col-span-20'>
					<InputField
						inputType='text'
						inputLabel={`${campiFormTranslation.cognome.itemName}: *`}
						inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
						inputName='cognomeS'
						inputStyle='border mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12 bg-white placeholder-opacity-50'
						isDisabled={isInputDisabledSpedizione}
						inputError={errorFunction({
							campoNome: errors?.cognomeS,
							messaggioErrore: errors?.cognomeS?.message,
						})}
						register={
							!isInputDisabledSpedizione &&
							!isUserOspite &&
							register('cognomeS', {
								required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.cognome.itemName}`,
							})
						}
					/>
				</div>
				<div className='col-span-60 sm:col-span-30 md:col-span-20'>
					<InputField
						inputType='text'
						inputLabel={`${campiFormTranslation.indirizzo.itemName}: *`}
						inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
						inputName='indirizzoS'
						inputStyle='border mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12 bg-white placeholder-opacity-50'
						isDisabled={isInputDisabledSpedizione}
						inputError={errorFunction({
							campoNome: errors?.indirizzoS,
							messaggioErrore: errors?.indirizzoS?.message,
						})}
						register={
							!isInputDisabledSpedizione &&
							!isUserOspite &&
							register('indirizzoS', {
								required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.indirizzo.itemName}`,
							})
						}
					/>
				</div>
				<div className='col-span-60 sm:col-span-30 md:col-span-20'>
					<div className='w-full flex flex-col justify-center items-start text-ribola-dove-gray'>
						<SelectField
							selectName='shipping_country_idS'
							selectListValue={elencoPaesiSpedizione}
							selectLabel={`${campiFormTranslation.nazione.itemName}: *`}
							selectLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
							selectClassStyle='mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12 bg-white'
							selectedValue={defaultItaliaId}
							selectError={errorFunction({
								campoNome: errors?.shipping_country_idS,
								messaggioErrore: errors?.shipping_country_idS?.message,
							})}
							selectHandlerFunction={onChangeNazioneSFunction}
							isDisabled={isEdit}
							{...validateNazioneS}
						/>
					</div>
				</div>
				{showCittaEsteraS}
				{showProvinciaEsteraS}
				<div className='col-span-60 sm:col-span-30 md:col-span-20'>
					<InputField
						inputType='text'
						inputLabel={`${campiFormTranslation.cap.itemName}: *`}
						inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
						inputName='capS'
						inputStyle='border mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12 bg-white placeholder-opacity-50'
						isDisabled={isInputDisabledSpedizione}
						inputError={errorFunction({
							campoNome: errors?.capS,
							messaggioErrore: errors?.capS?.message,
						})}
						register={
							!isInputDisabledSpedizione &&
							!isUserOspite &&
							register('capS', {
								required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.cap.itemName}`,
							})
						}
					/>
				</div>
				{user?.userData?.is_guest === 1 && (
					<div className='col-span-60 sm:col-span-30 md:col-span-20'>
						<InputField
							inputType='email'
							inputLabel={`${campiFormTranslation.email.itemName}: *`}
							inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
							inputName='emailS'
							inputStyle='border mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12 bg-white placeholder-opacity-50'
							isDisabled={isInputDisabledSpedizione}
							inputError={errorFunction({
								campoNome: errors?.emailS,
								messaggioErrore: errors?.emailS?.message,
							})}
							register={
								!isInputDisabledSpedizione &&
								!isUserOspite &&
								register('emailS', {
									required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.email.itemName}`,
									pattern: {
										value:
											/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
										message: erroriFormTranslation.erroremailsbagliata.itemName,
									},
								})
							}
						/>
					</div>
				)}

				<div className='col-span-60 sm:col-span-30 md:col-span-20'>
					<InputField
						inputType='text'
						inputLabel={`${campiFormTranslation.telefonoprofile.itemName}: *`}
						inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
						inputName='telefonoS'
						inputStyle='border mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12 bg-white placeholder-opacity-50'
						isDisabled={isInputDisabledSpedizione}
						inputError={errorFunction({
							campoNome: errors?.telefonoS,
							messaggioErrore: errors?.telefonoS?.message,
						})}
						register={
							!isInputDisabledSpedizione &&
							!isUserOspite &&
							register('telefonoS', {
								required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.telefonoprofile.itemName}`,
							})
						}
					/>
				</div>
				{showSubmitButton}
			</div>
		</div>
	);
};

export default BoxFormIndirizzo;
