import { useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import SpinnerLoading from '../../shared/spinnerLoading/SpinnerLoading';

const PulsanteAggiungiCarrello = ({
	onAddToCartHandler,
	isDisabled = true,
	isSending = false,
	isNotAvailable = false,
}) => {
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	useEffect(() => {}, []);

	const isButtonDisabled = isDisabled
		? 'disabled:opacity-50 pointer-events-none'
		: '';

	const isButtonSending = isSending ? 'flex' : 'hidden';
	const isButtonSendingCursor = isSending ? 'cursor-default' : '';
	const isHideAddToCart = isSending ? 'hidden' : 'flex';

	const buttonToShow = isNotAvailable ? (
		<button
			disabled={isDisabled}
			className={`bg-ribola-dust-gray text-white font-opensanssemibold mb-1 w-full 3xl:w-10/12 4xl:w-9/12 px-8 py-4 mt-6 ${isButtonSendingCursor} ${isButtonDisabled}`}
			type='button'>
			<span className='w-full items-center justify-center text-lg 3xl:text-xl'>
				{genericheTranslation.prodottoesaurito.itemName.toUpperCase()}
				<i className='fal fa-ban text-2xl ml-3'></i>
			</span>
		</button>
	) : (
		<button
			onClick={onAddToCartHandler}
			disabled={isDisabled}
			className={`bg-ribola-amber text-white active:bg-ribola-web-orange font-opensanssemibold hover:shadow-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 w-full 3xl:w-10/12 4xl:w-9/12 px-8 py-4 mt-6 ${isButtonSendingCursor} ${isButtonDisabled}`}
			type='button'>
			<span
				className={`w-full items-center justify-center text-lg 3xl:text-xl ${isHideAddToCart}`}>
				{genericheTranslation.aggiungiacarrello.itemName.toUpperCase()}
				<i className='fal fa-shopping-cart text-2xl ml-3'></i>
			</span>
			<span
				className={`w-full items-center justify-center text-white ${isButtonSending}`}>
				<SpinnerLoading
					label={genericheTranslation.attendi.itemName.toUpperCase()}
				/>
			</span>
		</button>
	);

	return <Fragment>{buttonToShow}</Fragment>;
};

export default PulsanteAggiungiCarrello;
