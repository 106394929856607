import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import CartDetail from '../../components/cart/cartDetail/CartDetail';

const Cart = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const staticPages = useSelector((state) => state.staticPages);
	const user = useSelector((state) => state.user);
	const tokenCarrello = useSelector((state) => state.user.token_id);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// controllo nello store se ho già il dettaglio della pagina del carrello, altrimenti eseguo action creator (lo eseguo anche ogni volta che mi loggo o sloggo, per ricalcolare gli sconti)
		const cartPage = staticPages.cart;

		if (cartPage) {
			// setto i metatags
			document.title = cartPage.metaTitle;
			document.getElementsByTagName('META')[2].content = cartPage.metaDesc;
		}

		if (
			cartPage === undefined ||
			cartPage.detail === undefined ||
			currentPage.isLogging === true ||
			currentPage.isLogging === false
		) {
			//console.log('sono in carrello');
			// non ho i dati del prodotto nello store, chiamo action creator fetchCartDetail
			setTimeout(
				() =>
					dispatch(
						allActions.cartDetailActions.fetchCartDetail(
							currentPage.currentLang,
							user?.userData?.id,
							tokenCarrello
						)
					),
				50
			);
		} else {
			// loading...
			dispatch(allActions.isFetchingAction.loadStoredData());

			// recupero dati da store
			dispatch(allActions.cartDetailActions.loadCartDetail(cartPage));
		}

		//console.log(cartPage);
	}, [location, staticPages]);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<CartDetail />
				</div>
			</div>
		</Layout>
	);
};

export default Cart;
