import { useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import Briciole from '../shared/briciole/Briciole';
import LazyLoadPicture from '../shared/lazyLoadPicture/LazyLoadPicture';

const MagazineArticleDetail = () => {
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const paginestaticheTranslation = useSelector(
		(state) => state.translations.paginestatiche
	);

	// local state

	useEffect(() => {
		//console.log(pageContent, pageContent?.detail);
	}, [pageContent]);

	// controllo che esista la proprietà detail (altrimenti vuol dire che sto ancora recuperando i dati)
	if (
		!pageContent.detail ||
		pageContent?.detail?.sottotitolo === undefined ||
		pageContent?.magazineBlocks === undefined
	) {
		return <div></div>;
	}

	const imgWidthQuadrato = {
		xl4: '536',
		xl3: '461',
		xl2: '470',
		xl: '470',
		lg: '721',
		md: '721',
		sm: '576',
		xs: '390',
		xxs: '390',
	};

	const imgHeightQuadrato = {
		xl4: '489',
		xl3: '461',
		xl2: '450',
		xl: '450',
		lg: '350',
		md: '350',
		sm: '280',
		xs: '356',
		xxs: '356',
	};

	const imgWidthRettangolo = {
		xl4: '1312',
		xl3: '948',
		xl2: '948',
		xl: '773',
		lg: '773',
		md: '704',
		sm: '416',
		xs: '416',
		xxs: '416',
	};

	const imgHeightRettangolo = {
		xl4: '546',
		xl3: '394',
		xl2: '394',
		xl: '321',
		lg: '321',
		md: '293',
		sm: '173',
		xs: '173',
		xxs: '173',
	};

	const elencoBlocchiMagazine = pageContent?.magazineBlocks.map((item) => {
		// controllo tipologia blocco
		//console.log(item);
		if (item.tipologiaBlocco === 'blocco_testosx_immaginedx') {
			return (
				<div key={_uniqueId('blocco_')} className='col-span-60 mb-8 sm:mb-16'>
					<div className='grid grid-cols-60 w-full'>
						<div
							className='col-span-60 xl:col-span-35 text-sm sm:text-base pr-0 xl:pr-20'
							dangerouslySetInnerHTML={{
								__html: item.descrizione,
							}}
						/>
						<div className='col-span-60 xl:col-span-25 order-first xl:order-none mb-8 xl:mb-0'>
							<LazyLoadPicture
								imgName={item.ImmagineBlocco.file_name}
								imgPath={item.ImmagineBlocco.folder}
								imgWidth={imgWidthQuadrato}
								imgHeight={imgHeightQuadrato}
								imgStyle='flex-start max-w-none lg:max-w-full w-full lg:w-auto'
								imgAlt={pageContent.detail.titolo}
							/>
						</div>
					</div>
				</div>
			);
		} else if (item.tipologiaBlocco === 'blocco_immaginecentrale') {
			return (
				<div
					key={_uniqueId('blocco_')}
					className='col-span-60 flex justify-center md:px-20 mb-8 sm:mb-16'>
					<LazyLoadPicture
						imgName={item.ImmagineBlocco.file_name}
						imgPath={item.ImmagineBlocco.folder}
						imgWidth={imgWidthRettangolo}
						imgHeight={imgHeightRettangolo}
						imgStyle='flex-start max-w-none lg:max-w-full w-full lg:w-auto'
						imgAlt={item.ImmagineBlocco.file_name}
					/>
				</div>
			);
		} else if (item.tipologiaBlocco === 'blocco_testocentrale') {
			return (
				<div
					key={_uniqueId('blocco_')}
					className='col-span-60 text-sm sm:text-base mb-8 sm:mb-16'
					dangerouslySetInnerHTML={{
						__html: item.descrizione,
					}}
				/>
			);
		} else if (item.tipologiaBlocco === 'blocco_immaginesx_testodx') {
			return (
				<div key={_uniqueId('blocco_')} className='col-span-60 mb-8 sm:mb-16'>
					<div className='grid grid-cols-60 w-full'>
						<div className='col-span-60 xl:col-span-25 mb-8 xl:mb-0'>
							<LazyLoadPicture
								imgName={item.ImmagineBlocco.file_name}
								imgPath={item.ImmagineBlocco.folder}
								imgWidth={imgWidthQuadrato}
								imgHeight={imgHeightQuadrato}
								imgStyle='flex-start max-w-none lg:max-w-full w-full lg:w-auto'
								imgAlt={item.ImmagineBlocco.file_name}
							/>
						</div>
						<div
							className='col-span-60 xl:col-span-35 text-sm sm:text-base pl-0 xl:pl-20'
							dangerouslySetInnerHTML={{
								__html: item.descrizione,
							}}
						/>
					</div>
				</div>
			);
		}
	});

	return (
		<div className='col-start-1 lg:col-start-15 4xl:col-start-16 col-end-61 3xl:col-end-59 4xl:col-end-57 sm:pb-12'>
			<Briciole />
			<div className='grid grid-cols-60 mt-0 sm:mt-3 xl:mt-6'>
				<div className='col-span-60 flex flex-col px-8 xl:px-12 3xl:px-20 xl:pt-8 pb-6 sm:pb-8 xl:pb-12 3xl:py-14'>
					<h1 className='flex text-ribola-congress-blue text-3xl md:text-4xl xl:text-5xl font-opensansbold mb-6 sm:mb-16'>
						<small className='leading-tight flex'>
							{pageContent.detail.titolo.toUpperCase()}
						</small>
					</h1>
					<h3
						className='flex text-ribola-dove-gray text-xl md:text-2xl xl:text-3xl font-opensansbold'
						dangerouslySetInnerHTML={{
							__html: pageContent.detail.sottotitolo?.toUpperCase(),
						}}
					/>
				</div>
			</div>
			<div className='grid grid-cols-60 px-8 xl:pl-12 xl:pr-0 3xl:pl-20 pb-0 sm:pb-20 elencoBlocchiMagazine'>
				{elencoBlocchiMagazine}
			</div>
		</div>
	);
};

export default MagazineArticleDetail;
