import { Route } from 'react-router-dom';
import CookiesPolicy from '../pages/cookiesPolicy/CookiesPolicy';

export default (cookiesPolicyRoute) => {
	return [
		<Route
			exact
			path={`/${cookiesPolicyRoute}.html`}
			component={CookiesPolicy}
			key={`${cookiesPolicyRoute}Route`}
		/>,
	];
};
