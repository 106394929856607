import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../state/actions';
import CustomButton from '../../../shared/customButton/CustomButton';
import LazyLoadImg from '../../../shared/lazyLoadImg/LazyLoadImg';
import { gtmProductClick } from '../../../../gtm/gtm';

const InspirationProductItem = ({
	markerId,
	detailToShowId,
	showDetail,
	pointerPositionX,
	pointerPositionY,
	productId,
	productImage,
	productTitle,
	productCode,
	productIconTag,
	productIconTagColor,
	productPrice,
	productPriceDiscounted,
	productDiscount,
	productUnitMeasure,
	productLink,
	productRouteName,
	orientation,
	titoloCategoria,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const currentLang = useSelector((state) => state.currentPage.currentLang);
	const siteParams = useSelector((state) => state.siteParams);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	// local state
	const [isDetailOpen, setIsDetailOpen] = useState(false);
	const [currentMarkerId, setCurrentMarkerId] = useState(null);

	useEffect(() => {
		//console.log('aaa', markerId, detailToShowId);

		// se sono io ad essere cliccato, setto isDetailOpen al contrario dello stato precedente (apri/chiudi)
		if (markerId !== detailToShowId) {
			setIsDetailOpen(false);
		} else {
			setCurrentMarkerId(markerId);
			setIsDetailOpen(!isDetailOpen);
		}
	}, [markerId, detailToShowId]);

	// click su marker chiama funziona onShowDetail del padre InspirationItem
	const onMarkerClick = (onShowDetail, markerId) => {
		//console.log('currentMarkerId', currentMarkerId, isDetailOpen);
		// nascondo dettaglio localmente se già aperto
		setIsDetailOpen(!isDetailOpen);
		onShowDetail(markerId, isDetailOpen);
	};

	const onProductClick = (event) => {
		event.preventDefault();

		const productData = {
			titoloBreve: productTitle,
			codice: productCode,
			imponibile: productPrice,
			sconto: productDiscount,
			prezzoScontato: productPriceDiscounted,
		};

		// gtm productClick
		const gtmSuffix = `gtm_code${currentLang}`;
		const currentGTMTAG = siteParams[gtmSuffix];
		gtmProductClick({
			productData,
			categoryName: titoloCategoria,
			position: markerId,
			callbackFunction: onGoToPage,
			isGtmEnabled: currentGTMTAG !== null ? true : false,
		});
	};

	const onGoToPage = () => {
		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(
				productId,
				productRouteName,
				productLink,
				history
			)
		);
	};

	const tag = productIconTag ? (
		<span className={`iconaTag bg-${productIconTagColor}`}>
			{productIconTag}
		</span>
	) : (
		''
	);

	const prezzoOriginale = productPriceDiscounted ? (
		<span className='prezzoScontato flex'>
			{parseFloat(productPrice).toFixed(2).toString().replace('.', ',')}€
		</span>
	) : (
		''
	);

	const prezzoProdotto = productPriceDiscounted ? (
		<span className='prezzoProdotto flex text-ribola-web-orange text-md xs:text-lg sm:text-xl md:text-3xl xl:text-2xl 3xl:text-3xl'>
			{parseFloat(productPriceDiscounted)
				.toFixed(2)
				.toString()
				.replace('.', ',')}
			€ <small>{productUnitMeasure}</small>
		</span>
	) : (
		<span className='prezzoProdotto flex text-ribola-congress-blue text-md xs:text-lg sm:text-xl md:text-3xl xl:text-2xl 3xl:text-3xl'>
			{parseFloat(productPrice).toFixed(2).toString().replace('.', ',')}€{' '}
			<small>{productUnitMeasure}</small>
		</span>
	);

	// toggle dettaglio
	const isProductDetailVisible =
		detailToShowId !== null &&
		markerId === detailToShowId &&
		isDetailOpen === true
			? 'opacity-100 pointer-events-auto'
			: 'opacity-0 pointer-events-none';

	// orientamento dettaglio
	let orientationDetail = '';
	if (orientation === 'left up') {
		orientationDetail = 'bottom-0 right-full';
	} else if (orientation === 'left down') {
		orientationDetail = 'top-0 right-full';
	} else if (orientation === 'right up') {
		orientationDetail = 'bottom-0 left-0';
	} else if (orientation === 'right down') {
		orientationDetail = 'left-0';
	} else if (orientation === 'top center') {
		orientationDetail = 'bottom-0 right-2/4';
	} else if (orientation === 'bottom center') {
		orientationDetail = 'right-2/4';
	}

	// immagine prodotto
	const immagineProdotto =
		productImage === null ? (
			<LazyLoadImg
				imgName='placeholder_elenco_prodotto.jpg'
				imgPath='statiche'
				imgWidth='120'
				imgHeight='120'
				imgStyle='flex-start mb-6 w-full'
				imgAlt={productTitle}
			/>
		) : (
			<LazyLoadImg
				imgName={`120x120-${productImage.file_name.replace('.jpg', '.webp')}`}
				imgPath={`${productImage.folder}/thumbs`}
				imgWidth='120'
				imgHeight='120'
				imgStyle='flex-start mb-6 w-full'
				imgAlt={productTitle}
			/>
		);

	return (
		<div
			className='inspirationProductItem w-7/12 xs:w-2/4 sm:w-2/4 md:w-2/4 lg:w-7/12 xl:w-2/4'
			style={{ top: `${pointerPositionY}%`, left: `${pointerPositionX}%` }}>
			<span
				className='inspirationMarker transform-gpu hover:scale-125 h-10 2xl:h-14 w-10 2xl:w-14'
				onClick={() => onMarkerClick(showDetail, markerId)}>
				<i className='fas fa-plus text-ribola-congress-blue-variant'></i>
			</span>
			<div
				className={`boxProdottoInspirationHover px-2 py-3 xs:p-4 3xl:pl-8 3xl:pr-4 3xl:py-8 ml-10 2xl:ml-14 mt-10 2xl:mt-14 z-10 ${isProductDetailVisible} ${orientationDetail}`}>
				<div className='w-3/12 sm:w-4/12'>{immagineProdotto}</div>
				<div className='w-9/12 sm:w-8/12 pl-3'>
					{tag}
					<span className='titolo font-opensansbold text-ribola-tundora text-xs xs:text-sm sm:text-base md:text-lg 3xl:text-xl flex'>
						<span className='leading-tight block'>{productTitle}</span>
					</span>
					<span className='codice text-ribola-tundora text-xs sm:text-sm 3xl:text-base flex mb-1'>
						{productCode}
					</span>
					{prezzoOriginale}
					{prezzoProdotto}
					<CustomButton
						buttonId={productId}
						buttonLink={productLink}
						buttonRouteName={productRouteName}
						buttonOnClickFunction={onProductClick}
						buttonName={genericheTranslation.dettagli.itemName}
						buttonStyle='dettagliButton text-xs 3xl:text-sm pl-3 xs:pl-4 pr-8 xs:pr-12 3xl:pr-16 py-1 md:py-2 lg:py-1 xl:py-2 md:mt-2 lg:mt-1 xl:mt-2'
					/>
				</div>
			</div>
		</div>
	);
};

export default InspirationProductItem;
