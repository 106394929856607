import {
	ADD_TO_CART,
	UPDATE_CART_QUANTITY,
	DELETE_CART_ITEM,
	DELETE_CART_ITEMS,
	NO_SHIPPING,
	SHIPPING_FROM_CAP,
	SELECT_ADDRESS,
	CREATE_ADDRESS,
	UPDATE_ADDRESS,
	SELECT_PAYMENT,
	FETCH_PAYMENT_DETAIL,
	LOGOUT_USER,
	STRIPE_PAYMENT_INTENT_CREATE,
	STRIPE_OK,
	PAYPAL_OK,
	BONIFICO_OK,
	RIBA_OK,
	CONTRASSEGNO_OK,
	ORDER_RESET,
	UPDATE_NOTES,
	ADD_COUPON,
} from '../actions/types';

const INITIAL_STATE = {
	order: {},
};

export const orderReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ADD_TO_CART:
		case UPDATE_CART_QUANTITY:
		case DELETE_CART_ITEM:
		case DELETE_CART_ITEMS:
		case NO_SHIPPING:
		case SELECT_ADDRESS:
		case SELECT_PAYMENT:
		case CREATE_ADDRESS:
		case UPDATE_ADDRESS:
			const { order, message, status } = action.payload;

			if (order) {
				state = { ...state, ...order, message, status };
			} else {
				state = { ...state, message, status };
			}

			return state;

		case SHIPPING_FROM_CAP:
			const { shippingCostCAP, shippingCountryCAP, capSelected } =
				action.payload;

			state = { ...state, shippingCostCAP, shippingCountryCAP, capSelected };

			return state;

		case ADD_COUPON:
			if (action.payload?.order) {
				state = action.payload.order;
				state.status = action.payload.status;
				state.dataUpdate = action.payload.dataUpdate;
				state.message = action.payload.message;
			} else {
				state.status = action.payload.status;
				state.dataUpdate = action.payload.dataUpdate;
				state.message = action.payload.message;
			}

			return state;

		case UPDATE_NOTES:
			const { notes } = action.payload.order;

			state = { ...state, notes };

			return state;

		case FETCH_PAYMENT_DETAIL:
			state = action.payload.order;

			return state;

		case STRIPE_PAYMENT_INTENT_CREATE:
			const { stripeClientSecret } = action.payload;

			state = { ...state, stripeClientSecret };

			return state;

		case STRIPE_OK:
			state.message = action.payload.message;
			state.stripeResult = action.payload.stripeResult;

			return state;

		case PAYPAL_OK:
			state.message = action.payload.message;
			state.paypalResult = action.payload.paypalResult;

			return state;

		case BONIFICO_OK:
			state.message = action.payload.message;
			state.bonificoResult = action.payload.bonificoResult;

			return state;

		case RIBA_OK:
			state.message = action.payload.message;
			state.ribaResult = action.payload.ribaResult;

			return state;

		case CONTRASSEGNO_OK:
			state.message = action.payload.message;
			state.contrassegnoResult = action.payload.contrassegnoResult;

			return state;

		case ORDER_RESET:
			state.totalItems = 0;
			state.subtotalCart = 0;
			state.subtotalCartVAT = 0;
			state.totalShipping = 0;
			state.totalShippingVAT = 0;
			state.totalCart = 0;
			state.totalWeight = 0;
			state.selectedCountryId = 14; // italia
			state.selectedCountryRealId = 36; // italia
			state.aliquotaIVA = 0;
			state.descrizioneAliquotaIVA = '';
			state.isNoShipping = false;
			state.addressId = 0;
			state.paymentId = 0;
			state.userId = 0;
			state.is_guest = null;
			state.stripeResult = null;
			state.paypalResult = null;
			state.bonificoResult = null;
			state.ribaResult = null;
			state.contrassegnoResult = null;
			state.status = '';
			state.message = null;
			state.stripeClientSecret = null;
			state.codiceCoupon = null;
			state.scontoCoupon = null;
			state.tipologiaSconto = null;
			state.shippingCostCAP = null;
			state.shippingCountryCAP = null;
			state.capSelected = null;

			return state;

		case LOGOUT_USER:
			state.addressId = 0;
			state.paymentId = 0;
			state.userId = 0;

			return state;

		default:
			return state;
	}
};
