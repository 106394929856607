import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import allActions from '../../../state/actions';
import Briciole from '../../shared/briciole/Briciole';
import BarraCheckout from '../barraCheckout/BarraCheckout';
import RiepilogoCarrello from '../riepilogoCarrello/RiepilogoCarrello';
import CustomButton from '../../shared/customButton/CustomButton';
import ModalConfirm from '../../shared/modalConfirm/ModalConfirm';
import TextareaField from '../../shared/textareaField/TextareaField';
import StripeElement from './stripeElement/StripeElement';
import debounce from '../../../utils/debounce';
import { BASENAME } from '../../../utils/basename';
import { gtmCheckoutConfermaOrdine } from '../../../gtm/gtm';

const CheckoutDetail = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const stripe = useStripe();
	const elements = useElements();
	const currentPage = useSelector((state) => state.currentPage);
	const siteParams = useSelector((state) => state.siteParams);
	const user = useSelector((state) => state.user);
	const order = useSelector((state) => state.order);
	const cart = useSelector((state) => state.cart);
	const tokenCarrello = useSelector((state) => state.user.token_id);
	const checkout = useSelector((state) => state.checkout);
	const campiFormTranslation = useSelector(
		(state) => state.translations.campiFormRegistrazione
	);
	const riepilogoCarrelloTranslation = useSelector(
		(state) => state.translations.riepilogoCarrello
	);
	const checkoutTranslation = useSelector(
		(state) => state.translations.checkout
	);
	const fasiCarrelloTranslation = useSelector(
		(state) => state.translations.fasiCarrello
	);
	const spedizionePagamentoTranslation = useSelector(
		(state) => state.translations.spedizionePagamento
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	// local state
	const [currentAddress, setCurrentAddress] = useState(null);
	const [currentPaymentMethod, setCurrentPaymentMethod] = useState(null);
	const [stripeClientSecret, setStripeClientSecret] = useState(null);
	const [apiErrorMessage, setApiErrorMessage] = useState(null);
	const [apiResult, setApiResult] = useState(false);
	const [isPaying, setIsPaying] = useState(false);
	const [isPaypal, setIsPaypal] = useState(false);
	const [isGTM, setIsGTM] = useState(false);
	const [note, setNote] = useState(order?.notes);

	useEffect(() => {
		/* console.log(
			'bbb',
			currentAddress,
			currentPaymentMethod,
			stripeClientSecret
		); */

		if (isGTM === false) {
			/***
			 * GTM CHECKOUT CONCLUDI ORDINE - STEP 3
			 */
			const paymentMethodID = Object.keys(checkout.paymentMethods).filter(
				(itemId) => {
					const payment = checkout.paymentMethods[itemId];
					return payment.id === order.paymentId;
				}
			)[0];
			const paymentMethod = checkout.paymentMethods[paymentMethodID].name;
			console.log(paymentMethod);

			const gtmSuffix = `gtm_code${currentPage.currentLang}`;
			const currentGTMTAG = siteParams[gtmSuffix];
			gtmCheckoutConfermaOrdine({
				cartData: cart,
				paymentMethod: paymentMethod,
				isGtmEnabled: currentGTMTAG !== null ? true : false,
			});

			setIsGTM(true);
		}

		// preseleziono l'indirizzo di consegna
		if (order.addressId !== null && order.addressId !== 0) {
			const addressIndex = Object.keys(user.indirizzi)
				.filter(
					(key) =>
						parseInt(user.indirizzi[key].id) === parseInt(order.addressId)
				)
				.toString();
			setCurrentAddress(user.indirizzi[addressIndex]);
		}

		// preseleziono il metodo di pagamento
		if (order.paymentId !== null && order.paymentId !== 0) {
			const paymentIndex = Object.keys(checkout.paymentMethods)
				.filter((key) => checkout.paymentMethods[key].id === order.paymentId)
				.toString();
			setCurrentPaymentMethod(checkout.paymentMethods[paymentIndex]);
		}

		//////////// INIZIALIZZAZIONI E RISULTATI DA METODI DI PAGAMENTO

		//////////////////////////////
		//////////// STRIPE //////////
		//////////////////////////////
		if (
			currentPaymentMethod?.id === 4 &&
			(order.stripeClientSecret === undefined ||
				order.stripeClientSecret === null)
		) {
			// chiamo action create
			dispatch(
				allActions.checkoutDetailActions.stripePaymentIntentCreate(
					currentPage.currentLang,
					user.token_id,
					user.userData.id,
					note
				)
			);
		} else if (
			currentPaymentMethod?.id === 4 &&
			stripeClientSecret === null &&
			(order.stripeClientSecret !== undefined ||
				order.stripeClientSecret !== null)
		) {
			setStripeClientSecret(order.stripeClientSecret);
		}

		/// RISULTATO DA STRIPE
		if (
			apiResult === false &&
			order.stripeResult !== undefined &&
			order.stripeResult !== null
		) {
			// mostro risultato nel modal
			const messageToShowStripe =
				order.status === 'OK'
					? genericheTranslation.modalmessaggioordineconclusook.itemName
					: genericheTranslation.modalmessaggioordineconclusoko.itemName;

			ModalConfirm({
				titleToShow: checkoutTranslation.modalTitoloRisultatoPagamento.itemName,
				messageToShow: messageToShowStripe,
				isShowConfirmButton: false,
				isShowCanceButton: false,
				isTimerModal: true,
				onClickHandler: () => {
					// invio a pagina finale
					// mostro spinner
					dispatch(allActions.isFetchingAction.loadSpinner());

					dispatch(
						allActions.changePageActions.changePage(
							'',
							'stripeDetail',
							'/stripe-ok.html',
							history
						)
					);
				},
			});

			setApiResult(true);
		}
		//////////////////////////////
		//////// FINE STRIPE /////////
		//////////////////////////////

		/////////////////////////////
		/////////// PAYPAL //////////
		/////////////////////////////
		/// RISULTATO DA PAYPAL
		if (
			apiResult === false &&
			order.paypalResult !== undefined &&
			order.paypalResult !== null
		) {
			// mostro risultato nel modal
			const messageToShowPaypal =
				order.status === 'OK'
					? genericheTranslation.modalmessaggioordineconclusook.itemName
					: genericheTranslation.modalmessaggioordineconclusoko.itemName;

			ModalConfirm({
				titleToShow: checkoutTranslation.modalTitoloRisultatoPagamento.itemName,
				messageToShow: messageToShowPaypal,
				isShowConfirmButton: false,
				isShowCanceButton: false,
				isTimerModal: true,
				onClickHandler: () => {
					// invio a pagina finale
					// mostro spinner
					dispatch(allActions.isFetchingAction.loadSpinner());

					dispatch(
						allActions.changePageActions.changePage(
							'',
							'paypalDetail',
							'/paypal-ok.html',
							history
						)
					);
				},
			});

			setApiResult(true);
		}
		/// FINE RISULTATO DA PAYPAL

		/// PAYPAL
		if (currentPaymentMethod?.id === 2 && isPaypal === false) {
			setIsPaypal(true);
		}
		//////////////////////////////////
		/////////// FINE PAYPAL //////////
		//////////////////////////////////

		///////////////////////////////
		/////////// BONIFICO //////////
		///////////////////////////////
		if (order.bonificoResult !== undefined && order.bonificoResult !== null) {
			// mostro risultato nel modal
			ModalConfirm({
				titleToShow: checkoutTranslation.modalTitoloRisultatoPagamento.itemName,
				messageToShow:
					genericheTranslation.modalmessaggioordineconclusook.itemName,
				isShowConfirmButton: false,
				isShowCanceButton: false,
				isTimerModal: true,
				onClickHandler: () => {
					// invio a pagina finale
					// mostro spinner
					dispatch(allActions.isFetchingAction.loadSpinner());

					dispatch(
						allActions.changePageActions.changePage(
							'',
							'bonificoDetail',
							'/bonifico-ok.html',
							history
						)
					);
				},
			});
		}
		///////////////////////////////
		//////// FINE BONIFICO ////////
		///////////////////////////////

		///////////////////////////
		/////////// RIBA //////////
		///////////////////////////
		if (order.ribaResult !== undefined && order.ribaResult !== null) {
			// mostro risultato nel modal
			ModalConfirm({
				titleToShow: checkoutTranslation.modalTitoloRisultatoPagamento.itemName,
				messageToShow:
					genericheTranslation.modalmessaggioordineconclusook.itemName,
				isShowConfirmButton: false,
				isShowCanceButton: false,
				isTimerModal: true,
				onClickHandler: () => {
					// invio a pagina finale
					// mostro spinner
					dispatch(allActions.isFetchingAction.loadSpinner());

					dispatch(
						allActions.changePageActions.changePage(
							'',
							'ribaDetail',
							'/riba-ok.html',
							history
						)
					);
				},
			});
		}
		///////////////////////////
		//////// FINE RIBA ////////
		///////////////////////////

		///////////////////////////////
		//////// CONTRASSEGNO /////////
		///////////////////////////////
		if (
			order.contrassegnoResult !== undefined &&
			order.contrassegnoResult !== null
		) {
			// mostro risultato nel modal
			ModalConfirm({
				titleToShow: checkoutTranslation.modalTitoloRisultatoPagamento.itemName,
				messageToShow:
					genericheTranslation.modalmessaggioordineconclusook.itemName,
				isShowConfirmButton: false,
				isShowCanceButton: false,
				isTimerModal: true,
				onClickHandler: () => {
					// invio a pagina finale
					// mostro spinner
					dispatch(allActions.isFetchingAction.loadSpinner());

					dispatch(
						allActions.changePageActions.changePage(
							'',
							'contrassegnoDetail',
							'/contrassegno-ok.html',
							history
						)
					);
				},
			});
		}
		///////////////////////////////
		////// FINE CONTRASSEGNO //////
		///////////////////////////////
	}, [
		currentAddress,
		currentPaymentMethod,
		order.stripeClientSecret,
		stripeClientSecret,
		user.token_id,
	]);

	// controllo di avere nello store i dati che mi servono
	if (
		!user.indirizzi ||
		!checkout.provinces ||
		!checkout.paymentMethods ||
		(currentAddress === null && order.isNoShipping === false) ||
		currentPaymentMethod === null
	) {
		return <div></div>;
	}

	const elencoImmaginiMetodoPagamento = currentPaymentMethod.immagini.map(
		(immagineMetodo) => {
			return (
				<img
					key={immagineMetodo}
					src={`${BASENAME}/images/${immagineMetodo}`}
					width='90'
					height='63'
					className='mt-3 mr-2 w-20 lg:w-24'
					alt={currentPaymentMethod.name}
					title={currentPaymentMethod.name}
				/>
			);
		}
	);

	const onChangeNote = debounce(function (event) {
		console.log(event.target.value);
		setNote(event.target.value);

		// chiamo action creator per aggiornare store con le note
		dispatch(
			allActions.cartDetailActions.updateNotes(
				currentPage.currentLang,
				user?.userData?.id,
				tokenCarrello,
				event.target.value
			)
		);
	}, 2000);

	/// SUBMIT DA PULSANTE STRIPE, BONIFICO, RIBA E CONTRASSEGNO
	const onPaySubmit = async (event) => {
		event.preventDefault();

		///////////////////////////////////////////////
		////////// Pagamento con Stripe ///////////////
		///////////////////////////////////////////////
		if (
			currentPaymentMethod.id === 4 &&
			stripeClientSecret !== null &&
			user.userData.email &&
			isPaying === false
		) {
			if (!stripe || !elements) {
				// Stripe.js has not loaded yet. Make sure to disable
				// form submission until Stripe.js has loaded.
				return;
			}

			setIsPaying(true);

			// Get a reference to a mounted CardElement. Elements knows how
			// to find your CardElement because there can only ever be one of
			// each type of element.
			const cardElement = elements.getElement(CardElement);

			// Use your card Element with other Stripe.js APIs
			const { error, paymentIntent } = await stripe.confirmCardPayment(
				stripeClientSecret,
				{
					receipt_email: user.userData.email,
					payment_method: {
						card: cardElement,
					},
				}
			);

			// Controllo il risultato del pagamento da Stripe
			if (error) {
				// ho ricevuto errori, non posso proseguire
				setApiErrorMessage(error);
				setIsPaying(false);
			} else if (paymentIntent) {
				// ho ricevuto da Stripe evento payment.intent succedeed, controllo anche lo stato (per sicurezza bisognerà controllare il webhook payment_intent.succeeded successivamente!!!!)

				// chiamo action creator
				dispatch(
					allActions.checkoutDetailActions.stripeOk(
						currentPage.currentLang,
						user.token_id,
						user.userData.id,
						paymentIntent,
						note
					)
				);

				setApiErrorMessage(null);
				//console.log(paymentIntent);
			}
		}
		////////////////////////////////////////////////////
		////////// Fine Pagamento con Stripe ///////////////
		////////////////////////////////////////////////////

		//////////////////////////////////////////////////////////////////////
		////////// Pagamento con Bonifico, Riba o Contrassegno ///////////////
		//////////////////////////////////////////////////////////////////////
		if (
			(currentPaymentMethod.id === 1 ||
				currentPaymentMethod.id === 5 ||
				currentPaymentMethod.id === 3) &&
			isPaying === false
		) {
			setIsPaying(true);

			// chiamo action creator (bonifico)
			if (currentPaymentMethod.id === 3) {
				dispatch(
					allActions.checkoutDetailActions.bonificoOk(
						currentPage.currentLang,
						user.token_id,
						user.userData.id,
						note
					)
				);
			} else if (currentPaymentMethod.id === 5) {
				// riba
				dispatch(
					allActions.checkoutDetailActions.ribaOk(
						currentPage.currentLang,
						user.token_id,
						user.userData.id,
						note
					)
				);
			} else if (currentPaymentMethod.id === 1) {
				// contrassegno
				dispatch(
					allActions.checkoutDetailActions.contrassegnoOk(
						currentPage.currentLang,
						user.token_id,
						user.userData.id,
						note
					)
				);
			}
		}
	};

	/// SUBMIT DA PULSANTE PAYPAL
	const onPaypalSubmit = (resultPaypal) => {
		// chiamo action creator
		dispatch(
			allActions.checkoutDetailActions.paypalOk(
				currentPage.currentLang,
				user.token_id,
				user.userData.id,
				resultPaypal,
				note
			)
		);
	};

	// Stripe error
	const stripeError = apiErrorMessage !== null && apiErrorMessage.message;

	// StripeElement
	const showStripe = stripeClientSecret !== null && user.userData.email && (
		<div className='col-start-1 lg:col-start-31 col-end-61 mb-4 sm:mb-6 xl:mb-0 bg-ribola-gallery-gray px-8 py-6'>
			<span className='text-lg xl:text-xl text-black font-opensansbold mb-3'>
				{checkoutTranslation.inseriscinumerocartacredito.itemName}
			</span>
			<StripeElement />
			<span className='text-red-700'>{stripeError}</span>
		</div>
	);

	// mostro il box dell'indirizzo selezionato solamente se non ho il ritiro in sede (isNoShipping === false)
	const showBoxIndirizzoSelezionato =
		order.isNoShipping === false ? (
			<div className='col-span-60 sm:col-span-30 flex flex-col justify-between bg-ribola-gallery-gray text-black px-8 py-6 mb-4 xl:mb-0'>
				<span className='flex flex-col'>
					<span className='text-lg 2xl:text-xl font-opensansbold mb-3'>
						{spedizionePagamentoTranslation.titoloindirizzodiconsegna.itemName}
					</span>
					<span className='font-opensansbold'>
						{currentAddress.meta_data.nomes}&nbsp;
						{currentAddress.meta_data.cognomes}
					</span>
					<span>
						{currentAddress.meta_data.indirizzos}
						<br />
						{currentAddress.meta_data.cittas}, (
						{currentAddress.meta_data.provinciaText ||
							currentAddress.meta_data.provinciaesteras}
						)&nbsp;{currentAddress.meta_data.caps}
						<br />
						{currentAddress.meta_data.nazioneText}
						<br />
						{genericheTranslation.telefono.itemName}:&nbsp;
						{currentAddress.meta_data.telefonos}
					</span>
				</span>
				<span className='flex justify-end'>
					<CustomButton
						buttonLink={fasiCarrelloTranslation.spedizionePagamento.linkName}
						buttonRouteName={
							fasiCarrelloTranslation.spedizionePagamento.routeName
						}
						buttonName={genericheTranslation.modifica.itemName}
						buttonStyle='w-max flex justify-center items-center text-white bg-ribola-astral hover:bg-ribola-congress-blue transition duration-300 text-lg 2xl:text-xl font-opensanssemibold py-3 px-6'
					/>
				</span>
			</div>
		) : (
			<div className='col-span-60 sm:col-span-30 flex flex-col justify-between bg-ribola-gallery-gray text-black px-8 py-6 mb-4 xl:mb-0'>
				<span className='flex flex-col'>
					<span className='text-lg 2xl:text-xl font-opensansbold mb-3'>
						{spedizionePagamentoTranslation.titoloindirizzodiconsegna.itemName}
					</span>
					<span className='font-opensansbold'>
						{riepilogoCarrelloTranslation.ritiroinsede.itemName}
					</span>
				</span>
			</div>
		);

	return (
		<div className='col-start-3 4xl:col-start-5 col-end-59 4xl:col-end-57 pb-6 sm:pb-12'>
			<Briciole />
			<BarraCheckout currentCheckoutPage={currentPage.routeName} />
			<form onSubmit={onPaySubmit}>
				<div className='grid grid-cols-60 mt-12 sm:mt-20'>
					<div className='col-start-1 col-end-61 xl:col-end-45'>
						<div className='grid grid-cols-60 sm:gap-2 2xl:gap-4'>
							{showBoxIndirizzoSelezionato}
							<div className='col-span-60 sm:col-span-30 flex flex-col justify-between bg-ribola-gallery-gray text-black px-8 py-6 mb-4 xl:mb-0'>
								<span className='flex flex-col'>
									<span className='text-lg 2xl:text-xl font-opensansbold mb-3'>
										{
											spedizionePagamentoTranslation.titolometododipagamento
												.itemName
										}
									</span>
									<span className='font-opensansbold'>
										{currentPaymentMethod.name}
									</span>
									<span className='flex'>{elencoImmaginiMetodoPagamento}</span>
								</span>
								<span className='flex justify-end'>
									<CustomButton
										buttonLink={
											fasiCarrelloTranslation.spedizionePagamento.linkName
										}
										buttonRouteName={
											fasiCarrelloTranslation.spedizionePagamento.routeName
										}
										buttonName={genericheTranslation.modifica.itemName}
										buttonStyle='w-max flex justify-center items-center text-white bg-ribola-astral hover:bg-ribola-congress-blue transition duration-300 text-lg 2xl:text-xl font-opensanssemibold py-3 px-6 mt-3 sm:mt-0'
									/>
								</span>
							</div>
							<div className='col-span-60 sm:col-span-30 flex flex-col justify-between bg-ribola-gallery-gray text-black px-8 py-6 mb-4 xl:mb-0'>
								<TextareaField
									textareaLabel={
										campiFormTranslation.noterelativeordine.itemName
									}
									textareaLabelStyle='text-lg 2xl:text-xl text-black font-opensansbold mb-3'
									textareaName='note'
									textareaPlaceholder={
										campiFormTranslation.inserirenoterelativeordine.itemName
									}
									textareaValue={note}
									textareaRows={6}
									textareaStyle='border-none bg-white resize-none w-full mt-3 p-3'
									textareaHandlerFunction={(event) => onChangeNote(event)}
								/>
							</div>
							{showStripe}
						</div>
					</div>
					<div className='col-start-1 col-end-61 xl:col-start-46 xl:col-end-61 flex flex-col bg-ribola-gallery-gray py-12 px-8'>
						<RiepilogoCarrello
							isButtonSending={isPaying}
							isPaypal={isPaypal}
							onPaypalSubmit={onPaypalSubmit}
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default CheckoutDetail;
