import { BASENAME } from '../../../utils/basename';

const Img = ({ imgPath, imgWidth, imgHeight, imgStyle, imgAlt }) => {
	return (
		<img
			src={BASENAME + imgPath}
			width={imgWidth}
			height={imgHeight}
			className={imgStyle}
			alt={imgAlt}
		/>
	);
};

export default Img;
