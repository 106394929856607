import {
	AUTHENTICATE_USER,
	REGISTER_USER,
	LOGIN_USER,
	LOGOUT_USER,
	UPDATE_USER,
	FETCH_ADDRESSES_DETAIL,
	FETCH_ORDERS_DETAIL,
	FETCH_PAYMENT_DETAIL,
	UPDATE_ADDRESS,
	CREATE_ADDRESS,
	DELETE_ADDRESS,
	STRIPE_OK,
	PAYPAL_OK,
	BONIFICO_OK,
	RIBA_OK,
	CONTRASSEGNO_OK,
	ORDER_RESET,
} from '../actions/types';

const INITIAL_STATE = {
	user: {},
};

export const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case AUTHENTICATE_USER:
			return state;

		case REGISTER_USER:
		case LOGIN_USER:
		case UPDATE_USER:
			const { status, message, userData, isLogged, loginDate, token_id } =
				action.payload;

			if (action.payload.indirizzi) {
				state.indirizzi = action.payload.indirizzi;
			}

			state = {
				...state,
				userData,
				isLogged,
				status,
				message,
				loginDate,
				token_id,
			};

			return state;

		case FETCH_ADDRESSES_DETAIL:
		case FETCH_PAYMENT_DETAIL:
		case UPDATE_ADDRESS:
		case CREATE_ADDRESS:
		case DELETE_ADDRESS:
			const { indirizzi, message: messageIndirizzo } = action.payload;

			state = {
				...state,
				indirizzi,
				messageIndirizzo,
			};

			return state;

		case FETCH_ORDERS_DETAIL:
			const { ordini } = action.payload;

			state = {
				...state,
				ordini,
			};

			return state;

		case LOGOUT_USER:
			state.isLogged = false;
			state.userData = null;
			state.indirizzi = [];
			state.ordini = [];
			state.message = null;
			state.messageIndirizzo = null;
			state.status = null;
			state.loginDate = null;

			return state;

		case STRIPE_OK:
		case PAYPAL_OK:
		case BONIFICO_OK:
		case RIBA_OK:
		case CONTRASSEGNO_OK:
			state.token_id = action.payload.token_id;
			state.ordini = action.payload.ordini;

			return state;

		case ORDER_RESET:
			if (state.userData?.is_guest === 1) {
				state.isLogged = false;
				state.userData = null;
				state.indirizzi = [];
				state.ordini = [];
				state.message = null;
				state.messageIndirizzo = null;
				state.status = null;
				state.loginDate = null;
			}

			return state;

		default:
			return state;
	}
};
