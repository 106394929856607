import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import LazyLoadPicture from '../lazyLoadPicture/LazyLoadPicture';
import TitoloBlocco from '../titoloBlocco/TitoloBlocco';
import CustomButton from '../customButton/CustomButton';

const MagazineItem = ({
	name,
	sottotitolo,
	ImmagineElencoMagazine,
	routeName,
	linkSeo,
}) => {
	const paginestaticheTranslation = useSelector(
		(state) => state.translations.paginestatiche
	);

	useEffect(() => {
		console.log(ImmagineElencoMagazine);
	}, []);

	return (
		<div className='col-span-60 sm:col-span-20 px-3 sm:mx-6 magazineHomeItem spanServerSide'>
			<LazyLoadPicture
				imgName={`323x156-${ImmagineElencoMagazine.file_name}`}
				imgPath={`${ImmagineElencoMagazine.folder}/thumbs`}
				imgWidth='323'
				imgHeight='156'
				imgStyle='flex-start mb-6 minContent w-full'
				imgAlt={name}
			/>
			<TitoloBlocco
				titoloStyle='titolo font-opensanssemibold text-ribola-dove-gray text-lg flex mb-2 line-clamp-2'
				titolo={name.toUpperCase()}
			/>
			<TitoloBlocco
				titoloStyle='titolo text-ribola-dove-gray text-sm flex mb-6 line-clamp-2'
				titolo={sottotitolo}
			/>
			<CustomButton
				buttonRouteName={routeName}
				buttonLink={linkSeo}
				buttonName={paginestaticheTranslation.scopridipiu.itemName.toUpperCase()}
				buttonStyle='buttonCongressBlue'
			/>
		</div>
	);
};

export default MagazineItem;
