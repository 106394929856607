import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CustomButton from '../../shared/customButton/CustomButton';

const BarraCheckout = ({ currentCheckoutPage }) => {
	const fasiCarrelloItems = useSelector(
		(state) => state.translations.fasiCarrello
	);

	useEffect(() => {
		/* console.log(currentCheckoutPage);
		console.log(currentCheckoutPage === 'Login Registrazione'); */
	}, []);

	const cartSelected =
		currentCheckoutPage === 'cartDetail'
			? 'col-span-30 lg:col-span-15'
			: 'col-span-10 lg:col-span-15';
	const cartSelectedBigCircle =
		currentCheckoutPage === 'cartDetail'
			? 'w-12 2xl:w-14 3xl:w-16 h-12 2xl:h-14 3xl:h-16 bg-ribola-congress-blue text-white'
			: 'w-10 2xl:w-12 3xl:w-14 h-10 2xl:h-12 3xl:h-14 bg-white';
	const cartSelectedBg = 'ribola-congress-blue';

	const registrationSelected =
		currentCheckoutPage === 'registrationDetail'
			? 'col-span-30 lg:col-span-15'
			: 'col-span-10 lg:col-span-15';
	const registrationSelectedBigCircle =
		currentCheckoutPage === 'registrationDetail'
			? 'w-12 2xl:w-14 3xl:w-16 h-12 2xl:h-14 3xl:h-16 bg-ribola-congress-blue text-white'
			: 'w-10 2xl:w-12 3xl:w-14 h-10 2xl:h-12 3xl:h-14 bg-white';
	const registrationSelectedBg =
		currentCheckoutPage === 'registrationDetail' ||
		currentCheckoutPage === 'paymentDetail' ||
		currentCheckoutPage === 'checkoutDetail' ||
		currentCheckoutPage === 'orderClosedDetail'
			? 'ribola-congress-blue'
			: 'ribola-nobel';

	const paymentSelected =
		currentCheckoutPage === 'paymentDetail'
			? 'selected col-span-30 lg:col-span-15'
			: 'col-span-10 lg:col-span-15';
	const paymentSelectedBigCircle =
		currentCheckoutPage === 'paymentDetail'
			? 'w-12 2xl:w-14 3xl:w-16 h-12 2xl:h-14 3xl:h-16 bg-ribola-congress-blue text-white'
			: 'w-10 2xl:w-12 3xl:w-14 h-10 2xl:h-12 3xl:h-14 bg-white';
	const paymentSelectedBg =
		currentCheckoutPage === 'paymentDetail' ||
		currentCheckoutPage === 'checkoutDetail' ||
		currentCheckoutPage === 'orderClosedDetail'
			? 'ribola-congress-blue'
			: 'ribola-nobel';

	const checkoutSelected =
		currentCheckoutPage === 'checkoutDetail' ||
		currentCheckoutPage === 'orderClosedDetail'
			? 'selected col-span-30 lg:col-span-15'
			: 'col-span-10 lg:col-span-15';
	const checkoutSelectedBigCircle =
		currentCheckoutPage === 'checkoutDetail' ||
		currentCheckoutPage === 'orderClosedDetail'
			? 'w-12 2xl:w-14 3xl:w-16 h-12 2xl:h-14 3xl:h-16 bg-ribola-congress-blue text-white'
			: 'w-10 2xl:w-12 3xl:w-14 h-10 2xl:h-12 3xl:h-14 bg-white';
	const checkoutSelectedBg =
		currentCheckoutPage === 'checkoutDetail' ||
		currentCheckoutPage === 'orderClosedDetail'
			? 'ribola-congress-blue'
			: 'ribola-nobel';

	/// NASCONDO TITOLI AD UNA CERTA RISOLUZIONE
	const showTitoloCarrelloMobile =
		currentCheckoutPage === 'cartDetail' ? '' : 'hidden lg:flex';
	const showTitoloRegistrazioneMobile =
		currentCheckoutPage === 'registrationDetail' ? '' : 'hidden lg:flex';
	const showTitoloSpedizioneMobile =
		currentCheckoutPage === 'paymentDetail' ? '' : 'hidden lg:flex';
	const showTitoloConcludiMobile =
		currentCheckoutPage === 'checkoutDetail' ||
		currentCheckoutPage === 'orderClosedDetail'
			? ''
			: 'hidden lg:flex';

	return (
		<div className='grid grid-cols-60 mt-12'>
			<div
				className={`flex flex-col justify-center relative selected ${cartSelected}`}>
				<span className={`absolute w-full h-1 z-0 bg-${cartSelectedBg}`}></span>
				<span
					className={`absolute flex justify-center items-center ${cartSelectedBigCircle} border-4 border-${cartSelectedBg} rounded-full z-10 font-poppinssemibold text-xl ml-6 sm:ml-10 lg:ml-2 2xl:ml-4 3xl:ml-10 4xl:ml-20 pt-1`}>
					1
				</span>
				<CustomButton
					buttonLink={fasiCarrelloItems.carrello.linkName}
					buttonName={fasiCarrelloItems.carrello.itemName.toUpperCase()}
					buttonRouteName={fasiCarrelloItems.carrello.routeName}
					buttonStyle={`font-opensanssemibold text-sm 2xl:text-base 3xl:text-lg text-ribola-dove-gray ml-20 sm:ml-28 lg:ml-14 2xl:ml-20 3xl:ml-28 4xl:ml-40 -mt-12 sm:-mt-10 ${showTitoloCarrelloMobile}`}
				/>
			</div>
			<div
				className={`flex flex-col justify-center relative ${registrationSelected}`}>
				<span
					className={`absolute w-full h-1 z-0 bg-${registrationSelectedBg}`}></span>
				<span
					className={`absolute flex justify-center items-center ${registrationSelectedBigCircle} border-4 border-${registrationSelectedBg} rounded-full z-10 font-poppinssemibold text-xl ml-6 sm:ml-10 lg:ml-2 2xl:ml-4 3xl:ml-10 4xl:ml-20 pt-1`}>
					2
				</span>
				<span
					className={`font-opensanssemibold text-xs sm:text-sm 2xl:text-base 3xl:text-lg text-ribola-dove-gray ml-20 sm:ml-28 lg:ml-14 2xl:ml-20 3xl:ml-28 4xl:ml-40 -mt-12 sm:-mt-10 ${showTitoloRegistrazioneMobile}`}>
					{fasiCarrelloItems.registrazione.itemName.toUpperCase()}
				</span>
			</div>
			<div
				className={`flex flex-col justify-center relative ${paymentSelected}`}>
				<span
					className={`absolute w-full h-1 z-0 bg-${paymentSelectedBg}`}></span>
				<span
					className={`absolute flex justify-center items-center ${paymentSelectedBigCircle} border-4 border-${paymentSelectedBg} rounded-full z-10 font-poppinssemibold text-xl ml-6 sm:ml-10 lg:ml-2 2xl:ml-4 3xl:ml-10 4xl:ml-20 pt-1`}>
					3
				</span>
				<span
					className={`font-opensanssemibold text-xs sm:text-sm 2xl:text-base 3xl:text-lg text-ribola-dove-gray ml-20 sm:ml-28 lg:ml-14 2xl:ml-20 3xl:ml-28 4xl:ml-40 -mt-12 sm:-mt-10 ${showTitoloSpedizioneMobile}`}>
					{fasiCarrelloItems.spedizionePagamento.itemName.toUpperCase()}
				</span>
			</div>
			<div
				className={`flex flex-col justify-center relative ${checkoutSelected}`}>
				<span
					className={`absolute w-full h-1 z-0 bg-${checkoutSelectedBg}`}></span>
				<span
					className={`absolute flex justify-center items-center ${checkoutSelectedBigCircle} border-4 border-${checkoutSelectedBg} rounded-full z-10 font-poppinssemibold text-xl ml-6 sm:ml-10 lg:ml-2 2xl:ml-4 3xl:ml-10 4xl:ml-20 pt-1`}>
					4
				</span>
				<span
					className={`font-opensanssemibold text-xs sm:text-sm 2xl:text-base 3xl:text-lg text-ribola-dove-gray ml-20 sm:ml-28 lg:ml-14 2xl:ml-20 3xl:ml-28 4xl:ml-40 -mt-12 sm:-mt-10 ${showTitoloConcludiMobile}`}
					dangerouslySetInnerHTML={{
						__html: fasiCarrelloItems.concludiOrdineFase.itemName.toUpperCase(),
					}}
				/>
			</div>
		</div>
	);
};

export default BarraCheckout;
