import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { BASENAME } from '../../../utils/basename';

const BloccoIconeFooter = () => {
	const vociIconeTranslation = useSelector(
		(state) => state.translations.iconeMenuLeft
	);

	return (
		<Fragment>
			<div className='flex items-center mr-4 mb-2 sm:mb-4'>
				<span className='font-opensanssemibold text-ribola-congress-blue mr-2 text-lg'>
					{vociIconeTranslation.resoGratuito.itemName.toUpperCase()}
				</span>
				<div className='w-8 h-auto flex justify-center items-center'>
					<img
						src={BASENAME + '/images/reso_gratuito.svg'}
						width='79'
						height='78'
						className='flex-start p-1 sm:p-0'
						alt={vociIconeTranslation.resoGratuito.itemName}
					/>
				</div>
			</div>
			<div className='flex items-center'>
				<span className='font-opensanssemibold text-ribola-congress-blue mr-2 text-lg'>
					{vociIconeTranslation.assistenza.itemName.toUpperCase()}
				</span>
				<div className='w-8 h-auto flex justify-center items-center'>
					<img
						src={BASENAME + '/images/assistenza.svg'}
						width='80'
						height='70'
						className='flex-start p-1 sm:p-0'
						alt={vociIconeTranslation.assistenza.itemName}
					/>
				</div>
			</div>
		</Fragment>
	);
};

export default BloccoIconeFooter;
