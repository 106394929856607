import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const PulsanteCalcolaDimensioni = ({ onCalcolaClick }) => {
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	useEffect(() => {}, []);

	return (
		<button
			onClick={onCalcolaClick}
			className='bg-ribola-astral text-white active:bg-ribola-congress-blue font-opensanssemibold text-xl hover:shadow-md outline-none focus:outline-none ease-linear transition-all duration-150 w-64 px-8 py-3 mt-6'
			type='button'>
			<span className='w-full items-center justify-center'>
				{genericheTranslation.calcola.itemName.toUpperCase()}
			</span>
		</button>
	);
};

export default PulsanteCalcolaDimensioni;
