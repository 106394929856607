import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import { sortArray } from '../../utils/sortArray';
import {
	Accordion,
	AccordionItem,
	AccordionItemButton,
	AccordionItemHeading,
	AccordionItemPanel,
} from 'react-accessible-accordion';
import Briciole from '../shared/briciole/Briciole';

const CondizioniVenditaDetail = () => {
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const siteParams = useSelector((state) => state.siteParams);
	const paginestaticheTranslation = useSelector(
		(state) => state.translations.paginestatiche
	);
	const condizioniVenditaTranslation = useSelector(
		(state) => state.translations.condizioniVendita
	);

	// local state

	useEffect(() => {}, []);

	// controllo che esista la proprietà detail (altrimenti vuol dire che sto ancora recuperando i dati)
	if (!pageContent.detail) {
		return <div></div>;
	}

	// riordino le condizioni
	const titoliAccordion = Object.keys(condizioniVenditaTranslation)
		.map((item) => {
			if (item.includes('descrizione')) {
				return null;
			}
			let itemExploded = item.split('_');
			return parseInt(itemExploded[0]);
		})
		.filter((item) => item !== null);

	const titoliAccordionSorted = titoliAccordion.sort(sortArray);

	const accordionElementsFirst = titoliAccordionSorted.map((item) => {
		const titoloIndex = item + '_titolo';
		const itemRow = condizioniVenditaTranslation[titoloIndex];
		const descrizioneIndex = item + '_descrizione';
		const itemRowDescription = condizioniVenditaTranslation[descrizioneIndex];
		/* console.log(itemRowDescription); */

		const itemToShow = (
			<AccordionItem key={_uniqueId('row_')}>
				<AccordionItemHeading>
					<AccordionItemButton>
						<span className='w-full flex items-center justify-between focus:outline-none bg-ribola-astral hover:bg-ribola-congress-blue text-white transition-all duration-200 ease-in-out px-6 py-4 mb-2'>
							<span
								className='text-left font-opensanssemibold text-lg'
								dangerouslySetInnerHTML={{
									__html: itemRow?.itemName,
								}}
							/>
							<i className='fal fa-long-arrow-down text-2xl'></i>
						</span>
					</AccordionItemButton>
				</AccordionItemHeading>
				<AccordionItemPanel>
					<Accordion allowZeroExpanded>
						<div
							className='text-sm px-6 pt-4 pb-8 mb-2'
							dangerouslySetInnerHTML={{
								__html: itemRowDescription?.itemName,
							}}
						/>
					</Accordion>
				</AccordionItemPanel>
			</AccordionItem>
		);
		return itemToShow;
	});

	return (
		<div className='col-start-1 lg:col-start-15 4xl:col-start-16 col-end-61 3xl:col-end-59 4xl:col-end-57 sm:pb-12'>
			<Briciole />
			<div className='grid grid-cols-60 mt-0 sm:mt-3 xl:mt-6'>
				<div className='col-span-60 flex justify-between items-center px-8 xl:px-12 3xl:px-20 xl:pt-8 pb-6 sm:pb-8 xl:pb-12 3xl:py-14'>
					<h1 className='text-ribola-congress-blue text-3xl md:text-4xl xl:text-5xl font-opensansbold'>
						<small className='leading-tight flex'>
							{paginestaticheTranslation.titolocondizionivendita.itemName.toUpperCase()}
						</small>
					</h1>
				</div>
				<div className='col-span-60 text-sm sm:text-base px-8 xl:px-12 3xl:px-20 pb-8 xl:pb-12'>
					<p
						dangerouslySetInnerHTML={{
							__html: paginestaticheTranslation.testocondizionivendita.itemName,
						}}></p>
					<br />
					<span className='font-opensansbold'>{siteParams.ragioneSociale}</span>
					<br />
					<span
						dangerouslySetInnerHTML={{
							__html: siteParams.sedeLegaleIndirizzo,
						}}
					/>
					<br />
					<br />
					<span className='mr-3'>
						<span className='font-opensansbold'>Tel:</span>&nbsp;
						{siteParams.telefono}
					</span>
					<span className='mr-3'>
						<span className='font-opensansbold'>Fax:</span>&nbsp;
						{siteParams.fax}
					</span>
					<span className='mr-3'>
						<span className='font-opensansbold'>Email:</span>&nbsp;
						{siteParams.email}
					</span>
				</div>
				<div className='col-span-60 px-8 xl:px-12 3xl:px-20 pb-8 xl:pb-12'>
					<Accordion allowZeroExpanded>{accordionElementsFirst}</Accordion>
				</div>
			</div>
		</div>
	);
};

export default CondizioniVenditaDetail;
