import isFetchingAction from './isFetchingAction';
import changePageActions from './changePageActions';
import homepageActions from './homepageActions';
import netsDetailActions from './netsDetailActions';
import parentCategoryDetailActions from './parentCategoryDetailActions';
import categoryDetailActions from './categoryDetailActions';
import productDetailActions from './productDetailActions';
import cartDetailActions from './cartDetailActions';
import registrationDetailActions from './registrationDetailActions';
import paymentDetailActions from './paymentDetailActions';
import checkoutDetailActions from './checkoutDetailActions';
import profileDetailActions from './profileDetailActions';
import userActions from './userActions';
import staticDetailActions from './staticDetailActions';
import magazineDetailActions from './magazineDetailActions';

const allActions = {
	isFetchingAction,
	changePageActions,
	homepageActions,
	netsDetailActions,
	parentCategoryDetailActions,
	categoryDetailActions,
	productDetailActions,
	cartDetailActions,
	registrationDetailActions,
	paymentDetailActions,
	checkoutDetailActions,
	profileDetailActions,
	userActions,
	staticDetailActions,
	magazineDetailActions,
};

export default allActions;
