import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Briciole from '../../shared/briciole/Briciole';
import OrderRow from './orderRow/OrderRow';

const OrdersDetail = () => {
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const orders = useSelector((state) => state.user.ordini);
	const menuProfiloItems = useSelector(
		(state) => state.translations.menuLeftProfilo
	);
	const gestioneOrdiniTranslation = useSelector(
		(state) => state.translations.ordini
	);

	useEffect(() => {
		console.log('aaa', orders);
	}, [orders]);

	// controllo che esista la proprietà detail (altrimenti vuol dire che sto ancora recuperando i dati)
	if (!pageContent.detail || orders === null) {
		return <div></div>;
	}

	const orderRows = Object.keys(orders).map((orderIndex) => {
		const orderRow = orders[orderIndex];
		return (
			<OrderRow
				key={orderIndex}
				orderId={orderRow.id}
				orderIdFormatted={orderRow.orderId}
				orderDate={orderRow.orderDate}
				orderState={orderRow.orderState}
				orderStateColor={orderRow.orderStateColor}
				paymentMethod={orderRow.paymentMethod}
				tracking={orderRow.tracking}
				dettaglioPdf={orderRow.ricevuta_pdf}
			/>
		);
	});

	return (
		<div
			className={`col-start-1 lg:col-start-15 4xl:col-start-16 col-end-61 3xl:col-end-59 4xl:col-end-57 pb-6 sm:pb-12`}>
			<Briciole />
			<div className='grid grid-cols-60 mt-0 sm:mt-3 xl:mt-6'>
				<div className='col-span-60 flex flex-col justify-center px-8 xl:px-12 3xl:px-20 xl:pt-8 pb-6 sm:pb-8 xl:pb-12 3xl:py-14'>
					<h1 className='text-ribola-congress-blue text-3xl md:text-4xl xl:text-5xl font-opensansbold'>
						<small className='leading-tight flex'>
							{menuProfiloItems.ordiniProfilo.itemName.toUpperCase()}
						</small>
					</h1>
					<h2 className='text-lg md:text-xl mt-3'>
						<span className='leading-tight flex'>
							{gestioneOrdiniTranslation.sottotitoloelencoordini.itemName}
						</span>
					</h2>
				</div>
			</div>
			<div className='px-8 xl:pl-12 3xl:pl-20'>
				<div className='grid grid-cols-60 sm:gap-2 2xl:gap-4 font-opensansbold text-ribola-tundora border-b border-ribola-dove-gray text-sm 2xl:text-base pb-2'>
					<div className='col-span-22 sm:col-span-12 md:col-span-10 flex items-center'>
						<span>
							{gestioneOrdiniTranslation.thDataOrdine.itemName.toUpperCase()}
						</span>
					</div>
					<div className='col-span-16 sm:col-span-12 md:col-span-10 flex items-center'>
						<span>
							{gestioneOrdiniTranslation.thNumeroOrdine.itemName.toUpperCase()}
						</span>
					</div>
					<div className='col-span-22 sm:col-span-12 md:col-span-10 flex items-center'>
						<span>
							{gestioneOrdiniTranslation.thMetodoPagamento.itemName.toUpperCase()}
						</span>
					</div>
					<div className='col-span-12 md:col-span-10 items-center hidden sm:flex'>
						<span>
							{gestioneOrdiniTranslation.thRicevutaPdf.itemName.toUpperCase()}
						</span>
					</div>
					<div className='col-span-12 md:col-span-10 items-center hidden sm:flex'>
						<span>
							{gestioneOrdiniTranslation.thStatoOrdine.itemName.toUpperCase()}
						</span>
					</div>
					<div className='col-span-10 items-center hidden md:flex'>
						<span>
							{gestioneOrdiniTranslation.thTracking.itemName.toUpperCase()}
						</span>
					</div>
				</div>
			</div>
			<div className='px-8 xl:pl-12 3xl:pl-20 text-sm 2xl:text-base'>
				{orderRows}
			</div>
		</div>
	);
};

export default OrdersDetail;
