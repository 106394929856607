import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import SpinnerLoading from '../../../shared/spinnerLoading/SpinnerLoading';
import allActions from '../../../../state/actions';

const PaypalButtons = ({ onPaypalSubmit }) => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const order = useSelector((state) => state.order);
	const [{ isPending }] = usePayPalScriptReducer();

	useEffect(() => {}, []);

	const showButtons = order?.totalCart !== null && user.token_id !== null && (
		<PayPalButtons
			style={{ layout: 'horizontal', label: 'buynow' }}
			createOrder={(data, actions) => {
				return actions.order.create({
					purchase_units: [
						{
							reference_id: `${user.token_id}_cart_items`,
							amount: {
								value: order.totalCart.toFixed(2),
							},
						},
					],
				});
			}}
			onApprove={(data, actions) => {
				return actions.order.capture().then(function (details) {
					//console.log(details);
					//alert('Transaction completed by ' + details.payer.name.given_name);
					onPaypalSubmit(details);
				});
			}}
		/>
	);

	return (
		<Fragment>
			{isPending ? <SpinnerLoading /> : null}
			{showButtons}
		</Fragment>
	);
};

export default PaypalButtons;
