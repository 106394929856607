import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import CheckoutDetail from '../../components/cart/checkoutDetail/CheckoutDetail';

const Checkout = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const staticPages = useSelector((state) => state.staticPages);
	const user = useSelector((state) => state.user);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// controllo nello store se ho già il dettaglio della pagina di concludi ordine (checkout), altrimenti eseguo action creator
		const checkoutPage = staticPages.checkout;

		if (checkoutPage === undefined || checkoutPage.detail === undefined) {
			// non ho i dati del prodotto nello store, chiamo action creator fetchCheckoutDetail
			setTimeout(
				() =>
					dispatch(
						allActions.checkoutDetailActions.fetchCheckoutDetail(
							currentPage.currentLang,
							user.token_id,
							user.userData.id
						)
					),
				50
			);
		} else {
			// loading...
			dispatch(allActions.isFetchingAction.loadStoredData());

			// recupero dati da store
			dispatch(
				allActions.checkoutDetailActions.loadCheckoutDetail(checkoutPage)
			);
		}

		//console.log(checkoutPage);
	}, [location]);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<CheckoutDetail />
				</div>
			</div>
		</Layout>
	);
};

export default Checkout;
