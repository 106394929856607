import { Route } from 'react-router-dom';
import ParentCategory from '../pages/parentCategory/ParentCategory';

export default (netsRoute) => {
	return [
		<Route
			exact
			path={`/${netsRoute}/:parentCategory/`}
			component={ParentCategory}
			key={`${netsRoute}parentCategoryRoute`}
		/>,
	];
};
