import axios from '../../utils/axiosBase';
import {
	FETCH_REGISTRATION_DETAIL,
	FETCH_REGISTRATION_FORM_DETAIL,
	LOAD_REGISTRATION_DETAIL,
	LOAD_REGISTRATION_FORM_DETAIL,
	REGISTER_USER,
} from './types';

const fetchRegistrationDetail =
	(currentLang, userId, tokenCarrello, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			tokenCarrello: tokenCarrello,
		};

		// chiamo web service
		const response = await axios.post(`api/registrationdetail/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_REGISTRATION_DETAIL,
			payload: response.data,
		});
	};

const fetchRegistrationFormDetail =
	(currentLang, userId, tokenCarrello, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			tokenCarrello: tokenCarrello,
		};

		// chiamo web service
		const response = await axios.post(`api/registrationformdetail/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_REGISTRATION_FORM_DETAIL,
			payload: response.data,
		});
	};

const loadRegistrationDetail =
	(registrationLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_REGISTRATION_DETAIL,
			payload: {
				registrationLoaded,
			},
		});
	};

const loadRegistrationFormDetail =
	(registrationFormLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_REGISTRATION_FORM_DETAIL,
			payload: {
				registrationFormLoaded,
			},
		});
	};

const registerUser =
	(currentLang, tokenCarrello, userData, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			tokenCarrello: tokenCarrello,
			user: userData,
		};

		// chiamo web service
		const response = await axios.post(`register/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: REGISTER_USER,
			payload: response.data,
		});
	};

export default {
	fetchRegistrationDetail,
	fetchRegistrationFormDetail,
	loadRegistrationDetail,
	loadRegistrationFormDetail,
	registerUser,
};
