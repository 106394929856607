import { BASENAME } from '../../../utils/basename';

const ImgPicture = ({
	imgName,
	imgPath,
	imgRoute,
	imgType,
	imgWidth,
	imgHeight,
	imgStyle,
	imgAlt,
	imgLink = null,
	imgFolder,
}) => {
	// source responsive
	const fullUrl = (stepKey) => {
		if (imgRoute) {
			return (
				BASENAME +
				'/' +
				imgRoute +
				'/' +
				imgType +
				'_' +
				imgWidth[stepKey] +
				'x' +
				imgHeight[stepKey] +
				'x' +
				imgPath +
				'_' +
				imgName
			);
		} else {
			if (imgWidth[stepKey] === undefined) {
				return BASENAME + '/storage/' + imgPath + '/' + imgName;
			} else {
				// solo per l'homepage i vari source li recupero da imgFolder
				if (imgFolder) {
					return (
						BASENAME +
						'/storage/' +
						imgFolder[stepKey].folder +
						'/thumbs/' +
						imgWidth[stepKey] +
						'x' +
						imgHeight[stepKey] +
						'-' +
						imgFolder[stepKey].file_name
					);
				} else {
					return (
						BASENAME +
						'/storage/' +
						imgPath +
						'/thumbs/' +
						imgWidth[stepKey] +
						'x' +
						imgHeight[stepKey] +
						'-' +
						imgName
					);
				}
			}
		}
	};

	const source4xl = imgWidth.xl4 ? (
		<source media='(min-width: 1920px)' srcSet={fullUrl('xl4')} />
	) : (
		''
	);

	const source3xl = imgWidth.xl3 ? (
		<source media='(min-width: 1620px)' srcSet={fullUrl('xl3')} />
	) : (
		''
	);

	const source2xl = imgWidth.xl2 ? (
		<source media='(min-width: 1536px)' srcSet={fullUrl('xl2')} />
	) : (
		''
	);

	const sourcexl = imgWidth.xl ? (
		<source media='(min-width: 1280px)' srcSet={fullUrl('xl')} />
	) : (
		''
	);

	const sourcelg = imgWidth.lg ? (
		<source media='(min-width: 1024px)' srcSet={fullUrl('lg')} />
	) : (
		''
	);

	const sourcemd = imgWidth.md ? (
		<source media='(min-width: 768px)' srcSet={fullUrl('md')} />
	) : (
		''
	);

	const sourcesm = imgWidth.sm ? (
		<source media='(min-width: 640px)' srcSet={fullUrl('sm')} />
	) : (
		''
	);

	const sourcexs = imgWidth.xs ? (
		<source media='(min-width: 480px)' srcSet={fullUrl('xs')} />
	) : (
		''
	);

	const sourcexxs = imgWidth.xxs ? (
		<source media='(min-width: 0px)' srcSet={fullUrl('xxs')} />
	) : (
		''
	);

	// link ?
	const immagine = imgLink ? (
		<a href={imgLink} className='flex w-full' target='_self'>
			<picture className='flex w-full overflow-hidden'>
				{source4xl}
				{source3xl}
				{source2xl}
				{sourcexl}
				{sourcelg}
				{sourcemd}
				{sourcesm}
				{sourcexs}
				{sourcexxs}
				<img
					src={fullUrl('xl4')}
					width={imgWidth['xl4']}
					height={imgHeight['xl4']}
					className={imgStyle}
					alt={imgAlt ? imgAlt : ''}
				/>
			</picture>
		</a>
	) : (
		<picture className='flex w-full overflow-hidden'>
			{source4xl}
			{source3xl}
			{source2xl}
			{sourcexl}
			{sourcelg}
			{sourcemd}
			{sourcesm}
			{sourcexs}
			{sourcexxs}
			<img
				src={fullUrl('xl4')}
				width={imgWidth['xl4']}
				height={imgHeight['xl4']}
				className={imgStyle}
				alt={imgAlt ? imgAlt : ''}
			/>
		</picture>
	);

	return immagine;
};

export default ImgPicture;
