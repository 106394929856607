import {
	FETCH_HOMEPAGE,
	REGISTER_USER,
	LOGIN_USER,
	LOGOUT_USER,
} from '../actions/types';

const INITIAL_STATE = [];

export const productsBestSoldHomeReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_HOMEPAGE:
			const { homepage } = action.payload;

			homepage.productsBestSoldHome.forEach((product, key) => {
				state[key] = product;
			});

			return state;

		case REGISTER_USER:
		case LOGIN_USER:
		case LOGOUT_USER:
			state = [];

			return state;

		default:
			return state;
	}
};
