import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import Briciole from '../shared/briciole/Briciole';
import CustomButton from '../shared/customButton/CustomButton';

const Error404Detail = () => {
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const siteParams = useSelector((state) => state.siteParams);
	const paginestaticheTranslation = useSelector(
		(state) => state.translations.paginestatiche
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	useEffect(() => {}, []);

	// controllo che esista la proprietà detail (altrimenti vuol dire che sto ancora recuperando i dati)
	if (!pageContent.detail) {
		return <div></div>;
	}

	return (
		<div className='col-start-1 lg:col-start-15 4xl:col-start-16 col-end-61 3xl:col-end-59 4xl:col-end-57 sm:pb-12'>
			<Briciole />
			<div className='grid grid-cols-60 mt-0 sm:mt-3 xl:mt-6'>
				<div className='col-span-60 flex justify-between items-center px-8 xl:px-12 3xl:px-20 xl:pt-8 pb-6 sm:pb-8 xl:pb-12 3xl:py-14'>
					<h1 className='text-ribola-congress-blue text-3xl md:text-4xl xl:text-5xl font-opensansbold'>
						<small className='leading-tight flex'>
							{paginestaticheTranslation.titolo404.itemName.toUpperCase()}
						</small>
					</h1>
				</div>
				<div className='col-span-60 px-8 xl:px-12 3xl:px-20 pb-8 xl:pb-12'>
					<p
						className='text-sm'
						dangerouslySetInnerHTML={{
							__html: paginestaticheTranslation.testo404.itemName,
						}}></p>

					<CustomButton
						buttonLink='/'
						buttonRouteName='homepageDetail'
						buttonName={genericheTranslation.tornahomepage.itemName.toUpperCase()}
						buttonStyle='w-full max-w-xs flex justify-center items-center text-white bg-ribola-amber hover:bg-ribola-web-orange transition duration-300 text-lg 2xl:text-xl font-opensanssemibold py-3 px-4 mt-8'
					/>
				</div>
			</div>
		</div>
	);
};

export default Error404Detail;
