import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const PulsanteAggiungiKit = ({
	idKit = 0,
	onKitHandler = () => {},
	isDisabled = true,
	isKitAdded = false,
	isKitRemoved = false,
	isKitShown = false,
	buttonStyle = '',
	spanStyle = '',
}) => {
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	useEffect(() => {}, []);

	const isButtonDisabled = isDisabled
		? 'disabled:opacity-50 pointer-events-none'
		: '';

	const isRemoveKit = isKitAdded ? (
		<i className='fal fa-trash-alt text-base'></i>
	) : (
		<i className='fal fa-search text-base ml-3'></i>
	);

	const buttonStyled =
		buttonStyle !== ''
			? buttonStyle
			: 'text-white active:bg-ribola-tundora rounded-md px-2 py-2';
	const spanStyled = spanStyle !== '' ? spanStyle : 'text-xs';

	const isButtonMessage = isKitRemoved ? (
		<span className={`w-full items-center justify-center ${spanStyled}`}>
			{genericheTranslation.chiudi.itemName.toUpperCase()}
		</span>
	) : (
		<span className={`w-full items-center justify-center ${spanStyled}`}>
			{genericheTranslation.aggiungikit.itemName.toUpperCase()}
			<i className='fal fa-plus text-base ml-3'></i>
		</span>
	);

	const addOrShowKit = isKitShown ? (
		isButtonMessage
	) : (
		<span className={`w-full items-center justify-center ${spanStyled}`}>
			{isKitAdded ? '' : genericheTranslation.mostrakit.itemName}
			{isRemoveKit}
		</span>
	);

	const bgButton = isKitAdded ? 'bg-red-700' : 'bg-ribola-quicksand';

	return (
		<button
			onClick={() => onKitHandler(idKit)}
			disabled={isDisabled}
			className={`font-opensanssemibold outline-none focus:outline-none ease-linear transition-all duration-150 ${buttonStyled} ${bgButton} ${isButtonDisabled}`}
			type='button'>
			{addOrShowKit}
		</button>
	);
};

export default PulsanteAggiungiKit;
