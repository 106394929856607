import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import MenuLeft from '../../components/shared/menuLeft/MenuLeft';
import CookiesPolicyDetail from '../../components/cookiesPolicyDetail/CookiesPolicyDetail';

const CookiesPolicy = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const staticPages = useSelector((state) => state.staticPages);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// controllo nello store se ho già il dettaglio di cookies policy, altrimenti eseguo action creator
		const cookiesPolicyPage = staticPages.cookiespolicy;

		if (cookiesPolicyPage) {
			// setto i metatags
			document.title = cookiesPolicyPage.metaTitle;
			document.getElementsByTagName('META')[2].content =
				cookiesPolicyPage.metaDesc;
		}

		if (
			cookiesPolicyPage === undefined ||
			cookiesPolicyPage.detail === undefined
		) {
			// non ho i dati del prodotto nello store, chiamo action creator fetchCookiesPolicyDetail
			setTimeout(
				() =>
					dispatch(
						allActions.staticDetailActions.fetchCookiesPolicyDetail(
							currentPage.currentLang,
							user?.userData?.id
						)
					),
				50
			);
		} else {
			// loading...
			dispatch(allActions.isFetchingAction.loadStoredData());

			// recupero dati da store
			dispatch(
				allActions.staticDetailActions.loadCookiesPolicyDetail(
					cookiesPolicyPage
				)
			);
		}

		//console.log(cookiesPolicyPage);
	}, [location, staticPages]);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<MenuLeft />
					<CookiesPolicyDetail />
				</div>
			</div>
		</Layout>
	);
};

export default CookiesPolicy;
