import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from '../../../utils/axiosBase';
import allActions from '../../../state/actions';
import Briciole from '../../shared/briciole/Briciole';
import InputField from '../../shared/inputField/InputField';
import CustomButton from '../../shared/customButton/CustomButton';
import BarraCheckout from '../barraCheckout/BarraCheckout';
import RiepilogoCarrello from '../riepilogoCarrello/RiepilogoCarrello';
import ModalConfirm from '../../shared/modalConfirm/ModalConfirm';
import { gtmCheckoutLoginRegistrazione } from '../../../gtm/gtm';

const RegistrationDetail = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm({
		mode: 'onChange',
	});
	const user = useSelector((state) => state.user);
	const order = useSelector((state) => state.order);
	const cart = useSelector((state) => state.cart);
	const currentPage = useSelector((state) => state.currentPage);
	const siteParams = useSelector((state) => state.siteParams);
	const loginRegistrazioneTranslation = useSelector(
		(state) => state.translations.loginRegistrazione
	);
	const erroriFormTranslation = useSelector(
		(state) => state.translations.erroriForm
	);
	const routeTranslation = useSelector(
		(state) => state.translations.routesTranslated
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);
	const campiFormTranslation = useSelector(
		(state) => state.translations.campiFormRegistrazione
	);

	const suffissoLang = currentPage.currentLang.replace('_', '');

	// local state
	const [isLogging, setIsLogging] = useState(false);

	const MySwal = withReactContent(Swal);

	useEffect(() => {
		if (isLogging === true && user.status === 'OK') {
			ModalConfirm({
				titleToShow: 'Login',
				messageToShow: genericheTranslation.modalmessaggiologinok.itemName,
				isShowConfirmButton: false,
				isShowCanceButton: false,
				isTimerModal: true,
				onClickHandler: onGoToNextPage,
			});

			/**
			 * GTM CHECKOUT LOGIN REGISTRAZIONE - STEP 1
			 */
			const gtmSuffix = `gtm_code${currentPage.currentLang}`;
			const currentGTMTAG = siteParams[gtmSuffix];
			gtmCheckoutLoginRegistrazione({
				cartData: cart,
				isGtmEnabled: currentGTMTAG !== null ? true : false,
			});
		} else if (isLogging === true && user.status === 'KO') {
			setIsLogging(false);
			ModalConfirm({
				titleToShow: 'Login',
				messageToShow: genericheTranslation.modalmessaggiologinko.itemName,
				isShowConfirmButton: false,
				isShowCanceButton: false,
				isTimerModal: true,
			});
		}
	}, [user]);

	const onGoToNextPage = () => {
		console.log('vado a profilo o pagamento');
		// vado al profilo utente se non ho prodotti a carrello, altrimenti alla pagina pagamento
		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());
		if (order.totalItems > 0) {
			const pagamentoRouteTranslated =
				'/' + routeTranslation.pagamentoroute[suffissoLang] + '.html';
			dispatch(
				allActions.changePageActions.changePage(
					'',
					'paymentDetail',
					pagamentoRouteTranslated,
					history
				)
			);
		} else {
			const profiloRouteTranslated =
				'/' + routeTranslation.profiloroute[suffissoLang] + '.html';
			dispatch(
				allActions.changePageActions.changePage(
					'',
					'profileDetail',
					profiloRouteTranslated,
					history
				)
			);
		}
	};

	const onLoginClick = (data) => {
		// chiamo action creator
		if (data.email !== '' && data.password !== '') {
			setIsLogging(true);

			dispatch(
				allActions.userActions.loginUser(
					currentPage.currentLang,
					data.email,
					data.password,
					user.token_id
				)
			);
		}
	};

	const onRecoverPasswordClick = (event) => {
		event.preventDefault();

		MySwal.fire({
			title: (
				<span
					dangerouslySetInnerHTML={{
						__html:
							genericheTranslation.recuperapassword.itemName.toUpperCase(),
					}}
				/>
			),
			html: genericheTranslation.recuperapasswordfase1.itemName,
			input: 'email',
			inputAttributes: {
				autocapitalize: 'off',
			},
			confirmButtonText: genericheTranslation.invia.itemName.toUpperCase(),
			cancelButtonText: genericheTranslation.chiudi.itemName.toUpperCase(),
			confirmButtonColor: '#FFA900',
			cancelButtonColor: '#707070',
			showCancelButton: true,
			customClass: {
				container: 'recoverPassword',
				confirmButton: 'confirmButtonRibola',
				cancelButton: 'cancelButtonRibola',
				title: 'titleRibola',
				htmlContainer: 'htmlContainerRibola',
			},
			validationMessage: erroriFormTranslation.erroremailsbagliata.itemName,
			preConfirm: async (email) => {
				const payload = {
					email: email,
					currentLang: currentPage.currentLang,
				};

				// chiamo web service
				const response = await axios.post(`password-recover/`, payload, {
					withCredentials: true,
				});

				if (response.data.status !== 'OK') {
					return Swal.showValidationMessage(
						`${genericheTranslation.richiestafallita.itemName} ${response.data.error}`
					);
				} else {
					return response.data;
				}
			},
			didOpen: () => {},
		}).then((result) => {
			if (result.isConfirmed === true) {
				MySwal.fire({
					title: (
						<span
							dangerouslySetInnerHTML={{
								__html:
									genericheTranslation.recuperapassword.itemName.toUpperCase(),
							}}
						/>
					),
					html: genericheTranslation.recuperapasswordfase2.itemName,
					input: 'text',
					inputAttributes: {
						autocapitalize: 'off',
					},
					confirmButtonText: genericheTranslation.invia.itemName.toUpperCase(),
					cancelButtonText: genericheTranslation.chiudi.itemName.toUpperCase(),
					confirmButtonColor: '#FFA900',
					cancelButtonColor: '#707070',
					showCancelButton: true,
					customClass: {
						container: 'recoverPassword',
						confirmButton: 'confirmButtonRibola',
						cancelButton: 'cancelButtonRibola',
						title: 'titleRibola',
						htmlContainer: 'htmlContainerRibola',
					},
					preConfirm: async (token) => {
						const payload = {
							token: token,
							currentLang: currentPage.currentLang,
						};

						// chiamo web service
						const response = await axios.post(`change-password/`, payload, {
							withCredentials: true,
						});

						if (response.data.status !== 'OK') {
							return Swal.showValidationMessage(
								`${genericheTranslation.richiestafallita.itemName} ${response.data.error}`
							);
						} else {
							return response.data;
						}
					},
				}).then((result) => {
					if (result.isConfirmed === true) {
						MySwal.fire({
							title: (
								<span
									dangerouslySetInnerHTML={{
										__html:
											genericheTranslation.recuperapassword.itemName.toUpperCase(),
									}}
								/>
							),
							html: genericheTranslation.recuperapasswordfase3.itemName,
							timer: 4000,
							timerProgressBar: true,
							allowOutsideClick: false,
							showConfirmButton: false,
						});
					}
				});
			}
		});
	};

	const isDisabledLogging = isLogging ? 'pointer-events-none' : '';
	const isButtonSending = isLogging ? true : false;

	//// ERRORI LOGIN
	const emailError = errors.email ? (
		<p className='text-sm text-red-600'>
			<i className='fal fa-exclamation-triangle mr-1'></i>
			{errors.email.message}
		</p>
	) : (
		''
	);

	const passwordError = errors.password ? (
		<p className='text-sm text-red-600'>
			<i className='fal fa-exclamation-triangle mr-1'></i>
			{errors.password.message}
		</p>
	) : (
		''
	);
	/// FINE ERRORI LOGIN

	// link a pagina registrazione form tradotta in lingua
	const registrationFormRouteTranslated =
		'/' + routeTranslation.registrazioneformroute[suffissoLang] + '.html';

	const showOspiteButton = order.totalItems > 0 && (
		<CustomButton
			buttonLink={registrationFormRouteTranslated}
			buttonRouteName='registrationFormDetail'
			buttonName={campiFormTranslation.ospite.itemName.toUpperCase()}
			buttonStyle='w-full xl:w-7/12 flex justify-center items-center text-white bg-ribola-web-orange sm:text-lg 4xl:text-xl font-opensanssemibold mt-6 sm:mt-10 md:mt-16 py-4 px-6 border-r-2 border-ribola-gallery-gray'
			propsToSend='ospite'
		/>
	);

	return (
		<div className='col-start-3 4xl:col-start-5 col-end-59 4xl:col-end-57 pb-12'>
			<Briciole />
			<BarraCheckout currentCheckoutPage={currentPage.routeName} />
			<div className='grid grid-cols-60 sm:gap-2 2xl:gap-4 mt-12 sm:mt-20'>
				<div
					className={`col-span-60 md:col-span-30 lg:col-span-25 xl:col-span-20 bg-ribola-gallery-gray py-10 2xl:py-12 px-8 sm:px-10 2xl:px-14 mb-6 md:mb-0 ${isDisabledLogging}`}>
					<span className='text-2xl text-ribola-congress-blue font-opensanssemibold'>
						{loginRegistrazioneTranslation.sonogiaregistrato.itemName}
					</span>
					<form onSubmit={handleSubmit(onLoginClick)}>
						<InputField
							inputType='text'
							inputLabel='EMAIL'
							inputLabelStyle='flex justify-between w-full sm:text-lg 4xl:text-xl text-black mt-6 sm:mt-8'
							inputName='email'
							inputPlaceholder={
								loginRegistrazioneTranslation.inserireemail.itemName
							}
							inputStyle='border mt-3 py-3 px-8 sm:text-lg 4xl:text-xl bg-white placeholder-opacity-50 w-full'
							inputError={emailError}
							divStyle='w-full'
							register={register('email', {
								required: erroriFormTranslation.errorinserireemail.itemName,
								pattern: {
									value:
										/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
									message: erroriFormTranslation.erroremailsbagliata.itemName,
								},
							})}
						/>
						<InputField
							inputType='password'
							inputLabel='PASSWORD'
							inputLabelStyle='flex justify-between w-full sm:text-lg 4xl:text-xl text-black mt-6 sm:mt-8'
							inputName='password'
							inputPlaceholder='password'
							inputStyle='border mt-3 py-3 px-8 sm:text-lg 4xl:text-xl bg-white placeholder-opacity-50 w-full'
							inputError={passwordError}
							divStyle='w-full'
							register={register('password', {
								required: erroriFormTranslation.errorinserirepassword.itemName,
							})}
						/>
						<CustomButton
							buttonLink='#'
							buttonName={
								loginRegistrazioneTranslation.dimenticatopassword.itemName
							}
							buttonOnClickFunction={(event) => onRecoverPasswordClick(event)}
							buttonStyle='sm:text-lg 4xl:text-xl text-ribola-dove-gray font-opensanssemibold block my-6 md:mt-12 md:mb-16'
						/>

						<InputField
							inputType='submit'
							inputValue={loginRegistrazioneTranslation.login.itemName.toUpperCase()}
							inputStyle='w-full flex justify-center items-center text-white bg-ribola-congress-blue sm:text-lg 4xl:text-xl font-opensanssemibold cursor-pointer py-4 px-6'
							divStyle='w-full'
							showLabel={false}
							isSpinnerLoading={isButtonSending}
						/>
					</form>
				</div>
				<div className='col-span-60 md:col-span-30 lg:col-span-35 xl:col-span-25 flex flex-col justify-between bg-ribola-gallery-gray py-10 2xl:py-12 px-8 sm:px-10 2xl:px-14 mb-6 md:mb-0'>
					<div>
						<span className='text-2xl text-ribola-congress-blue font-opensanssemibold'>
							{loginRegistrazioneTranslation.nonsonoregistrato.itemName}
						</span>
						<p
							className='block mt-6 sm:mt-10 md:mt-16 sm:text-lg'
							dangerouslySetInnerHTML={{
								__html:
									loginRegistrazioneTranslation.messaggiononsonoregistrato
										.itemName,
							}}
						/>
					</div>
					<div className='flex justify-end'>
						<CustomButton
							buttonLink={registrationFormRouteTranslated}
							buttonRouteName='registrationFormDetail'
							buttonName={campiFormTranslation.privato.itemName.toUpperCase()}
							buttonStyle='w-full xl:w-7/12 flex justify-center items-center text-white bg-ribola-astral sm:text-lg 4xl:text-xl font-opensanssemibold mt-6 sm:mt-10 md:mt-16 py-4 px-6 border-r-2 border-ribola-gallery-gray'
							propsToSend='privato'
						/>
						<CustomButton
							buttonLink={registrationFormRouteTranslated}
							buttonRouteName='registrationFormDetail'
							buttonName={campiFormTranslation.azienda.itemName.toUpperCase()}
							buttonStyle='w-full xl:w-7/12 flex justify-center items-center text-white bg-ribola-chelsea-cucumber sm:text-lg 4xl:text-xl font-opensanssemibold mt-6 sm:mt-10 md:mt-16 py-4 px-6 border-r-2 border-ribola-gallery-gray'
							propsToSend='azienda'
						/>
						{showOspiteButton}
					</div>
				</div>
				<div className='col-span-60 xl:col-span-15 bg-ribola-gallery-gray py-10 2xl:py-12 px-8'>
					<RiepilogoCarrello />
				</div>
			</div>
		</div>
	);
};

export default RegistrationDetail;
