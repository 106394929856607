import { FETCH_HOMEPAGE } from '../actions/types';

const INITIAL_STATE = [];

export const categoriesSlideHomeReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_HOMEPAGE:
			const { homepage } = action.payload;

			homepage.categoriesSlideHome.forEach((category, key) => {
				state[key] = category;
			});

			return state;

		default:
			return state;
	}
};
