import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../state/actions';
import LazyLoadImg from '../lazyLoadImg/LazyLoadImg';
import CustomButton from '../customButton/CustomButton';
import { URLASSOLUTO } from '../../../utils/urlassoluto';
import { BASENAME } from '../../../utils/basename';
import { gtmProductClick } from '../../../gtm/gtm';

const ProductOutletBoxItem = ({
	id,
	index,
	immagine,
	titoloBreve,
	sottotitoloElenco = '',
	codice,
	imponibile,
	sconto,
	prezzoScontato,
	lunghezza,
	larghezza,
	profondita,
	metrilineari,
	giacenza,
	venduti,
	colore,
	routeName,
	linkSeo,
	categoriaTitolo,
	scrollPosition,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const currentLang = useSelector((state) => state.currentPage.currentLang);
	const siteParams = useSelector((state) => state.siteParams);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	useEffect(() => {
		//console.log(lunghezza, larghezza);
		/* console.log(titoloBreve);
		if (titoloBreve === 'Teli copertura invernale') {
			console.log(richiediInfo);
		} */
	}, []);

	const onProductClick = (event) => {
		event.preventDefault();

		const productData = {
			titoloBreve,
			codice,
			imponibile,
			sconto,
			prezzoScontato,
		};

		// gtm productClick
		const gtmSuffix = `gtm_code${currentLang}`;
		const currentGTMTAG = siteParams[gtmSuffix];
		gtmProductClick({
			productData,
			categoryName: categoriaTitolo,
			position: index,
			callbackFunction: onGoToPage,
			isGtmEnabled: currentGTMTAG !== null ? true : false,
		});
	};

	const onGoToPage = () => {
		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(id, routeName, linkSeo, history)
		);
	};

	const sottotitolo =
		sottotitoloElenco !== '' ? (
			<span className={`text-sm 3xl:text-base text-${colore}`}>
				{sottotitoloElenco}
			</span>
		) : (
			<span className='text-sm 3xl:text-base hidden sm:inline-block'>
				&nbsp;
			</span>
		);

	const prezzoProdottoScontato =
		sconto && sconto > 0 ? (
			<span className='prezzoScontato flex'>
				{parseFloat(imponibile).toFixed(2).toString().replace('.', ',')} €
			</span>
		) : (
			<span className='prezzoScontato opacity-0 hidden sm:inline-block'>
				&nbsp;
			</span>
		);

	const prezzoProdotto =
		sconto && sconto > 0 ? (
			<span className='prezzoProdotto flex text-ribola-web-orange text-3xl'>
				{parseFloat(prezzoScontato).toFixed(2).toString().replace('.', ',')} €
			</span>
		) : (
			<span className='prezzoProdotto flex text-ribola-congress-blue text-3xl'>
				{parseFloat(imponibile).toFixed(2).toString().replace('.', ',')} €
			</span>
		);

	const scontoFasciaArancione =
		sconto && sconto > 0 ? (
			<div
				className={`scontoFasciaArancione fascia_arancione${currentLang} small pointer-events-none z-20`}>
				<span className='scontoFasciaInterna small'>
					<span className='titoloFasciaInterna'>
						<span className='leading-none'>{sconto}%</span>
					</span>
					<span className='leading-none'>
						{genericheTranslation.sconto.itemName}
					</span>
				</span>
				<img
					src={`${BASENAME}/images/sconto_fascia_arancione.svg`}
					width='106'
					height='106'
					className='flex-start'
					alt='Sconto outlet'
				/>
			</div>
		) : (
			''
		);

	const immagineElenco =
		immagine === null || immagine === undefined ? (
			<LazyLoadImg
				imgName='placeholder_elenco_prodotto.jpg'
				imgPath='statiche'
				imgWidth='300'
				imgHeight='232'
				imgStyle='flex-start mb-3 w-full object-fit cursor-pointer'
				imgAlt={`${titoloBreve} - cod.${codice}`}
			/>
		) : (
			<LazyLoadImg
				imgName={`300x232-${immagine.file_name.replace('.jpg', '.webp')}`}
				imgPath={`${immagine.folder}/thumbs`}
				imgWidth='300'
				imgHeight='232'
				imgStyle='flex-start mb-3 w-full object-fit cursor-pointer'
				imgAlt={`${titoloBreve} - cod.${codice}`}
			/>
		);

	const dettaglioLunghezza = lunghezza ? (
		<span>
			{genericheTranslation.lunghezza.itemName}:
			<strong>
				{parseFloat(lunghezza).toFixed(2).toString().replace('.', ',')}&nbsp;
				{genericheTranslation.metri.itemName}
			</strong>
		</span>
	) : (
		''
	);

	const dettaglioLarghezza = larghezza ? (
		<span>
			{genericheTranslation.larghezza.itemName}:
			<strong>
				{parseFloat(larghezza).toFixed(2).toString().replace('.', ',')}&nbsp;
				{genericheTranslation.metri.itemName}
			</strong>
		</span>
	) : (
		''
	);

	const dettaglioProfondita = profondita ? (
		<span>
			{genericheTranslation.profondita.itemName}:
			<strong>
				{parseFloat(profondita).toFixed(2).toString().replace('.', ',')}&nbsp;
				{genericheTranslation.metri.itemName}
			</strong>
		</span>
	) : (
		''
	);

	const dettaglioMetriLineari = metrilineari ? (
		<span>
			{genericheTranslation.metrilineari.itemName}:
			<strong>
				{parseFloat(metrilineari).toFixed(2).toString().replace('.', ',')}&nbsp;
				{genericheTranslation.metrilineari.itemName}
			</strong>
		</span>
	) : (
		''
	);

	const disponibilita = parseInt(giacenza) - parseInt(venduti);

	return (
		<div className='productBoxItem flex flex-col justify-between max-w-xxs sm:max-w-none mx-6 sm:mx-0 lg:mx-3 xl:mx-2 4xl:mx-3'>
			{scontoFasciaArancione}
			<div>
				<a
					href={URLASSOLUTO + linkSeo}
					onClick={onProductClick}
					className='h-52 overflow-hidden'>
					{immagineElenco}
				</a>
				<span className='text-lg 3xl:text-xl'>
					<span className='titolo leading-tight text-ribola-tundora'>
						<span className='line-clamp-2'>{titoloBreve}</span>
					</span>
				</span>
				{sottotitolo}
				<span className='codice text-sm 3xl:text-base'>{codice}</span>
			</div>
			<div>
				<div className='bg-ribola-amber text-sm text-ribola-heavy-metal rounded-md flex flex-col py-2 px-3 my-1'>
					<span className='font-opensanssemibold'>
						{genericheTranslation.prodottosumisura.itemName}
					</span>
					{dettaglioLunghezza}
					{dettaglioLarghezza}
					{dettaglioProfondita}
					{dettaglioMetriLineari}
					<span>
						{genericheTranslation.disponibilita.itemName}:&nbsp;
						<strong>{disponibilita}</strong>
					</span>
				</div>
				{prezzoProdottoScontato}
				{prezzoProdotto}
				<CustomButton
					buttonId={id}
					buttonLink={linkSeo}
					buttonRouteName={routeName}
					buttonOnClickFunction={onProductClick}
					buttonName={genericheTranslation.dettagli.itemName}
					buttonStyle='dettagliButton text-sm pl-4 pr-16 py-2 3xl:py-3 mt-2'
				/>
			</div>
		</div>
	);
};

export default ProductOutletBoxItem;
