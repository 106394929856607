import { useSelector } from 'react-redux';
import CustomButton from '../../../shared/customButton/CustomButton';
import { BASESTORAGE } from '../../../../utils/basestorage';

const LastSlideCategoryHome = ({
	id,
	category,
	icona,
	colore,
	linkCategory,
	routeName,
}) => {
	const homepageTranslation = useSelector(
		(state) => state.translations.homepage
	);

	return (
		<div
			className={`lastSlideCategoryHome max-w-xxs sm:max-w-none w-full mx-1 sm:mx-0 lg:mx-3 xl:mx-2 4xl:mx-3 flex flex-col justify-center items-center text-white bg-${colore}`}>
			<img
				src={BASESTORAGE + '/' + icona?.folder + '/' + icona?.file_name}
				width='90'
				height='90'
				className='filter brightness-0 invert'
				alt={category}
			/>
			<span className='titolo text-lg 3xl:text-2xl text-center py-10 block'>
				{category}
			</span>
			<CustomButton
				buttonId={id}
				buttonLink={linkCategory}
				buttonRouteName={routeName}
				buttonName={homepageTranslation.veditutti.itemName.toUpperCase()}
				buttonStyle='buttonBorderedTransparent text-white border-white text-center px-5'
			/>
		</div>
	);
};

// buttonLink={linkCategory}

export default LastSlideCategoryHome;
