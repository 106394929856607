import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ModalConfirm = ({
	titleToShow,
	messageToShow,
	onClickHandler = () => {},
	onCancelHandler = () => {},
	isShowConfirmButton = true,
	isShowCanceButton = true,
	isTimerModal = false,
	textConfirmButton,
	textCancelButton,
}) => {
	// modal sweetalert2
	const MySwal = withReactContent(Swal);

	let timerInterval;
	if (isTimerModal) {
		MySwal.fire({
			title: (
				<span
					dangerouslySetInnerHTML={{
						__html: titleToShow,
					}}
				/>
			),
			html: messageToShow,
			timer: 2000,
			timerProgressBar: true,
			showConfirmButton: isShowConfirmButton,
			showCancelButton: isShowCanceButton,
			allowOutsideClick: false,
			customClass: {
				container: 'addedToCartRibola',
				title: 'titleRibola',
				htmlContainer: 'htmlContainerRibola',
			},
			didOpen: () => {
				Swal.showLoading();
			},
			willClose: () => {
				clearInterval(timerInterval);
			},
		}).then((event) => {
			if (onClickHandler) {
				onClickHandler();
			} else {
				onCancelHandler();
			}
		});
	} else {
		MySwal.fire({
			title: (
				<span
					dangerouslySetInnerHTML={{
						__html: titleToShow,
					}}
				/>
			),
			html: messageToShow,
			confirmButtonText: textConfirmButton,
			cancelButtonText: textCancelButton,
			confirmButtonColor: '#004F91',
			cancelButtonColor: '#707070',
			showConfirmButton: isShowConfirmButton,
			showCancelButton: isShowCanceButton,
			allowOutsideClick: false,
			customClass: {
				container: 'addedToCartRibola',
				confirmButton: 'confirmButtonRibola',
				cancelButton: 'cancelButtonRibola',
				title: 'titleRibola',
				htmlContainer: 'htmlContainerRibola',
			},
			didOpen: () => {},
		}).then((event) => {
			if (event.isConfirmed === true) {
				onClickHandler();
			}
		});
	}

	return <div></div>;
};

export default ModalConfirm;
