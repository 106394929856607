import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { URLASSOLUTO } from '../../../utils/urlassoluto';
import allActions from '../../../state/actions';
import LazyLoadImg from '../../shared/lazyLoadImg/LazyLoadImg';

const MessaggioProdottoOmaggio = ({ datiProdottoAbbinato, pageContentId }) => {
	const history = useHistory();
	const dispatch = useDispatch();

	// se mi trovo nella scheda del prodotto abbinato devo recuperare i dati del padre!
	const isPageAbbinato =
		datiProdottoAbbinato.prodottoAbbinato.id === pageContentId ? true : false;

	const idProdottoAbbinato = isPageAbbinato
		? pageContentId
		: datiProdottoAbbinato.prodottoAbbinato.id;
	const linkSeo = isPageAbbinato
		? datiProdottoAbbinato.link_seo
		: datiProdottoAbbinato.prodottoAbbinato.link_seo;
	const routeName = isPageAbbinato
		? datiProdottoAbbinato.routeName
		: datiProdottoAbbinato.prodottoAbbinato.routeName;
	const immagine = isPageAbbinato
		? datiProdottoAbbinato.immagineelenco
		: datiProdottoAbbinato.prodottoAbbinato.immagineelenco;
	const nomeProdotto = isPageAbbinato
		? datiProdottoAbbinato.name
		: datiProdottoAbbinato.prodottoAbbinato.name;
	const codiceProdotto = isPageAbbinato
		? datiProdottoAbbinato.codice
		: datiProdottoAbbinato.prodottoAbbinato.codice;

	const onProductClick = (event) => {
		event.preventDefault();

		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(
				idProdottoAbbinato,
				routeName,
				linkSeo,
				history
			)
		);
	};

	const immagineOmaggio =
		immagine === null || immagine === undefined ? (
			<LazyLoadImg
				imgName='placeholder_elenco_prodotto.jpg'
				imgPath='statiche'
				imgWidth='59'
				imgHeight='59'
				imgStyle='flex-start w-full object-fit cursor-pointer'
				imgAlt={immagine.file_name}
			/>
		) : (
			<LazyLoadImg
				imgName={`59x59-${immagine.file_name.replace('.jpg', '.webp')}`}
				imgPath={`${immagine.folder}/thumbs`}
				imgWidth='59'
				imgHeight='59'
				imgStyle='flex-start w-full object-fit cursor-pointer'
				imgAlt={immagine.file_name}
			/>
		);

	let msgPadreOAbbinato = '';
	if (
		isPageAbbinato &&
		datiProdottoAbbinato.tipologiaScontoSingolo === 'Omaggio'
	) {
		msgPadreOAbbinato = 'INSIEME IN REGALO SE ACQUISTI';
	} else if (
		!isPageAbbinato &&
		datiProdottoAbbinato.tipologiaScontoSingolo === 'Omaggio'
	) {
		msgPadreOAbbinato = 'INSIEME IN REGALO';
	} else if (
		isPageAbbinato &&
		datiProdottoAbbinato.tipologiaScontoSingolo !== 'Omaggio'
	) {
		msgPadreOAbbinato = 'OTTIENI SCONTATO SE ACQUISTI';
	} else {
		msgPadreOAbbinato = 'ACQUISTA SCONTATO';
	}

	const mostraQuantita = isPageAbbinato ? 'hidden' : '';

	return (
		<div className='flex flex-col mt-8'>
			<span className='text-xl text-ribola-chelsea-cucumber font-opensanssemibold block mb-2'>
				{msgPadreOAbbinato}
			</span>
			<div className='grid grid-cols-60 bg-ribola-chelsea-cucumber px-3 py-3 text-white'>
				<div className='col-span-10'>
					<a
						href={URLASSOLUTO + linkSeo}
						onClick={onProductClick}
						className='flex items-center h-full'>
						{immagineOmaggio}
					</a>
				</div>
				<div className='col-span-40 text-sm pl-3'>
					<a
						href={URLASSOLUTO + linkSeo}
						onClick={onProductClick}
						className='flex flex-col justify-center h-full'>
						<span className='font-opensansbold'>{nomeProdotto}</span>
						<span>{codiceProdotto}</span>
					</a>
				</div>
				<div
					className={`col-span-10 text-sm flex justify-center items-center ${mostraQuantita}`}>
					<span>
						{datiProdottoAbbinato.tipologiaScontoSingolo === 'Omaggio'
							? 'x' + datiProdottoAbbinato.quantita
							: datiProdottoAbbinato.quantita + '%'}
					</span>
				</div>
			</div>
		</div>
	);
};

export default MessaggioProdottoOmaggio;
