import { Route } from 'react-router-dom';
import Lenostrereti from '../pages/lenostrereti/Lenostrereti';

export default (lenostreretiRoute) => {
	return [
		<Route
			exact
			path={`/${lenostreretiRoute}.html`}
			component={Lenostrereti}
			key={`${lenostreretiRoute}Route`}
		/>,
	];
};
