import { Route } from 'react-router-dom';
import Contatti from '../pages/contatti/Contatti';

export default (contattiRoute) => {
	return [
		<Route
			exact
			path={`/${contattiRoute}.html`}
			component={Contatti}
			key={`${contattiRoute}Route`}
		/>,
	];
};
