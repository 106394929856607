import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import CategoriaSlideItem from './categoriaSlideItem/CategoriaSlideItem';

const BloccoCategorieSlideHome = () => {
	const categoriesSlideHome = useSelector((state) => state.categoriesSlideHome);

	const elencoCategoriesSlide = categoriesSlideHome.map((category) => (
		<CategoriaSlideItem key={_uniqueId('categorySlide_')} {...category} />
	));

	return (
		<div className='containerFluido container mx-auto overflow-hidden bg-ribola-gallery-gray'>
			<div className='grid grid-cols-60'>{elencoCategoriesSlide}</div>
		</div>
	);
};

export default BloccoCategorieSlideHome;
