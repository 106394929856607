import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import Briciole from '../shared/briciole/Briciole';
import CategoryBoxItem from './categoryBoxItem/CategoryBoxItem';
import { BASESTORAGE } from '../../utils/basestorage';

const ParentCategoryDetail = () => {
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const promotion = useSelector((state) => state.promotion);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);
	const [showFullDescription, setShowFullDescription] = useState(false);
	const [categoriaFiltroAccessori, setCategoriaFiltroAccessori] = useState('');

	useEffect(() => {
		//console.log(categoriaFiltroAccessori);
	}, [categoriaFiltroAccessori]);

	// controllo che esista la proprietà detail e che pageContent abbia la proprietà childCategories (altrimenti vuol dire che sto ancora recuperando i dati)
	if (
		!pageContent.detail ||
		pageContent.childCategories === undefined ||
		pageContent.icona === undefined ||
		pageContent.detail.titolo === undefined ||
		pageContent.detail.sottotitolo === undefined
	) {
		return <div></div>;
	}

	// recupero eventuale promozione a tempo di categorie
	const promozioneCategorie = promotion?.elencoCategorie;

	const elencoCategories = Object.keys(pageContent.childCategories).map(
		(key, index) => {
			const {
				id: categoryId,
				image,
				detail,
				hoverColor,
				routeName,
				urlSeo,
				linkSeo,
			} = pageContent.childCategories[key];

			const titolo = detail?.titolo;
			const sottotitolo = detail?.sottotitolo;
			const prezzoapartireda = detail?.prezzoapartireda;
			const scontofinoa = detail?.scontofinoa;
			const macro_padre_accessori = detail?.macro_padre_accessori;

			// sconto promozione?
			let promoCategoria = null;
			let scontoPromoCategoria = null;
			let tipologiaScontoP = 'Percentuale';
			if (promozioneCategorie !== undefined) {
				promoCategoria = promozioneCategorie.filter((item, key) => {
					if (item.id === categoryId) {
						return promozioneCategorie[key].id;
					}
					return false;
				});

				if (promoCategoria.length) {
					scontoPromoCategoria = promotion.scontoGlobale;
				}

				tipologiaScontoP = promotion.tipologiaSconto;
			}
			// fine sconto promozione

			// filtro per categorie accessori?
			if (
				pageContent.is_macro_accessori === '1' &&
				categoriaFiltroAccessori !== '' &&
				(macro_padre_accessori === null ||
					macro_padre_accessori === undefined ||
					!macro_padre_accessori.split(',').includes(categoriaFiltroAccessori))
			) {
				return;
			}

			return (
				<CategoryBoxItem
					key={_uniqueId('category_')}
					categoryId={categoryId}
					titolo={titolo}
					sottotitolo={sottotitolo}
					prezzoapartireda={prezzoapartireda}
					scontofinoa={scontofinoa}
					scontoPromozione={scontoPromoCategoria}
					tipologiaScontoPromozione={tipologiaScontoP}
					immagine={image}
					colore={hoverColor}
					is_macro_accessori={pageContent.is_macro_accessori}
					flags_macro_accessori={pageContent.flags_macro_accessori}
					macro_padre_accessori={macro_padre_accessori}
					routeName={routeName}
					urlSeo={urlSeo}
					linkSeo={linkSeo}
				/>
			);
		}
	);

	const onScopriClickHandler = () => {
		setShowFullDescription(!showFullDescription);
	};

	const onFiltroCategoriaClick = (item) => {
		setCategoriaFiltroAccessori(item.id);
	};

	const onFiltroCategoriaResetClick = () => {
		setCategoriaFiltroAccessori('');
	};

	const showDesc = showFullDescription ? 'line-clamp-none' : 'line-clamp-6';
	const rotateArrow = showFullDescription
		? 'transform -rotate-180'
		: 'transform rotate-0';

	const sottotitolo = pageContent.detail.sottotitolo ? (
		<h2 className='text-xl xl:text-2xl font-opensansbold mb-6'>
			{pageContent.detail.sottotitolo.toUpperCase()}
		</h2>
	) : (
		''
	);

	// filtri per macrocategorie accessori?
	const elencoFiltriAccessori =
		pageContent.is_macro_accessori === '1' &&
		pageContent.flags_macro_accessori.map((item) => {
			const opacityFiltro =
				categoriaFiltroAccessori !== item.id &&
				categoriaFiltroAccessori !== '' &&
				'opacity-50';

			return (
				<div
					className='px-2 w-full sm:w-auto'
					key={item.id}
					onClick={() => onFiltroCategoriaClick(item)}>
					<span
						className={`iconaTag bg-${item.hoverColor} ${opacityFiltro} hover:opacity-100 flex items-center cursor-pointer transition-opacity duration-300 px-2 py-1 my-1`}>
						<img
							src={
								BASESTORAGE +
								'/' +
								item.icona.folder +
								'/' +
								item.icona.file_name
							}
							width='25'
							height='25'
							className='flex-start mr-2 filter brightness-0 invert'
							alt={item.name}
						/>
						{item.name.toUpperCase()}
					</span>
				</div>
			);
		});
	const showFiltriAccessori = pageContent.is_macro_accessori === '1' &&
		pageContent?.flags_macro_accessori?.length > 0 && (
			<div className='w-full flex flex-wrap mt-4 sm:mt-8 px-3 sm:px-0'>
				<div className='px-2 pb-1 w-full flex items-center font-opensansbold'>
					<span className='text-xs sm:text-sm'>
						{genericheTranslation.filtraaccessori.itemName}
					</span>
					{categoriaFiltroAccessori !== '' && (
						<span
							onClick={onFiltroCategoriaResetClick}
							className='bg-red-700 text-white text-xs px-2 py-1 ml-3 flex items-center cursor-pointer rounded-sm'>
							<small>{genericheTranslation.resettafiltro.itemName}</small>
							<i className='far fa-times-circle ml-1'></i>
						</span>
					)}
				</div>
				{elencoFiltriAccessori}
			</div>
		);

	return (
		<div className='col-start-1 lg:col-start-15 4xl:col-start-16 col-end-61 3xl:col-end-59 4xl:col-end-57 pb-12'>
			<Briciole />
			<div className='grid grid-cols-60 mt-0 sm:mt-3 xl:mt-6'>
				<div className='col-span-60 flex justify-between items-center px-8 xl:px-12 3xl:px-20 pt-3 pb-6 sm:py-3'>
					<h1
						className={`text-${pageContent.hoverColor} text-3xl md:text-4xl xl:text-5xl font-opensansbold`}>
						<small className='leading-tight flex'>
							{pageContent.detail.titolo.toUpperCase()}
						</small>
					</h1>
					<img
						src={
							BASESTORAGE +
							'/' +
							pageContent?.icona?.folder +
							'/' +
							pageContent?.icona?.file_name
						}
						width='140'
						height='140'
						className='flex-start w-12 sm:w-32'
						alt={pageContent.detail.titolo}
					/>
				</div>
				<div className='col-span-60 flex flex-col bg-ribola-gallery-gray px-8 xl:px-12 3xl:px-20 py-8 sm:py-12'>
					{sottotitolo}
					<p
						className={`text-sm ${showDesc} md:line-clamp-none`}
						dangerouslySetInnerHTML={{
							__html: pageContent.detail.descrizione,
						}}></p>
					<span
						className='md:hidden font-opensanssemibold italic text-sm text-ribola-heavy-metal flex justify-center py-3 cursor-pointer'
						onClick={onScopriClickHandler}>
						<span className='underline'>
							{genericheTranslation.scopristatic.itemName}
						</span>
						<i className={`fal fa-chevron-down ml-2 ${rotateArrow}`}></i>
					</span>
				</div>
			</div>
			{showFiltriAccessori}
			<div className='grid grid-cols-60 mt-12'>{elencoCategories}</div>
		</div>
	);
};

export default ParentCategoryDetail;
