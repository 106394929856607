import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import MenuLeft from '../../components/shared/menuLeft/MenuLeft';
import RisultatiricercaDetail from '../../components/risultatiricercaDetail/RisultatiricercaDetail';

const Risultatiricerca = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// chiamo sempre action creator per recuperare pagina di ricerca e passando le parole
		const wordsToSearch = location.state.propsToSend;
		//console.log(location.state.propsToSend);

		if (
			currentPage.pageContent?.paroleRicercate?.risultati === null ||
			currentPage.pageContent?.paroleRicercate?.risultati === undefined
		) {
			setTimeout(
				() =>
					dispatch(
						allActions.staticDetailActions.fetchSearchDetail(
							currentPage.currentLang,
							user?.userData?.id,
							wordsToSearch
						)
					),
				50
			);
		}
	}, [location]);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<MenuLeft />
					<RisultatiricercaDetail />
				</div>
			</div>
		</Layout>
	);
};

export default Risultatiricerca;
