import { useEffect, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';

const BoxAddress = ({
	idIndirizzo,
	isSelected,
	nome,
	cognome,
	indirizzo,
	citta,
	provinciaText,
	provincia,
	cap,
	shipping_country_id,
	nazioneText,
	telefono,
	onAddAddressClick = () => {},
	onNewAddressClick = () => {},
	onEditAddressClick = () => {},
	onDeleteAddressClick = () => {},
}) => {
	const currentPage = useSelector((state) => state.currentPage);
	const order = useSelector((state) => state.order);
	const gestioneIndirizziTranslation = useSelector(
		(state) => state.translations.gestioneIndirizzi
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	// local state

	useEffect(() => {
		//console.log(onEditAddressClick);
	}, [currentPage.pageName]);

	const isAddressSelected = isSelected
		? 'border-4 border-ribola-congress-blue'
		: 'opacity-40 transition duration-300 hover:opacity-100';

	// se ho selezionato contrassegno e cerco di selezionare un indirizzo estero, non lo permetto
	const isAddressDisabled =
		order.paymentId === 1 && shipping_country_id !== 36
			? 'pointer-events-none opacity-20'
			: '';

	const buttonsToShow =
		currentPage.routeName === 'indirizziDetail' ? (
			<Fragment>
				<span
					className='underline cursor-pointer'
					onClick={() => onEditAddressClick({ addressId: idIndirizzo })}
					dangerouslySetInnerHTML={{
						__html: genericheTranslation.modifica.itemName,
					}}
				/>
				<span className='mx-6'>|</span>
				<span
					className='underline cursor-pointer'
					onClick={() => onDeleteAddressClick({ addressId: idIndirizzo })}
					dangerouslySetInnerHTML={{
						__html: genericheTranslation.elimina.itemName,
					}}
				/>
			</Fragment>
		) : (
			!isSelected && (
				<span
					className={`w-max flex justify-center items-center text-white bg-ribola-astral hover:bg-ribola-congress-blue transition duration-300 sm:text-lg 2xl:text-xl font-opensanssemibold py-3 px-6 ${isAddressDisabled} cursor-pointer`}
					onClick={() => onAddAddressClick({ addressId: idIndirizzo })}
					dangerouslySetInnerHTML={{
						__html: genericheTranslation.seleziona.itemName,
					}}
				/>
			)
		);

	const divBoxIndirizzo =
		idIndirizzo === undefined ? (
			<div
				onClick={onNewAddressClick}
				className='col-span-60 sm:col-span-30 lg:col-span-20 h-40 sm:h-96 border-6 border-ribola-gallery-gray px-8 py-8 flex flex-col justify-center items-center font-opensansbold text-ribola-nobel cursor-pointer transition duration-300 hover:bg-ribola-cultured-gray'>
				<i className='fas fa-plus text-6xl'></i>
				<span
					className='text-xl mt-3'
					dangerouslySetInnerHTML={{
						__html: gestioneIndirizziTranslation.aggiungiindirizzo.itemName,
					}}
				/>
			</div>
		) : (
			<div
				className={`col-span-60 sm:col-span-30 lg:col-span-20 h-72 sm:h-96 px-8 py-8 mb-6 sm:mb-0 flex flex-col bg-ribola-gallery-gray relative ${isAddressSelected}`}>
				{/* <div className='rounded-full border-2 border-black text-black text-center font-opensansbold w-8 h-8 mb-3'>
					<span className='leading-7'>{idIndirizzo}</span>
				</div> */}
				<div className='h-4/6 flex flex-col justify-center'>
					<span className='text-lg 2xl:text-xl text-black font-opensansbold mb-3'>
						{nome} {cognome}
					</span>
					<span className='text-black'>
						{indirizzo}
						<br />
						{citta} ({provinciaText}) {cap}
						<br />
						{nazioneText}
						<br />
						{genericheTranslation.telefono.itemName}: {telefono}
					</span>
				</div>
				<div className='h-1/3 flex justify-end items-end text-ribola-congress-blue'>
					{buttonsToShow}
				</div>
			</div>
		);

	return <Fragment>{divBoxIndirizzo}</Fragment>;
};

export default BoxAddress;
