import { Route } from 'react-router-dom';
import MagazineCategory from '../pages/magazineCategory/MagazineCategory';

export default (magazineRoute) => {
	return [
		<Route
			exact
			path={`/${magazineRoute}/:magazineCategory/`}
			component={MagazineCategory}
			key={`${magazineRoute}magazineCategoryRoute`}
		/>,
	];
};
