import {
	FETCH_MAGAZINE_DETAIL,
	FETCH_MAGAZINE_CATEGORY_DETAIL,
	FETCH_MAGAZINE_ARTICLE_DETAIL,
} from '../actions/types';

const INITIAL_STATE = [];

export const magazineReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_MAGAZINE_DETAIL:
			const { childCategories } = action.payload.magazine;

			state = childCategories;

			return state;

		case FETCH_MAGAZINE_CATEGORY_DETAIL:
			const { magazine, magazineCategory } = action.payload;

			let categoryIndex = state.findIndex(
				(item) => item.id === magazineCategory.id
			);

			let isFetched = magazineCategory.isFetched;
			let detail = magazineCategory.detail;
			let briciole = magazineCategory.briciole;
			let imageHeader = magazineCategory.imageHeader;
			let childMagazines = magazineCategory.childMagazines;

			if (categoryIndex === -1 || categoryIndex === undefined) {
				// non ho trovato l'indice, vuol dire che childMagazines o magazine stesso nello store è vuoto, copio tutto da magazine che mi arriva dalle API
				state = magazine;
			} else {
				// ho trovato la categoria
				state[categoryIndex] = {
					...state[categoryIndex],
					isFetched,
					detail,
					briciole,
					imageHeader,
					childMagazines,
				};
			}

			return state;

		case FETCH_MAGAZINE_ARTICLE_DETAIL:
			const { magazine: magazin, magazineArticle } = action.payload;

			// cerco index categoria magazine
			const parentCategoryIndex = state.findIndex(
				(item) => item.id === magazineArticle.magazine_category_id
			);

			// cerco index magazine
			const magazineArticleIndex = state[
				parentCategoryIndex
			]?.childMagazines.findIndex((item) => item.id === magazineArticle.id);

			//console.log(magazineArticleIndex);

			if (magazineArticleIndex === -1 || magazineArticleIndex === undefined) {
				// non ho trovato l'indice, vuol dire che childMagazines o magazine stesso nello store è vuoto, copio tutto da magazine che mi arriva dalle API
				state = magazin;
			} else {
				// ho trovato il magazine
				state[parentCategoryIndex].childMagazines[magazineArticleIndex] =
					magazineArticle;
			}

			return state;

		default:
			return state;
	}
};
