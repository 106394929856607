import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import allActions from '../../../state/actions';
import InputField from '../inputField/InputField';

const TopSearch = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm({
		mode: 'onChange',
	});
	// store data
	const paginestaticheTranslation = useSelector(
		(state) => state.translations.paginestatiche
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	// local state

	const onSubmitFormTopSearch = (data) => {
		console.log(data, paginestaticheTranslation.search.itemLink);

		if (data.search !== '') {
			// mostro spinner
			dispatch(allActions.isFetchingAction.loadSpinner());

			dispatch(
				allActions.changePageActions.changePage(
					'',
					'searchDetail',
					paginestaticheTranslation.search.itemLink,
					history,
					data.search
				)
			);
		}
	};

	return (
		<div className='flex items-center text-sm 2xl:text-base text-white lg:text-ribola-dove-gray'>
			<form
				onSubmit={handleSubmit(onSubmitFormTopSearch)}
				className='w-full grid justify-items-end'
				noValidate>
				<InputField
					inputType='text'
					inputName='search'
					inputPlaceholder={genericheTranslation.cerca.itemName.toUpperCase()}
					inputStyle='w-full bg-transparent lg:bg-white text-white lg:text-ribola-dove-gray border-l-0 border-t-0 border-r-0 border-b border-white focus:shadow-none lg:border-ribola-dove-gray lg:focus:border-ribola-amber placeholder-white xl:placeholder-gray-500 px-4 xs:px-8 py-2'
					divStyle='topSearch w-full'
					mainDivStyle='gridArea11 w-full'
					autoComplete='on'
					register={register('search', {
						required: true,
					})}
				/>
				<InputField
					inputType='submit'
					inputValue=''
					inputStyle='w-full h-full cursor-pointer'
					mainDivStyle='gridArea11 topSearchSubmit relative text-white lg:text-ribola-dove-gray w-10'
					showLabel={false}
					isSpinnerLoading={false}
				/>
			</form>
		</div>
	);
};

export default TopSearch;
