import { useSelector } from 'react-redux';
import InputField from '../../shared/inputField/InputField';

const QuantitaInput = ({
	quantitaValue,
	onQuantitaChange,
	customStyle = '',
}) => {
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	return (
		<div className={`flex items-center ${customStyle}`}>
			<span className='w-28 3xl:w-auto text-xl font-opensanssemibold'>
				{genericheTranslation.quantitastatic.itemName.toUpperCase()}
			</span>
			<div className='flex items-center ml-3 4xl:ml-6'>
				<i
					className='far fa-minus text-xs mx-2 cursor-pointer'
					onClick={() => onQuantitaChange('sottrai')}></i>
				<InputField
					inputType='number'
					inputName='qta'
					inputValue={quantitaValue}
					inputHandlerFunction={(event) => {
						//console.log(parseInt(event.target.value));
						if (parseInt(event.target.value) > 999) {
							onQuantitaChange(999);
						} else {
							onQuantitaChange(event);
						}
					}}
					inputStyle='border-2 text-center noArrows w-14'
					isReadOnly={false}
				/>
				<i
					className='far fa-plus text-xs mx-2 cursor-pointer'
					onClick={() => {
						if (quantitaValue < 999) {
							onQuantitaChange('somma');
						}
					}}></i>
			</div>
		</div>
	);
};

export default QuantitaInput;
