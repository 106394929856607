import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

export function debounce(fn, wait) {
  let timer = null;
  return function (...params) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...params);
    }, wait);
  };
}

export const pathMap = new Map();

function ScrollToTop({ children }) {
  const history = useHistory();
  const { pathname } = useLocation();
  const isLoading = useSelector((state) => state.currentPage.isLoading);

  useEffect(() => {
    if (pathMap.has(pathname)) {
      if (history.action === 'PUSH') {
        setTimeout(() => window.scrollTo(0, 0), 50);
      } else {
        //console.log(isLoading);
        if (!isLoading) {
          //console.log(isLoading);
          setTimeout(() => window.scrollTo(0, pathMap.get(pathname), 50));
        }
      }
    } else {
      pathMap.set(pathname, 0);
      setTimeout(() => window.scrollTo(0, 0), 50);
    }
  }, [pathname, isLoading]);

  useEffect(() => {
    const fn = debounce(() => {
      /* console.log(history.location.pathname);
      console.log(window.scrollY); */
      pathMap.set(history.location.pathname, window.scrollY);
    }, 200);

    window.addEventListener('scroll', fn);
    return () => window.removeEventListener('scroll', fn);
  }, [pathname, isLoading]);

  return children;
}

export default ScrollToTop;
