import { useEffect } from 'react';

const DropdownKitItem = ({ idKit, labelKit, onItemClickHandler }) => {
	useEffect(() => {}, []);

	return (
		<div
			className='flex items-center w-full py-3 px-3 border-b border-gray-200 bg-ribola-gallery-gray hover:bg-gray-100 transition'
			onClick={() => onItemClickHandler(idKit, labelKit)}>
			<span className=''>{labelKit}</span>
		</div>
	);
};

export default DropdownKitItem;
