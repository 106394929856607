import { useState, Fragment } from 'react';

const TabContent = ({ idContent, openTab, children, showScopri = false }) => {
	const [showFullDescription, setShowFullDescription] = useState(false);

	const onScopriClickHandler = () => {
		setShowFullDescription(!showFullDescription);
	};

	const showDesc = showFullDescription ? 'line-clamp-none' : 'line-clamp-3';
	const rotateArrow = showFullDescription
		? 'transform -rotate-180'
		: 'transform rotate-0';

	const spanScopri = showScopri ? (
		<span
			className='md:hidden font-opensanssemibold italic text-sm text-ribola-heavy-metal flex justify-center py-3 cursor-pointer'
			onClick={onScopriClickHandler}>
			<span className='underline'>scopri</span>
			<i className={`fal fa-chevron-down ml-2 ${rotateArrow}`}></i>
		</span>
	) : (
		''
	);

	return (
		<Fragment>
			<div
				className={
					openTab === idContent
						? `block ${showDesc} md:line-clamp-none`
						: 'hidden'
				}
				id={`link${idContent}`}
				dangerouslySetInnerHTML={{
					__html: children,
				}}></div>
			{spanScopri}
		</Fragment>
	);
};

export default TabContent;
