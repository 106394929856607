import { Route } from 'react-router-dom';
import PrivacyPolicy from '../pages/privacyPolicy/PrivacyPolicy';

export default (privacyPolicyRoute) => {
	return [
		<Route
			exact
			path={`/${privacyPolicyRoute}.html`}
			component={PrivacyPolicy}
			key={`${privacyPolicyRoute}Route`}
		/>,
	];
};
