import React from 'react';
import { useSelector } from 'react-redux';

const SelectField = React.forwardRef(
	(
		{
			selectListValue,
			selectClassStyle,
			selectInlineStyle,
			selectLabel,
			selectName,
			selectLabelStyle,
			selectHandlerFunction = () => {},
			selectedValue,
			selectError = '',
			isReadOnly = false,
			isDisabled = false,
			showLabel = true,
			onChange,
			onBlur,
		},
		ref
	) => {
		const genericheTranslation = useSelector(
			(state) => state.translations.generiche
		);
		const selectList = selectListValue.map((item) => {
			let isSelected = selectedValue === item.id ? true : false;
			return (
				<option key={item.id} value={item.id} defaultValue={isSelected}>
					{item.value}
				</option>
			);
		});

		const labelStyled = selectLabelStyle !== '' ? selectLabelStyle : 'text-sm';

		const labelDiv = showLabel ? (
			<div className={labelStyled}>
				<span
					dangerouslySetInnerHTML={{
						__html: selectLabel,
					}}
				/>
				{selectError}
			</div>
		) : (
			''
		);

		const selectStyled = selectClassStyle
			? selectClassStyle
			: 'font-opensanssemibold border border-ribola-dove-gray bg-transparent text-xl';

		const disabledStyle = isDisabled
			? 'opacity-20 cursor-default'
			: 'cursor-pointer';

		return (
			<div className='w-full flex flex-col justify-center items-start text-ribola-dove-gray'>
				{labelDiv}
				<select
					name={selectName}
					ref={ref}
					onChange={(e) => {
						if (typeof onChange === 'function') {
							onChange(e);
						}
						selectHandlerFunction(e);
					}}
					onBlur={onBlur}
					className={`form-select appearance-none focus:outline-none ${selectStyled} ${disabledStyle}`}
					style={selectInlineStyle}
					readOnly={isReadOnly}
					disabled={isDisabled}>
					<option value='' key={0}>
						{genericheTranslation.selezionaselect.itemName}
					</option>
					{selectList}
				</select>
			</div>
		);
	}
);

export default SelectField;
