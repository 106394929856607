import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../state/actions';
import { URLASSOLUTO } from '../../../utils/urlassoluto';
import { BASESTORAGE } from '../../../utils/basestorage';

const CategoryLeftItem = ({
	categoryId,
	categoryName,
	categoryIcon,
	categoryHoverColor,
	routeName,
	linkSeo,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const currentLinkSeo = useSelector((state) => state.currentPage.pageLinkSeo);
	const currentUrlSeo = useSelector((state) => state.currentPage.pageUrlSeo);

	const onCategoryClick = (event) => {
		event.preventDefault();

		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(
				categoryId,
				routeName,
				linkSeo,
				history
			)
		);
	};

	const parentLinkSeo = currentLinkSeo.replace(currentUrlSeo + '/', '');
	const parentParentLinkSeoExploded = currentLinkSeo.split('/');
	const parentParentLinkSeo =
		'/' +
		parentParentLinkSeoExploded[1] +
		'/' +
		parentParentLinkSeoExploded[2] +
		'/';

	const isSelected =
		currentLinkSeo === linkSeo ||
		parentLinkSeo === linkSeo ||
		parentParentLinkSeo === linkSeo
			? 'selected text-white bg-' + categoryHoverColor + ''
			: '';
	const isLinked =
		currentLinkSeo === linkSeo ? (
			<span className='h-12 flex'>
				<img
					src={
						BASESTORAGE +
						'/' +
						categoryIcon.folder +
						'/' +
						categoryIcon.file_name
					}
					width='50'
					height='50'
					className='flex-start w-10 3xl:w-14 hidden 2xl:flex'
					alt={categoryName}
				/>
				<span
					className='categoryItemName flex pl-1 pr-4 3xl:pl-3 4xl:px-3 text-xs 3xl:text-sm line-clamp-3'
					dangerouslySetInnerHTML={{ __html: categoryName.toUpperCase() }}
				/>
				<i className='far fa-chevron-right'></i>
			</span>
		) : (
			<a
				href={URLASSOLUTO + linkSeo}
				onClick={onCategoryClick}
				className='h-12 flex'>
				<img
					src={
						BASESTORAGE +
						'/' +
						categoryIcon.folder +
						'/' +
						categoryIcon.file_name
					}
					width='50'
					height='50'
					className='flex-start w-10 3xl:w-14 hidden 2xl:flex'
					alt={categoryName}
				/>
				<span
					className='categoryItemName flex pl-1 pr-4 3xl:pl-3 4xl:px-3 text-xs 3xl:text-sm line-clamp-3'
					dangerouslySetInnerHTML={{ __html: categoryName.toUpperCase() }}
				/>
				<i className='far fa-chevron-right'></i>
			</a>
		);

	return (
		<div
			className={`categoryItemSub border-b border-gray-300 hover:text-white hover:bg-${categoryHoverColor} px-2 3xl:pl-3 3xl:pr-0 py-3 ${isSelected}`}>
			{isLinked}
		</div>
	);
};

export default CategoryLeftItem;
