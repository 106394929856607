import axios from '../../utils/axiosBase';
import {
	FETCH_CART_DETAIL,
	LOAD_CART_DETAIL,
	ADD_TO_CART,
	UPDATE_CART_QUANTITY,
	DELETE_CART_ITEM,
	DELETE_CART_ITEMS,
	NO_SHIPPING,
	SHIPPING_FROM_CAP,
	UPDATE_NOTES,
	ADD_COUPON,
} from './types';

const fetchCartDetail =
	(currentLang, userId, tokenCarrello, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			tokenCarrello: tokenCarrello,
		};

		// chiamo web service
		const response = await axios.post(`api/cartdetail/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_CART_DETAIL,
			payload: response.data,
		});
	};

const loadCartDetail = (cartLoaded, history) => async (dispatch, getState) => {
	dispatch({
		type: LOAD_CART_DETAIL,
		payload: {
			cartLoaded,
		},
	});
};

const postCartAdd =
	(currentLang, userId, selectedShippingCountryID, productDetail, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			selectedShippingCountryID: selectedShippingCountryID,
			productDetail: productDetail,
		};

		// chiamo web service
		const response = await axios.post(`api/cartadd/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: ADD_TO_CART,
			payload: response.data,
		});
	};

const updateCartQuantity =
	(
		currentLang,
		userId,
		selectedShippingCountryID,
		tokenCarrello,
		cartId,
		quantity,
		history
	) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			selectedShippingCountryID: selectedShippingCountryID,
			tokenCarrello: tokenCarrello,
			cartId: cartId,
			quantity: quantity,
		};

		// chiamo web service
		const response = await axios.post(`api/updateqty/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: UPDATE_CART_QUANTITY,
			payload: response.data,
		});
	};

const deleteCartItem =
	(
		currentLang,
		userId,
		selectedShippingCountryID,
		tokenCarrello,
		cartId,
		history
	) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			selectedShippingCountryID: selectedShippingCountryID,
			tokenCarrello: tokenCarrello,
			cartId: cartId,
		};

		// chiamo web service
		const response = await axios.post(`api/deleteitem/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: DELETE_CART_ITEM,
			payload: response.data,
		});
	};

const deleteCartItems =
	(currentLang, userId, tokenCarrello, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			tokenCarrello: tokenCarrello,
		};

		// chiamo web service
		const response = await axios.post(`api/deleteitems/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: DELETE_CART_ITEMS,
			payload: response.data,
		});
	};

const sendNoShipping =
	(
		currentLang,
		userId,
		selectedShippingCountryID,
		tokenCarrello,
		isNoShipping,
		history
	) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			selectedShippingCountryID: selectedShippingCountryID,
			tokenCarrello: tokenCarrello,
			isNoShipping: isNoShipping,
		};

		// chiamo web service
		const response = await axios.post(`api/noshipping/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: NO_SHIPPING,
			payload: response.data,
		});
	};

const shippingFromCap =
	(currentLang, userId, selectedCountryRealId, tokenCarrello, cap, history) =>
	async (dispatch, getState) => {
		console.log(selectedCountryRealId);
		const payload = {
			currentLang,
			userId,
			selectedCountryRealId,
			tokenCarrello,
			cap,
		};

		// chiamo web service
		const response = await axios.post(`api/shippingfromcap/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: SHIPPING_FROM_CAP,
			payload: response.data,
		});
	};

const updateNotes =
	(currentLang, userId, tokenCarrello, note, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			tokenCarrello: tokenCarrello,
			note: note,
		};

		// chiamo web service
		const response = await axios.post(`api/updatenotes/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: UPDATE_NOTES,
			payload: response.data,
		});
	};

const postCouponAdd =
	(currentLang, userId, tokenCarrello, codiceCoupon, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang,
			userId,
			tokenCarrello,
			codiceCoupon,
		};

		// chiamo web service
		const response = await axios.post(`api/couponadd/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: ADD_COUPON,
			payload: response.data,
		});
	};

export default {
	fetchCartDetail,
	loadCartDetail,
	postCartAdd,
	updateCartQuantity,
	deleteCartItem,
	deleteCartItems,
	sendNoShipping,
	shippingFromCap,
	updateNotes,
	postCouponAdd,
};
