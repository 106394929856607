import { Route } from 'react-router-dom';
import Magazine from '../pages/magazine/Magazine';

export default (magazineRoute) => {
	return [
		<Route
			exact
			path={`/${magazineRoute}/`}
			component={Magazine}
			key={`${magazineRoute}Route`}
		/>,
	];
};
