import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import MenuLeftProfilo from '../../components/shared/menuLeftProfilo/MenuLeftProfilo';
import ProfileDetail from '../../components/profile/profileDetail/ProfileDetail';
import BloccoProfiloMenuLeft from '../../components/shared/bloccoProfiloMenuLeft/BloccoProfiloMenuLeft';

const Profile = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const staticPages = useSelector((state) => state.staticPages);
	const tokenCarrello = useSelector((state) => state.user.token_id);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// controllo nello store se ho già il dettaglio del profilo, altrimenti eseguo action creator
		const profilePage = staticPages.profile;

		if (profilePage === undefined || profilePage.detail === undefined) {
			// non ho i dati nello store, chiamo action creator fetchProfileDetail
			setTimeout(
				() =>
					dispatch(
						allActions.profileDetailActions.fetchProfileDetail(
							currentPage.currentLang,
							user?.userData?.id,
							tokenCarrello
						)
					),
				50
			);
		} else {
			// loading...
			dispatch(allActions.isFetchingAction.loadStoredData());

			// recupero dati da store
			dispatch(allActions.profileDetailActions.loadProfileDetail(profilePage));
		}

		//console.log(profilePage);
	}, [location]);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<MenuLeftProfilo />
					<ProfileDetail />
					<div className='col-span-60 flex lg:hidden'>
						<BloccoProfiloMenuLeft />
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Profile;
