import {
	ADD_TO_CART,
	UPDATE_CART_QUANTITY,
	DELETE_CART_ITEM,
	DELETE_CART_ITEMS,
	REGISTER_USER,
	LOGIN_USER,
	LOGOUT_USER,
	FETCH_CART_DETAIL,
	FETCH_REGISTRATION_DETAIL,
	ORDER_RESET,
} from '../actions/types';

const INITIAL_STATE = {
	cart: {},
};

export const cartReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ADD_TO_CART:
		case DELETE_CART_ITEM:
			state = action.payload.cartItem;

			return state;

		case UPDATE_CART_QUANTITY:
			const { cartItem } = action.payload;

			state = { ...state, ...cartItem };

			return state;

		case FETCH_CART_DETAIL:
		case FETCH_REGISTRATION_DETAIL:
		case REGISTER_USER:
		case LOGIN_USER:
		case LOGOUT_USER:
			const { currentCart } = action.payload;

			state = { ...state, ...currentCart };

			return state;

		case DELETE_CART_ITEMS:
		case ORDER_RESET:
			state = {};

			return state;

		default:
			return state;
	}
};
