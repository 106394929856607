import { Route } from 'react-router-dom';
import Product from '../pages/product/Product';

export default (netsRoute) => {
	return [
		<Route
			exact
			path={`/${netsRoute}/:parentCategory/:category/:product.html`}
			component={Product}
			key={`${netsRoute}productRoute`}
		/>,
	];
};
