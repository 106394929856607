import { Route } from 'react-router-dom';
import MagazineArticle from '../pages/magazineArticle/MagazineArticle';

export default (magazineRoute) => {
	return [
		<Route
			exact
			path={`/${magazineRoute}/:magazineCategory/:magazineArticle.html`}
			component={MagazineArticle}
			key={`${magazineRoute}magazineArticleRoute`}
		/>,
	];
};
