import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../../state/actions';
import ReactTooltip from 'react-tooltip';
import CheckboxField from '../../../shared/checkboxField/CheckboxField';
import LazyLoadImg from '../../../shared/lazyLoadImg/LazyLoadImg';
import InputField from '../../../shared/inputField/InputField';
import ModalConfirm from '../../../shared/modalConfirm/ModalConfirm';
import { gtmAddToCart, gtmRemoveFromCart } from '../../../../gtm/gtm';

const CartRow = ({
	idProdotto,
	nomeProdotto,
	codiceProdotto,
	titoloCategoria,
	dimensioni,
	unitaMisura,
	lunghezza,
	larghezza,
	profondita,
	metrilineari,
	diametro,
	prezzoFisso,
	prontaConsegna,
	giacenza,
	colore,
	peso,
	quantita,
	imponibile,
	imponibileOriginale,
	totale,
	totaleOriginale,
	immagine,
	isKit = false,
	kit_product_related_id = null,
	kit_product_cart_id = null,
	kit_data = null,
	isCheckedRows,
	onSelectRowFunction,
}) => {
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const currentLang = useSelector((state) => state.currentPage.currentLang);
	const siteParams = useSelector((state) => state.siteParams);
	const order = useSelector((state) => state.order);
	const user = useSelector((state) => state.user);
	const tokenCarrello = useSelector((state) => state.user.token_id);
	const carrelloTranslation = useSelector(
		(state) => state.translations.carrello
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);
	const paginestaticheTranslation = useSelector(
		(state) => state.translations.paginestatiche
	);

	// local state
	const [quantity, setQuantity] = useState(quantita);

	useEffect(() => {
		console.log(isCheckedRows);
	}, [isCheckedRows]);

	const onChangeQuantity = (event) => {
		//console.log(event);
		setQuantity(event.floatValue);
	};

	const onClickToChangeQuantity = (oldQuantity, newQuantity) => {
		// gtm remove from cart o add to cart (se aumento o diminuisco quantità)
		const productData = {
			titoloBreve: nomeProdotto,
			codice: codiceProdotto,
		};
		const categoryName = titoloCategoria;
		const prezzoProdotto = parseFloat(totale / quantita).toFixed(2);

		const diffQuantity = Math.abs(newQuantity - oldQuantity);
		console.log(newQuantity, oldQuantity, diffQuantity);
		const gtmSuffix = `gtm_code${currentLang}`;
		const currentGTMTAG = siteParams[gtmSuffix];
		if (newQuantity > oldQuantity) {
			// add to cart
			gtmAddToCart({
				productData,
				categoryName,
				prezzoCarrello: prezzoProdotto,
				quantitaCarrello: diffQuantity,
				callbackFunction: onGoToChangeQuantity,
				isGtmEnabled: currentGTMTAG !== null ? true : false,
			});
		} else {
			// remove from cart
			gtmRemoveFromCart({
				productData,
				categoryName,
				prezzoCarrello: prezzoProdotto,
				quantitaCarrello: diffQuantity,
				callbackFunction: onGoToChangeQuantity,
				isGtmEnabled: currentGTMTAG !== null ? true : false,
			});
		}
	};

	const onGoToChangeQuantity = () => {
		// chiamo action creator per aggiornare store
		console.log('chiamo updateCartQuantity', quantity);
		dispatch(
			allActions.cartDetailActions.updateCartQuantity(
				currentPage.currentLang,
				user?.userData?.id,
				order.selectedCountryRealId,
				tokenCarrello,
				idProdotto,
				quantity
			)
		);
	};

	const onAskUpdateCartItem = () => {
		ModalConfirm({
			titleToShow: carrelloTranslation.modalTitoloAggiornaQta.itemName,
			messageToShow: `${carrelloTranslation.modalConfermaAggiornaQta.itemName} <b>${quantity}</b>?`,
			textConfirmButton: genericheTranslation.conferma.itemName,
			textCancelButton: genericheTranslation.annulla.itemName,
			onClickHandler: () => onClickToChangeQuantity(quantita, quantity),
		});
	};

	const onSelectCartRow = (cart_id) => {
		setIsSelectedRow(!isSelectedRow);
		onSelectRowFunction(cart_id, isSelectedRow);
	};

	const onAskDeleteCartItem = (nomeProdotto) => {
		ModalConfirm({
			titleToShow: carrelloTranslation.modalTitoloRimozioneArticolo.itemName,
			messageToShow: `${carrelloTranslation.modalConfermaRimozioneArticolo.itemName} <b>${nomeProdotto}</b>?`,
			textConfirmButton: genericheTranslation.conferma.itemName,
			textCancelButton: genericheTranslation.annulla.itemName,
			onClickHandler: () => onDeleteCartItem(),
		});
	};

	const onDeleteCartItem = () => {
		// gtm remove from cart
		const productData = {
			titoloBreve: nomeProdotto,
			codice: codiceProdotto,
		};
		const categoryName = titoloCategoria;
		const prezzoProdotto = parseFloat(totale / quantita).toFixed(2);

		const gtmSuffix = `gtm_code${currentLang}`;
		const currentGTMTAG = siteParams[gtmSuffix];
		gtmRemoveFromCart({
			productData,
			categoryName,
			prezzoCarrello: prezzoProdotto,
			quantitaCarrello: quantita,
			callbackFunction: onGoToDeleteCartItem,
			isGtmEnabled: currentGTMTAG !== null ? true : false,
		});
	};

	const onGoToDeleteCartItem = () => {
		// chiamo action creator per aggiornare store
		dispatch(
			allActions.cartDetailActions.deleteCartItem(
				currentPage.currentLang,
				user?.userData?.id,
				order.selectedCountryRealId,
				tokenCarrello,
				idProdotto
			)
		);
	};

	const prezzoProdottoOriginale =
		imponibileOriginale > 0 ? (
			<span className='line-through font-opensanssemibold text-ribola-astral'>
				{parseFloat(imponibileOriginale)
					.toFixed(2)
					.toString()
					.replace('.', ',')}
				€
			</span>
		) : (
			''
		);

	const prezzoProdotto =
		imponibileOriginale > 0 ? (
			<span className='text-lg 3xl:text-xl text-ribola-amber font-opensansbold'>
				{parseFloat(imponibile).toFixed(2).toString().replace('.', ',')}€
			</span>
		) : (
			<span className='text-lg 3xl:text-xl text-ribola-astral font-opensansbold'>
				{parseFloat(imponibile).toFixed(2).toString().replace('.', ',')} €
			</span>
		);

	const prezzoTotaleOriginale =
		totaleOriginale > 0 ? (
			<span className='line-through font-opensanssemibold text-ribola-astral'>
				{parseFloat(totaleOriginale).toFixed(2).toString().replace('.', ',')} €
			</span>
		) : (
			''
		);

	const prezzoTotale =
		totaleOriginale > 0 ? (
			<span className='text-lg 3xl:text-xl text-ribola-amber font-opensansbold'>
				{parseFloat(totale).toFixed(2).toString().replace('.', ',')} €
			</span>
		) : (
			<span className='text-lg 3xl:text-xl text-ribola-astral font-opensansbold'>
				{parseFloat(totale).toFixed(2).toString().replace('.', ',')} €
			</span>
		);

	const immagineCartArray = immagine?.split('/').filter((val) => val !== '');
	const immagineFormatted =
		immagineCartArray?.length > 0 ? (
			<LazyLoadImg
				imgName={immagineCartArray[2]}
				imgPath={immagineCartArray[0] + '/' + immagineCartArray[1]}
				imgWidth='59'
				imgHeight='59'
				imgStyle='flex-start'
				imgAlt={nomeProdotto}
			/>
		) : (
			''
		);

	const divColore = colore ? (
		<span className='font-opensanssemibold text-sm'>
			{genericheTranslation.colore.itemName}: {colore}
		</span>
	) : (
		''
	);

	let divMisure;
	if (lunghezza && larghezza && profondita) {
		divMisure = (
			<span className='font-opensanssemibold text-sm'>
				{parseFloat(dimensioni).toFixed(2).toString().replace('.', ',')}&nbsp;
				{unitaMisura} (
				{parseFloat(lunghezza).toFixed(2).toString().replace('.', ',')}x
				{parseFloat(larghezza).toFixed(2).toString().replace('.', ',')}x
				{parseFloat(profondita).toFixed(2).toString().replace('.', ',')})
			</span>
		);
	} else if (lunghezza && larghezza) {
		divMisure = (
			<span className='font-opensanssemibold text-sm'>
				{parseFloat(dimensioni).toFixed(2).toString().replace('.', ',')}&nbsp;
				{unitaMisura} (
				{parseFloat(lunghezza).toFixed(2).toString().replace('.', ',')}x
				{parseFloat(larghezza).toFixed(2).toString().replace('.', ',')})
			</span>
		);
	} else if (metrilineari) {
		divMisure = (
			<span className='font-opensanssemibold text-sm'>
				{parseFloat(metrilineari).toFixed(2).toString().replace('.', ',')}&nbsp;
				{unitaMisura}
			</span>
		);
	} else if (diametro) {
		divMisure = (
			<span className='font-opensanssemibold text-sm'>
				{parseFloat(diametro).toFixed(2).toString().replace('.', ',')}&nbsp;
				{genericheTranslation.metri.itemName}&nbsp; (
				{parseFloat(dimensioni).toFixed(2).toString().replace('.', ',')}&nbsp;
				{unitaMisura})
			</span>
		);
	} else {
		divMisure =
			quantity > 1 ? (
				<span className='font-opensanssemibold text-sm'>
					{quantity} {genericheTranslation.pezzi.itemName}
				</span>
			) : (
				<span className='font-opensanssemibold text-sm'>
					{quantity} {genericheTranslation.pezzo.itemName}
				</span>
			);
	}

	// sovrascrivo divMisure nel caso di prodotto a prezzo fisso
	divMisure = prezzoFisso ? '' : divMisure;

	// pronta consegna?
	const showTagProntaConsegna = prontaConsegna ? (
		<span className='iconaTag bg-ribola-quicksand px-2 py-1 mt-2'>
			{paginestaticheTranslation.titoloprontaconsegna.itemName}
		</span>
	) : (
		''
	);

	// stili e variabili custom per kit
	const showFullBorder =
		isKit === false && 'border-b border-ribola-dove-gray border-opacity-20';
	const showBorderButtonKit =
		isKit && 'border-b-3 border-ribola-quicksand pb-1';
	const titleKitMontaggio = isKit && (
		<span className='font-opensansbold text-black text-sm xl:text-base mt-8'>
			{genericheTranslation.kitdimontaggio.itemName.toUpperCase()}
			<small className='text-xs text-ribola-quicksand ml-3'>
				{kit_data?.name?.toUpperCase()}
			</small>
		</span>
	);
	const accessoryRowStyle =
		kit_product_related_id !== null ? 'py-3' : isKit ? 'pt-6' : 'py-6';
	const selfStartImmagine = isKit && 'self-start';
	const showImmagineProdotto = kit_product_related_id === null && (
		<div className={`w-14 mr-3 hidden xl:flex ${selfStartImmagine}`}>
			{immagineFormatted}
		</div>
	);
	const showImmagineProdottoAccessorio = kit_product_related_id !== null && (
		<div className='w-14 mr-3 hidden xl:flex'>{immagineFormatted}</div>
	);
	const styleTextAccessori =
		kit_product_related_id === null
			? 'text-sm 3xl:text-base'
			: 'text-sm text-ribola-quicksand';

	return (
		<div
			className={`grid grid-cols-60 text-ribola-tundora ${showFullBorder} ${accessoryRowStyle}`}>
			<div className='col-span-6 sm:col-span-3 flex justify-center items-center text-red-700'>
				<i
					className='far fa-times text-2xl cursor-pointer'
					data-tip={carrelloTranslation.tooltipCancellaArticolo.itemName}
					onClick={() => onAskDeleteCartItem(nomeProdotto)}></i>
				<ReactTooltip uuid={`${idProdotto}_cancellaSingolo`} />
			</div>
			<div className='col-span-6 flex justify-between items-center'>
				<CheckboxField
					checkboxType='checkbox'
					checkboxName='selectCartRow'
					isChecked={isCheckedRows}
					isFocus={false}
					isReadOnly={true}
					isDefaultChecked={false}
					checkboxLabelStyle='pointer-events-none'
					checkboxStyle='border-2 text-2xl text-ribola-dove-gray border-ribola-dove-gray pointer-events-none cursor-default opacity-40'
				/>
				<ReactTooltip uuid={`${idProdotto}_selezionaSingolo`} />
				{showImmagineProdotto}
			</div>
			<div
				className={`col-span-33 sm:col-span-36 xl:col-span-23 flex items-center ${showBorderButtonKit} pr-3`}>
				{showImmagineProdottoAccessorio}
				<div className='flex flex-col justify-center'>
					<span
						className={`font-opensansbold mb-3 flex flex-col items-start ${styleTextAccessori}`}>
						{nomeProdotto}
						{showTagProntaConsegna}
					</span>
					{divMisure}
					{divColore}
					<span className='font-opensanssemibold text-sm'>
						{genericheTranslation.peso.itemName}:&nbsp;
						{parseFloat(peso).toFixed(2).toString().replace('.', ',')} kg
					</span>
					<span className='font-opensanssemibold text-sm 3xl:text-base xl:hidden'>
						Cod. {codiceProdotto}
					</span>
					{titleKitMontaggio}
				</div>
			</div>
			<div
				className={`col-span-7 items-center hidden xl:flex ${showBorderButtonKit}`}>
				<span className='font-opensanssemibold text-sm 3xl:text-base'>
					{codiceProdotto}
				</span>
			</div>
			<div
				className={`col-span-15 xl:col-span-7 flex flex-col lg:flex-row lg:items-center ${showBorderButtonKit}`}>
				<div className='flex items-center'>
					<div className='w-16 2xl:w-20'>
						{prontaConsegna === false ? (
							<InputField
								inputType='number'
								inputName='qta'
								inputValue={quantity}
								inputPlaceholder='1'
								inputStyle='border border-ribola-dove-gray mt-0'
								inputDecimalScale={0}
								inputDecimalSeparator={null}
								inputThousandSeparator={''}
								inputHandlerFunction={onChangeQuantity}
								inputMaxValue={999}
								inputMinValue={0}
								isNumberFormat={true}
								isAllowLeadingZero={false}
							/>
						) : (
							<span className='flex justify-center'>{quantity}</span>
						)}
					</div>
					{prontaConsegna === false && (
						<span
							className='text-ribola-heavy-metal pr-3'
							onClick={onAskUpdateCartItem}>
							<i
								className='far fa-sync-alt text-xl 2xl:text-2xl cursor-pointer ml-1 2xl:ml-3'
								data-tip={carrelloTranslation.tooltipAggiornaQta.itemName}></i>
							<ReactTooltip uuid={`${idProdotto}_qta`} />
						</span>
					)}
				</div>
				<span className='flex flex-col xl:hidden mt-3'>
					{prezzoTotaleOriginale}
					{prezzoTotale}
				</span>
			</div>
			<div
				className={`col-span-7 flex-col justify-center hidden xl:flex ${showBorderButtonKit}`}>
				{prezzoProdottoOriginale}
				{prezzoProdotto}
			</div>
			<div
				className={`col-span-7 flex-col justify-center hidden xl:flex ${showBorderButtonKit}`}>
				{prezzoTotaleOriginale}
				{prezzoTotale}
			</div>
		</div>
	);
};

export default CartRow;
