import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import MenuLeftProfilo from '../../components/shared/menuLeftProfilo/MenuLeftProfilo';
import AddressesDetail from '../../components/profile/addressesDetail/AddressesDetail';
import BloccoProfiloMenuLeft from '../../components/shared/bloccoProfiloMenuLeft/BloccoProfiloMenuLeft';

const Addresses = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const staticPages = useSelector((state) => state.staticPages);
	const user = useSelector((state) => state.user);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// controllo nello store se ho già il dettaglio della pagina indirizzi, altrimenti eseguo action creator
		const addressesPage = staticPages.addresses;

		if (addressesPage === undefined || addressesPage.detail === undefined) {
			// non ho i dati nello store, chiamo action creator fetchAddressesDetail
			setTimeout(
				() =>
					dispatch(
						allActions.profileDetailActions.fetchAddressesDetail(
							currentPage.currentLang,
							user.token_id,
							user.userData.id
						)
					),
				50
			);
		} else {
			// loading...
			dispatch(allActions.isFetchingAction.loadStoredData());

			// recupero dati da store
			dispatch(
				allActions.profileDetailActions.loadAddressesDetail(addressesPage)
			);
		}

		//console.log(addressesPage);
	}, [location]);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<MenuLeftProfilo />
					<AddressesDetail />
					<div className='col-span-60 flex lg:hidden'>
						<BloccoProfiloMenuLeft />
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Addresses;
