import { useSelector } from 'react-redux';
import InputField from '../../../components/shared/inputField/InputField';

const CampoDiametro = ({ diametroValue, onDiametroChange }) => {
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	return (
		<div className='grid grid-cols-60 mt-5'>
			<div className='col-start-1 col-end-29'>
				<InputField
					inputType='number'
					inputLabel={genericheTranslation.diametro.itemName.toUpperCase()}
					inputName='diametro'
					inputValue={diametroValue}
					inputHandlerFunction={onDiametroChange}
					inputPlaceholder='0,0'
					inputSuffix={` ${genericheTranslation.metri.itemName}`}
					inputStyle='border'
					isNumberFormat={true}
				/>
			</div>
		</div>
	);
};

export default CampoDiametro;
