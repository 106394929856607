const TabLink = ({ idTab, label, openTab, onTabClick }) => {
	return (
		<li className='text-center'>
			<a
				className={
					'font-opensanssemibold px-2 xs:px-4 sm:px-6 lg:px-10 2xl:px-16 py-4 block leading-normal text-xs xs:text-sm sm:text-base ' +
					(openTab === idTab
						? 'text-ribola-dove-gray bg-ribola-gallery-gray border-r border-white'
						: 'text-white bg-ribola-nobel border-r border-white')
				}
				onClick={(e) => {
					e.preventDefault();
					onTabClick(idTab);
				}}
				data-toggle='tab'
				href={`#link${idTab}`}
				role='tablist'>
				{label}
			</a>
		</li>
	);
};

export default TabLink;
