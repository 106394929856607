import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import MenuLeft from '../../components/shared/menuLeft/MenuLeft';
import CondizioniVenditaDetail from '../../components/condizioniVenditaDetail/CondizioniVenditaDetail';

const CondizioniVendita = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const staticPages = useSelector((state) => state.staticPages);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// controllo nello store se ho già il dettaglio di condizioni di vendita, altrimenti eseguo action creator
		const condizioniVenditaPage = staticPages.condizionivendita;

		if (condizioniVenditaPage) {
			// setto i metatags
			document.title = condizioniVenditaPage.metaTitle;
			document.getElementsByTagName('META')[2].content =
				condizioniVenditaPage.metaDesc;
		}

		if (
			condizioniVenditaPage === undefined ||
			condizioniVenditaPage.detail === undefined
		) {
			// non ho i dati del prodotto nello store, chiamo action creator fetchCondizioniVenditaDetail
			setTimeout(
				() =>
					dispatch(
						allActions.staticDetailActions.fetchCondizioniVenditaDetail(
							currentPage.currentLang,
							user?.userData?.id
						)
					),
				50
			);
		} else {
			// loading...
			dispatch(allActions.isFetchingAction.loadStoredData());

			// recupero dati da store
			dispatch(
				allActions.staticDetailActions.loadCondizioniVenditaDetail(
					condizioniVenditaPage
				)
			);
		}

		//console.log(condizioniVenditaPage);
	}, [location, staticPages]);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<MenuLeft />
					<CondizioniVenditaDetail />
				</div>
			</div>
		</Layout>
	);
};

export default CondizioniVendita;
