import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../state/actions';
import { URLASSOLUTO } from '../../../utils/urlassoluto';

const NavigationItem = ({
	itemId = '',
	itemLink,
	itemName,
	itemRouteName,
	itemStyle,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const currentPageLinkSeo = useSelector(
		(state) => state.currentPage.pageLinkSeo
	);

	useEffect(() => {
		/* console.log(currentPageLinkSeo);
		console.log(itemLink); */
	}, []);

	const onMenuItemClick = (event) => {
		event.preventDefault();

		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(
				itemId,
				itemRouteName,
				itemLink,
				history
			)
		);
	};

	// nessun link, mi trovo nella pagina corrente?
	if (currentPageLinkSeo === itemLink) {
		return (
			<span className={`${itemStyle} selected`}>
				<span>{itemName}</span>
			</span>
		);
	}

	return (
		<a
			href={URLASSOLUTO + itemLink}
			onClick={onMenuItemClick}
			className={itemStyle}>
			<span
				dangerouslySetInnerHTML={{
					__html: itemName,
				}}
			/>
		</a>
	);
};

export default NavigationItem;
