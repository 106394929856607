import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ReactTooltip from 'react-tooltip';

const OrderRow = ({
	orderId,
	orderIdFormatted,
	orderDate,
	orderState,
	orderStateColor,
	paymentMethod,
	tracking,
	dettaglioPdf,
}) => {
	const gestioneOrdiniTranslation = useSelector(
		(state) => state.translations.ordini
	);

	useEffect(() => {}, []);

	return (
		<div className='grid grid-cols-60 sm:gap-2 2xl:gap-4 text-ribola-tundora border-b border-ribola-dove-gray border-opacity-20 py-4'>
			<div className='col-span-22 sm:col-span-12 md:col-span-10 flex items-center'>
				{orderDate}
			</div>
			<div className='col-span-16 sm:col-span-12 md:col-span-10 flex items-center'>
				n. {orderIdFormatted}
			</div>
			<div className='col-span-22 sm:col-span-12 md:col-span-10 flex items-center'>
				{paymentMethod}
			</div>
			<div className='col-span-22 sm:col-span-12 md:col-span-10 items-center flex pt-3 sm:pt-0'>
				<a
					href={dettaglioPdf}
					target='_blank'
					className='underline text-ribola-astral'>
					{gestioneOrdiniTranslation.scaricapdf.itemName}&nbsp;
					<i className='far fa-file-pdf'></i>
				</a>
			</div>
			<div className='col-span-38 sm:col-span-12 md:col-span-10 items-center flex pt-3 sm:pt-0'>
				<span className={`bg-${orderStateColor} text-white px-3 py-1`}>
					{orderState}
				</span>
			</div>
			<div className='col-span-10 items-center hidden md:flex'>{tracking}</div>
		</div>
	);
};

export default OrderRow;
