import { FETCH_HOMEPAGE } from '../actions/types';

const INITIAL_STATE = [];

export const bannersHomeReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_HOMEPAGE:
			const { bannersHome } = action.payload.homepage;

			state = bannersHome;

			return state;

		default:
			return state;
	}
};
