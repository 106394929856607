import LazyLoadPicture from '../../../shared/lazyLoadPicture/LazyLoadPicture';

const AccessorioBoxItem = ({ id, name, codice, immagine, qta }) => {
	return (
		<div className='grid grid-cols-60'>
			<div className='col-span-15 2xl:col-span-20 pl-3 h-16'>
				<LazyLoadPicture
					imgName={immagine.file_name}
					imgPath={immagine.folder}
					imgWidth='59'
					imgHeight='59'
					imgStyle='flex-start'
					imgAlt={immagine.file_name}
				/>
			</div>
			<div className='col-span-40 text-xs flex flex-col justify-center pl-3'>
				<span className='font-opensansbold'>{name}</span>
				<span>{codice}</span>
			</div>
		</div>
	);
};

/***
 * 			<div className='col-span-15 2xl:col-span-10 text-xs flex items-center pl-3 2xl:pl-0'>
				<span>x{qta}</span>
			</div>
 */

export default AccessorioBoxItem;
