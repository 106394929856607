import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import allActions from '../../../state/actions';
import { BASENAME } from '../../../utils/basename';
import { URLASSOLUTO } from '../../../utils/urlassoluto';

const Logo = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const currentPageLinkSeo = useSelector(
		(state) => state.currentPage.pageLinkSeo
	);

	const onLogoClick = (event) => {
		event.preventDefault();

		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage('', 'homepage', '/', history)
		);
	};

	let linkLogo = '';
	if (currentPageLinkSeo === '/' || currentPageLinkSeo === '') {
		linkLogo = (
			<img
				src={`${BASENAME}/images/logo.svg`}
				width='275'
				height='188'
				className='flex-start'
				alt='Ribola Retificio Srl'
			/>
		);
	} else {
		linkLogo = (
			<a href={URLASSOLUTO} onClick={onLogoClick}>
				<img
					src={`${BASENAME}/images/logo.svg`}
					width='275'
					height='188'
					className='flex-start'
					alt='Ribola Retificio Srl'
				/>
			</a>
		);
	}

	return linkLogo;
};

export default Logo;
