import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../state/actions';
import SpinnerLoading from '../spinnerLoading/SpinnerLoading';
import { URLASSOLUTO } from '../../../utils/urlassoluto';

const CustomButton = ({
	buttonId = '',
	buttonLink = '/',
	buttonOnClickFunction = null,
	buttonName,
	buttonRouteName,
	buttonStyle,
	buttonTarget = '_self',
	propsToSend = null,
	iconaElement = null,
	iconaElementDirection = 'right',
	isButtonDisabled = false,
	isSpinnerLoading = false,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();

	useEffect(() => {}, []);

	const onButtonClick = (event) => {
		event.preventDefault();

		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(
				buttonId,
				buttonRouteName,
				buttonLink,
				history,
				propsToSend
			)
		);
	};

	const isCallBackFunction =
		buttonOnClickFunction !== null ? buttonOnClickFunction : onButtonClick;

	const isButtonDisabledStyle = isButtonDisabled
		? 'opacity-50 pointer-events-none'
		: 'opacity-100';

	const showSpinner = isSpinnerLoading && <SpinnerLoading />;

	const linkToShow =
		buttonLink !== null
			? buttonTarget === '_self'
				? URLASSOLUTO + buttonLink
				: buttonLink
			: '#';

	return (
		<a
			href={linkToShow}
			onClick={isCallBackFunction}
			target={buttonTarget}
			className={`${buttonStyle} ${isButtonDisabledStyle}`}>
			{iconaElement && iconaElementDirection === 'left' ? (
				<span
					dangerouslySetInnerHTML={{
						__html: iconaElement,
					}}
				/>
			) : (
				''
			)}
			<span
				dangerouslySetInnerHTML={{
					__html: buttonName,
				}}
			/>
			{iconaElement && iconaElementDirection === 'right' ? (
				<span
					dangerouslySetInnerHTML={{
						__html: iconaElement,
					}}
				/>
			) : (
				''
			)}
			{showSpinner}
		</a>
	);
};

export default CustomButton;
