import { CHANGE_PAGE } from './types';

const changePage =
	(pageId, routeName, pageLinkSeo, history, propsToSend = null) =>
	async (dispatch, getState) => {
		dispatch({
			type: CHANGE_PAGE,
			payload: {
				pageId,
				routeName,
				pageLinkSeo,
				propsToSend,
			},
		});

		history.push({
			pathname: pageLinkSeo,
			state: {
				propsToSend: propsToSend,
			},
		});
	};

export default {
	changePage,
};
