import { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import allActions from '../../state/actions';
import SocialItem from '../shared/socials/socialItem/SocialItem';
import { URLASSOLUTO } from '../../utils/urlassoluto';
import { BASENAME } from '../../utils/basename';
import { BASESTORAGE } from '../../utils/basestorage';

const Hamburger = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const currentPageLinkSeo = useSelector(
		(state) => state.currentPage.pageLinkSeo
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);
	const menuHamburger = useSelector((state) => state.menuHamburger);
	const siteParams = useSelector((state) => state.siteParams);

	// modal sweetalert2
	const MySwal = withReactContent(Swal);

	// lista lingue per menu mobile
	const array_languages = {
		IT: siteParams.sitoDominio_it,
		EN: siteParams.sitoDominio_en,
		FR: siteParams.sitoDominio_fr,
		DE: siteParams.sitoDominio_de,
	};

	const langsList = Object.keys(array_languages).map((language, key) => {
		return (
			language !== siteParams.current_language.toUpperCase() && (
				<option key={language} value={array_languages[language] + '/'}>
					{language}
				</option>
			)
		);
	});

	const langSelected = Object.keys(array_languages).map((language, key) => {
		return (
			language === siteParams.current_language.toUpperCase() && (
				<option key={language}>{language}</option>
			)
		);
	});

	const onChangeLanguage = (event) => {
		//console.log(event.target.value);
		window.location = event.target.value;
	};

	const onClickVoceMenu = (event, menuLi) => {
		event.preventDefault();

		MySwal.close();

		setTimeout(() => {
			// mostro spinner
			dispatch(allActions.isFetchingAction.loadSpinner());

			dispatch(
				allActions.changePageActions.changePage(
					menuLi.itemId,
					menuLi.routeName,
					menuLi.linkName,
					history
				)
			);
		}, 50);
	};

	const onToggleLivello = (event, menuLi) => {
		event.preventDefault();
		const elementToShow = document.getElementById(menuLi);
		elementToShow.classList.toggle('hidden');
		//console.log(elementToShow);
	};

	/*****
	 * HTML MENU MOBILE
	 */

	const vociMenu = Object.keys(menuHamburger).map((item) => {
		const menuLi = menuHamburger[item];

		// sottomenu secondo livello (quando presente)
		const subLevelArray =
			menuLi.productCategories?.length > 0
				? menuLi.productCategories
				: menuLi.magazineCategories;
		const submenu =
			menuLi.productCategories?.length > 0 ||
			menuLi.magazineCategories?.length > 0
				? Object.keys(subLevelArray).map((item2) => {
						const menuLi2 = subLevelArray[item2];

						// sottomenu terzo livello (quando presente)
						const submenu2 =
							menuLi2.childCategories?.length > 0
								? Object.keys(menuLi2.childCategories).map((item3) => {
										const menuLi3 = menuLi2.childCategories[item3];

										const linkToShow3 =
											currentPageLinkSeo === menuLi3.linkName ? (
												<span className='text-ribola-web-orange'>
													{menuLi3.itemName}
												</span>
											) : (
												<Fragment>
													<a
														href={`${URLASSOLUTO}${menuLi3.linkName}`}
														className='flex flex-1 opacity-70'
														onClick={(event) =>
															onClickVoceMenu(event, menuLi3)
														}>
														{menuLi3.itemName}
													</a>
												</Fragment>
											);
										return (
											<li
												key={_uniqueId('li3_')}
												className='text-left flex justify-between items-center py-1'>
												{linkToShow3}
											</li>
										);
								  })
								: null;

						const imgIcon = menuLi2?.icona !== undefined && (
							<img
								src={
									BASESTORAGE +
									'/' +
									menuLi2?.icona?.folder +
									'/' +
									menuLi2?.icona?.file_name
								}
								width='25'
								height='25'
								className='mr-2'
								alt={menuLi2?.icona?.file_name}
							/>
						);

						const linkToShow2 =
							currentPageLinkSeo === menuLi2.linkName ? (
								menuLi2?.childCategories === undefined ? (
									<span className='text-ribola-web-orange'>
										{menuLi2.itemName}
									</span>
								) : (
									<span className='flex flex-col w-full'>
										<span
											className='submenu2 flex justify-between items-center'
											onClick={(event) =>
												onToggleLivello(event, menuLi2.linkName)
											}>
											<span className='flex'>
												{imgIcon}
												<span>{menuLi2.itemName}</span>
											</span>
											<i className='fal fa-chevron-right'></i>
										</span>
										<ul
											className='flex-col terzoLivello text-sm rounded-sm py-2 pl-3 my-2 hidden'
											id={menuLi2.linkName}>
											<li className='flex pb-2'>
												<a
													href={`${URLASSOLUTO}${menuLi2.linkName}`}
													className='flex flex-1'
													onClick={(event) => onClickVoceMenu(event, menuLi2)}>
													{genericheTranslation.vaia.itemName}&nbsp;
													{menuLi2.itemName}
													&nbsp; »
												</a>
											</li>
											{submenu2}
										</ul>
									</span>
								)
							) : menuLi2?.childCategories === undefined ? (
								<Fragment>
									<a
										href={`${URLASSOLUTO}${menuLi2.linkName}`}
										className={`flex flex-1 text-${menuLi2.hoverColor}`}
										onClick={(event) => onClickVoceMenu(event, menuLi2)}>
										{imgIcon}
										{menuLi2.itemName}
									</a>
									<i className='fal fa-chevron-right'></i>
								</Fragment>
							) : (
								<span className='flex flex-col w-full'>
									<span
										className='submenu2 flex justify-between items-center'
										onClick={(event) =>
											onToggleLivello(event, menuLi2.linkName)
										}>
										<span className='flex'>
											{imgIcon}
											<span className={`text-${menuLi2.hoverColor}`}>
												{menuLi2.itemName}
											</span>
										</span>
										<i className='fal fa-chevron-right'></i>
									</span>
									<ul
										className='flex-col terzoLivello text-sm rounded-sm py-2 pl-3 my-2 hidden'
										id={menuLi2.linkName}>
										<li className='flex pb-2'>
											<a
												href={`${URLASSOLUTO}${menuLi2.linkName}`}
												className='flex flex-1'
												onClick={(event) => onClickVoceMenu(event, menuLi2)}>
												{genericheTranslation.vaia.itemName}&nbsp;
												{menuLi2.itemName}
												&nbsp; »
											</a>
										</li>
										{submenu2}
									</ul>
								</span>
							);

						return (
							<li
								key={_uniqueId('li2_')}
								className='text-left flex justify-between items-center py-1'>
								{linkToShow2}
							</li>
						);
				  })
				: null;
		// fine menu secondo livello

		// menu primo livello
		const linkToShow =
			currentPageLinkSeo === menuLi.linkName ? (
				menuLi?.productCategories === undefined &&
				menuLi?.magazineCategories === undefined ? (
					<Fragment>
						<span className='text-ribola-web-orange'>{menuLi.itemName}</span>
						<i className='fal fa-chevron-right'></i>
					</Fragment>
				) : (
					<span className='flex flex-col w-full'>
						<span
							className='submenu flex justify-between items-center'
							onClick={(event) => onToggleLivello(event, menuLi.linkName)}>
							{menuLi.itemName} <i className='fal fa-chevron-right'></i>
						</span>
						<ul
							className='flex-col secondoLivello bg-ribola-cultured-gray text-sm rounded-sm py-2 px-3 mt-2 hidden'
							id={menuLi.linkName}>
							<li className='flex py-2'>
								<a
									href={`${URLASSOLUTO}${menuLi.linkName}`}
									className='flex flex-1'
									onClick={(event) => onClickVoceMenu(event, menuLi)}>
									{genericheTranslation.vaia.itemName}&nbsp;{menuLi.itemName}
									&nbsp; »
								</a>
							</li>
							{submenu}
						</ul>
					</span>
				)
			) : menuLi?.productCategories === undefined &&
			  menuLi?.magazineCategories === undefined ? (
				<Fragment>
					<a
						href={`${URLASSOLUTO}${menuLi.linkName}`}
						className='flex flex-1'
						onClick={(event) => onClickVoceMenu(event, menuLi)}>
						{menuLi.itemName}
					</a>
					<i className='fal fa-chevron-right'></i>
				</Fragment>
			) : (
				<span className='flex flex-col w-full'>
					<span
						className='submenu flex justify-between items-center'
						onClick={(event) => onToggleLivello(event, menuLi.linkName)}>
						{menuLi.itemName} <i className='fal fa-chevron-right'></i>
					</span>
					<ul
						className='flex-col secondoLivello bg-ribola-cultured-gray text-sm rounded-sm py-2 px-3 mt-2 hidden'
						id={menuLi.linkName}>
						<li className='flex py-2'>
							<a
								href={`${URLASSOLUTO}${menuLi.linkName}`}
								className='flex flex-1'
								onClick={(event) => onClickVoceMenu(event, menuLi)}>
								{genericheTranslation.vaia.itemName}&nbsp;{menuLi.itemName}
								&nbsp; »
							</a>
						</li>
						{submenu}
					</ul>
				</span>
			);

		return (
			<li
				key={_uniqueId('li_')}
				className='text-left flex justify-between items-center py-1'>
				{linkToShow}
			</li>
		);
		// fine menu primo livello
	});

	// html menu
	const htmlMenu = (
		<div className='grid grid-cols-60'>
			<div className='col-span-60 flex justify-center'>
				<img
					src={`${BASENAME}/images/logo.svg`}
					width='130'
					height='90'
					className='flex-start'
					alt='Ribola Retificio Srl'
				/>
			</div>
			<div className='col-span-60 mt-4'>
				<span className='flex text-xl pb-1 border-b border-ribola-heavy-metal text-ribola-heavy-metal'>
					MENU
				</span>
			</div>
			<div className='col-span-60 mt-4'>
				<ul className='flex flex-col'>{vociMenu}</ul>
			</div>
			<div className='col-span-60 mt-8'>
				<div className='grid grid-cols-60 w-full'>
					<div className='col-span-40 flex'>
						<SocialItem
							socialLink='https://www.facebook.com/retificioribola/'
							socialIcon='fab fa-facebook-f'
						/>
						<SocialItem
							socialLink='https://www.instagram.com/retificioribola/'
							socialIcon='fab fa-instagram'
						/>
						<SocialItem
							socialLink='https://www.pinterest.it/0ghmmgi15wukg9djrxqkz6m46zyeoi/'
							socialIcon='fab fa-pinterest-p'
						/>
					</div>
					<div className='col-span-20'>
						<div className='customSelect'>
							<select onChange={onChangeLanguage} className='w-full'>
								{langSelected}
								{langsList}
							</select>
						</div>
					</div>
				</div>
				<div className='grid grid-cols-60 w-full'>
					<div className='col-span-60'>
						<a
							href='https://wa.me/393334870763'
							target='_blank'
							className='text-green-500 flex items-center w-full mt-3'>
							<i className='fab fa-whatsapp text-3xl inline-block mr-3'></i>
							<span>3334870763</span>
						</a>
					</div>
				</div>
			</div>
		</div>
	);

	/**
	 * FINE HTML MENU MOBILE
	 */

	const onClickMenu = (event) => {
		event.preventDefault();

		MySwal.fire({
			html: htmlMenu,
			showCancelButton: false,
			showConfirmButton: false,
			showCloseButton: true,
			position: 'top-start',
			customClass: {
				container: 'menuHamburger',
				htmlContainer: 'htmlHamburger',
			},
			showClass: {
				popup: 'animate__animated animate__slideInLeft animate__faster',
			},
			hideClass: {
				popup: 'animate__animated animate__slideOutLeft animate__faster',
			},
			didOpen: () => {
				//document.body.classList.add('modal-open');
			},
			didClose: () => {
				//document.body.classList.remove('modal-open');
			},
		}).then((event) => {});
	};

	return (
		<div
			className='flex flex-col justify-center items-center text-ribola-congress-blue font-opensansbold mx-3'
			onClick={onClickMenu}>
			<div className='hamburgerLines h-8 flex flex-col justify-between w-11/12 relative mb-1'>
				<input type='checkbox' autoComplete='off' className='h-full' />
				<span className='flex w-full h-auto border-t-3 border-ribola-congress-blue origin-top-left'></span>
				<span className='flex w-10/12 h-auto border-t-3 border-ribola-congress-blue'></span>
				<span className='flex w-full h-auto border-t-3 border-ribola-congress-blue origin-bottom-left'></span>
			</div>
			<span>MENU</span>
		</div>
	);
};

export default Hamburger;
