import { useSelector } from 'react-redux';
import InputField from '../../../components/shared/inputField/InputField';

const CampiMetriLineari = ({ metriValue, onMetriChange }) => {
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	return (
		<div className='grid grid-cols-60 mt-3'>
			<div className='col-start-1 col-end-29'>
				<InputField
					inputType='number'
					inputLabel={genericheTranslation.metrilineari.itemName.toUpperCase()}
					inputName='metri'
					inputValue={metriValue}
					inputHandlerFunction={onMetriChange}
					inputPlaceholder='0,0'
					inputSuffix={` ${genericheTranslation.ml.itemName}`}
					inputStyle='border'
					isNumberFormat={true}
				/>
			</div>
		</div>
	);
};

export default CampiMetriLineari;
