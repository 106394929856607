import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import MenuLeft from '../../components/shared/menuLeft/MenuLeft';
import ParentCategoryDetail from '../../components/parentCategoryDetail/ParentCategoryDetail';
import Error404Detail from '../../components/error404Detail/Error404Detail';

const ParentCategory = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const catalogue = useSelector((state) => state.catalogue);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// recupero struttura percorso da url (per via del back dal browser, devo sempre recuperare i dati via url)
		const pathNodes = location.pathname.split('/').filter((val) => val !== '');

		const reti_urlseo = pathNodes[0];
		const parent_product_category_urlseo = pathNodes[1];
		const parent_product_category_id = Object.keys(catalogue)
			.filter((key) => catalogue[key].urlSeo === parent_product_category_urlseo)
			.toString();

		// controllo nello store se ho già il dettaglio della parentCategory (isFetched = true), altrimenti eseguo action creator
		const parentCategory = catalogue[parent_product_category_id];

		console.log(parentCategory);

		if (parentCategory) {
			// setto i metatags
			document.title = parentCategory.metaTitle;
			document.getElementsByTagName('META')[2].content =
				parentCategory.metaDesc;
		}

		// controllo per pagina 404, se currentPage.routeName === 'error404Detail' vuol dire che ho inserito un url sbagliato, restituisco la pagina 404
		if (
			currentPage.routeName === 'error404Detail' ||
			parentCategory === undefined ||
			parentCategory === null
		) {
			// recupero pagina 404
			const wrongPage404 =
				reti_urlseo + '/' + parent_product_category_urlseo + '/';
			setTimeout(
				() =>
					dispatch(
						allActions.staticDetailActions.fetchError404Detail(
							currentPage.currentLang,
							user?.userData?.id,
							wrongPage404
						)
					),
				50
			);
		} else {
			// controllo i dati della categoria
			if (
				parentCategory === null ||
				parentCategory?.isFetched === undefined ||
				parentCategory?.isFetched === false
			) {
				// non ho i dati del prodotto nello store, chiamo action creator fetchParentCategoryDetail
				setTimeout(
					() =>
						dispatch(
							allActions.parentCategoryDetailActions.fetchParentCategoryDetail(
								currentPage.currentLang,
								user?.userData?.id,
								parentCategory.id
							)
						),
					50
				);
			} else {
				// loading...
				dispatch(allActions.isFetchingAction.loadStoredData());

				// recupero dati da store
				dispatch(
					allActions.parentCategoryDetailActions.loadParentCategoryDetail(
						parentCategory
					)
				);
			}
		}
	}, [location]);

	const isPage404 =
		currentPage.routeName === 'error404Detail' ? (
			<Error404Detail />
		) : (
			<ParentCategoryDetail />
		);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<MenuLeft />
					{isPage404}
				</div>
			</div>
		</Layout>
	);
};

export default ParentCategory;
