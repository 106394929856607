import { useSelector } from 'react-redux';
import NavigationItem from '../navigationItem/NavigationItem';
import SelectLanguage from '../selectLanguage/SelectLanguage';

const MainNavigation = () => {
	const navigationItems = useSelector(
		(state) => state.translations.mainNavigation
	);

	return (
		<div className='col-span-50 flex'>
			<nav className='flex'>
				<NavigationItem
					itemLink={navigationItems.home.itemLink}
					itemName={navigationItems.home.itemName.toUpperCase()}
					itemRouteName={navigationItems.home.itemRouteName}
					itemStyle='navTopItem text-sm 2xl:text-base mr-8 2xl:mr-16 pb-1'
				/>
				<NavigationItem
					itemLink={navigationItems.chisiamo.itemLink}
					itemName={navigationItems.chisiamo.itemName.toUpperCase()}
					itemRouteName={navigationItems.chisiamo.itemRouteName}
					itemStyle='navTopItem text-sm 2xl:text-base mr-8 2xl:mr-16 pb-1'
				/>
				<NavigationItem
					itemLink={navigationItems.prodotti.itemLink}
					itemName={navigationItems.prodotti.itemName.toUpperCase()}
					itemRouteName={navigationItems.prodotti.itemRouteName}
					itemStyle='navTopItem text-sm 2xl:text-base mr-8 2xl:mr-16 pb-1'
				/>
				<NavigationItem
					itemLink={navigationItems.magazine.itemLink}
					itemName={navigationItems.magazine.itemName.toUpperCase()}
					itemRouteName={navigationItems.magazine.itemRouteName}
					itemStyle='navTopItem text-sm 2xl:text-base mr-8 2xl:mr-16 pb-1'
				/>
				<NavigationItem
					itemLink={navigationItems.contatti.itemLink}
					itemName={navigationItems.contatti.itemName.toUpperCase()}
					itemRouteName={navigationItems.contatti.itemRouteName}
					itemStyle='navTopItem text-sm 2xl:text-base mr-8 2xl:mr-16 pb-1'
				/>
			</nav>
			<SelectLanguage />
		</div>
	);
};

export default MainNavigation;
