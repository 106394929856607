import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Briciole from '../../shared/briciole/Briciole';
import GestioneIndirizzi from '../../cart/gestioneIndirizzi/GestioneIndirizzi';

const AddressesDetail = () => {
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const menuProfiloItems = useSelector(
		(state) => state.translations.menuLeftProfilo
	);
	const gestioneIndirizziTranslation = useSelector(
		(state) => state.translations.gestioneIndirizzi
	);

	useEffect(() => {}, []);

	// controllo che esista la proprietà detail (altrimenti vuol dire che sto ancora recuperando i dati)
	if (!pageContent.detail) {
		return <div></div>;
	}

	return (
		<div
			className={`col-start-1 lg:col-start-15 4xl:col-start-16 col-end-61 3xl:col-end-59 4xl:col-end-57 pb-12`}>
			<Briciole />
			<div className='grid grid-cols-60 mt-0 sm:mt-3 xl:mt-6'>
				<div className='col-span-60 flex flex-col justify-center px-8 xl:px-12 3xl:px-20 xl:pt-8 pb-6 sm:pb-8 xl:pb-12 3xl:py-14'>
					<h1 className='text-ribola-congress-blue text-3xl md:text-4xl xl:text-5xl font-opensansbold'>
						<small className='leading-tight flex'>
							{menuProfiloItems.indirizzi.itemName.toUpperCase()}
						</small>
					</h1>
					<h2 className='text-lg md:text-xl mt-3'>
						<span className='leading-tight flex'>
							{gestioneIndirizziTranslation.sottotitoloituoiindirizzi.itemName}
						</span>
					</h2>
				</div>
			</div>
			<div className='grid grid-cols-60 sm:gap-2 2xl:gap-4 px-6 xl:pl-12 3xl:pl-20'>
				<GestioneIndirizzi />
			</div>
		</div>
	);
};

export default AddressesDetail;
