import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../state/actions';
import ReactTooltip from 'react-tooltip';
import Briciole from '../../shared/briciole/Briciole';
import BarraCheckout from '../barraCheckout/BarraCheckout';
import CheckboxField from '../../shared/checkboxField/CheckboxField';
import TextareaField from '../../shared/textareaField/TextareaField';
import CustomButton from '../../shared/customButton/CustomButton';
import ModalConfirm from '../../shared/modalConfirm/ModalConfirm';
import RiepilogoCarrello from '../riepilogoCarrello/RiepilogoCarrello';
import CartRow from './cartRow/CartRow';
import debounce from '../../../utils/debounce';
import { gtmRemoveFromCart } from '../../../gtm/gtm';

const CartDetail = () => {
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const tokenCarrello = useSelector((state) => state.user.token_id);
	const user = useSelector((state) => state.user);
	const order = useSelector((state) => state.order);
	const cart = useSelector((state) => state.cart);
	const promotion = useSelector((state) => state.promotion);
	const campiFormTranslation = useSelector(
		(state) => state.translations.campiFormRegistrazione
	);
	const carrelloTranslation = useSelector(
		(state) => state.translations.carrello
	);
	const mainNavigation = useSelector(
		(state) => state.translations.mainNavigation
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	// local state
	const [isSelectedRows, setIsSelectedRows] = useState(false);
	const [itemsToDelete, setItemsToDelete] = useState([]);
	const [note, setNote] = useState(order?.notes);

	useEffect(() => {
		/* console.log(itemsToDelete); */
	}, [isSelectedRows, itemsToDelete]);

	const onSelectCartRow = (cart_id, isSelectedRow) => {
		/* console.log('ho chiamato onSelectCartRow', [cart_id, isSelectedRow]); */
	};

	const onChangeNote = debounce(function (event) {
		setNote(event.target.value);

		// chiamo action creator per aggiornare store con le note
		dispatch(
			allActions.cartDetailActions.updateNotes(
				currentPage.currentLang,
				user?.userData?.id,
				tokenCarrello,
				event.target.value
			)
		);
	}, 2000);

	const onSelectCartRows = () => {
		// rimuovo aggiungo tutte le righe
		setIsSelectedRows(!isSelectedRows);

		console.log('onSelectCartRows', isSelectedRows);

		// aggiorno stato locale
		const array_selected_rows = Object.keys(cart).map((cartIndex) => {
			return cart[cartIndex].id;
		});
		if (isSelectedRows === false) {
			// aggiungo tutti gli id
			setItemsToDelete(array_selected_rows);
		} else {
			// rimuovo tutti
			setItemsToDelete([]);
		}

		console.log('array_selected_rows', array_selected_rows);
	};

	const onAskDeleteCartItems = () => {
		if (order.totalItems === 0) return;

		if (itemsToDelete.length > 0) {
			ModalConfirm({
				titleToShow: carrelloTranslation.modalTitoloRimozioneArticoli.itemName,
				messageToShow:
					carrelloTranslation.modalConfermaRimozioneArticoli.itemName,
				textConfirmButton: genericheTranslation.conferma.itemName,
				textCancelButton: genericheTranslation.annulla.itemName,
				onClickHandler: () => onDeleteCartItems(),
			});
		} else {
			ModalConfirm({
				titleToShow: carrelloTranslation.modalTitoloRimozioneArticoli.itemName,
				messageToShow:
					carrelloTranslation.modalErroreRimozioneArticoli.itemName,
				isShowConfirmButton: false,
				isShowCanceButton: false,
				isTimerModal: true,
				textConfirmButton: genericheTranslation.conferma.itemName,
				textCancelButton: genericheTranslation.annulla.itemName,
			});
		}
	};

	const onDeleteCartItems = () => {
		// gtm remove all items from cart
		// loop cart
		const totalElements = Object.keys(cart).length;
		Object.keys(cart).map((itemId, index) => {
			const cartElement = cart[itemId];
			console.log(cartElement);
			gtmRemoveFromCart({
				productData: {
					titoloBreve: cartElement.name,
					codice: cartElement.attributes.codiceProdotto,
				},
				categoryName: cartElement.attributes.titoloCategoria,
				prezzoCarrello: cartElement.price,
				quantitaCarrello: cartElement.quantity,
				callbackFunction: () => onGoToDeleteCartItems(index, totalElements),
			});
		});
	};

	const onGoToDeleteCartItems = (index, totalElementsToDelete) => {
		console.log(index, totalElementsToDelete);
		if (index + 1 <= totalElementsToDelete) {
			// chiamo action creator per aggiornare store
			dispatch(
				allActions.cartDetailActions.deleteCartItems(
					currentPage.currentLang,
					user?.userData?.id,
					tokenCarrello
				)
			);
		}
	};

	// variabili per kit
	let currentKitGroup = null;
	let showHeaderKit = false;

	const cartRows = Object.keys(cart).map((cartIndex) => {
		const cartRow = cart[cartIndex];

		// controllo se è presente un kit ed al primo elemento che trovo aggiungo una voce in più per mostrare l'header del kit
		if (
			cartRow.attributes?.kit_data === undefined ||
			cartRow.attributes?.kit_data === null
		) {
			currentKitGroup = null;
			showHeaderKit = false;
		} else {
			if (showHeaderKit === false && currentKitGroup === null) {
				showHeaderKit = true;
			} else {
				showHeaderKit = false;
			}
			if (currentKitGroup === null) {
				currentKitGroup = cartRow.attributes.kit_data;
			}
		}

		return (
			<CartRow
				key={cartIndex}
				idProdotto={cartRow.id}
				nomeProdotto={cartRow.name}
				titoloCategoria={cartRow.attributes.titoloCategoria}
				codiceProdotto={cartRow.attributes.codiceProdotto}
				dimensioni={cartRow.attributes.dimensione}
				unitaMisura={cartRow.attributes.unitaMisura}
				lunghezza={cartRow.attributes.lunghezza}
				larghezza={cartRow.attributes.larghezza}
				profondita={cartRow.attributes.profondita}
				metrilineari={cartRow.attributes.metrilineari}
				diametro={cartRow.attributes.diametro}
				colore={cartRow.attributes.colore}
				prezzoFisso={cartRow.attributes.prezzoFisso}
				prontaConsegna={cartRow.attributes.prontaConsegna}
				giacenza={cartRow.attributes.giacenza}
				peso={cartRow.attributes.pesoCollo}
				quantita={cartRow.quantity}
				imponibile={cartRow.price}
				imponibileOriginale={cartRow.attributes.imponibileOriginale}
				totale={cartRow.price * cartRow.quantity}
				totaleOriginale={
					cartRow.attributes.imponibileOriginale * cartRow.quantity
				}
				immagine={cartRow.attributes.immagine}
				isKit={showHeaderKit}
				kit_product_related_id={cartRow.attributes?.kit_product_related_id}
				kit_product_cart_id={cartRow.attributes?.kit_product_cart_id}
				kit_data={cartRow.attributes?.kit_data}
				isCheckedRows={isSelectedRows}
				onSelectRowFunction={onSelectCartRow}
			/>
		);
	});

	const messageNoItems =
		order.totalItems === 0 ? (
			<div className='flex justify-center py-8 text-xl font-opensansbold'>
				{carrelloTranslation.messaggioCarrelloVuoto.itemName}
			</div>
		) : (
			''
		);

	// controllo promozione a tempo spedizione gratuita sopra soglia (mostro banner)
	const showTrasportoGratuito = promotion?.sogliaCarrello !== undefined &&
		order.totalItems > 0 && (
			<div className='w-full relative flex items-center bg-ribola-chelsea-cucumber text-white text-sm sm:text-base mt-8 px-6 py-3'>
				<i className='fal fa-shipping-timed text-2xl mr-6'></i>
				<span className='flex flex-col justify-center'>
					<span>
						{genericheTranslation.superatii.itemName.toUpperCase()}
						<b className='text-xl mx-2'>{promotion.sogliaCarrello} €</b>
						{genericheTranslation.spedizionegratuita.itemName.toUpperCase()}
					</span>
					<span className='text-sm'>
						{genericheTranslation.promozioneatempovalida.itemName}
						&nbsp;
						{genericheTranslation.dal.itemName}&nbsp;
						<strong>{promotion.dataDa}</strong>{' '}
						{genericheTranslation.al.itemName}
						&nbsp;
						<strong>{promotion.dataA}</strong>
					</span>
				</span>
			</div>
		);

	// mostra note ordine solo se ci sono prodotti a carrello
	const showNotes = order.totalItems > 0 && (
		<div className='grid grid-cols-60 mt-6'>
			<div className='col-span-60 md:col-span-30 flex flex-col justify-between bg-ribola-gallery-gray text-black px-8 py-6 mb-4 xl:mb-0'>
				<TextareaField
					textareaName='note'
					textareaValue={note}
					textareaPlaceholder={
						campiFormTranslation.inserirenoterelativeordine.itemName
					}
					textareaRows={6}
					textareaHandlerFunction={(event) => onChangeNote(event)}
					textareaStyle='border-none bg-white resize-none w-full p-3'
				/>
			</div>
		</div>
	);

	return (
		<div className='col-start-3 4xl:col-start-5 col-end-59 4xl:col-end-57 pb-12'>
			<Briciole />
			<BarraCheckout currentCheckoutPage={currentPage.routeName} />
			<form>
				<div className='grid grid-cols-60 mt-20'>
					<div className='col-start-1 col-end-61 xl:col-end-45'>
						<div className='grid grid-cols-60 font-opensansbold text-ribola-tundora border-b border-ribola-dove-gray pb-2'>
							<div className='col-span-6 sm:col-span-3 flex justify-center items-center text-red-700'>
								<i
									className='fal fa-trash-alt text-2xl cursor-pointer'
									data-tip={
										carrelloTranslation.tooltipCancellaArticoli.itemName
									}
									onClick={onAskDeleteCartItems}></i>
								<ReactTooltip uuid={`123_cancellaTutti`} />
							</div>
							<div
								className='col-span-6 flex justify-between items-center'
								data-tip={
									carrelloTranslation.tooltipSelezionaArticoli.itemName
								}>
								<CheckboxField
									checkboxType='checkbox'
									checkboxName='selectAllCartRows'
									checkboxHandlerFunction={onSelectCartRows}
									isChecked={isSelectedRows}
									isFocus={false}
									isDefaultChecked={false}
									checkboxStyle='border-2 text-2xl text-ribola-dove-gray border-ribola-dove-gray cursor-pointer'
								/>
								<ReactTooltip uuid={`123_selezionaTutti`} />
							</div>
							<div className='col-span-33 sm:col-span-36 xl:col-span-23 text-sm sm:text-base flex items-center'>
								<span>
									{carrelloTranslation.thProdotto.itemName.toUpperCase()}
								</span>
							</div>
							<div className='col-span-7 items-center hidden xl:flex'>
								<span>
									{carrelloTranslation.thCodice.itemName.toUpperCase()}
								</span>
							</div>
							<div className='col-span-15 xl:col-span-7 text-sm sm:text-base flex items-center'>
								<span>
									{carrelloTranslation.thQuantita.itemName.toUpperCase()}
								</span>
							</div>
							<div className='col-span-7 items-center hidden xl:flex'>
								<span>
									{carrelloTranslation.thImponibile.itemName.toUpperCase()}
								</span>
							</div>
							<div className='col-span-7 items-center hidden xl:flex'>
								<span>
									{carrelloTranslation.thTotale.itemName.toUpperCase()}
								</span>
							</div>
						</div>
						{cartRows}
						{messageNoItems}
						{showTrasportoGratuito}
						{showNotes}
						<div className='flex justify-end'>
							<CustomButton
								buttonLink={mainNavigation.prodotti.itemLink}
								buttonRouteName={mainNavigation.prodotti.itemRouteName}
								buttonName={`${carrelloTranslation.pulsanteAggiungiAltriProdotti.itemName.toUpperCase()} <i class="far fa-plus ml-3"></i>`}
								buttonStyle='w-full md:w-6/12 xl:w-5/12 3xl:w-5/12 flex justify-center items-center text-white bg-ribola-nobel hover:bg-ribola-dove-gray text-center transition duration-300 sm:text-lg 4xl:text-xl font-opensanssemibold mt-8 mb-8 py-4 px-6 border-r-2 border-ribola-gallery-gray'
							/>
						</div>
					</div>
					<div className='col-start-1 col-end-61 xl:col-start-46 xl:col-end-61 bg-ribola-gallery-gray py-8 3xl:py-12 px-6 3xl:px-8'>
						<RiepilogoCarrello />
					</div>
				</div>
			</form>
		</div>
	);
};

export default CartDetail;
