import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import Slideshow from '../../shared/slideshow/Slideshow';
import LazyLoadImg from '../../shared/lazyLoadImg/LazyLoadImg';
import { BASENAME } from '../../../utils/basename';

const ProductGallery = ({ promotion = null }) => {
	const deviceType = useSelector((state) => state.device);
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);
	const photoGallery = pageContent.detail.photoGallery;

	useEffect(() => {
		console.log(promotion);
	}, []);

	const imgWidth = {
		xl4: '743',
		xl3: '673',
		xl2: '673',
		xl: '556',
		lg: '784',
		md: '767',
		sm: '640',
		xs: '432',
		xxs: '480',
	};

	const imgHeight = {
		xl4: '580',
		xl3: '525',
		xl2: '525',
		xl: '434',
		lg: '612',
		md: '598',
		sm: '499',
		xs: '337',
		xxs: '374',
	};

	//console.log(photoGallery);

	const elencoGallery =
		photoGallery?.length > 0 ? (
			photoGallery.map(({ imgName, imgPath }) => {
				const imgWeb = imgName.replace('.jpg', '.webp');
				return (
					<LazyLoadImg
						key={_uniqueId('galleryItem_')}
						imgName={imgWeb}
						imgPath={imgPath}
						imgWidth={imgWidth}
						imgHeight={imgHeight}
						imgStyle='flex-start w-full cursor-pointer'
						imgAlt={pageContent.detail.titolo}
						visibleByDefaultProp={true}
					/>
				);
			})
		) : (
			<LazyLoadImg
				key='placeholder_prodotto'
				imgName='placeholder_scheda_prodotto.jpg'
				imgPath='statiche'
				imgWidth={imgWidth}
				imgHeight={imgHeight}
				imgStyle='flex-start w-full cursor-pointer'
				imgAlt={pageContent.detail.titolo}
				visibleByDefaultProp={true}
			/>
		);

	/**
   * 			imgRoute='immagini-responsive-type'
			imgType='fitnoresizeToWebp'
   * 
   */

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 1,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1536 },
			items: 1,
		},
		bigtablet: {
			breakpoint: { max: 1536, min: 1024 },
			items: 1,
		},
		tablet: {
			breakpoint: { max: 1024, min: 640 },
			items: 1,
		},
		mobile: {
			breakpoint: { max: 640, min: 0 },
			items: 1,
		},
	};

	const removeArrowsOnDeviceType = [];

	const scontoFasciaArancione =
		pageContent.detail.sconto > 0 ? (
			<div className='scontoFasciaArancione pointer-events-none z-20'>
				<span className='scontoFasciaInterna'>
					<span className='titoloFasciaInterna'>
						<span className='leading-none'>
							{pageContent.detail.sconto}
							{pageContent.detail.tipologia_sconto === 'Percentuale'
								? '%'
								: '€'}
						</span>
					</span>
					<span className='leading-none'>
						{genericheTranslation.sconto.itemName}
					</span>
				</span>
				<img
					src={`${BASENAME}/images/sconto_fascia_arancione.svg`}
					width='180'
					height='180'
					className='flex-start'
					alt='Sconto'
				/>
			</div>
		) : (
			''
		);

	// non la uso più, controllo se il prodotto fa parte di una promozione a tempo, direttamente da pageContent.detail.promozioneATempo
	/* const messaggioPromoProdotto = promotion?.elencoProdotti?.filter((item) => {
		return item.id === pageContent.id;
	})[0]; */

	const messaggioPromozioneATempo =
		promotion?.id && pageContent.detail.promozioneATempo ? (
			<span className='absolute top-2 right-2 bg-ribola-amber text-ribola-heavy-metal rounded-md text-xs p-2'>
				{genericheTranslation.promozioneatempovalida.itemName}
				<br /> {genericheTranslation.dal.itemName}&nbsp;
				<strong>{promotion.dataDa}</strong> {genericheTranslation.al.itemName}
				&nbsp;
				<strong>{promotion.dataA}</strong>
			</span>
		) : (
			''
		);

	const hasMoreThanOncePhoto = photoGallery?.length > 1 ? 'pb-16 sm:pb-20' : '';

	return (
		<div className='col-span-60 relative mt-3'>
			{scontoFasciaArancione}
			<Slideshow
				slideList={elencoGallery}
				swipeableProp={true}
				draggableProp={false}
				showDotsProp={false}
				infiniteProp={false}
				autoPlayProp={false}
				autoPlaySpeedProp={3000}
				arrowsProp={true}
				partialVisible={false}
				responsiveProp={responsive}
				deviceTypeProp={deviceType}
				removeArrowOnDeviceTypeProp={removeArrowsOnDeviceType}
				containerClass={`slideshowProductDetail ${hasMoreThanOncePhoto}`}
				itemClass='transition-all productImageBig'
			/>
			{messaggioPromozioneATempo}
		</div>
	);
};

export default ProductGallery;
