import { useEffect } from 'react';
import Carousel from 'react-multi-carousel';

const Slideshow = ({
	slideList,
	containerClass,
	itemClass,
	dotListClass,
	swipeableProp,
	draggableProp,
	showDotsProp,
	infiniteProp,
	autoPlayProp,
	autoPlaySpeedProp,
	responsiveProp,
	arrowsProp,
	partialVisibleProp,
	customLeftArrowProp,
	customRightArrowProp,
	removeArrowOnDeviceTypeProp,
	deviceTypeProp,
	transitionDurationProp = '500',
}) => {
	useEffect(() => {
		console.log('Slideshow rerender');
	}, []);

	return (
		<Carousel
			swipeable={swipeableProp}
			draggable={draggableProp}
			showDots={showDotsProp}
			responsive={responsiveProp}
			ssr={true}
			arrows={arrowsProp ? arrowsProp : false}
			infinite={infiniteProp}
			centerMode={false}
			additionalTransfrom={0}
			autoPlay={autoPlayProp}
			autoPlaySpeed={autoPlaySpeedProp ? autoPlaySpeedProp : 1000}
			keyBoardControl={false}
			transitionDuration={transitionDurationProp}
			minimumTouchDrag={40}
			containerClass={containerClass ? containerClass : 'carousel-container'}
			customLeftArrow={customLeftArrowProp ? customLeftArrowProp : null}
			customRightArrow={customRightArrowProp ? customRightArrowProp : null}
			removeArrowOnDeviceType={
				removeArrowOnDeviceTypeProp ? removeArrowOnDeviceTypeProp : ''
			}
			partialVisible={partialVisibleProp ? partialVisibleProp : false}
			deviceType={deviceTypeProp ? deviceTypeProp : 'desktop'}
			dotListClass={dotListClass ? dotListClass : ''}
			itemClass={itemClass ? itemClass : 'custom-dot-list-style'}
			customTransition='transform 300ms ease-in-out'>
			{slideList}
		</Carousel>
	);
};

export default Slideshow;
