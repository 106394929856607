import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
//import { composeWithDevTools } from 'redux-devtools-extension/logOnly';
import rootReducer from '../reducers';

export default function configureStore(initialData) {
	const store = createStore(
		rootReducer(initialData),
		initialData,
		composeWithDevTools(
			applyMiddleware(
				reduxThunk // async actions creator
			)
		)
	);

	return store;
}
