import axios from '../../utils/axiosBase';
import {
	FETCH_MAGAZINE_DETAIL,
	LOAD_MAGAZINE_DETAIL,
	FETCH_MAGAZINE_CATEGORY_DETAIL,
	LOAD_MAGAZINE_CATEGORY_DETAIL,
	FETCH_MAGAZINE_ARTICLE_DETAIL,
	LOAD_MAGAZINE_ARTICLE_DETAIL,
} from './types';

const fetchMagazineDetail =
	(currentLang, userId, history) => async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
      userId: userId,
		};

		// chiamo web service
		const response = await axios.post(`api/magazinedetail/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_MAGAZINE_DETAIL,
			payload: response.data,
		});
	};

const loadMagazineDetail =
	(magazineLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_MAGAZINE_DETAIL,
			payload: {
				magazineLoaded,
			},
		});
	};

const fetchMagazineCategoryDetail =
	(currentLang, userId, category_id, history) => async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
      userId: userId,
		};

		// chiamo web service
		const response = await axios.post(
			`api/magazinecategorydetail/${category_id}/`,
			payload,
			{
				withCredentials: true,
			}
		);

		dispatch({
			type: FETCH_MAGAZINE_CATEGORY_DETAIL,
			payload: response.data,
		});
	};

const loadMagazineCategoryDetail =
	(magazineCategoryLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_MAGAZINE_CATEGORY_DETAIL,
			payload: {
				magazineCategoryLoaded,
			},
		});
	};

const fetchMagazineArticleDetail =
	(currentLang, userId, magazine_id, history) => async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
      userId: userId,
		};

		// chiamo web service
		const response = await axios.post(
			`api/magazinearticledetail/${magazine_id}/`,
			payload,
			{
				withCredentials: true,
			}
		);

		dispatch({
			type: FETCH_MAGAZINE_ARTICLE_DETAIL,
			payload: response.data,
		});
	};

const loadMagazineArticleDetail =
	(magazineArticleLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_MAGAZINE_ARTICLE_DETAIL,
			payload: {
				magazineArticleLoaded,
			},
		});
	};

export default {
	fetchMagazineDetail,
	fetchMagazineCategoryDetail,
	fetchMagazineArticleDetail,
	loadMagazineDetail,
	loadMagazineCategoryDetail,
	loadMagazineArticleDetail,
};
