import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import MenuLeft from '../../components/shared/menuLeft/MenuLeft';
import ContattiOKDetail from '../../components/contattiOKDetail/ContattiOKDetail';

const ContattiOK = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const staticPages = useSelector((state) => state.staticPages);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// controllo nello store se ho già il dettaglio di error404, altrimenti eseguo action creator
		const contattiOKPage = staticPages.contattiok;

		if (contattiOKPage) {
			// setto i metatags
			document.title = contattiOKPage.metaTitle;
			document.getElementsByTagName('META')[2].content =
				contattiOKPage.metaDesc;
		}

		if (contattiOKPage === undefined || contattiOKPage.detail === undefined) {
			// non ho i dati del prodotto nello store, chiamo action creator fetchContattiOKDetail
			setTimeout(
				() =>
					dispatch(
						allActions.staticDetailActions.fetchContattiOKDetail(
							currentPage.currentLang,
							user?.userData?.id
						)
					),
				50
			);
		} else {
			// loading...
			dispatch(allActions.isFetchingAction.loadStoredData());

			// recupero dati da store
			dispatch(
				allActions.staticDetailActions.loadContattiOKDetail(contattiOKPage)
			);
		}

		//console.log(contattiOKPage);
	}, [location, staticPages]);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<MenuLeft />
					<ContattiOKDetail />
				</div>
			</div>
		</Layout>
	);
};

export default ContattiOK;
