import { useState } from 'react';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import _uniqueId from 'lodash/uniqueId';
import LazyLoadPicture from '../../../shared/lazyLoadPicture/LazyLoadPicture';
import InspirationProductItem from '../inspirationProductItem/InspirationProductItem';

const InspirationItem = ({
	inspirationImage,
	imgPath,
	imgRoute,
	imgType,
	inspirationProducts,
}) => {
	const [currentMarkerId, setCurrentMarkerId] = useState(null);

	const imgWidth = {
		xl4: '952',
		xl3: '820',
		xl2: '760',
		xl: '632',
		lg: '504',
		md: '1024',
		sm: '768',
		xs: '640',
		xxs: '480',
	};

	const imgHeight = {
		xl4: '665',
		xl3: '560',
		xl2: '531',
		xl: '441',
		lg: '352',
		md: '715',
		sm: '768',
		xs: '640',
		xxs: '480',
	};

	const onShowDetail = (currentMarkerId) => {
		//console.log(currentMarkerId);
		setCurrentMarkerId(currentMarkerId);
	};

	let elencoInspirationProducts;
	if (inspirationProducts.length > 0) {
		elencoInspirationProducts = inspirationProducts.map(
			(inspirationProduct, id) => (
				<InspirationProductItem
					key={_uniqueId('inspirationProductItem_')}
					markerId={id}
					detailToShowId={currentMarkerId}
					showDetail={onShowDetail}
					{...inspirationProduct}
				/>
			)
		);
	}

	return (
		<div className='inspirationItem relative'>
			<LazyLoadPicture
				imgName={inspirationImage}
				imgPath={imgPath}
				imgRoute={imgRoute}
				imgType={imgType}
				imgWidth={imgWidth}
				imgHeight={imgHeight}
				imgStyle='flex-start'
				imgAlt='Inspiration'
			/>
			{elencoInspirationProducts}
		</div>
	);
};

export default trackWindowScroll(InspirationItem);
