import axios from '../../utils/axiosBase';
import {
	FETCH_CHECKOUT_DETAIL,
	LOAD_CHECKOUT_DETAIL,
	FETCH_ORDER_CLOSED_DETAIL,
	LOAD_ORDER_CLOSED_DETAIL,
	STRIPE_PAYMENT_INTENT_CREATE,
	STRIPE_OK,
	PAYPAL_OK,
	BONIFICO_OK,
	RIBA_OK,
	CONTRASSEGNO_OK,
	ORDER_RESET,
} from './types';

const fetchCheckoutDetail =
	(currentLang, tokenCarrello, userId, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			tokenCarrello: tokenCarrello,
			userId: userId,
		};

		// chiamo web service
		const response = await axios.post(`api/checkoutdetail/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_CHECKOUT_DETAIL,
			payload: response.data,
		});
	};

const loadCheckoutDetail =
	(checkoutLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_CHECKOUT_DETAIL,
			payload: {
				checkoutLoaded,
			},
		});
	};

const stripePaymentIntentCreate =
	(currentLang, tokenCarrello, userId, noteOrdine, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			tokenCarrello: tokenCarrello,
			userId: userId,
			noteOrdine: noteOrdine,
		};

		// chiamo web service
		const response = await axios.post(
			`api/stripepaymentintentcreate/`,
			payload,
			{
				withCredentials: true,
			}
		);

		dispatch({
			type: STRIPE_PAYMENT_INTENT_CREATE,
			payload: response.data,
		});
	};

const stripeOk =
	(currentLang, tokenCarrello, userId, resultStripe, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			tokenCarrello: tokenCarrello,
			userId: userId,
			resultStripe: resultStripe,
			paymentMethod: 'stripe',
		};

		// chiamo web service
		const response = await axios.post(`stripeok/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: STRIPE_OK,
			payload: response.data,
		});
	};

const paypalOk =
	(currentLang, tokenCarrello, userId, resultPaypal, noteOrdine, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			tokenCarrello: tokenCarrello,
			userId: userId,
			resultPaypal: resultPaypal,
			paymentMethod: 'paypal',
			noteOrdine: noteOrdine,
		};

		// chiamo web service
		const response = await axios.post(`paypalok/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: PAYPAL_OK,
			payload: response.data,
		});
	};

const bonificoOk =
	(currentLang, tokenCarrello, userId, noteOrdine, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			tokenCarrello: tokenCarrello,
			userId: userId,
			paymentMethod: 'bonifico',
			noteOrdine: noteOrdine,
		};

		// chiamo web service
		const response = await axios.post(`bonificook/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: BONIFICO_OK,
			payload: response.data,
		});
	};

const ribaOk =
	(currentLang, tokenCarrello, userId, noteOrdine, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			tokenCarrello: tokenCarrello,
			userId: userId,
			paymentMethod: 'riba',
			noteOrdine: noteOrdine,
		};

		// chiamo web service
		const response = await axios.post(`ribaok/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: RIBA_OK,
			payload: response.data,
		});
	};

const contrassegnoOk =
	(currentLang, tokenCarrello, userId, noteOrdine, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			tokenCarrello: tokenCarrello,
			userId: userId,
			paymentMethod: 'contrassegno',
			noteOrdine: noteOrdine,
		};

		// chiamo web service
		const response = await axios.post(`contrassegnook/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: CONTRASSEGNO_OK,
			payload: response.data,
		});
	};

const fetchOrderClosedDetail =
	(currentLang, tokenCarrello, userId, targetPageOrderClosed, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			tokenCarrello: tokenCarrello,
			userId: userId,
			targetPage: targetPageOrderClosed,
		};

		// chiamo web service
		const response = await axios.post(`orderclosed/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_ORDER_CLOSED_DETAIL,
			payload: response.data,
		});
	};

const loadOrderClosedDetail =
	(orderClosedLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_ORDER_CLOSED_DETAIL,
			payload: {
				orderClosedLoaded,
			},
		});
	};

const orderReset = (history) => async (dispatch, getState) => {
	dispatch({
		type: ORDER_RESET,
	});
};

export default {
	fetchCheckoutDetail,
	loadCheckoutDetail,
	fetchOrderClosedDetail,
	loadOrderClosedDetail,
	stripePaymentIntentCreate,
	stripeOk,
	paypalOk,
	bonificoOk,
	ribaOk,
	contrassegnoOk,
	orderReset,
};
