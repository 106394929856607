import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import TitoloBlocco from '../../shared/titoloBlocco/TitoloBlocco';
import ProductBoxSmallItem from '../../shared/productBoxSmallItem/ProductBoxSmallItem';
import Slideshow from '../../shared/slideshow/Slideshow';

const ProductSlideshowOutlet = () => {
	const deviceType = useSelector((state) => state.device);
	const prodottiOutlet = useSelector(
		(state) => state.currentPage.pageContent.detail.prontaConsegnaCorrelati
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	useEffect(() => {
		//console.log(prodottiOutlet);
	}, []);

	// controllo che lo store sia aggiornato e che ci siano i prodottiOutlet
	if (prodottiOutlet === undefined) {
		return <div></div>;
	}

	const elencoProdottiProntaConsegna = prodottiOutlet.map(
		(
			{
				id,
				measure_unit_id,
				isProntaConsegna,
				image,
				detail,
				routeName,
				linkSeo,
			},
			index
		) => {
			const titolo_breve = detail.titolo_breve;
			const codice = detail.codice;
			const imponibile = detail.imponibile;
			const sconto = detail.sconto;
			const tipologiaScontoP = detail.tipologia_sconto;
			const prezzoScontato = detail.prezzoScontato;
			const measureUnitId = measure_unit_id;
			const measure_unit = detail.measure_unit;
			const prontaConsegna = isProntaConsegna;
			const lunghezza = detail.lunghezza;
			const larghezza = detail.larghezza;
			const profondita = detail.profondita;
			const metrilineari = detail.metrilineari;

			return (
				<ProductBoxSmallItem
					key={_uniqueId('productOutletItem_')}
					id={id}
					index={index}
					immagine={image}
					titoloBreve={titolo_breve}
					codice={codice}
					imponibile={imponibile}
					sconto={sconto}
					tipologiaScontoPromozione={tipologiaScontoP}
					prezzoScontato={prezzoScontato}
					unitaMisura={measure_unit}
					measure_unit_id={measureUnitId}
					isProntaConsegna={prontaConsegna}
					lunghezza={lunghezza}
					larghezza={larghezza}
					profondita={profondita}
					metrilineari={metrilineari}
					routeName={routeName}
					linkSeo={linkSeo}
					categoriaTitolo={detail?.titoloCategoria}
				/>
			);
		}
	);

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 3,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1540 },
			items: 3,
			partialVisibilityGutter: 0,
		},
		minipc: {
			breakpoint: { max: 1540, min: 1280 },
			items: 2,
		},
		bigtablet: {
			breakpoint: { max: 1280, min: 1024 },
			items: 2,
		},
		tablet: {
			breakpoint: { max: 1024, min: 768 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 768, min: 0 },
			items: 1,
		},
	};

	const removeArrowsOnDeviceType = [];

	// nessun prodotto correlato?
	if (elencoProdottiProntaConsegna.length === 0) {
		return <div></div>;
	}

	return (
		<Fragment>
			<div className='flex flex-col'>
				<TitoloBlocco
					titoloStyle='titoloBlocco flex text-ribola-alto text-2xl sm:text-4xl px-6 xl:px-0 mt-12 xl:mt-8'
					titolo={genericheTranslation.inprontaconsegna.itemName.toUpperCase()}
				/>
				<TitoloBlocco
					titoloStyle='sottoTitoloBlocco flex text-ribola-dove-gray px-6 xl:px-0 mt-2 text-xl sm:text-2xl'
					titolo={genericheTranslation.sceglinostriprodotti.itemName.toUpperCase()}
				/>
			</div>
			<Slideshow
				slideList={elencoProdottiProntaConsegna}
				swipeableProp={true}
				draggableProp={false}
				showDotsProp={false}
				infiniteProp={false}
				autoPlayProp={false}
				autoPlaySpeedProp={3000}
				arrowsProp={true}
				partialVisible={false}
				responsiveProp={responsive}
				deviceTypeProp={deviceType}
				removeArrowOnDeviceTypeProp={removeArrowsOnDeviceType}
				containerClass='slideshowOutlet pt-3'
				itemClass='transition-all itemProductSmallItem flex justify-center p-4'
			/>
		</Fragment>
	);
};

export default ProductSlideshowOutlet;
