import axios from '../../utils/axiosBase';
import { FETCH_CATEGORY_DETAIL, LOAD_CATEGORY_DETAIL } from './types';

const fetchCategoryDetail =
	(currentLang, userId, category_id, pronta_consegna_id = null, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
		};

		// chiamo web service
		let response;
		if (pronta_consegna_id) {
			response = await axios.post(
				`api/categorydetail/${category_id}/${pronta_consegna_id}/`,
				payload,
				{
					withCredentials: true,
				}
			);
		} else {
			response = await axios.post(
				`api/categorydetail/${category_id}/`,
				payload,
				{
					withCredentials: true,
				}
			);
		}

		dispatch({
			type: FETCH_CATEGORY_DETAIL,
			payload: response.data,
		});
	};

const loadCategoryDetail =
	(categoryLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_CATEGORY_DETAIL,
			payload: {
				categoryLoaded,
			},
		});
	};

export default {
	fetchCategoryDetail,
	loadCategoryDetail,
};
