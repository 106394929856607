import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import CustomButton from '../customButton/CustomButton';

const CartButton = (props) => {
	const order = useSelector((state) => state.order);
	const fasiCarrelloTranslation = useSelector(
		(state) => state.translations.fasiCarrello
	);

	useEffect(() => {}, [order.subtotalCart]);

	const subtotaleCarrello = parseFloat(order.subtotalCart)
		.toFixed(2)
		.toString()
		.replace('.', ',');

	return (
		<div className='flex items-center text-white lg:text-ribola-dove-gray'>
			<CustomButton
				buttonLink={fasiCarrelloTranslation.carrello.linkName}
				buttonName={`${fasiCarrelloTranslation.carrello.itemName.toUpperCase()} (${subtotaleCarrello}€)`}
				buttonRouteName={fasiCarrelloTranslation.carrello.routeName}
				buttonStyle='cartButton hidden lg:flex items-center text-sm 2xl:text-base pl-3 xl:pl-4 3xl:pl-6 pr-3 xl:pr-5'
				iconaElement={`<span class='ml-3'><i class='fal fa-shopping-cart text-2xl'></i></span>`}
				iconaElementDirection='right'
			/>
			<CustomButton
				buttonLink={fasiCarrelloTranslation.carrello.linkName}
				buttonName={`(${subtotaleCarrello}€)`}
				buttonRouteName={fasiCarrelloTranslation.carrello.routeName}
				buttonStyle='cartButton flex items-center lg:hidden text-xs sm:text-sm md:text-base pt-1'
				iconaElement={`<i class='fal fa-shopping-cart mr-3 lg:mr-0 text-2xl'></i>`}
				iconaElementDirection='left'
			/>
		</div>
	);
};

export default CartButton;
