import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../state/actions';
import { URLASSOLUTO } from '../../../utils/urlassoluto';

const ProfiloLeftItem = ({
	profiloId = '',
	profiloName,
	profiloOnClickFunction = null,
	routeName,
	linkSeo,
	smallVersion = false,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const currentLinkSeo = useSelector((state) => state.currentPage.pageLinkSeo);

	const onProfiloClick = (event) => {
		event.preventDefault();

		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(
				profiloId,
				routeName,
				linkSeo,
				history
			)
		);
	};

	const classStyled = smallVersion
		? 'py-1'
		: 'categoryItemSub border-b border-gray-300 px-2 3xl:pl-3 3xl:pr-0 py-3';
	const spanStyledOne = smallVersion
		? 'w-full justify-between px-3 xl:pr-3 xl:pl-0'
		: 'h-12';
	const spanStyledTwo = smallVersion
		? 'text-base'
		: 'text-lg xl:text-xl 3xl:text-2xl';

	const isCallBackFunction =
		profiloOnClickFunction !== null ? profiloOnClickFunction : onProfiloClick;

	const isSelected =
		currentLinkSeo === linkSeo ? 'selected text-ribola-astral' : '';
	const isLinked =
		currentLinkSeo === linkSeo ? (
			<span className={`flex items-center ${spanStyledOne}`}>
				<span
					className={`flex font-poppinssemibold pl-1 pr-4 3xl:pl-3 4xl:px-4 ${spanStyledTwo}`}
					dangerouslySetInnerHTML={{ __html: profiloName.toUpperCase() }}
				/>
				<i className='far fa-chevron-right'></i>
			</span>
		) : (
			<a
				href={URLASSOLUTO + linkSeo}
				onClick={isCallBackFunction}
				className={`flex items-center ${spanStyledOne}`}>
				<span
					className={`flex font-poppinsregular pl-1 pr-4 3xl:pl-3 4xl:px-4 ${spanStyledTwo}`}
					dangerouslySetInnerHTML={{ __html: profiloName.toUpperCase() }}
				/>
				<i className='far fa-chevron-right'></i>
			</a>
		);

	return (
		<div className={`hover:text-ribola-astral ${classStyled} ${isSelected}`}>
			{isLinked}
		</div>
	);
};

export default ProfiloLeftItem;
