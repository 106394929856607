import { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../state/actions';
import CustomButton from '../customButton/CustomButton';
import ProfiloLeftItem from '../profiloLeftItem/ProfiloLeftItem';

const LoginRegistrationButton = () => {
	const dispatch = useDispatch();
	const isUserLogged = useSelector((state) => state.user.isLogged);
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const userData = useSelector((state) => state.user.userData);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);
	const profiloTranslation = useSelector(
		(state) => state.translations.menuLeftProfilo
	);
	const fasiCarrelloTranslation = useSelector(
		(state) => state.translations.fasiCarrello
	);

	useEffect(() => {
		//console.log(isUserLogged, userData?.is_guest);
	}, []);

	const onLogoutClick = (event) => {
		event.preventDefault();

		// chiamo action creator
		dispatch(
			allActions.userActions.logoutUser(currentPage.currentLang, user.token_id)
		);
	};

	const showMenuProfilo = isUserLogged && userData?.is_guest === 0 && (
		<div className='absolute flex-col top-10 z-10 w-48 rounded-sm bg-white border border-gray-300 text-ribola-dove-gray py-3 subMenuLogged'>
			<ProfiloLeftItem
				linkSeo={profiloTranslation.profilo.itemLink}
				profiloName={profiloTranslation.profilo.itemName.toUpperCase()}
				routeName={profiloTranslation.profilo.itemRouteName}
				smallVersion={true}
			/>
			<ProfiloLeftItem
				linkSeo={profiloTranslation.indirizzi.itemLink}
				profiloName={profiloTranslation.indirizzi.itemName.toUpperCase()}
				routeName={profiloTranslation.indirizzi.itemRouteName}
				smallVersion={true}
			/>
			<ProfiloLeftItem
				linkSeo={profiloTranslation.ordiniProfilo.itemLink}
				profiloName={profiloTranslation.ordiniProfilo.itemName.toUpperCase()}
				routeName={profiloTranslation.ordiniProfilo.itemRouteName}
				smallVersion={true}
			/>
			<ProfiloLeftItem
				linkSeo={profiloTranslation.logout.itemLink}
				profiloName={profiloTranslation.logout.itemName.toUpperCase()}
				profiloOnClickFunction={onLogoutClick}
				smallVersion={true}
			/>
		</div>
	);

	const buttonToShow = isUserLogged ? (
		userData.is_guest === 0 && (
			<Fragment>
				<CustomButton
					buttonLink={profiloTranslation.profilo.itemLink}
					buttonName={`${genericheTranslation.ciao.itemName} <b>${userData.meta_data.nome}</b> <i class="fal fa-user-unlock"></i>`}
					buttonRouteName={profiloTranslation.profilo.itemRouteName}
					buttonStyle='amberButton hidden lg:flex'
				/>
				<CustomButton
					buttonLink={profiloTranslation.profilo.itemLink}
					buttonName={`${genericheTranslation.ciao.itemName} <b>${userData.meta_data.nome}</b> <i class="fal fa-user-unlock"></i>`}
					buttonRouteName={profiloTranslation.profilo.itemRouteName}
					buttonStyle='amberButton flex lg:hidden'
				/>
				{showMenuProfilo}
			</Fragment>
		)
	) : (
		<Fragment>
			<i className='fal fa-user text-2xl mr-3 flex lg:hidden'></i>
			<CustomButton
				buttonLink={fasiCarrelloTranslation.registrazione.linkName}
				buttonName={fasiCarrelloTranslation.registrazione.itemName}
				buttonRouteName={fasiCarrelloTranslation.registrazione.routeName}
				buttonStyle='amberButton hidden lg:flex text-sm'
			/>
			<CustomButton
				buttonLink={fasiCarrelloTranslation.registrazione.linkName}
				buttonName={fasiCarrelloTranslation.registrazione.itemName}
				buttonRouteName={fasiCarrelloTranslation.registrazione.routeName}
				buttonStyle='flex lg:hidden text-xs sm:text-sm md:text-base xs:pt-1'
			/>
		</Fragment>
	);

	return (
		<div className='flex justify-end lg:justify-start items-center lg:items-start relative text-sm 2xl:text-base text-white showProfileSubMenu h-10 lg:h-20'>
			{buttonToShow}
		</div>
	);
};

export default LoginRegistrationButton;
