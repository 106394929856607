import { Route } from 'react-router-dom';
import ContattiOK from '../pages/contattiok/ContattiOK';

export default (contattiokRoute) => {
	return [
		<Route
			exact
			path={`/${contattiokRoute}.html`}
			component={ContattiOK}
			key={`${contattiokRoute}Route`}
		/>,
	];
};
