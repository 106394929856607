import {
	FETCH_NETS_DETAIL,
	FETCH_PARENT_CATEGORY_DETAIL,
	FETCH_CATEGORY_DETAIL,
	FETCH_PRODUCT_DETAIL,
} from '../actions/types';

const INITIAL_STATE = [];

export const catalogueReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_NETS_DETAIL:
			state = [...action.payload.nets.childCategories];

			return state;

		case FETCH_PARENT_CATEGORY_DETAIL:
			const { parentCategory } = action.payload;

			const categoryIndex = state.findIndex(
				(item) => item.id === parentCategory.id
			);

			let isFetched = parentCategory.isFetched;
			let detail = parentCategory.detail;
			let briciole = parentCategory.briciole;
			let imageHeader = parentCategory.imageHeader;
			let childCategories = parentCategory.childCategories;

			state[categoryIndex] = {
				...state[categoryIndex],
				isFetched,
				detail,
				briciole,
				imageHeader,
				childCategories,
			};

			return state;

		case FETCH_CATEGORY_DETAIL:
			const { category } = action.payload;

			const parentCategoryIndex = state.findIndex(
				(item) => item.id === category.padre_id
			);

			const categoryChildIndex = state[
				parentCategoryIndex
			].childCategories.findIndex((item) => item.id === category.id);

			isFetched = category.isFetched;
			detail = category.detail;
			briciole = category.briciole;
			imageHeader = category.imageHeader;
			let childProducts = category.childProducts;

			state[parentCategoryIndex].childCategories[categoryChildIndex] = {
				...state[parentCategoryIndex].childCategories[categoryChildIndex],
				isFetched,
				detail,
				briciole,
				imageHeader,
				childProducts,
			};

			return state;

		case FETCH_PRODUCT_DETAIL:
			const { catalogue, product } = action.payload;

			const parentCategoryIndexP = state.findIndex(
				(item) => item.id === product.parent_product_category_id
			);

			//console.log(parentCategoryIndexP);

			const categoryChildIndexP = state[
				parentCategoryIndexP
			].childCategories.findIndex(
				(item) => item.id === product.product_category_id
			);

			//console.log(categoryChildIndexP);

			if (categoryChildIndexP === -1) {
				// non ho trovato l'indice, vuol dire che childCategories è vuota, copio childCategories da catalogue
				state[parentCategoryIndexP].childCategories =
					catalogue[parentCategoryIndexP].childCategories;
			} else {
				// cerco index prodotto
				const productIndex = state[parentCategoryIndexP].childCategories[
					categoryChildIndexP
				].childProducts.findIndex((item) => item.id === product.id);

				//console.log(productIndex);

				if (productIndex === -1) {
					// non ho trovato l'indice, vuol dire che childProducts è vuoto, copio childProducts da catalogue
					state[parentCategoryIndexP].childCategories[
						categoryChildIndexP
					].childProducts =
						catalogue[parentCategoryIndexP].childCategories[
							categoryChildIndexP
						].childProducts;
				} else {
					detail = product.detail;
					briciole = product.briciole;
					imageHeader = product.imageHeader;
					const isFetched = product.isFetched;
					const parent_product_category_id = product.parent_product_category_id;

					state[parentCategoryIndexP].childCategories[
						categoryChildIndexP
					].childProducts[productIndex] = {
						...state[parentCategoryIndexP].childCategories[categoryChildIndexP]
							.childProducts[productIndex],
						detail,
						briciole,
						imageHeader,
						isFetched,
						parent_product_category_id,
					};
				}
			}

			return state;

		default:
			return state;
	}
};
