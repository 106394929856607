import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import allActions from '../../../state/actions';
import InputField from '../../../components/shared/inputField/InputField';
import TextareaField from '../../../components/shared/textareaField/TextareaField';
import CheckboxField from '../../../components/shared/checkboxField/CheckboxField';
import { URLASSOLUTO } from '../../../utils/urlassoluto';

const CampiContatti = ({
	isDisabledClick,
	isButtonSending,
	errors,
	register = () => {},
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const campiFormTranslation = useSelector(
		(state) => state.translations.campiFormRegistrazione
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);
	const erroriFormTranslation = useSelector(
		(state) => state.translations.erroriForm
	);
	const paginestaticheTranslation = useSelector(
		(state) => state.translations.paginestatiche
	);

	useEffect(() => {
		console.log('aaa', isDisabledClick, isButtonSending);
	}, [isDisabledClick, isButtonSending]);

	//// ERRORI VARI
	const errorFunction = ({ campoNome, messaggioErrore }) => {
		const erroreMsg = campoNome ? (
			<p className='text-xs text-red-600'>
				<i className='fal fa-exclamation-triangle mr-1'></i>
				{messaggioErrore}
			</p>
		) : (
			''
		);

		return erroreMsg;
	};
	/// FINE ERRORI

	return (
		<div className={`grid grid-cols-60 ${isDisabledClick}`}>
			<div className='col-start-1 col-end-61 sm:col-end-29'>
				<InputField
					inputType='text'
					inputLabel={`${campiFormTranslation.nome.itemName.toUpperCase()} *`}
					inputLabelStyle='flex justify-between items-center w-full sm:w-11/12'
					inputName='nome'
					inputPlaceholder=''
					inputStyle='border mt-1 py-2 px-4 text-sm w-full'
					inputError={errorFunction({
						campoNome: errors?.nome,
						messaggioErrore: errors?.nome?.message,
					})}
					register={register('nome', {
						required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.nome.itemName}`,
					})}
				/>
			</div>
			<div className='col-start-1 sm:col-start-31 col-end-61 sm:mt-0 mt-5'>
				<InputField
					inputType='text'
					inputLabel={`${campiFormTranslation.cognome.itemName.toUpperCase()} *`}
					inputLabelStyle='flex justify-between items-center w-full sm:w-11/12'
					inputName='cognome'
					inputPlaceholder=''
					inputStyle='border mt-1 py-2 px-4 text-sm w-full'
					inputError={errorFunction({
						campoNome: errors?.cognome,
						messaggioErrore: errors?.cognome?.message,
					})}
					register={register('cognome', {
						required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.cognome.itemName}`,
					})}
				/>
			</div>
			<div className='col-start-1 col-end-61 sm:col-end-29 mt-5'>
				<InputField
					inputType='text'
					inputLabel={`${campiFormTranslation.telefonoprofile.itemName.toUpperCase()} *`}
					inputLabelStyle='flex justify-between items-center w-full sm:w-11/12'
					inputName='telefono'
					inputPlaceholder=''
					inputStyle='border mt-1 py-2 px-4 text-sm w-full'
					inputError={errorFunction({
						campoNome: errors?.telefono,
						messaggioErrore: errors?.telefono?.message,
					})}
					register={register('telefono', {
						required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.telefonoprofile.itemName}`,
					})}
				/>
			</div>
			<div className='col-start-1 sm:col-start-31 col-end-61 mt-5'>
				<InputField
					inputType='email'
					inputLabel={`${campiFormTranslation.email.itemName.toUpperCase()} *`}
					inputLabelStyle='flex justify-between items-center w-full sm:w-11/12'
					inputName='email'
					inputPlaceholder=''
					inputStyle='border mt-1 py-2 px-4 text-sm w-full'
					inputError={errorFunction({
						campoNome: errors?.email,
						messaggioErrore: errors?.email?.message,
					})}
					register={register('email', {
						required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.email.itemName}`,
						pattern: {
							value:
								/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
							message: erroriFormTranslation.erroremailsbagliata.itemName,
						},
					})}
				/>
			</div>
			<div className='col-start-1 col-end-61 mt-5'>
				<TextareaField
					textareaLabel={`${campiFormTranslation.messaggio.itemName.toUpperCase()} *`}
					textareaLabelStyle='flex justify-between items-center w-full'
					textareaName='messaggio'
					textareaRows={7}
					textareaStyle='border text-sm resize-none w-full mt-1 py-2 px-4'
					textareaError={errorFunction({
						campoNome: errors?.messaggio,
						messaggioErrore: errors?.messaggio?.message,
					})}
					register={register('messaggio', {
						required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.messaggio.itemName}`,
					})}
				/>
			</div>
			<div className='col-start-1 col-end-61 mt-5'>
				<div className='flex items-center'>
					<a
						href={`${URLASSOLUTO}${paginestaticheTranslation.privacypolicy.itemLink}`}
						target='_blank'
						className='block text-xs underline'>
						{genericheTranslation.accettazioneprivacy.itemName}
					</a>
					<CheckboxField
						checkboxType='checkbox'
						checkboxName='privacy'
						checkboxStyle='border-2 text-ribola-congress-blue border-ribola-nobel mx-3 w-6 h-6 cursor-pointer'
						direction='left'
						checkboxError={errorFunction({
							campoNome: errors?.privacy,
							messaggioErrore: errors?.privacy?.message,
						})}
						register={register('privacy', {
							required: erroriFormTranslation.erroraccettacondizioni.itemName,
						})}
					/>
				</div>
				<span className='font-opensanssemibold text-sm block mt-3'>
					{genericheTranslation.campoobbligatorio.itemName}
				</span>
			</div>
			<div className='col-start-1 col-end-61 mt-10 flex justify-center'>
				<InputField
					inputType='submit'
					inputValue={genericheTranslation.invia.itemName.toUpperCase()}
					inputStyle='buttonBorderedTransparent bg-ribola-amber text-ribola-heavy-metal border-ribola-amber text-xl text-center w-48 px-5'
					divStyle='w-full justify-end'
					showLabel={false}
					isSpinnerLoading={isButtonSending}
				/>
			</div>
		</div>
	);
};

export default CampiContatti;
