import React, { useEffect } from 'react';
import NumberFormat from 'react-number-format';
import AsyncSelect from 'react-select/async';
import NoSSR from 'react-no-ssr';
import SpinnerLoading from '../spinnerLoading/SpinnerLoading';

const InputField = React.forwardRef(
	(
		{
			inputType,
			inputLabel,
			inputLabelStyle = '',
			inputName,
			inputValue,
			inputError = '',
			inputHandlerFunction,
			inputPlaceholder = '',
			inputSuffix = '',
			inputStyle = '',
			inputDecimalScale = '4',
			inputThousandSeparator = '.',
			inputDecimalSeparator = ',',
			inputMaxValue = 9999.9999,
			inputMinValue = 0,
			isNumberFormat = false,
			isAllowLeadingZero = true,
			isReadOnly = false,
			isDisabled = false,
			isSpinnerLoading = false,
			isSelectAsync = false,
			loadOptionsAsync,
			showLabel = true,
			divStyle = '',
			mainDivStyle = '',
			noOptionsMessageText = '',
			autoComplete = 'off',
			register = () => {},
			asyncSelectDefaultOption,
		},
		ref
	) => {
		useEffect(() => {
			//console.log(asyncSelectDefaultOption);
		}, [inputValue, asyncSelectDefaultOption]);

		const MAX_VAL = inputMaxValue;
		const MIN_VAL = inputMinValue;
		const withValueLimit = ({ floatValue }) => {
			//console.log(floatValue);
			const valueFromInput = floatValue === undefined ? 0 : floatValue;
			return valueFromInput <= MAX_VAL && valueFromInput >= MIN_VAL;
		};

		const showSpinner = isSpinnerLoading && inputType === 'submit' && (
			<SpinnerLoading spinnerStyle='flex items-center justify-end w-full h-full absolute pointer-events-none text-white pr-2' />
		);

		const labelStyled = inputLabelStyle !== '' ? inputLabelStyle : 'text-sm';
		const divStyled = divStyle !== '' ? divStyle : 'w-full';

		const inputStyled =
			inputStyle !== ''
				? inputStyle
				: 'border-ribola-dove-gray border-opacity-20 focus:border-opacity-100 text-ribola-dove-gray placeholder-gray-500 w-full text-sm py-1';

		const disabledStyle = isDisabled && 'opacity-50';

		let inputElement;

		if (isSelectAsync) {
			inputElement = (
				<div className={`${divStyled} flex relative`}>
					<NoSSR>
						<AsyncSelect
							cacheOptions
							className='react-select-container'
							classNamePrefix='react-select'
							loadOptions={loadOptionsAsync}
							placeholder={inputPlaceholder}
							noOptionsMessage={() => noOptionsMessageText}
							isClearable={false}
							onChange={(val) => inputHandlerFunction(val.value)}
							defaultValue={asyncSelectDefaultOption}
						/>
					</NoSSR>
				</div>
			);
		} else if (isNumberFormat) {
			inputElement = (
				<div className={`${inputStyle} ${disabledStyle} inputNumberStyled`}>
					<NumberFormat
						thousandSeparator={inputThousandSeparator}
						decimalSeparator={inputDecimalSeparator}
						decimalScale={inputDecimalScale}
						suffix={inputSuffix}
						allowLeadingZeros={isAllowLeadingZero}
						allowNegative={false}
						decimalScale={inputDecimalScale}
						defaultValue={inputValue}
						onValueChange={inputHandlerFunction}
						disabled={isDisabled}
						displayType='input'
						type='text'
						isAllowed={withValueLimit}
					/>
				</div>
			);
		} else {
			inputElement = (
				<div className={`${divStyled} flex relative`}>
					<input
						type={inputType}
						placeholder={inputPlaceholder}
						name={inputName}
						value={inputValue}
						autoComplete={autoComplete}
						readOnly={isReadOnly}
						disabled={isDisabled}
						className={`${inputStyled} bg-transparent focus:outline-none`}
						ref={ref}
						onChange={(e) => {
							if (typeof onChange === 'function') {
								onChange(e);
							}
							inputHandlerFunction(e);
						}}
						{...register}
					/>
					{showSpinner}
				</div>
			);
		}

		const labelDiv = showLabel ? (
			<div className={labelStyled}>
				<span
					dangerouslySetInnerHTML={{
						__html: inputLabel,
					}}
				/>
				{inputError}
			</div>
		) : (
			''
		);

		const mainDivStyled =
			mainDivStyle !== '' ? mainDivStyle : 'w-full text-ribola-dove-gray';

		return (
			<div
				className={`${mainDivStyled} flex flex-col justify-center items-start`}>
				{labelDiv}
				{inputElement}
			</div>
		);
	}
);

export default InputField;
