import { Route, Redirect } from 'react-router-dom';
import Payment from '../pages/payment/Payment';

const PaymentRoute = ({ isLogged, orderItems = 0, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			isLogged && orderItems > 0 ? (
				<Payment />
			) : (
				// se non sono loggato e non ho prodotti a carrello lo mando in homepage
				<Redirect
					exact
					to={{
						pathname: '/',
						state: {
							from: props.location,
						},
					}}
				/>
			)
		}
	/>
);

export default PaymentRoute;
