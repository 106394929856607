import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import Briciole from '../shared/briciole/Briciole';
import MagazineItem from '../shared/magazineItem/MagazineItem';

const MagazineCategoryDetail = () => {
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const paginestaticheTranslation = useSelector(
		(state) => state.translations.paginestatiche
	);

	// local state

	useEffect(() => {
		//console.log(pageContent, pageContent?.childMagazines);
	}, [pageContent?.childMagazines]);

	// controllo che esista la proprietà detail (altrimenti vuol dire che sto ancora recuperando i dati)
	if (!pageContent.detail || pageContent?.childMagazines === undefined) {
		return <div></div>;
	}

	const elencoMagazines =
		pageContent?.childMagazines.length > 0 &&
		pageContent.childMagazines.map((magazine) => (
			<MagazineItem key={_uniqueId('magazine_')} {...magazine} />
		));

	return (
		<div className='col-start-1 lg:col-start-15 4xl:col-start-16 col-end-61 3xl:col-end-59 4xl:col-end-57 sm:pb-12'>
			<Briciole />
			<div className='grid grid-cols-60 mt-0 sm:mt-3 xl:mt-6'>
				<div className='col-span-60 flex justify-between items-center px-8 xl:px-12 3xl:px-20 xl:pt-8 pb-6 sm:pb-8 xl:pb-12 3xl:py-14'>
					<h1 className='text-ribola-congress-blue text-3xl md:text-4xl xl:text-5xl font-opensansbold'>
						<small className='leading-tight flex'>
							{pageContent.detail.titolo.toUpperCase()} |&nbsp;
							{paginestaticheTranslation.titolomagazine.itemName.toUpperCase()}
						</small>
					</h1>
				</div>
				<div className='col-span-60 bg-ribola-gallery-gray px-8 xl:px-12 3xl:px-20 py-8 xl:py-12'>
					<p
						className='text-sm'
						dangerouslySetInnerHTML={{
							__html: pageContent.detail.descrizione,
						}}></p>
				</div>
			</div>
			<div className='grid grid-cols-60 sm:gap-5 px-8 xl:px-12 pb-8 sm:pb-20 mt-8 sm:mt-20'>
				{elencoMagazines}
			</div>
		</div>
	);
};

export default MagazineCategoryDetail;
