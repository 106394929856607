import {
	FETCH_CART_DETAIL,
	FETCH_REGISTRATION_DETAIL,
	FETCH_REGISTRATION_FORM_DETAIL,
	FETCH_PAYMENT_DETAIL,
	FETCH_CHECKOUT_DETAIL,
	FETCH_PROFILE_DETAIL,
	ADD_TO_CART,
	UPDATE_CART_QUANTITY,
	DELETE_CART_ITEM,
	DELETE_CART_ITEMS,
	NO_SHIPPING,
} from '../actions/types';

const INITIAL_STATE = {
	shippingCountries: [],
	provinces: [],
	discountGroups: [],
};

export const checkoutReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_CART_DETAIL:
		case FETCH_REGISTRATION_DETAIL:
		case FETCH_REGISTRATION_FORM_DETAIL:
		case FETCH_PAYMENT_DETAIL:
		case FETCH_CHECKOUT_DETAIL:
		case FETCH_PROFILE_DETAIL:
		case ADD_TO_CART:
		case UPDATE_CART_QUANTITY:
		case DELETE_CART_ITEM:
		case DELETE_CART_ITEMS:
		case NO_SHIPPING:
			const { shippingCountries, provinces, discountGroups, paymentMethods } =
				action.payload;

			if (paymentMethods) {
				state = {
					...state,
					shippingCountries,
					provinces,
					discountGroups,
					paymentMethods,
				};
			} else {
				state = {
					...state,
					shippingCountries,
					provinces,
					discountGroups,
				};
			}

			return state;

		default:
			return state;
	}
};
