import { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../state/actions';
import LazyLoadImg from '../../shared/lazyLoadImg/LazyLoadImg';
import CustomButton from '../../shared/customButton/CustomButton';
import { BASESTORAGE } from '../../../utils/basestorage';
import { URLASSOLUTO } from '../../../utils/urlassoluto';

const CategoryBoxItem = ({
	categoryId,
	immagine,
	titolo,
	sottotitolo,
	prezzoapartireda,
	icona,
	colore,
	routeName,
	linkSeo,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	useEffect(() => {
		//console.log(titolo, prezzoapartireda);
		/* console.log(immagine);
		console.log(immagine?.file_name); */
	}, []);

	const onCategoryClick = (event) => {
		event.preventDefault();

		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(
				categoryId,
				routeName,
				linkSeo,
				history
			)
		);
	};

	const immagineElenco =
		immagine === null ? (
			<LazyLoadImg
				imgName='placeholder_elenco_prodotto.jpg'
				imgPath='statiche'
				imgWidth='300'
				imgHeight='232'
				imgStyle='flex-start mb-3 w-full object-fit cursor-pointer'
				imgAlt={titolo}
			/>
		) : (
			<LazyLoadImg
				imgName={`300x232-${immagine.file_name.replace('.jpg', '.webp')}`}
				imgPath={`${immagine.folder}/thumbs`}
				imgWidth='300'
				imgHeight='232'
				imgStyle='flex-start mb-3 w-full object-fit cursor-pointer'
				imgAlt={titolo}
			/>
		);

	const prezzoAPartireDaString = prezzoapartireda ? (
		<Fragment>
			{genericheTranslation.apartireda.itemName}&nbsp;
			<span className='text-2xl'>
				{parseFloat(prezzoapartireda).toFixed(2).toString().replace('.', ',')}€
			</span>
		</Fragment>
	) : (
		''
	);

	return (
		<div className='col-span-60 sm:col-span-30 md:col-span-20 xl:col-span-15 mb-10 flex justify-center'>
			<div className='productBoxItem border-t border-gray-200 max-w-xs sm:max-w-none mx-8 sm:mx-4 md:mx-2 lg:mx-3 xl:mx-2 4xl:mx-4'>
				<a
					href={URLASSOLUTO + linkSeo}
					onClick={onCategoryClick}
					className='flex items-center mb-3'>
					<img
						src={BASESTORAGE + '/' + icona.folder + '/' + icona.file_name}
						width='40'
						height='40'
						className='flex-start mr-3'
						alt={titolo}
					/>
					<span
						className={`titolo flex items-center text-lg sm:text-sm 3xl:text-base 4xl:text-lg sm:h-14 text-${colore}`}>
						<span className='leading-tight line-clamp-2'>{titolo}</span>
					</span>
				</a>
				<a
					href={URLASSOLUTO + linkSeo}
					onClick={onCategoryClick}
					className='h-52 overflow-hidden'>
					{immagineElenco}
				</a>
				<span className='sottotitolo text-sm block sm:h-12'>{sottotitolo}</span>
				<span className='sottotitolo text-sm 4xl:text-xl text-ribola-congress-blue block font-opensansbold my-3'>
					{prezzoAPartireDaString}
				</span>
				<CustomButton
					buttonId={categoryId}
					buttonLink={linkSeo}
					buttonRouteName={routeName}
					buttonName={genericheTranslation.continua.itemName}
					buttonStyle='dettagliButton text-sm pl-4 pr-16 py-2 4xl:py-3 mt-2'
				/>
			</div>
		</div>
	);
};

export default CategoryBoxItem;
