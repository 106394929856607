import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import BloccoCategorieHome from '../../components/homepage/bloccoCategorieHome/BloccoCategorieHome';
import BloccoBannerHome from '../../components/homepage/bloccoBannerHome/BloccoBannerHome';
import BloccoPiuVendutiHome from '../../components/homepage/bloccoPiuVendutiHome/BloccoPiuVendutiHome';
import BloccoInspirationHome from '../../components/homepage/bloccoInspirationHome/BloccoInspirationHome';
import BloccoCategorieSlideHome from '../../components/homepage/bloccoCategorieSlideHome/BloccoCategorieSlideHome';
import BloccoChisiamoHome from '../../components/homepage/bloccoChisiamoHome/BloccoChisiamoHome';
import BloccoMagazineHome from '../../components/homepage/bloccoMagazineHome/BloccoMagazineHome';

const Homepage = () => {
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const headerStatic = useSelector((state) => state.headerStatic);
	const homepageStatic = useSelector((state) => state.staticPages.homepage);
	const productsBestSoldHome = useSelector(
		(state) => state.productsBestSoldHome
	);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// controllo nello store se ho già il dettaglio di homepage
		const homepagePage = homepageStatic;

		console.log('homepage');

		if (homepagePage) {
			// setto i metatags
			document.title = homepageStatic.metaTitle;
			document.getElementsByTagName('META')[2].content =
				homepageStatic.metaDesc;
		}

		// controllo di avere almeno productsBestSoldHome nello store, altrimenti chiamo action creator fetchHomepage che recupera tutti i dati della homepage
		if (productsBestSoldHome.length === 0) {
			// chiamo web service
			setTimeout(
				() =>
					dispatch(
						allActions.homepageActions.fetchHomepage(
							currentPage.currentLang,
							user?.userData?.id
						)
					),
				50
			);
		} else {
			// loading...
			dispatch(allActions.isFetchingAction.loadStoredData());

			// carico i dati dallo store se arrivo da un'altra pagina
			dispatch(
				allActions.homepageActions.loadHomepage(
					headerStatic[0],
					homepageStatic.metaTitle,
					homepageStatic.metaDesc
				)
			);
		}
	}, [homepageStatic]);

	return (
		<Layout>
			<PreloaderBg showPreloader={isFetching} />
			<BloccoCategorieHome />
			<BloccoBannerHome />
			<BloccoPiuVendutiHome />
			<BloccoInspirationHome />
			<BloccoCategorieSlideHome />
			<BloccoChisiamoHome />
			<BloccoMagazineHome />
		</Layout>
	);
};

export default Homepage;
