import { Route } from 'react-router-dom';
import CondizioniVendita from '../pages/condizioniVendita/CondizioniVendita';

export default (condizioniVenditaRoute) => {
	return [
		<Route
			exact
			path={`/${condizioniVenditaRoute}.html`}
			component={CondizioniVendita}
			key={`${condizioniVenditaRoute}Route`}
		/>,
	];
};
