import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import Briciole from '../shared/briciole/Briciole';
import ProductBoxSearchItem from '../shared/productBoxSearchItem/ProductBoxSearchItem';
import { gtmImpressions } from '../../gtm/gtm';

const RisultatiricercaDetail = () => {
	const currentLang = useSelector((state) => state.currentPage.currentLang);
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const promotion = useSelector((state) => state.promotion);
	const siteParams = useSelector((state) => state.siteParams);
	const paginestaticheTranslation = useSelector(
		(state) => state.translations.paginestatiche
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	// local state

	useEffect(() => {}, [pageContent]);

	// controllo che esista la proprietà detail o paroleRicercate (altrimenti vuol dire che sto ancora recuperando i dati)
	if (!pageContent.detail || !pageContent?.paroleRicercate) {
		return <div></div>;
	}

	// gtm impressions prodotti ricerca
	const gtmSuffix = `gtm_code${currentLang}`;
	const currentGTMTAG = siteParams[gtmSuffix];
	if (
		pageContent?.paroleRicercate?.risultati !== null &&
		pageContent?.paroleRicercate?.risultati !== undefined &&
		pageContent?.paroleRicercate?.risultati.length > 0 &&
		currentGTMTAG !== null
	) {
		gtmImpressions({
			productList: pageContent.paroleRicercate.risultati,
			categoryName: 'risultati ricerca',
		});
	}

	// recupero eventuale promozione a tempo di categorie
	const promozioneCategoria = promotion?.elencoCategorie?.filter(
		(item) => item.id === pageContent.id
	);

	const elencoProducts =
		pageContent.paroleRicercate.found > 0 &&
		Object.keys(pageContent.paroleRicercate.risultati).map((key, index) => {
			const { id, image, detail, hoverColor, routeName, linkSeo } =
				pageContent.paroleRicercate.risultati[key];

			const titoloCategoria = detail.titoloCategoria;
			const titolo_breve = detail.titolo_breve;
			const sottotitolo_elenco = detail.sottotitolo_elenco;
			const codice = detail.codice;
			const imponibile = detail.imponibile;
			const sconto = detail.sconto;
			const prezzoScontato = detail.prezzoScontato;
			const measure_unit = detail.measure_unit;
			const flag_abilita_tag_bestseller =
				detail.flags.flag_abilita_tag_bestseller;
			const flag_abilita_tag_novita = detail.flags.flag_abilita_tag_novita;
			const flag_abilita_informazioni_prodotto =
				detail.flags.flag_abilita_informazioni_prodotto;
			const flag_abilita_prezzi_fissi = detail.flags.flag_abilita_prezzi_fissi;
			const colore = hoverColor;

			// sconto promozione categoria?
			let tipologiaScontoP = null;
			if (promozioneCategoria !== undefined) {
				tipologiaScontoP = promotion.tipologiaSconto;
			}
			// fine sconto promozione categoria

			// recupero eventuale sconto prodotto
			if (sconto > 0) {
				tipologiaScontoP = detail.tipologia_sconto;
			}
			// fine promozione prodotto

			// promozione 3x2?
			let isProdotto3x2 = false;
			if (promotion?.tipologiaPromozione === 'Promozione 3x2') {
				const prodotto3x2 = promotion?.elencoProdotti?.filter((item) => {
					return item.id === id;
				})[0];
				if (prodotto3x2) {
					isProdotto3x2 = true;
				}
			}

			// promozione prodotto abbinato?
			let promoProdottoAbbinato = null;
			if (
				promotion &&
				promotion?.tipologiaPromozione === 'Promozione Prodotto abbinato'
			) {
				promoProdottoAbbinato = promotion?.elencoProdotti?.filter((item) => {
					return item.id === id;
				})[0];
			}

			// prezzo fisso?
			const prezzoFisso =
				flag_abilita_prezzi_fissi === 1 ? detail?.prezzoFisso?.prezzo : null;

			return (
				<div
					key={_uniqueId('productItem_')}
					className='col-span-60 sm:col-span-30 md:col-span-20 2xl:col-span-15 flex justify-center elencoProdotti mb-10 mx-8 sm:mx-0'>
					<ProductBoxSearchItem
						id={id}
						index={index}
						immagine={image}
						titoloBreve={titolo_breve}
						sottotitoloElenco={sottotitolo_elenco}
						codice={codice}
						imponibile={imponibile}
						sconto={sconto}
						tipologiaScontoPromozione={tipologiaScontoP}
						prezzoScontato={prezzoScontato}
						unitaMisura={measure_unit}
						iconaTagBestseller={flag_abilita_tag_bestseller}
						iconaTagNovita={flag_abilita_tag_novita}
						richiediInfo={flag_abilita_informazioni_prodotto}
						prodotto3x2={isProdotto3x2}
						prodottoAbbinatoObj={promoProdottoAbbinato}
						prezzoFisso={prezzoFisso}
						colore={colore}
						routeName={routeName}
						linkSeo={linkSeo}
						titoloCategoria={titoloCategoria}
					/>
				</div>
			);
		});

	return (
		<div className='col-start-1 lg:col-start-15 4xl:col-start-16 col-end-61 3xl:col-end-59 4xl:col-end-57 sm:pb-12'>
			<Briciole />
			<div className='grid grid-cols-60 mt-0 sm:mt-3 xl:mt-6'>
				<div className='col-span-60 flex justify-between items-center px-8 xl:px-12 3xl:px-20 xl:pt-8 pb-6 sm:py-3'>
					<h1 className='text-ribola-congress-blue text-3xl md:text-4xl xl:text-5xl font-opensansbold'>
						<small className='leading-tight flex'>
							{paginestaticheTranslation.titoloricerca.itemName.toUpperCase()}
						</small>
					</h1>
				</div>
				<div className='col-span-60 bg-ribola-gallery-gray px-8 xl:px-12 3xl:px-20 py-8 sm:py-12 sm:mt-6'>
					<span
						className='text-sm'
						dangerouslySetInnerHTML={{
							__html: paginestaticheTranslation.sottotitoloricerca.itemName,
						}}
					/>
					<span className='font-opensansbold ml-1'>
						{pageContent.paroleRicercate.words}
					</span>
					<span className='ml-3 text-sm'>
						{genericheTranslation.trovate.itemName}
						<span className='font-opensansbold text-base ml-3'>
							{pageContent.paroleRicercate.found}
						</span>
					</span>
				</div>
			</div>
			<div className='grid grid-cols-60 mt-12'>{elencoProducts}</div>
		</div>
	);
};

export default RisultatiricercaDetail;
