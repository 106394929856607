import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import axios from '../../../../utils/axiosBase';
import SelectField from '../../../shared/selectField/SelectField';
import InputField from '../../../shared/inputField/InputField';
import debounce from '../../../../utils/debounce';

const filterSelect = (inputValue, response) => {
	return response.filter((i) =>
		i.label.toLowerCase().includes(inputValue.toLowerCase())
	);
};

// chiamo action creator dopo 1 secondo con debounce
const loadOptions = debounce(async (inputValue, callback) => {
	if (inputValue.length < 3) {
		return;
	}

	const payload = {
		municipality: inputValue,
	};

	// chiamo web service
	const response = await axios.post(`api/searchmunicipality/`, payload, {
		withCredentials: true,
	});

	console.log(response.data);

	// popolo select
	callback(filterSelect(inputValue, response.data));
}, 1000);

const RegistrationFormFields = ({
	onChangeNazioneFunction,
	isDivDisabledStyle,
	isDivDisabledStyleAzienda,
	isInputDisabled,
	isinputDisabledStyle,
	isinputDisabledStyleAzienda,
	isSDIRequired,
	isFullNameRequired,
	nazioneEsteraCampiVisibili,
	disableCampiProvinciaCitta,
	showFieldsAzienda = true,
	errors,
	register = () => {},
	Controller,
	control,
}) => {
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const checkout = useSelector((state) => state.checkout);
	const campiFormTranslation = useSelector(
		(state) => state.translations.campiFormRegistrazione
	);
	const erroriFormTranslation = useSelector(
		(state) => state.translations.erroriForm
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	useEffect(() => {
		console.log('aaa');
	}, []);

	let defaultItaliaId;

	const elencoPaesi = checkout.shippingCountries.map((item) => {
		if (item.shippingCountry === 'Italia') {
			defaultItaliaId = item.id;
		}
		return { id: item.id, value: item.shippingCountry };
	});

	const elencoProvince = checkout.provinces.map((item) => {
		return { id: item.id, value: item.province };
	});

	const elencoTipologieUtente = checkout.discountGroups.map((item) => {
		return { id: item.id, value: item.nomeGruppo };
	});

	//// ERRORI VARI
	const errorFunction = ({ campoNome, messaggioErrore }) => {
		const erroreMsg = campoNome ? (
			<p className='text-xs text-red-600'>
				<i className='fal fa-exclamation-triangle mr-1'></i>
				{messaggioErrore}
			</p>
		) : (
			''
		);

		return erroreMsg;
	};
	/// FINE ERRORI

	// selezione città
	const onCittaSelectHandler = (cittaSelected) => {
		console.log(cittaSelected);
	};

	const disableCampiCittaProvincia =
		disableCampiProvinciaCitta && 'pointer-events-none opacity-50';

	// MOSTRO SELECT PER PROVINCIA ITALIANA O INPUT PER PROVINCIA ESTERA?
	const showProvinciaEstera = nazioneEsteraCampiVisibili ? (
		<div
			className={`col-span-60 sm:col-span-30 md:col-span-20 px-3 sm:px-0 ${disableCampiCittaProvincia}`}>
			<InputField
				inputType='text'
				inputLabel={`${campiFormTranslation.provinciaestera.itemName}:`}
				inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
				inputName='provinciaEstera'
				inputStyle='border border-ribola-dove-gray border-opacity-100 mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12'
				inputError={errorFunction({
					campoNome: errors?.provinciaEstera,
					messaggioErrore: errors?.provinciaEstera?.message,
				})}
				register={register('provinciaEstera')}
			/>
		</div>
	) : (
		<div
			className={`col-span-60 sm:col-span-30 md:col-span-20 px-3 sm:px-0 ${disableCampiCittaProvincia}`}>
			<SelectField
				selectName='provincia'
				selectListValue={elencoProvince}
				selectLabel={`${campiFormTranslation.provincia.itemName}: *`}
				selectLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
				selectClassStyle='mt-3 py-3 px-8 w-full leading-7 sm:w-11/12'
				selectError={errorFunction({
					campoNome: errors?.provincia,
					messaggioErrore: errors?.provincia?.message,
				})}
				{...register('provincia', {
					required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.provincia.itemName}`,
				})}
			/>
		</div>
	);

	// MOSTRO SELECT PER COMUNI ITALIANI O INPUT PER COMUNI ESTERI?
	const showCittaEstera = nazioneEsteraCampiVisibili ? (
		<div
			className={`col-span-60 sm:col-span-30 md:col-span-20 px-3 sm:px-0 ${disableCampiCittaProvincia}`}>
			<InputField
				inputType='text'
				inputLabel={`${campiFormTranslation.citta.itemName}: *`}
				inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
				inputName='citta'
				inputStyle='border mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12 bg-white placeholder-opacity-50'
				inputError={errorFunction({
					campoNome: errors?.citta,
					messaggioErrore: errors?.citta?.message,
				})}
				register={register('citta', {
					required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.citta.itemName}`,
				})}
			/>
		</div>
	) : (
		<div
			className={`col-span-60 sm:col-span-30 md:col-span-20 px-3 sm:px-0 ${disableCampiCittaProvincia}`}>
			<Controller
				control={control}
				name='citta'
				rules={{
					required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.citta.itemName}`,
				}}
				defaultValue={user?.userData?.meta_data?.citta}
				render={({ field }) => (
					<InputField
						{...field}
						isSelectAsync={true}
						inputLabel={`${campiFormTranslation.citta.itemName}: *`}
						inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
						inputHandlerFunction={field.onChange}
						inputStyle='border border-ribola-dove-gray border-opacity-100 mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12'
						inputPlaceholder={genericheTranslation.selezionaselect.itemName}
						noOptionsMessageText={genericheTranslation.nessunaopzione.itemName}
						loadOptionsAsync={loadOptions}
						inputError={errorFunction({
							campoNome: errors?.citta,
							messaggioErrore: errors?.citta?.message,
						})}
						asyncSelectDefaultOption={{
							value: user?.userData?.meta_data?.citta,
							label: user?.userData?.meta_data?.citta,
						}}
					/>
				)}
			/>
		</div>
	);

	const validateTipoAttivita = !isInputDisabled
		? {
				...register('discount_group_id', {
					required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.tipodiattivita.itemName}`,
				}),
		  }
		: '';

	const divCampiPerAzienda = showFieldsAzienda && (
		<Fragment>
			<div
				className={`col-span-60 sm:col-span-30 md:col-span-20 px-3 sm:px-0 ${isDivDisabledStyle}`}>
				<InputField
					inputType='text'
					inputLabel={`${campiFormTranslation.piva.itemName}: *`}
					inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
					inputName='partitaIva'
					isDisabled={isInputDisabled}
					inputStyle={`border border-ribola-dove-gray border-opacity-100 mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12 ${isinputDisabledStyle}`}
					inputError={errorFunction({
						campoNome: errors?.partitaIva,
						messaggioErrore: errors?.partitaIva?.message,
					})}
					register={
						!isInputDisabled &&
						register('partitaIva', {
							required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.piva.itemName}`,
						})
					}
				/>
			</div>
			<div
				className={`col-span-60 sm:col-span-30 md:col-span-20 px-3 sm:px-0 ${isDivDisabledStyle}`}>
				<InputField
					inputType='text'
					inputLabel={`${campiFormTranslation.codicefiscale.itemName}: *`}
					inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
					inputName='codiceFiscale'
					isDisabled={isInputDisabled}
					inputStyle={`border border-ribola-dove-gray border-opacity-100 mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12 ${isinputDisabledStyle}`}
					inputError={errorFunction({
						campoNome: errors?.codiceFiscale,
						messaggioErrore: errors?.codiceFiscale?.message,
					})}
					register={
						!isInputDisabled &&
						register('codiceFiscale', {
							required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.codicefiscale.itemName}`,
						})
					}
				/>
			</div>
			<div
				className={`col-span-60 sm:col-span-30 md:col-span-20 px-3 sm:px-0 ${isDivDisabledStyle}`}>
				<InputField
					inputType='text'
					inputLabel={`${campiFormTranslation.pec.itemName}:`}
					inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
					inputName='pec'
					isDisabled={isInputDisabled}
					inputStyle={`border border-ribola-dove-gray border-opacity-100 mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12 ${isinputDisabledStyle}`}
					inputError={errorFunction({
						campoNome: errors?.pec,
						messaggioErrore: errors?.pec?.message,
					})}
					register={
						!isInputDisabled &&
						register('pec', {
							pattern: {
								value:
									/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
								message: erroriFormTranslation.errorepecsbagliata.itemName,
							},
						})
					}
				/>
			</div>
			<div
				className={`col-span-60 sm:col-span-30 md:col-span-20 px-3 sm:px-0 ${isDivDisabledStyle}`}>
				<InputField
					inputType='text'
					inputLabel={`${campiFormTranslation.sdi.itemName}: *`}
					inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
					inputName='sdi'
					isDisabled={isInputDisabled}
					inputStyle={`border border-ribola-dove-gray border-opacity-100 mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12 ${isinputDisabledStyle}`}
					inputError={errorFunction({
						campoNome: errors?.sdi,
						messaggioErrore: errors?.sdi?.message,
					})}
					register={
						!isInputDisabled &&
						register('sdi', {
							required: isSDIRequired
								? `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.sdi.itemName}`
								: false,
						})
					}
				/>
			</div>
			<div
				className={`col-span-60 sm:col-span-30 md:col-span-20 px-3 sm:px-0 ${isDivDisabledStyle}`}>
				<SelectField
					selectName='discount_group_id'
					selectListValue={elencoTipologieUtente}
					selectLabel={`${campiFormTranslation.tipodiattivita.itemName}: *`}
					selectLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
					selectClassStyle='mt-3 py-3 px-8 w-full sm:w-11/12'
					selectError={errorFunction({
						campoNome: errors?.discount_group_id,
						messaggioErrore: errors?.discount_group_id?.message,
					})}
					{...validateTipoAttivita}
				/>
			</div>
		</Fragment>
	);

	const isNazioneDisabled =
		currentPage.routeName === 'profiloDetail' ? true : false;

	return (
		<Fragment>
			<div
				className={`col-span-60 sm:col-span-30 md:col-span-20 px-3 sm:px-0 ${isDivDisabledStyleAzienda}`}>
				<InputField
					inputType='text'
					inputLabel={`${campiFormTranslation.nome.itemName}: *`}
					inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
					inputName='nome'
					inputStyle={`border border-ribola-dove-gray border-opacity-100 mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12 ${isinputDisabledStyleAzienda}`}
					inputError={errorFunction({
						campoNome: errors?.nome,
						messaggioErrore: errors?.nome?.message,
					})}
					register={register('nome', {
						required: isFullNameRequired
							? `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.nome.itemName}`
							: false,
					})}
				/>
			</div>
			<div
				className={`col-span-60 sm:col-span-30 md:col-span-20 px-3 sm:px-0 ${isDivDisabledStyleAzienda}`}>
				<InputField
					inputType='text'
					inputLabel={`${campiFormTranslation.cognome.itemName}: *`}
					inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
					inputName='cognome'
					inputStyle={`border border-ribola-dove-gray border-opacity-100 mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12 ${isinputDisabledStyleAzienda}`}
					inputError={errorFunction({
						campoNome: errors?.cognome,
						messaggioErrore: errors?.cognome?.message,
					})}
					register={register('cognome', {
						required: isFullNameRequired
							? `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.cognome.itemName}`
							: false,
					})}
				/>
			</div>
			<div
				className={`col-span-60 sm:col-span-30 md:col-span-20 px-3 sm:px-0 ${isDivDisabledStyle}`}>
				<InputField
					inputType='text'
					inputLabel={`${campiFormTranslation.ragionesociale.itemName}: *`}
					inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
					inputName='ragioneSociale'
					isDisabled={isInputDisabled}
					inputStyle={`border border-ribola-dove-gray border-opacity-100 mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12 ${isinputDisabledStyle}`}
					inputError={errorFunction({
						campoNome: errors?.ragioneSociale,
						messaggioErrore: errors?.ragioneSociale?.message,
					})}
					register={
						!isInputDisabled &&
						register('ragioneSociale', {
							required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.ragionesociale.itemName}`,
						})
					}
				/>
			</div>
			<div className='col-span-60 sm:col-span-30 md:col-span-20 px-3 sm:px-0'>
				<InputField
					inputType='text'
					inputLabel={`${campiFormTranslation.indirizzo.itemName}: *`}
					inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
					inputName='indirizzo'
					inputStyle='border border-ribola-dove-gray border-opacity-100 mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12'
					inputError={errorFunction({
						campoNome: errors?.indirizzo,
						messaggioErrore: errors?.indirizzo?.message,
					})}
					register={register('indirizzo', {
						required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.indirizzo.itemName}`,
					})}
				/>
			</div>
			<div className='col-span-60 sm:col-span-30 md:col-span-20 px-3 sm:px-0'>
				<SelectField
					selectName='shipping_country_id'
					selectListValue={elencoPaesi}
					selectLabel={`${campiFormTranslation.nazione.itemName}: *`}
					selectLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
					selectClassStyle='mt-3 py-3 px-8 w-full leading-7 sm:w-11/12'
					selectedValue={defaultItaliaId}
					isDisabled={isNazioneDisabled}
					selectError={errorFunction({
						campoNome: errors?.shipping_country_id,
						messaggioErrore: errors?.shipping_country_id?.message,
					})}
					selectHandlerFunction={onChangeNazioneFunction}
					{...register('shipping_country_id', {
						required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.nazione.itemName}`,
					})}
				/>
			</div>
			{showCittaEstera}
			{showProvinciaEstera}
			<div className='col-span-60 sm:col-span-30 md:col-span-20 px-3 sm:px-0'>
				<InputField
					inputType='text'
					inputLabel={`${campiFormTranslation.cap.itemName}: *`}
					inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
					inputName='cap'
					inputStyle='border border-ribola-dove-gray border-opacity-100 mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12'
					inputError={errorFunction({
						campoNome: errors?.cap,
						messaggioErrore: errors?.cap?.message,
					})}
					register={register('cap', {
						required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.cap.itemName}`,
					})}
				/>
			</div>
			<div className='col-span-60 sm:col-span-30 md:col-span-20 px-3 sm:px-0'>
				<InputField
					inputType='email'
					inputLabel={`${campiFormTranslation.email.itemName}: *`}
					inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
					inputName='email'
					inputStyle='border border-ribola-dove-gray border-opacity-100 mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12'
					inputError={errorFunction({
						campoNome: errors?.email,
						messaggioErrore: errors?.email?.message,
					})}
					register={register('email', {
						required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.email.itemName}`,
						pattern: {
							value:
								/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
							message: erroriFormTranslation.erroremailsbagliata.itemName,
						},
					})}
				/>
			</div>
			<div className='col-span-60 sm:col-span-30 md:col-span-20 px-3 sm:px-0'>
				<InputField
					inputType='text'
					inputLabel={`${campiFormTranslation.telefonoprofile.itemName}: *`}
					inputLabelStyle='flex justify-between w-full sm:w-11/12 sm:text-lg 2xl:text-xl text-black mt-4'
					inputName='telefono'
					inputStyle='border border-ribola-dove-gray border-opacity-100 mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12'
					inputError={errorFunction({
						campoNome: errors?.telefono,
						messaggioErrore: errors?.telefono?.message,
					})}
					register={register('telefono', {
						required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.telefonoprofile.itemName}`,
					})}
				/>
			</div>
			{divCampiPerAzienda}
		</Fragment>
	);
};

export default RegistrationFormFields;
