import axios from '../../utils/axiosBase';
import {
	FETCH_PAYMENT_DETAIL,
	LOAD_PAYMENT_DETAIL,
	SELECT_ADDRESS,
	SELECT_PAYMENT,
} from './types';

const fetchPaymentDetail =
	(currentLang, tokenCarrello, userId, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			tokenCarrello: tokenCarrello,
			userId: userId,
		};

		// chiamo web service
		const response = await axios.post(`api/paymentdetail/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_PAYMENT_DETAIL,
			payload: response.data,
		});
	};

const loadPaymentDetail =
	(paymentLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_PAYMENT_DETAIL,
			payload: {
				paymentLoaded,
			},
		});
	};

const selectAddress =
	(currentLang, tokenCarrello, userId, addressId, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			tokenCarrello: tokenCarrello,
			userId: userId,
			addressId: parseInt(addressId),
		};

		// chiamo web service
		const response = await axios.post(`api/selectaddress/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: SELECT_ADDRESS,
			payload: response.data,
		});
	};

const selectPaymentMethod =
	(currentLang, tokenCarrello, userId, paymentId, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			tokenCarrello: tokenCarrello,
			userId: userId,
			paymentId: paymentId,
		};

		// chiamo web service
		const response = await axios.post(`api/selectpaymentmethod/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: SELECT_PAYMENT,
			payload: response.data,
		});
	};

export default {
	fetchPaymentDetail,
	loadPaymentDetail,
	selectAddress,
	selectPaymentMethod,
};
