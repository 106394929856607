import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const ModalAddedToCart = ({
	cartLastItem,
	dimensione,
	subtotalCart,
	onClickHandler,
	genericheTranslation,
}) => {
	// modal sweetalert2
	const MySwal = withReactContent(Swal);

	const coloreTxt = cartLastItem.attributes?.colore
		? `${genericheTranslation.colore.itemName}: <b>${cartLastItem.attributes.colore}</b><br />`
		: '';

	const dimensioneTxt = dimensione
		? `${genericheTranslation.dimensione.itemName}: <b>${dimensione}</b><br />`
		: '';

	const addedToCartHtml = `
  ${genericheTranslation.ilprodotto.itemName} <b>${cartLastItem.name}</b> ${
		genericheTranslation.statoaggiuntocarrello.itemName
	}<br /><br />
    ${dimensioneTxt}
    ${coloreTxt}
    ${genericheTranslation.prezzo.itemName}: <b>${parseFloat(
		cartLastItem.price * cartLastItem.quantity
	)
		.toFixed(2)
		.toString()
		.replace('.', ',')} €</b> - ${
		genericheTranslation.quantitastatic.itemName
	}: <b>${cartLastItem.quantity} ${genericheTranslation.pz.itemName}</b><br />
  ${genericheTranslation.subtotalecarrello.itemName} <b>${parseFloat(
		subtotalCart
	)
		.toFixed(2)
		.toString()
		.replace('.', ',')} €</b> <small>${
		genericheTranslation.piuiva.itemName
	}</small>
  `;

	MySwal.fire({
		title: (
			<span
				dangerouslySetInnerHTML={{
					__html: cartLastItem.message,
				}}
			/>
		),
		html: addedToCartHtml,
		confirmButtonText:
			genericheTranslation.concludiacquisto.itemName.toUpperCase(),
		cancelButtonText:
			genericheTranslation.tornaalprodotto.itemName.toUpperCase(),
		confirmButtonColor: '#FFA900',
		cancelButtonColor: '#707070',
		showCancelButton: true,
		customClass: {
			container: 'addedToCartRibola',
			confirmButton: 'confirmButtonRibola',
			cancelButton: 'cancelButtonRibola',
			title: 'titleRibola',
			htmlContainer: 'htmlContainerRibola',
		},
		didOpen: () => {},
	}).then((event) => {
		if (event.isConfirmed === true) {
			onClickHandler();
		}
	});

	return <div></div>;
};

export default ModalAddedToCart;
