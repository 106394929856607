import { useSelector } from 'react-redux';
import InputField from '../../../components/shared/inputField/InputField';
import TextareaField from '../../../components/shared/textareaField/TextareaField';
import CheckboxField from '../../../components/shared/checkboxField/CheckboxField';
import { URLASSOLUTO } from '../../../utils/urlassoluto';

const CampiRichiediInfo = ({
	isDisabledClick,
	isButtonSending,
	errors,
	register = () => {},
}) => {
	const campiFormTranslation = useSelector(
		(state) => state.translations.campiFormRegistrazione
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);
	const erroriFormTranslation = useSelector(
		(state) => state.translations.erroriForm
	);
	const paginestaticheTranslation = useSelector(
		(state) => state.translations.paginestatiche
	);

	//// ERRORI VARI
	const errorFunction = ({ campoNome, messaggioErrore }) => {
		const erroreMsg = campoNome ? (
			<p className='text-xs text-red-600'>
				<i className='fal fa-exclamation-triangle mr-1'></i>
				{messaggioErrore}
			</p>
		) : (
			''
		);

		return erroreMsg;
	};

	const privacyError = errors.privacy ? (
		<p className='text-sm text-red-600 ml-3 self-end'>
			<i className='fal fa-exclamation-triangle mr-1'></i>
			{errors.privacy.message}
		</p>
	) : (
		''
	);
	/// FINE ERRORI

	return (
		<div className={`grid grid-cols-60 mt-5 ${isDisabledClick}`}>
			<div className='col-start-1 col-end-29'>
				<InputField
					inputType='text'
					inputLabel={`${campiFormTranslation.nome.itemName.toUpperCase()} *`}
					inputLabelStyle='flex justify-between items-center w-full sm:w-11/12'
					inputName='nome'
					inputPlaceholder=''
					inputStyle='border mt-1 py-1 px-4 text-sm w-full'
					inputError={errorFunction({
						campoNome: errors?.nome,
						messaggioErrore: errors?.nome?.message,
					})}
					register={register('nome', {
						required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.nome.itemName}`,
					})}
				/>
			</div>
			<div className='col-start-31 col-end-61'>
				<InputField
					inputType='email'
					inputLabel={`${campiFormTranslation.email.itemName.toUpperCase()} *`}
					inputLabelStyle='flex justify-between items-center w-full sm:w-11/12'
					inputName='email'
					inputPlaceholder=''
					inputStyle='border mt-1 py-1 px-4 text-sm w-full'
					inputError={errorFunction({
						campoNome: errors?.email,
						messaggioErrore: errors?.email?.message,
					})}
					register={register('email', {
						required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.email.itemName}`,
						pattern: {
							value:
								/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
							message: erroriFormTranslation.erroremailsbagliata.itemName,
						},
					})}
				/>
			</div>
			<div className='col-start-1 col-end-61 mt-5'>
				<TextareaField
					textareaLabel={`${campiFormTranslation.messaggio.itemName.toUpperCase()} *`}
					textareaLabelStyle='flex justify-between items-center w-full'
					textareaName='messaggio'
					textareaRows={5}
					textareaStyle='border text-sm resize-none w-full mt-1 py-1 px-4'
					textareaError={errorFunction({
						campoNome: errors?.messaggio,
						messaggioErrore: errors?.messaggio?.message,
					})}
					register={register('messaggio', {
						required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.messaggio.itemName}`,
					})}
				/>
			</div>
			<div className='col-start-1 col-end-61 mt-5'>
				<CheckboxField
					checkboxType='checkbox'
					checkboxLabel={`<a href='${URLASSOLUTO}${paginestaticheTranslation.privacypolicy.itemLink}' target='_blank'>${campiFormTranslation.holettolaprivacy.itemName}</a>`}
					checkboxLabelStyle='text-xs italic flex underline mt-8'
					checkboxName='privacy'
					checkboxStyle='border-2 text-ribola-congress-blue border-ribola-nobel mx-3 w-6 h-6 cursor-pointer'
					direction='left'
					checkboxError={privacyError}
					register={register('privacy', {
						required: erroriFormTranslation.erroraccettacondizioni.itemName,
					})}
				/>
				<span className='font-opensanssemibold text-sm block mt-3'>
					{genericheTranslation.campoobbligatorio.itemName}
				</span>
			</div>
			<div className='col-start-1 col-end-61 mt-10 flex justify-center'>
				<InputField
					inputType='submit'
					inputValue={genericheTranslation.richiediinformazioni.itemName.toUpperCase()}
					inputStyle='buttonBorderedTransparent bg-ribola-amber text-ribola-heavy-metal border-ribola-amber text-xl text-center w-full px-12'
					divStyle='self-center'
					showLabel={false}
					isSpinnerLoading={isButtonSending}
				/>
			</div>
		</div>
	);
};

export default CampiRichiediInfo;
