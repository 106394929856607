import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import allActions from '../../../state/actions';
import InfoTooltipElement from '../../shared/infoTooltipElement/InfoTooltipElement';
import SelectField from '../../shared/selectField/SelectField';
import CampiMetriQuadri from '../campiMetriQuadri/CampiMetriQuadri';
import MessaggioBordatura from '../messaggioBordatura/MessaggioBordatura';
import MessaggioOffertaTrePerDue from '../messaggioOffertaTrePerDue/MessaggioOffertaTrePerDue';
import MessaggioProdottoOmaggio from '../messaggioProdottoOmaggio/MessaggioProdottoOmaggio';
import QuantitaInput from '../quantitaInput/QuantitaInput';
import DropdownColore from '../dropdownColore/DropdownColore';
import ProductKit from '../productKit/ProductKit';
import TotaleCostoProdotto from '../totaleCostoProdotto/TotaleCostoProdotto';
import PulsanteAggiungiCarrello from '../pulsanteAggiungiCarrello/PulsanteAggiungiCarrello';
import ModalAddedToCart from '../modalAddedToCart/ModalAddedToCart';
import ProductKitModal from '../productKitModal/ProductKitModal';
import { gtmAddToCart } from '../../../gtm/gtm';

const ProductConfigurationMetriQuadri = ({ updateMainData }) => {
	// store data
	const dispatch = useDispatch();
	const history = useHistory();
	const token = useSelector((state) => state.user.token_id);
	const cartLastItem = useSelector((state) => state.cartLastItem);
	const order = useSelector((state) => state.order);
	const user = useSelector((state) => state.user);
	const currentLang = useSelector((state) => state.currentPage.currentLang);
	const siteParams = useSelector((state) => state.siteParams);
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const promotion = useSelector((state) => state.promotion);
	const fasiCarrelloTranslation = useSelector(
		(state) => state.translations.fasiCarrello
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);
	const flags = useSelector(
		(state) => state.currentPage.pageContent.detail.flags
	);
	const prezzoProdotto =
		pageContent.detail.prezzoScontato > 0
			? pageContent.detail.prezzoScontato
			: pageContent.detail.imponibile;
	const prezzoProdottoOriginale =
		pageContent.detail.prezzoScontato > 0 ? pageContent.detail.imponibile : 0;
	const sconto = pageContent.detail.sconto > 0 ? pageContent.detail.sconto : 0;

	// Varianti colore?
	let productHasColors = false;
	let variantiColore = [];
	if (pageContent.detail.variantiColore.length > 0) {
		variantiColore = pageContent.detail.variantiColore;
		productHasColors = true;
	}

	// Varianti altezza fissa?
	let variantiAltezzaFissa = [];
	if (pageContent.detail.altezzeFisse.length > 0) {
		variantiAltezzaFissa = pageContent.detail.altezzeFisse;
	}

	// menu a tendina di prezzi fissi?
	let elencoPrezziFissi = '';
	if (pageContent.detail?.prezziFissi.length > 0) {
		elencoPrezziFissi = pageContent.detail.prezziFissi.map(({ id, titolo }) => {
			return { id, value: titolo };
		});
	}

	// KIT?
	let productHasKit = false;
	let primoKitElenco = null;
	let idKit = null;
	if (Object.keys(pageContent.detail.elencoKitsAssociati).length > 0) {
		idKit = Object.keys(pageContent.detail.elencoKitsAssociati)[0];
		primoKitElenco = pageContent.detail.elencoKitsAssociati[idKit];
		productHasKit = true;
	}

	// local state
	const [prezzoFissoObj, setPrezzoFissoObj] = useState({});
	const [larghezza, setLarghezza] = useState('');
	const [lunghezza, setLunghezza] = useState('');
	const [quantita, setQuantita] = useState(1);
	const [dimensione, setDimensione] = useState(0);
	const [peso, setPeso] = useState(0);
	const [totaleImponibile, setTotaleImponibile] = useState(0);
	const [totaleImponibileOriginale, setTotaleImponibileOriginale] = useState(0);
	const [totalePrezzo, setTotalePrezzo] = useState(0);
	const [totalePrezzoOriginale, setTotalePrezzoOriginale] = useState(0);
	const [isAddToCart, setIsAddToCart] = useState(false);
	const [refreshCampi, setRefreshCampi] = useState(1);

	// state per dropdown colori
	const [dropdownColorSelected, setDropdownColorSelected] = useState(
		variantiColore[0]?.coloreText | false
	);
	const [dropdownBgColorSelected, setDropdownBgColorSelected] = useState(
		variantiColore[0]?.coloreHex
	);

	// state per i KIT
	const [dropdownIDKitSelected, setDropdownIDKitSelected] = useState(
		parseFloat(idKit)
	);
	const [dropdownNameKitSelected, setDropdownNameKitSelected] = useState(
		primoKitElenco?.name
	);
	const [isShowKit, setIsShowKit] = useState(false);
	const [selectedKit, setSelectedKit] = useState({});
	const [addedKit, setAddedKit] = useState({});
	const [totalePrezzoKit, setTotalePrezzoKit] = useState(0);
	const [arrayIdsAccessoriKit, setArrayIdsAccessoriKit] = useState([]);
	const [arrayQuantityAccessoriKit, setArrayQuantityAccessoriKit] = useState(
		[]
	);

	const onClickGoToCart = () => {
		//console.log('aaaa');
		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		// vado al carrello
		dispatch(
			allActions.changePageActions.changePage(
				'',
				fasiCarrelloTranslation.carrello.routeName,
				fasiCarrelloTranslation.carrello.linkName,
				history
			)
		);
	};

	useEffect(() => {
		changeDimensioneTotali(prezzoFissoObj);

		// mostro modal per item aggiunto a carrello e reimposto isAddToCart a false
		if (isAddToCart === true && cartLastItem?.id !== undefined) {
			setIsAddToCart(false);

			let dimensioneCartItem = `${parseFloat(lunghezza)
				.toFixed(2)
				.toString()
				.replace('.', ',')}x${parseFloat(larghezza)
				.toFixed(2)
				.toString()
				.replace('.', ',')} (${parseFloat(cartLastItem.attributes.dimensione)
				.toFixed(2)
				.toString()
				.replace('.', ',')} ${cartLastItem.attributes.unitaMisura})`;

			ModalAddedToCart({
				cartLastItem: cartLastItem,
				dimensione: dimensioneCartItem,
				subtotalCart: order.subtotalCart,
				onClickHandler: onClickGoToCart,
				genericheTranslation: genericheTranslation,
			});

			// gtm add to cart
			const productData = {
				titoloBreve: pageContent.name,
				codice: pageContent.detail.codice,
			};
			const categoryName = pageContent.detail.titoloCategoria;
			const prezzoProdotto = parseFloat(totalePrezzo / quantita).toFixed(2);

			const gtmSuffix = `gtm_code${currentLang}`;
			const currentGTMTAG = siteParams[gtmSuffix];
			gtmAddToCart({
				productData,
				categoryName,
				prezzoCarrello: prezzoProdotto,
				quantitaCarrello: quantita,
				isGtmEnabled: currentGTMTAG !== null ? true : false,
			});
		}
	}, [
		lunghezza,
		larghezza,
		quantita,
		totalePrezzo,
		totalePrezzoOriginale,
		cartLastItem,
		order,
		prezzoFissoObj,
		isShowKit,
		addedKit,
	]);

	const onPrezziFissiChange = (event) => {
		//console.log(event.target.value);
		if (event.target.value !== '') {
			const prezziFissi = pageContent.detail.prezziFissi;
			const prezzoFissoId = Object.keys(prezziFissi)
				.filter((key) => prezziFissi[key].id === parseInt(event.target.value))
				.toString();
			setPrezzoFissoObj(prezziFissi[prezzoFissoId]);

			// aggiorno lunghezza, larghezza nello state locale
			setLunghezza(prezziFissi[prezzoFissoId].lunghezza);
			setLarghezza(prezziFissi[prezzoFissoId].larghezza);

			// aggiorno anche ProductMainData mostrando il prezzo fisso selezionato
			updateMainData(prezzoFissoId);
		} else {
			setPrezzoFissoObj({});

			// aggiorno anche ProductMainData ricaricando il primo prezzo fisso (indice 0)
			updateMainData(0);
		}
	};

	const onLunghezzaChange = (event) => {
		if (event.value === undefined) {
			setLunghezza(event.target.value);
		} else {
			setLunghezza(event.value);
		}

		// rimuovo kit perchè deve essere ricalcolato da modal
		onRemoveKitClick();
	};

	const onLarghezzaChange = (event) => {
		console.log(event.value);
		setLarghezza(event.value);

		// rimuovo kit perchè deve essere ricalcolato da modal
		onRemoveKitClick();
	};

	const onQuantitaChange = (event) => {
		if (event === 'sottrai' && quantita > 1) {
			setQuantita(quantita - 1);
		} else if (event === 'somma') {
			setQuantita(quantita * 1 + 1);
		} else {
			// mi arriva da digitazione diretta
			if (event?.target !== undefined && event?.target?.value !== '') {
				setQuantita(parseInt(event.target.value));
			} else {
				if (!isNaN(event)) {
					setQuantita(event);
				} else {
					if (!isNaN(event)) {
						setQuantita(event);
					} else {
						setQuantita('');
					}
				}
			}
		}

		// rimuovo kit perchè deve essere ricalcolato da modal
		onRemoveKitClick();
	};

	const onAddToCartHandler = () => {
		// mostro preloader add to cart
		setIsAddToCart(true);

		// preparo oggetto prodotto per add to cart
		const productCart = {
			tokenCarrello: token,
			product_id: pageContent.id,
			name: pageContent.name,
			codice: pageContent.detail.codice,
			titoloCategoria: pageContent.detail.titoloCategoria,
			lunghezza: lunghezza,
			larghezza: larghezza,
			dimensione: dimensione,
			quantita: quantita.toString(),
			totalePrezzo: totaleImponibile,
			totalePrezzoOriginale: totaleImponibileOriginale,
			sconto: pageContent.detail.sconto,
			tipologia_sconto: pageContent.detail.tipologia_sconto,
			tipologia_sconto_msg: pageContent.detail.tipologia_sconto_msg,
			promozione3x2: pageContent.detail.promozione3x2,
			promozioneProdottoAbbinato: pageContent.detail.promozioneProdottoAbbinato,
			colore: dropdownColorSelected,
			pesoCollo: peso.toString(),
			prezzoFissoObj: prezzoFissoObj,
			kit: selectedKit,
			kitTotale: totalePrezzoKit,
			kitIdsAccessori: arrayIdsAccessoriKit,
			kitQuantityAccessori: arrayQuantityAccessoriKit,
		};

		// chiamo action creator postCartAdd
		dispatch(
			allActions.cartDetailActions.postCartAdd(
				currentLang,
				user?.userData?.id,
				order.selectedCountryRealId,
				productCart
			)
		);
	};

	const changeDimensioneTotali = (prezzoFissoObj = null) => {
		let dimensioneValue = 0;
		let dimensioneValueQta = 0;
		let totalePrezzo = 0;
		let totalePrezzoOriginale = 0;
		let totalePrezzoQta = 0;
		let totalePrezzoOriginaleQta = 0;
		let pesoCollo = 0;

		// prodotto venduto a prezzi fissi?
		if (
			pageContent.detail?.prezziFissi.length > 0 &&
			prezzoFissoObj.id !== undefined
		) {
			dimensioneValue = prezzoFissoObj.larghezza * prezzoFissoObj.lunghezza;
			dimensioneValueQta = dimensioneValue * quantita;
			totalePrezzo =
				sconto > 0
					? prezzoFissoObj.prezzo - (prezzoFissoObj.prezzo / 100) * sconto
					: prezzoFissoObj.prezzo;
			totalePrezzoOriginale = sconto > 0 ? prezzoFissoObj.prezzo : 0;
			totalePrezzoQta = quantita * totalePrezzo;
			totalePrezzoOriginaleQta = quantita * totalePrezzoOriginale;
			pesoCollo = quantita * prezzoFissoObj.peso;
		} else if (pageContent.detail?.prezziFissi.length === 0) {
			// prodotto normale venduto con campi liberi
			dimensioneValue = larghezza * lunghezza;
			dimensioneValueQta = dimensioneValue * quantita;
			totalePrezzo = dimensioneValue * prezzoProdotto;
			totalePrezzoOriginale = dimensioneValue * prezzoProdottoOriginale;
			totalePrezzoQta =
				dimensioneValueQta > 0 ? dimensioneValueQta * prezzoProdotto : 0;
			totalePrezzoOriginaleQta =
				dimensioneValueQta > 0
					? dimensioneValueQta * prezzoProdottoOriginale
					: 0;
			pesoCollo = dimensioneValueQta * pageContent.detail.peso;
		} else {
			dimensioneValue = 0;
			dimensioneValueQta = 0;
			totalePrezzo = 0;
			totalePrezzoOriginale = 0;
			totalePrezzoQta = 0;
			totalePrezzoOriginaleQta = 0;
			pesoCollo = 0;
		}

		setDimensione(parseFloat(dimensioneValueQta).toFixed(4));
		setPeso(pesoCollo);
		setTotaleImponibile(parseFloat(totalePrezzo).toFixed(4));
		setTotaleImponibileOriginale(parseFloat(totalePrezzoOriginale).toFixed(4));
		setTotalePrezzo(parseFloat(totalePrezzoQta).toFixed(4));
		setTotalePrezzoOriginale(parseFloat(totalePrezzoOriginaleQta).toFixed(4));
	};

	// funzioni per dropdown colori
	const onDropdownColorChange = (color, bgColor) => {
		setDropdownColorSelected(color);
		setDropdownBgColorSelected(bgColor);
	};

	// funzioni per i KIT
	const onDropdownKitChange = (id, nameKit) => {
		//console.log(id, nameKit);
		setDropdownIDKitSelected(parseFloat(id));
		setDropdownNameKitSelected(nameKit);
	};

	const onAddKitClick = (
		idKit = 0,
		totaleKit = 0,
		array_ids_accessori = [],
		array_quantity_accessori = [],
		lunghezzaNewValue = 0,
		larghezzaNewValue = 0,
		profonditaNewValue = 0,
		metrilineariNewValue = 0,
		diametroNewValue = 0,
		quantitaNewValue = 0
	) => {
		/* console.log(
			idKit,
			totaleKit,
			array_ids_accessori,
			array_quantity_accessori
		); */

		// kit aggiunto
		setAddedKit(pageContent.detail.elencoKitsAssociati[idKit]);

		// totale kit
		setTotalePrezzoKit(totaleKit);

		// array ids accessori kit
		setArrayIdsAccessoriKit(array_ids_accessori);

		// array quantità accessori kit
		setArrayQuantityAccessoriKit(array_quantity_accessori);

		// reimposto misure rete
		setLunghezza(parseFloat(lunghezzaNewValue));
		setLarghezza(parseFloat(larghezzaNewValue));
		setQuantita(parseFloat(quantitaNewValue));
		setRefreshCampi(lunghezzaNewValue);
	};

	const onRemoveKitClick = () => {
		setAddedKit({});
		setTotalePrezzoKit(0);
		setArrayIdsAccessoriKit([]);
		setArrayQuantityAccessoriKit([]);
	};

	const onCloseModalHandler = () => {
		// nascondo il modal
		setIsShowKit(false);
	};

	const onShowKitClick = (idKit) => {
		// kit selezionato
		setSelectedKit(pageContent.detail.elencoKitsAssociati[idKit]);

		// mostro il modal
		setIsShowKit(true);
	};

	const showModalKit = isShowKit && (
		<ProductKitModal
			kit={selectedKit}
			immagineProdotto={pageContent.detail.photoGallery[0]}
			nomeProdotto={pageContent.detail.titolo}
			codiceProdotto={pageContent.detail.codice}
			measure_unit_id={pageContent.measure_unit_id}
			larghezzaValue={larghezza}
			lunghezzaValue={lunghezza}
			dimensioneValue={dimensione}
			quantitaValue={quantita}
			genericheTranslation={genericheTranslation}
			flags={flags}
			onKitAddHandler={onAddKitClick}
			onKitRemoveHandler={onRemoveKitClick}
			onCloseModal={onCloseModalHandler}
		/>
	);

	const divTendinaPrezziFissi =
		elencoPrezziFissi != '' ? (
			<div className='grid grid-cols-60 mt-6'>
				<div className='col-span-60'>
					<SelectField
						selectName='prezzifissi'
						selectListValue={elencoPrezziFissi}
						selectLabel={genericheTranslation.scegliopzione.itemName.toUpperCase()}
						selectHandlerFunction={onPrezziFissiChange}
						selectLabelStyle='text-sm'
						selectClassStyle='bg-transparent w-full border border-ribola-dove-gray border-opacity-20 focus:border-opacity-100 py-1.5 mt-px text-sm'
					/>
				</div>
			</div>
		) : (
			''
		);

	const contentDivKit = productHasKit ? (
		<div className='grid grid-cols-60'>
			<div className='col-span-60'>
				<ProductKit
					idKit={dropdownIDKitSelected}
					nameKit={dropdownNameKitSelected}
					onDropdownKitChange={onDropdownKitChange}
					onKitHandler={
						addedKit?.id === dropdownIDKitSelected
							? onRemoveKitClick
							: onShowKitClick
					}
					onShowKitHandler={onShowKitClick}
					totaleKit={totalePrezzoKit}
					isDisabled={totalePrezzo > 0 ? false : true}
					isKitAdded={addedKit?.id === dropdownIDKitSelected ? true : false}
				/>
			</div>
		</div>
	) : (
		''
	);

	// visualizzo dropdown colori?
	const contentDivColors = productHasColors ? (
		<div className='col-span-60 sm:col-span-30 xl:col-span-60 3xl:col-span-30 mt-4 sm:mt-0 xl:mt-4 3xl:mt-0'>
			<DropdownColore
				bgColor={dropdownBgColorSelected}
				onDropdownColorChange={onDropdownColorChange}
			/>
		</div>
	) : (
		''
	);

	// visualizzo messaggio bordatura?
	const messaggioBordatura =
		pageContent.detail.flags.flag_abilita_messaggio_bordatura === 1 ? (
			<MessaggioBordatura />
		) : (
			''
		);

	// visualizzo box prodotto in promozione 3x2?
	let isProdotto3x2 = false;
	if (promotion && promotion?.tipologiaPromozione === 'Promozione 3x2') {
		const prodotto3x2 = promotion?.elencoProdotti?.filter((item) => {
			return item.id === pageContent.id;
		})[0];
		if (prodotto3x2) {
			isProdotto3x2 = true;
		}
	}
	const boxPromo3x2 = isProdotto3x2 ? <MessaggioOffertaTrePerDue /> : '';
	// fine prodotto 3x2

	// visualizzo box promozione prodotto abbinato?
	let isPromoProdottoAbbinato = false;
	let prodottoAbbinato;
	if (
		promotion &&
		promotion?.tipologiaPromozione === 'Promozione Prodotto abbinato'
	) {
		prodottoAbbinato = promotion?.elencoProdotti?.filter((item) => {
			return item.id === pageContent.id;
		})[0];
		if (prodottoAbbinato) {
			isPromoProdottoAbbinato = true;
		}
	}
	const boxProdottoAbbinato = isPromoProdottoAbbinato ? (
		<MessaggioProdottoOmaggio datiProdottoAbbinato={prodottoAbbinato} />
	) : (
		''
	);
	// fine prodotto abbinato

	const boxTotale =
		prezzoProdottoOriginale > 0 ? (
			<TotaleCostoProdotto
				prezzoProdotto={parseFloat(totalePrezzo).toFixed(2)}
				prezzoOriginale={parseFloat(totalePrezzoOriginale).toFixed(2)}
				totalePrezzoKit={totalePrezzoKit}
			/>
		) : (
			<TotaleCostoProdotto
				prezzoProdotto={parseFloat(totalePrezzo).toFixed(2)}
				totalePrezzoKit={totalePrezzoKit}
			/>
		);

	// controllo se devo mostrare i campi di inserimento libero (metri quadri) oppure il menu a tendina dei prezzi fissi
	const showPrezziFissoOrCampimetriQuadri =
		elencoPrezziFissi != '' ? (
			divTendinaPrezziFissi
		) : (
			<CampiMetriQuadri
				key={`campiMetriQuadri_${refreshCampi}`}
				larghezzaValue={larghezza}
				lunghezzaValue={lunghezza}
				altezzeFisseList={variantiAltezzaFissa}
				onLarghezzaChange={onLarghezzaChange}
				onLunghezzaChange={onLunghezzaChange}
			/>
		);

	const disableFormWhileSending = isAddToCart ? 'pointer-events-none' : '';

	return (
		<div className={`${disableFormWhileSending}`}>
			<span className='text-xl font-opensanssemibold flex items-center justify-between'>
				{genericheTranslation.dimensioni.itemName.toUpperCase()} (
				{parseFloat(dimensione).toFixed(2).toString().replace('.', ',')}&nbsp;
				{genericheTranslation.mq.itemName})
				<InfoTooltipElement
					iconColor='ribola-astral'
					fontSize='text-3xl'
					tooltipColor='ribola-astral'
					tooltipText={genericheTranslation.tooltipmq.itemName}
				/>
			</span>
			{showPrezziFissoOrCampimetriQuadri}
			<div className='grid grid-cols-60 mt-8'>
				<div className='col-span-60 sm:col-span-30 xl:col-span-60 3xl:col-span-30'>
					<QuantitaInput
						quantitaValue={quantita}
						onQuantitaChange={onQuantitaChange}
					/>
				</div>
				{contentDivColors}
			</div>
			{boxPromo3x2}
			{boxProdottoAbbinato}
			{messaggioBordatura}
			{contentDivKit}
			{boxTotale}
			<div className='w-full flex justify-center'>
				<PulsanteAggiungiCarrello
					onAddToCartHandler={onAddToCartHandler}
					isDisabled={totalePrezzo > 0 ? false : true}
					isSending={isAddToCart}
				/>
			</div>
			{showModalKit}
		</div>
	);
};

export default ProductConfigurationMetriQuadri;
