import { useEffect } from 'react';

const DropdownColoreItem = ({ labelColore, coloreHex, onItemClickHandler }) => {
	useEffect(() => {
		//console.log(coloreHex);
	}, []);

	// doppio colore?
	const arrayDoubleColor = coloreHex.split('|');
	const styleBgColor =
		coloreHex.split('|').length > 1 ? (
			<span
				className='w-8 h-8'
				style={{
					background: `linear-gradient(90deg, ${arrayDoubleColor[0]} 50%, ${arrayDoubleColor[1]} 50%)`,
				}}></span>
		) : (
			<span className='w-8 h-8' style={{ backgroundColor: coloreHex }}></span>
		);

	return (
		<div
			className='flex items-center w-full py-1 pl-2 pr-3 border-b border-gray-200 bg-white hover:bg-gray-100 transition'
			onClick={() => onItemClickHandler(labelColore, coloreHex)}>
			{styleBgColor}
			<span className='ml-3'>{labelColore}</span>
		</div>
	);
};

export default DropdownColoreItem;
