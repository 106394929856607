import { useDispatch, useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import allActions from '../../../state/actions';
import ProfiloLeftItem from '../profiloLeftItem/ProfiloLeftItem';

const BloccoProfiloMenuLeft = () => {
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const menuProfiloItems = useSelector(
		(state) => state.translations.menuLeftProfilo
	);

	const onLogoutClick = (event) => {
		event.preventDefault();

		// chiamo action creator
		dispatch(
			allActions.userActions.logoutUser(currentPage.currentLang, user.token_id)
		);
	};

	return (
		<div className='menuLeft w-full lg:border-r border-gray-300 lg:pt-12 px-6 lg:px-0 mb-6 lg:mb-0'>
			<nav>
				<ProfiloLeftItem
					linkSeo={menuProfiloItems.profilo.itemLink}
					profiloName={menuProfiloItems.profilo.itemName.toUpperCase()}
					routeName={menuProfiloItems.profilo.itemRouteName}
				/>
				<ProfiloLeftItem
					linkSeo={menuProfiloItems.indirizzi.itemLink}
					profiloName={menuProfiloItems.indirizzi.itemName.toUpperCase()}
					routeName={menuProfiloItems.indirizzi.itemRouteName}
				/>
				<ProfiloLeftItem
					linkSeo={menuProfiloItems.ordiniProfilo.itemLink}
					profiloName={menuProfiloItems.ordiniProfilo.itemName.toUpperCase()}
					routeName={menuProfiloItems.ordiniProfilo.itemRouteName}
				/>
				<ProfiloLeftItem
					linkSeo={menuProfiloItems.logout.itemLink}
					profiloName={menuProfiloItems.logout.itemName.toUpperCase()}
					profiloOnClickFunction={onLogoutClick}
				/>
			</nav>
		</div>
	);
};

export default BloccoProfiloMenuLeft;
