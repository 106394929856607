import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import MenuLeft from '../../components/shared/menuLeft/MenuLeft';
import ContattiKODetail from '../../components/contattiKODetail/ContattiKODetail';

const ContattiKO = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const staticPages = useSelector((state) => state.staticPages);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// controllo nello store se ho già il dettaglio di error404, altrimenti eseguo action creator
		const contattiKOPage = staticPages.contattiko;

		if (contattiKOPage === undefined || contattiKOPage.detail === undefined) {
			// non ho i dati del prodotto nello store, chiamo action creator fetchContattiKODetail
			setTimeout(
				() =>
					dispatch(
						allActions.staticDetailActions.fetchContattiKODetail(
							currentPage.currentLang,
							user?.userData?.id
						)
					),
				50
			);
		} else {
			// loading...
			dispatch(allActions.isFetchingAction.loadStoredData());

			// recupero dati da store
			dispatch(
				allActions.staticDetailActions.loadContattiKODetail(contattiKOPage)
			);
		}

		//console.log(contattiKOPage);
	}, [location]);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<MenuLeft />
					<ContattiKODetail />
				</div>
			</div>
		</Layout>
	);
};

export default ContattiKO;
