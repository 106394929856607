import { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import AOS from 'aos';
import allActions from '../../../state/actions';
import _uniqueId from 'lodash/uniqueId';
import TitoloBlocco from '../../shared/titoloBlocco/TitoloBlocco';
import InspirationItem from './inspirationItem/InspirationItem';

const BloccoInspirationHome = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [handLeftVisible, setHandLeftVisible] = useState(false);
	const [handRightVisible, setHandRightVisible] = useState(true);
	const trackFirstElement = useRef(null);
	const trackLastElement = useRef(null);
	const inspirationsHome = useSelector((state) => state.inspirationsHome);
	const totalInspiration = inspirationsHome.length;
	const homepageTranslation = useSelector(
		(state) => state.translations.homepage
	);
	const navigationItems = useSelector(
		(state) => state.translations.mainNavigation
	);

	useEffect(() => {
		// inizializzo AOS
		AOS.init();
		AOS.refresh();
	}, []);

	const elencoInspiration = inspirationsHome.map((inspiration, id) => {
		let animationType = id === 0 || id === 2 ? 'fade-right' : 'fade-left';

		if (id === 0) {
			return (
				<div
					key={_uniqueId('inspiration_')}
					ref={trackFirstElement}
					className='lg:w-auto lg:col-span-30'
					data-aos={animationType}
					data-aos-delay='100'
					data-aos-offset='200'
					data-aos-once={false}>
					<InspirationItem {...inspiration} />
				</div>
			);
		} else if (id === totalInspiration - 1) {
			return (
				<div
					key={_uniqueId('inspiration_')}
					ref={trackLastElement}
					className='lg:w-auto lg:col-span-30'
					data-aos={animationType}
					data-aos-delay='100'
					data-aos-offset='200'
					data-aos-once={false}>
					<InspirationItem {...inspiration} />
				</div>
			);
		} else {
			return (
				<div
					key={_uniqueId('inspiration_')}
					className='lg:w-auto lg:col-span-30'
					data-aos={animationType}
					data-aos-delay='100'
					data-aos-offset='200'
					data-aos-once={false}>
					<InspirationItem {...inspiration} />
				</div>
			);
		}
	});

	const onScrollHandler = () => {};

	const onClickShowNow = (event) => {
		event.preventDefault();

		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(
				'',
				navigationItems.prodotti.itemRouteName,
				navigationItems.prodotti.itemLink,
				history
			)
		);
	};

	/* 
	///  DA SISTEMARE CREA PROBLEMI
	const onScrollHandler = () => {
		const myFirstElement = trackFirstElement.current;
		const firstElementX = myFirstElement.getBoundingClientRect().x;
		if (firstElementX < 0) {
			setHandLeftVisible(true);
		} else {
			setHandLeftVisible(false);
		}

		const myLastElement = trackLastElement.current;
		const lastElementX = myLastElement.getBoundingClientRect().x;
		if (lastElementX < 100) {
			setHandRightVisible(false);
		} else {
			setHandRightVisible(true);
		}
	}; */

	const handLeft = handLeftVisible ? 'opacity-100' : 'opacity-0';
	const handRight = handRightVisible ? 'opacity-100' : 'opacity-0';

	return (
		<div className='containerFluido container mx-auto mb-8 sm:mb-20'>
			<div className='grid grid-cols-60'>
				<div className='col-span-60 flex flex-col justify-center items-center pt-8 sm:pt-16 sm:pb-4 lg:pb-16'>
					<TitoloBlocco
						titoloStyle='titoloBlocco text-ribola-astral text-3xl sm:text-4xl lg:text-5xl 4xl:text-6xl'
						titolo={homepageTranslation.titoloLasciatiIspirare.itemName.toUpperCase()}
					/>
				</div>
			</div>
			<div className='relative flex flex-col justify-center items-center'>
				<div className='flex justify-between text-ribola-astral py-3 lg:hidden'>
					{/* <span
						className={`text-md flex items-center transition mx-3 ${handLeft}`}>
						<i className='fal fa-hand-point-left text-lg mr-1'></i>
						{homepageTranslation.scorri.itemName}
					</span> */}
					<span
						className={`text-md flex items-center transition mx-3 ${handRight}`}>
						{homepageTranslation.scorri.itemName}
						{/* <i className='fal fa-hand-point-right text-lg ml-1'></i> */}
					</span>
				</div>
				<span
					className='shopNowHome w-52 xl:w-72 h-52 xl:h-72 rounded-full font-poppinssemibold text-2xl xl:text-4xl text-ribola-astral hidden lg:flex justify-center items-center text-center bg-white absolute z-10 cursor-pointer pointer-none'
					onClick={onClickShowNow}
					data-aos='zoom-in'
					data-aos-delay='600'
					data-aos-offset='200'
					data-aos-once={false}>
					{homepageTranslation.titoloShopNow.itemName.toUpperCase()}
				</span>
				<div className='grid grid-cols-60'>
					<div className='col-span-60'>
						<div
							onScroll={onScrollHandler}
							className='elencoInspiration grid grid-rows-1 grid-flow-col lg:grid-flow-row lg:grid-cols-60 gap-0 lg:gap-4'>
							{elencoInspiration}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BloccoInspirationHome;
