import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import allActions from '../../../state/actions';
import Briciole from '../../shared/briciole/Briciole';
import InputField from '../../shared/inputField/InputField';
import ModalConfirm from '../../shared/modalConfirm/ModalConfirm';
import RegistrationFormFields from '../../cart/registrationFormDetail/registrationFormFields/RegistrationFormFields';

const ProfileDetail = () => {
	const dispatch = useDispatch();
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const {
		register,
		formState: { errors },
		handleSubmit,
		control,
		getValues,
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			userId: user?.userData.id,
			shipping_country_id: user?.userData.shipping_country_id,
			nome: user?.userData.meta_data.nome,
			cognome: user?.userData.meta_data.cognome,
			indirizzo: user?.userData.meta_data.indirizzo,
			cap: user?.userData.meta_data.cap,
			provincia: user?.userData.meta_data.provincia,
			provinciaEstera: user?.userData.meta_data.provinciaestera,
			email: user?.userData.email,
			telefono: user?.userData.meta_data.telefono,
			ragioneSociale: user?.userData.meta_data.ragionesociale,
			partitaIva: user?.userData.meta_data.partitaiva,
			codiceFiscale: user?.userData.meta_data.codicefiscale,
			pec: user?.userData.meta_data.pec,
			sdi: user?.userData.meta_data.sdi,
			discount_group_id: user?.userData.discount_group_id,
			targetUser: user?.userData.meta_data.targetuser,
		},
	});
	const profiloTranslation = useSelector((state) => state.translations.profilo);
	const campiFormTranslation = useSelector(
		(state) => state.translations.campiFormRegistrazione
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	// local state
	const [isUpdating, setIsUpdating] = useState(false);
	const [currentUserTarget, setCurrentUserTarget] = useState(
		user?.userData.meta_data.targetuser
	);
	const [nazione, setNazione] = useState(user?.userData.shipping_country_id);

	useEffect(() => {
		//console.log(currentUserTarget);
		if (isUpdating) {
			ModalConfirm({
				titleToShow: profiloTranslation.modalTitoloAggiornaProfilo.itemName,
				messageToShow:
					genericheTranslation.modalmessaggioprofiloaggiornatook.itemName,
				isShowConfirmButton: false,
				isShowCanceButton: false,
				isTimerModal: true,
			});
			setIsUpdating(false);
		}
	}, [user]);

	const onChangeNazione = (event) => {
		setNazione(event.target.value);
	};

	const onSubmitFormProfilo = (data) => {
		// aggiorno profilo utente
		setIsUpdating(true);

		console.log(data);

		// chiamo action creator
		dispatch(
			allActions.userActions.updateUser(
				currentPage.currentLang,
				user.token_id,
				data
			)
		);
	};

	const isProvinciaEsteraVisible =
		nazione != 36 && nazione !== '' && nazione !== undefined ? true : false;
	const isInputDisabled = currentUserTarget !== 'azienda' ? true : false;
	const inputDisabledStyle =
		currentUserTarget !== 'azienda' ? 'bg-gray-300' : '';
	const divDisabledStyle = currentUserTarget !== 'azienda' ? 'opacity-20' : '';

	const confermaPasswordError = errors.confermaPassword ? (
		<p className='text-sm text-red-600'>
			<i className='fal fa-exclamation-triangle mr-1'></i>
			{errors.confermaPassword.message}
		</p>
	) : (
		''
	);

	const isDisabledClick = isUpdating ? 'pointer-events-none' : '';
	const isButtonSending = isUpdating ? true : false;

	const showFieldsAzienda =
		user?.userData.meta_data.targetuser === 'azienda' ? true : false;

	// controllo che esista la proprietà detail (altrimenti vuol dire che sto ancora recuperando i dati)
	if (!pageContent.detail) {
		return <div></div>;
	}

	return (
		<div
			className={`col-start-1 lg:col-start-15 4xl:col-start-16 col-end-61 3xl:col-end-59 4xl:col-end-57 pb-12 ${isDisabledClick}`}>
			<Briciole />
			<div className='grid grid-cols-60 mt-0 sm:mt-3 xl:mt-6'>
				<div className='col-span-60 flex flex-col justify-center px-6 xl:px-12 3xl:px-20 xl:pt-8 pb-3 sm:pb-8 xl:pb-12 3xl:py-14'>
					<h1 className='text-ribola-congress-blue text-3xl md:text-4xl xl:text-5xl font-opensansbold'>
						<small className='leading-tight flex'>
							{profiloTranslation.iltuoprofilo.itemName.toUpperCase()}
						</small>
					</h1>
					<h2 className='text-lg md:text-xl mt-3'>
						<span className='leading-tight flex'>
							{profiloTranslation.sottotitoloiltuoprofilo.itemName}
						</span>
					</h2>
				</div>
			</div>
			<form onSubmit={handleSubmit(onSubmitFormProfilo)}>
				<InputField
					inputType='hidden'
					inputName='userId'
					showLabel={false}
					register={register('userId')}
				/>
				<InputField
					inputType='hidden'
					inputName='targetUser'
					showLabel={false}
					register={register('targetUser')}
				/>
				<div className='grid grid-cols-60 px-3 xl:pl-12 3xl:pl-20'>
					<RegistrationFormFields
						onChangeNazioneFunction={onChangeNazione}
						isDivDisabledStyle={divDisabledStyle}
						isInputDisabled={isInputDisabled}
						isinputDisabledStyle={inputDisabledStyle}
						isProvinciaEsteraVisible={isProvinciaEsteraVisible}
						showFieldsAzienda={showFieldsAzienda}
						isFullNameRequired={!showFieldsAzienda}
						errors={errors}
						register={register}
						Controller={Controller}
						control={control}
					/>
				</div>
				<div className='grid grid-cols-60 mt-12 sm:mt-20'>
					<div className='col-span-60 flex flex-col justify-center px-6 xl:px-12 3xl:px-20'>
						<hr className='w-3/6 h-2' />
						<span className='text-ribola-congress-blue text-3xl md:text-4xl xl:text-5xl font-opensansbold mt-6'>
							<small className='leading-tight flex'>
								{campiFormTranslation.datilogin.itemName.toUpperCase()}
							</small>
						</span>
						<span className='text-lg md:text-xl mt-3'>
							<span className='leading-tight flex'>
								{profiloTranslation.sottotitolocambiapassword.itemName}
							</span>
						</span>
					</div>
				</div>
				<div className='grid grid-cols-60 mt-3 px-6 xl:pl-12 3xl:pl-20'>
					<div className='col-span-60 sm:col-span-30 xl:col-span-25'>
						<InputField
							inputType='password'
							inputLabel='<b>Password:</b>'
							inputLabelStyle='flex justify-between w-full sm:text-lg 2xl:text-xl text-black mt-8'
							inputName='password'
							inputPlaceholder='password'
							inputStyle='border border-ribola-dove-gray border-opacity-100 mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12'
							register={register('password')}
						/>
					</div>
					<div className='col-span-60 sm:col-span-30 xl:col-span-25'>
						<InputField
							inputType='password'
							inputLabel={`<b>${campiFormTranslation.confermapassword.itemName}:</b>`}
							inputLabelStyle='flex justify-between w-full sm:text-lg 2xl:text-xl text-black mt-8'
							inputName='confermaPassword'
							inputPlaceholder={campiFormTranslation.confermapassword.itemName}
							inputStyle='border border-ribola-dove-gray border-opacity-100 mt-3 py-3 px-8 sm:text-lg 2xl:text-xl w-full sm:w-11/12'
							inputError={confermaPasswordError}
							register={register('confermaPassword', {
								validate: {
									matchesPreviousPassword: (value) => {
										const { password } = getValues();
										return (
											password === value ||
											campiFormTranslation.passworddiverse.itemName
										);
									},
								},
							})}
						/>
					</div>
				</div>
				<div className='grid grid-cols-60 mt-10 px-6 xl:pl-12 3xl:pl-20'>
					<div className='col-start-1 2xl:col-start-45 col-end-61 2xl:col-end-59 flex justify-end'>
						<InputField
							inputType='submit'
							inputValue={profiloTranslation.aggiorna.itemName.toUpperCase()}
							inputStyle='flex justify-center items-center text-white bg-ribola-selective-yellow text-lg sm:text-xl font-opensanssemibold py-4 px-24 border-r-2 border-ribola-gallery-gray cursor-pointer w-full sm:w-auto'
							showLabel={false}
							isSpinnerLoading={isButtonSending}
						/>
					</div>
				</div>
			</form>
		</div>
	);
};

export default ProfileDetail;
