import { Route } from 'react-router-dom';
import ContattiKO from '../pages/contattiko/ContattiKO';

export default (contattikoRoute) => {
	return [
		<Route
			exact
			path={`/${contattikoRoute}.html`}
			component={ContattiKO}
			key={`${contattikoRoute}Route`}
		/>,
	];
};
