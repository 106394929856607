import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import MenuLeft from '../../components/shared/menuLeft/MenuLeft';
import ChisiamoDetail from '../../components/chisiamoDetail/ChisiamoDetail';

const Chisiamo = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const staticPages = useSelector((state) => state.staticPages);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// controllo nello store se ho già il dettaglio di chisiamo, altrimenti eseguo action creator
		const chisiamoPage = staticPages.chisiamo;

		if (chisiamoPage) {
			// setto i metatags
			document.title = chisiamoPage.metaTitle;
			document.getElementsByTagName('META')[2].content = chisiamoPage.metaDesc;
		}

		if (chisiamoPage === undefined || chisiamoPage.detail === undefined) {
			// non ho i dati del prodotto nello store, chiamo action creator fetchChisiamoDetail
			setTimeout(
				() =>
					dispatch(
						allActions.staticDetailActions.fetchChisiamoDetail(
							currentPage.currentLang,
							user?.userData?.id
						)
					),
				50
			);
		} else {
			// loading...
			dispatch(allActions.isFetchingAction.loadStoredData());

			// recupero dati da store
			dispatch(allActions.staticDetailActions.loadChisiamoDetail(chisiamoPage));
		}

		//console.log(chisiamoPage);
	}, [location, staticPages]);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<MenuLeft />
					<ChisiamoDetail />
				</div>
			</div>
		</Layout>
	);
};

export default Chisiamo;
