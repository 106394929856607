import { Route } from 'react-router-dom';
import Category from '../pages/category/Category';

export default (netsRoute) => {
	return [
		<Route
			exact
			path={`/${netsRoute}/:parentCategory/:category/`}
			component={Category}
			key={`${netsRoute}categoryRoute`}
		/>,
	];
};
