import { Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../state/actions';

const ProductMainData = ({ prezzoFissoIndex }) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);
	const fasiCarrelloTranslation = useSelector(
		(state) => state.translations.fasiCarrello
	);
	const isUserLogged = useSelector((state) => state.user.isLogged);

	useEffect(() => {
		console.log(pageContent.detail.unitamisuratext);
	}, []);

	const onClickHaiPiva = (event) => {
		event.preventDefault();

		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(
				'',
				fasiCarrelloTranslation.registrazione.routeName,
				fasiCarrelloTranslation.registrazione.linkName,
				history
			)
		);
	};

	// prezzo fisso da tendina?
	const prezzoDaMenuATendinaFisso =
		pageContent.detail?.prezziFissi?.length > 0
			? pageContent.detail.prezziFissi[prezzoFissoIndex]
			: null;
	const primoPrezzoFisso =
		pageContent.detail.sconto > 0
			? prezzoDaMenuATendinaFisso?.prezzo -
			  (prezzoDaMenuATendinaFisso?.prezzo / 100) * pageContent.detail.sconto
			: prezzoDaMenuATendinaFisso?.prezzo;
	const primoPrezzoFissoOriginale =
		pageContent.detail.sconto > 0 ? prezzoDaMenuATendinaFisso?.prezzo : 0;

	const prezzoOriginale = prezzoDaMenuATendinaFisso
		? primoPrezzoFissoOriginale
		: pageContent.detail.imponibile;
	const prezzo = prezzoDaMenuATendinaFisso
		? primoPrezzoFisso
		: pageContent.detail.sconto > 0
		? pageContent.detail.prezzoScontato
		: pageContent.detail.imponibile;

	const prezzoProdottoOriginale =
		pageContent.detail.sconto > 0 ? (
			<span className='text-ribola-congress-blue text-2xl'>
				<span className='line-through'>
					{parseFloat(prezzoOriginale).toFixed(2).toString().replace('.', ',')}€
				</span>
			</span>
		) : (
			''
		);

	const prezzoProdotto =
		pageContent.detail.sconto > 0 ? (
			<span className='text-ribola-web-orange text-5xl'>
				{parseFloat(prezzo).toFixed(2).toString().replace('.', ',')}€
				<small className='ml-3'>
					{pageContent.isProntaConsegna === false
						? pageContent.detail.unitamisuratext
						: ''}
				</small>
			</span>
		) : (
			<span className='text-ribola-congress-blue text-4xl'>
				{parseFloat(prezzo).toFixed(2).toString().replace('.', ',')}€
				<small className='ml-3'>
					{pageContent.isProntaConsegna === false
						? pageContent.detail.unitamisuratext
						: ''}
				</small>
			</span>
		);

	const showPrezzi = pageContent.detail.flags
		?.flag_abilita_informazioni_prodotto ? (
		''
	) : (
		<Fragment>
			{prezzoProdottoOriginale}
			{prezzoProdotto}
			<span className='text-xl'>{genericheTranslation.piuiva.itemName}</span>
		</Fragment>
	);

	return (
		<Fragment>
			<h1 className='font-opensansbold text-3xl text-ribola-astral'>
				{pageContent.detail.titolo.toUpperCase()} - {pageContent.detail.codice}
			</h1>
			<h2
				className='text-base sm:text-lg mt-3'
				dangerouslySetInnerHTML={{
					__html: pageContent.detail.sottotitolo,
				}}
			/>
			<div className='w-full flex justify-between items-center mt-6 sm:mt-10'>
				<div className='flex flex-col text-congress-blue font-opensanssemibold'>
					{showPrezzi}
				</div>
				{!isUserLogged && (
					<div
						className='flex flex-col justify-center items-center cursor-pointer'
						onClick={onClickHaiPiva}>
						<span className='bg-ribola-nobel hover:bg-ribola-dove-gray text-white transition text-base sm:text-xl py-3 sm:py-4 px-6 sm:px-10'>
							{genericheTranslation.haipartitaiva.itemName.toUpperCase()}
						</span>
						<span className='text-xs sm:text-sm p-3'>
							{genericheTranslation.registratiiva.itemName}
						</span>
					</div>
				)}
			</div>
		</Fragment>
	);
};

export default ProductMainData;
