import { Route, Redirect } from 'react-router-dom';
import RegistrationForm from '../pages/registrationForm/RegistrationForm';

const RegistrationFormRoute = ({ isLogged, redirectPage, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			!isLogged ? (
				<RegistrationForm />
			) : (
				// se sono loggato ed ho prodotti a carrello lo mando a pagamento/spedizione, altrimenti al profilo
				<Redirect
					exact
					to={{
						pathname: '/' + redirectPage + '.html',
						state: {
							from: props.location,
						},
					}}
				/>
			)
		}
	/>
);

export default RegistrationFormRoute;
