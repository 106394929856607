import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { createPopper } from '@popperjs/core';
import _uniqueId from 'lodash/uniqueId';
import DropdownColoreItem from './dropdownColoreItem/DropdownColoreItem';

const DropdownColore = ({ bgColor, onDropdownColorChange }) => {
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	useEffect(() => {
		//console.log(bgColor);
	}, []);

	const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
	const btnDropdownRef = useRef();
	const popoverDropdownRef = useRef();
	const openDropdownPopover = () => {
		createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
			placement: 'bottom-start',
		});
		setDropdownPopoverShow(true);
	};
	const closeDropdownPopover = () => {
		setDropdownPopoverShow(false);
	};

	const onColorClick = (colorClicked, bgColorClicked) => {
		closeDropdownPopover();
		onDropdownColorChange(colorClicked, bgColorClicked);
	};

	const variantiColore = pageContent.detail.variantiColore.map(
		({ coloreText, coloreHex }) => (
			<DropdownColoreItem
				key={_uniqueId('dropdownColorItem_')}
				labelColore={coloreText}
				coloreHex={coloreHex}
				onItemClickHandler={onColorClick}
			/>
		)
	);

	// doppio colore?
	const arrayDoubleColor = bgColor.split('|');
	const styleButtonBgColor =
		bgColor.split('|').length > 1 ? (
			<button
				className='outline-none focus:outline-none w-8 h-8'
				style={{
					background: `linear-gradient(90deg, ${arrayDoubleColor[0]} 50%, ${arrayDoubleColor[1]} 50%)`,
				}}
				type='button'
				ref={btnDropdownRef}></button>
		) : (
			<button
				className='outline-none focus:outline-none w-8 h-8'
				style={{ backgroundColor: bgColor }}
				type='button'
				ref={btnDropdownRef}></button>
		);

	return (
		<div className='flex justify-start 3xl:justify-end items-center'>
			<span className='w-32 3xl:w-auto text-xl font-opensanssemibold'>
				{genericheTranslation.colore.itemName.toUpperCase()}
			</span>
			<div
				className='flex items-center ml-6 mb-1 cursor-pointer'
				onClick={() => {
					dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
				}}>
				{styleButtonBgColor}
				<i className='far fa-chevron-down text-xs mx-2'></i>
			</div>
			<div
				ref={popoverDropdownRef}
				className={
					(dropdownPopoverShow ? 'block ' : 'hidden ') +
					'text-xs bg-white shadow-sm rounded-md border border-gray-300 overflow-hidden z-20 cursor-pointer'
				}>
				{variantiColore}
			</div>
		</div>
	);
};

export default DropdownColore;
