import { useSelector } from 'react-redux';
import TitoloBlocco from '../../shared/titoloBlocco/TitoloBlocco';
import CustomButton from '../../shared/customButton/CustomButton';
import LazyLoadPicture from '../../shared/lazyLoadPicture/LazyLoadPicture';

const BloccoChisiamoHome = () => {
	const catalogue = useSelector((state) => state.catalogue);
	const paginestaticheTranslation = useSelector(
		(state) => state.translations.paginestatiche
	);
	const mainNavigation = useSelector(
		(state) => state.translations.mainNavigation
	);

	const prontaConsegnaId = Object.keys(catalogue).filter(
		(key) => catalogue[key].name === 'Pronta consegna'
	)[0];
	const prontaConsegna = catalogue[prontaConsegnaId];

	const imgHalfWidth = {
		xl4: '832',
		xl3: '702',
		xl2: '665',
		xl: '640',
		lg: '1024',
		md: '1024',
		sm: '640',
		xs: '640',
		xxs: '480',
	};

	const imgHalfHeight = {
		xl4: '476',
		xl3: '401',
		xl2: '380',
		xl: '390',
		lg: '312',
		md: '312',
		sm: '312',
		xs: '500',
		xxs: '400',
	};

	const imgFullWidth = {
		xl4: '1664',
		xl3: '1403',
		xl2: '1330',
		xl: '1280',
		lg: '1024',
		md: '1024',
		sm: '768',
		xs: '640',
		xxs: '480',
	};

	const imgFullHeight = {
		xl4: '476',
		xl3: '401',
		xl2: '380',
		xl: '390',
		lg: '628',
		md: '800',
		sm: '768',
		xs: '800',
		xxs: '700',
	};

	return (
		<div className='containerFluido container mx-auto'>
			<div className='grid grid-cols-60 pt-0 sm:pt-16'>
				<div className='col-start-1 2xl:col-start-5 col-end-61 2xl:col-end-57'>
					<div className='grid grid-cols-60'>
						<div className='col-start-5 xl:col-start-5 col-end-56 xl:col-end-26 flex flex-col justify-center pt-8 pb-12 sm:py-12 xl:py-0'>
							<TitoloBlocco
								titoloStyle='titoloBlocco text-3xl xs:text-4xl lg:text-5xl 4xl:text-5xl text-ribola-alto mb-6'
								titolo={paginestaticheTranslation.titoloprontaconsegna.itemName}
							/>
							<span
								className='text-sm sm:text-base 4xl:text-lg text-ribola-heavy-metal flex mb-6 sm:mb-12'
								dangerouslySetInnerHTML={{
									__html:
										paginestaticheTranslation.testoprontaconsegna.itemName,
								}}
							/>
							<CustomButton
								buttonId={prontaConsegna.id}
								buttonLink={prontaConsegna.linkSeo}
								buttonRouteName={prontaConsegna.routeName}
								buttonName={paginestaticheTranslation.scopridipiu.itemName.toUpperCase()}
								buttonStyle='amberButtonBig text-base sm:text-xl py-3 px-5'
							/>
						</div>
						<div className='col-start-1 xl:col-start-31 col-end-61 order-first xl:order-last spanServerSide'>
							<LazyLoadPicture
								imgName='pronta_consegna_home.jpg'
								imgPath='statiche'
								imgWidth={imgHalfWidth}
								imgHeight={imgHalfHeight}
								imgStyle='flex-start max-w-none lg:max-w-full w-full lg:w-auto'
								imgAlt='Pronta consegna'
							/>
						</div>
					</div>
					<div className='grid grid-cols-60'>
						<div className='col-start-1 col-end-61 xl:col-end-31 spanServerSide'>
							<LazyLoadPicture
								imgName='chisiamo_home.jpg'
								imgPath='statiche'
								imgWidth={imgHalfWidth}
								imgHeight={imgHalfHeight}
								imgStyle='flex-start max-w-none lg:max-w-full w-full lg:w-auto'
								imgAlt='Chi siamo'
							/>
						</div>
						<div className='col-start-5 xl:col-start-36 col-end-56 flex flex-col justify-center pt-8 pb-12 sm:py-12 xl:py-0'>
							<TitoloBlocco
								titoloStyle='titoloBlocco text-3xl xs:text-4xl 4xl:text-5xl text-ribola-congress-blue mb-6'
								titolo={
									paginestaticheTranslation.titolochisiamohomepage.itemName
								}
							/>
							<span
								className='text-sm sm:text-base 4xl:text-lg text-ribola-heavy-metal flex mb-6 sm:mb-12'
								dangerouslySetInnerHTML={{
									__html:
										paginestaticheTranslation.testochisiamohomepage.itemName,
								}}
							/>
							<CustomButton
								buttonLink={mainNavigation.chisiamo.itemLink}
								buttonRouteName={mainNavigation.chisiamo.itemRouteName}
								buttonName={mainNavigation.chisiamo.itemName.toUpperCase()}
								buttonStyle='amberButtonBig text-base sm:text-xl py-3 px-5'
							/>
						</div>
					</div>
					<div className='grid grid-cols-60'>
						<div className='col-span-60 relative spanServerSide'>
							<LazyLoadPicture
								imgName='chisiamo2_home.jpg'
								imgPath='statiche'
								imgWidth={imgFullWidth}
								imgHeight={imgFullHeight}
								imgStyle='flex-start max-w-none lg:max-w-full w-auto sm:w-full lg:w-auto'
								imgAlt='Produzione di reti'
							/>
							<div className='grid grid-cols-60 absolute bottom-0 xl:top-0 xl:h-full'>
								<div className='col-span-60 xl:col-span-30 flex flex-col justify-center bg-ribola-astral bg-opacity-70'>
									<div className='grid grid-cols-60'>
										<div className='col-start-5 xl:col-start-11 col-end-56 xl:col-end-51 flex flex-col pt-8 pb-12 sm:py-12 xl:py-0'>
											<TitoloBlocco
												titoloStyle='titoloBlocco text-3xl xs:text-4xl 4xl:text-5xl text-white mb-6'
												titolo={
													paginestaticheTranslation.titoloretifibrasintetica
														.itemName
												}
											/>
											<span
												className='text-sm sm:text-base 4xl:text-lg text-white flex mb-6 sm:mb-12'
												dangerouslySetInnerHTML={{
													__html:
														paginestaticheTranslation.testoretifibrasintetica
															.itemName,
												}}
											/>
											<CustomButton
												buttonLink={
													paginestaticheTranslation.lenostrereti.itemLink
												}
												buttonName={paginestaticheTranslation.scopridipiu.itemName.toUpperCase()}
												buttonStyle='whiteButtonBordered text-base sm:text-xl py-3 px-5'
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

/*
imgRoute='immagini-responsive-type'
imgType='fitnoresizeToWebp'
*/

export default BloccoChisiamoHome;
