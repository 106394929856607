import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import MenuLeft from '../../components/shared/menuLeft/MenuLeft';
import Error404Detail from '../../components/error404Detail/Error404Detail';

const Error404 = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const staticPages = useSelector((state) => state.staticPages);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// controllo nello store se ho già il dettaglio di error404, altrimenti eseguo action creator
		const error404Page = staticPages.error404;

		if (error404Page) {
			// setto i metatags
			document.title = error404Page.metaTitle;
			document.getElementsByTagName('META')[2].content = error404Page.metaDesc;
		}

		if (error404Page === undefined || error404Page.detail === undefined) {
			// non ho i dati del prodotto nello store, chiamo action creator fetchError404Detail
			setTimeout(
				() =>
					dispatch(
						allActions.staticDetailActions.fetchError404Detail(
							currentPage.currentLang,
							user?.userData?.id
						)
					),
				50
			);
		} else {
			// loading...
			dispatch(allActions.isFetchingAction.loadStoredData());

			// recupero dati da store
			dispatch(allActions.staticDetailActions.loadError404Detail(error404Page));
		}

		//console.log(error404Page);
	}, [location, staticPages]);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<MenuLeft />
					<Error404Detail />
				</div>
			</div>
		</Layout>
	);
};

export default Error404;
