import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import Briciole from '../shared/briciole/Briciole';
import CategoryBoxItem from './categoryBoxItem/CategoryBoxItem';
import CustomButton from '../shared/customButton/CustomButton';
import { BASESTORAGE } from '../../utils/basestorage';

const NetsDetail = () => {
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const catalogue = useSelector((state) => state.catalogue);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	useEffect(() => {
		/* console.log(catalogue);
		console.log(pageContent); */
	}, []);

	// controllo che esista la proprietà detail (altrimenti vuol dire che sto ancora recuperando i dati)
	if (!pageContent.detail) {
		return <div></div>;
	}

	// prontaConsegna name === 'Pronta consegna'
	const prontaConsegnaId = Object.keys(catalogue).filter(
		(key) => catalogue[key].name === 'Pronta consegna'
	)[0];
	const prontaConsegna = catalogue[prontaConsegnaId];

	// controllo che esista la proprietà prontaConsegna
	if (prontaConsegna === undefined) {
		return <div></div>;
	}

	const elencoCategories = Object.keys(catalogue).map((key, index) => {
		// escludo prontaConsegna
		if (key === prontaConsegnaId) return false;
		const {
			id: categoryId,
			image,
			icona,
			detail,
			hoverColor,
			routeName,
			linkSeo,
		} = catalogue[key];

		const titolo = detail.titolo;
		const sottotitolo = detail.sottotitolo;
		const prezzoapartireda = detail.prezzoapartireda;

		return (
			<CategoryBoxItem
				key={_uniqueId('category_')}
				categoryId={categoryId}
				titolo={titolo}
				sottotitolo={sottotitolo}
				prezzoapartireda={prezzoapartireda}
				immagine={image}
				icona={icona}
				colore={hoverColor}
				routeName={routeName}
				linkSeo={linkSeo}
			/>
		);
	});

	return (
		<div className='col-start-1 lg:col-start-15 4xl:col-start-16 col-end-61 3xl:col-end-59 4xl:col-end-57 pb-12'>
			<Briciole />
			<div className='grid grid-cols-60 mt-0 sm:mt-3 xl:mt-6'>
				<div className='col-span-60 flex justify-between items-center px-8 xl:px-12 3xl:px-20 xl:pt-8 pb-6 sm:pb-8 xl:pb-12 3xl:py-14'>
					<h1 className='text-ribola-congress-blue text-3xl md:text-4xl xl:text-5xl font-opensansbold'>
						<small className='leading-tight flex'>
							{pageContent.detail.titolo.toUpperCase()}
						</small>
					</h1>
				</div>
				<div className='col-span-60 md:col-span-40 flex flex-col bg-ribola-gallery-gray md:border-r-8 border-white px-8 xl:px-12 3xl:px-20 py-8 sm:py-12'>
					<p
						className='text-sm'
						dangerouslySetInnerHTML={{
							__html: pageContent.detail.descrizione,
						}}></p>
				</div>
				<div className='col-span-60 md:col-span-20 flex md:flex-col xl:flex-row sm:justify-center md:justify-start items-center bg-ribola-quicksand text-white px-4 3xl:px-8 py-6 md:py-12'>
					<div className='pr-3 3xl:pr-12'>
						<img
							src={
								BASESTORAGE +
								'/' +
								prontaConsegna.icona.folder +
								'/' +
								prontaConsegna.icona.file_name
							}
							width='90'
							height='90'
							className='flex-start filter brightness-0 invert'
							alt='Pronta consegna'
						/>
					</div>
					<div className='flex flex-col sm:flex-row md:flex-col justify-center sm:items-center md:items-start'>
						<div className='pr-6 md:pr-0'>
							<span className='font-opensansbold text-lg 3xl:text-xl block mb-2'>
								{prontaConsegna.detail.titolo}
							</span>
							<span className='text-lg 3xl:text-xl block mb-4'>
								{prontaConsegna.detail.sottotitolo}
							</span>
						</div>
						<CustomButton
							buttonId={prontaConsegna.id}
							buttonLink={prontaConsegna.linkSeo}
							buttonRouteName={prontaConsegna.routeName}
							buttonName={genericheTranslation.continua.itemName}
							buttonStyle='bg-ribola-gallery-gray text-ribola-quicksand font-opensansbold rounded-md text-center text-sm px-10 py-2 3xl:py-3 mt-2'
						/>
					</div>
				</div>
			</div>
			<div className='grid grid-cols-60 mt-12'>{elencoCategories}</div>
		</div>
	);
};

export default NetsDetail;
