const STRIPE_PUBLISHABLE_KEY = process.env.MIX_STRIPE_PUBLISHABLE_KEY;
const STRIPE_SECRET_KEY = process.env.MIX_STRIPE_SECRET_KEY;
const STRIPE_ENDPOINT_WEBHOOK_SECRET =
	process.env.MIX_STRIPE_ENDPOINT_WEBHOOK_SECRET;

export {
	STRIPE_PUBLISHABLE_KEY,
	STRIPE_SECRET_KEY,
	STRIPE_ENDPOINT_WEBHOOK_SECRET,
};
