import axios from '../../utils/axiosBase';
import { FETCH_HOMEPAGE, LOAD_HOMEPAGE } from './types';

const fetchHomepage =
	(currentLang, userId, history) => async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
		};

		// chiamo web service
		const response = await axios.post(`api/homepage/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_HOMEPAGE,
			payload: response.data,
		});
	};

const loadHomepage =
	(homepageHeader, metaTitle, metaDesc, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_HOMEPAGE,
			payload: {
				pageHeader: homepageHeader,
        metaTitle: metaTitle,
        metaDesc: metaDesc,
			},
		});
	};

export default {
	fetchHomepage,
	loadHomepage,
};
