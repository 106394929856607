import { useEffect, useRef, useState, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { createPopper } from '@popperjs/core';
import _uniqueId from 'lodash/uniqueId';
import DropdownKitItem from './dropdownKitItem/DropdownKitItem';
import Slideshow from '../../shared/slideshow/Slideshow';
import AccessorioBoxItem from './accessorioBoxItem/AccessorioBoxItem';
import PulsanteAggiungiKit from '../pulsanteAggiungiKit/PulsanteAggiungiKit';

const ProductKit = ({
	idKit,
	nameKit,
	onDropdownKitChange,
	onKitHandler,
	onShowKitHandler,
	totaleKit = 0,
	isDisabled = true,
	isKitAdded = false,
}) => {
	const deviceType = useSelector((state) => state.device);
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	const [dropdownPopoverShow, setDropdownPopoverShow] = useState(false);
	const btnDropdownRef = useRef();
	const popoverDropdownRef = useRef();
	const openDropdownPopover = () => {
		console.log('openDropdownPopover');
		createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
			placement: 'bottom-start',
		});
		setDropdownPopoverShow(true);
	};
	const closeDropdownPopover = () => {
		setDropdownPopoverShow(false);
	};

	const onKitClick = (idClicked, nameClicked) => {
		closeDropdownPopover();
		onDropdownKitChange(idClicked, nameClicked);
		console.log('onKitClick');
	};

	const elencoKit = Object.keys(pageContent.detail.elencoKitsAssociati).map(
		(key) => {
			const { id, name } = pageContent.detail.elencoKitsAssociati[key];
			return (
				<DropdownKitItem
					key={_uniqueId('dropdownKitItem_')}
					idKit={id}
					labelKit={name}
					onItemClickHandler={onKitClick}
				/>
			);
		}
	);

	const elencoAccessoriKit = pageContent.detail.elencoKitsAssociati[
		idKit
	].elencoAccessori.map(({ id, name, codice, immagine, qta }) => {
		return (
			<div key={_uniqueId('accessorioBoxItem_')}>
				<AccessorioBoxItem
					id={id}
					name={name}
					codice={codice}
					immagine={immagine}
					qta={qta}
				/>
			</div>
		);
	});

	useEffect(() => {
		console.log('productKit');
	}, []);

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 1,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1536 },
			items: 1,
		},
		bigtablet: {
			breakpoint: { max: 1536, min: 1024 },
			items: 1,
		},
		tablet: {
			breakpoint: { max: 1024, min: 640 },
			items: 1,
		},
		mobile: {
			breakpoint: { max: 640, min: 0 },
			items: 1,
		},
	};

	const removeArrowsOnDeviceType = [];

	const showMessaggioScorri = elencoAccessoriKit.length > 1 && (
		<span className='block text-sm xl:text-xs 4xl:text-sm mt-3'>
			{genericheTranslation.scorrikit.itemName}
		</span>
	);

	const isEditKit = isKitAdded && (
		<Fragment>
			<span
				className='bg-ribola-astral text-white text-sm rounded-full flex justify-center items-center cursor-pointer w-7 h-7 mr-4'
				onClick={() => onShowKitHandler(idKit)}>
				<i className='far fa-search'></i>
			</span>
			<span className='text-ribola-astral text-sm font-opensansbold'>
				{parseFloat(totaleKit).toFixed(2).toString().replace('.', ',')}€
			</span>
		</Fragment>
	);

	return (
		<div className='flex flex-col mt-6 sm:mt-12 xl:mt-6 2xl:mt-12 mb-8 relative'>
			<span className='text-xl text-ribola-quicksand font-opensanssemibold flex items-center justify-between'>
				{genericheTranslation.nostrikit.itemName.toUpperCase()}
			</span>
			<div
				className='text-ribola-astral cursor-pointer mt-3 2xl:mt-6'
				onClick={() => {
					dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
				}}>
				<button
					className='border-2 border-ribola-astral outline-none focus:outline-none text-ribola-dove-gray text-left text-xs sm:text-sm xl:text-xs 3xl:text-sm w-full h-100'
					type='button'
					ref={btnDropdownRef}>
					<span className='flex justify-between items-center px-3 py-2 leading-snug'>
						{nameKit}
						<i className='far fa-chevron-down text-xs mx-2'></i>
					</span>
				</button>
			</div>
			<div
				ref={popoverDropdownRef}
				className={
					(dropdownPopoverShow ? 'block ' : 'hidden ') +
					'w-full border-l-2 border-r-2 border-b-2 border-ribola-astral overflow-hidden z-50 text-xs cursor-pointer'
				}>
				{elencoKit}
			</div>
			<div className='grid grid-cols-60 mt-6'>
				<div className='col-start-1 col-end-61 sm:col-end-30 xl:col-end-61 2xl:col-end-36 2xl:h-16'>
					<Slideshow
						slideList={elencoAccessoriKit}
						swipeableProp={true}
						draggableProp={false}
						showDotsProp={false}
						infiniteProp={false}
						autoPlayProp={false}
						autoPlaySpeedProp={3000}
						arrowsProp={true}
						partialVisible={false}
						responsiveProp={responsive}
						deviceTypeProp={deviceType}
						removeArrowOnDeviceTypeProp={removeArrowsOnDeviceType}
						containerClass='slideshowAccessoriKit'
						itemClass='transition-all itemAccessorioKit flex justify-center h-full'
					/>
					{showMessaggioScorri}
				</div>
				<div className='col-start-1 sm:col-start-32 xl:col-start-1 2xl:col-start-36 col-end-61 sm:col-end-56 xl:col-end-61 flex justify-between items-center pl-3 mt-3 sm:mt-0'>
					{isEditKit}
					<PulsanteAggiungiKit
						idKit={idKit}
						onKitHandler={onKitHandler}
						isDisabled={isDisabled}
						isKitAdded={isKitAdded}
					/>
				</div>
			</div>
		</div>
	);
};

export default ProductKit;
