import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../state/actions';
import Briciole from '../../shared/briciole/Briciole';
import BarraCheckout from '../barraCheckout/BarraCheckout';
import RiepilogoCarrello from '../riepilogoCarrello/RiepilogoCarrello';
import CheckboxField from '../../shared/checkboxField/CheckboxField';
import ModalConfirm from '../../shared/modalConfirm/ModalConfirm';
import GestioneIndirizzi from '../gestioneIndirizzi/GestioneIndirizzi';
import { BASENAME } from '../../../utils/basename';
import { gtmCheckoutSpedizionePagamento } from '../../../gtm/gtm';

const PaymentDetail = () => {
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const siteParams = useSelector((state) => state.siteParams);
	const user = useSelector((state) => state.user);
	const cart = useSelector((state) => state.cart);
	const order = useSelector((state) => state.order);
	const checkout = useSelector((state) => state.checkout);
	const spedizionePagamentoTranslation = useSelector(
		(state) => state.translations.spedizionePagamento
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	// local state
	const [isSelectingPaymentMethod, setIsSelectingPaymentMethod] =
		useState(false);
	const [isHideElencoIndirizzo, setIsHideElencoIndirizzo] = useState(null);
	const [isHideElencoPagamenti, setIsHideElencoPagamenti] = useState(false);
	const [currentPaymentMethodId, setCurrentPaymentMethodId] = useState(null);
	const [currentPaymentMethod, setCurrentPaymentMethod] = useState(null);
	const [currentAddressId, setCurrentAddressId] = useState(null);
	const [currentAddress, setCurrentAddress] = useState(null);

	useEffect(() => {
		//console.log(user.indirizzi);
		// selezionando indirizzo nella pagina pagamento
		if (isSelectingPaymentMethod && order.message) {
			const messageToShow =
				order.status === 'OK'
					? genericheTranslation.modalmessaggioselezionepagamentook.itemName
					: genericheTranslation.modalmessaggioselezionepagamentoko.itemName;

			ModalConfirm({
				titleToShow:
					spedizionePagamentoTranslation.modalTitoloSelezionaPagamento.itemName,
				messageToShow: messageToShow,
				isShowConfirmButton: false,
				isShowCanceButton: false,
				isTimerModal: true,
				onClickHandler: () => {
					setCurrentPaymentMethodId(currentPaymentMethodId);
					setCurrentPaymentMethod(
						checkout.paymentMethods[currentPaymentMethodId]
					); // setto il metodo di pagamento corrente
					setIsHideElencoPagamenti(true);
				},
			});
			setIsSelectingPaymentMethod(false);
		}

		// preseleziono l'indirizzo di consegna
		if (
			currentAddressId === null &&
			user.indirizzi !== undefined &&
			order.addressId !== null &&
			order.addressId !== 0
		) {
			setCurrentAddressId(order.addressId);
			const addressIndex = Object.keys(user.indirizzi)
				.filter(
					(key) =>
						parseInt(user.indirizzi[key].id) === parseInt(order.addressId)
				)
				.toString();
			setCurrentAddress(user.indirizzi[addressIndex]);
			if (isHideElencoIndirizzo !== null) {
				setIsHideElencoIndirizzo(true);
			}
		}

		// preseleziono il metodo di pagamento
		if (
			currentPaymentMethodId === null &&
			checkout.paymentMethods !== undefined &&
			order.paymentId !== null &&
			order.paymentId !== 0
		) {
			setCurrentPaymentMethodId(order.paymentId);
			const paymentIndex = Object.keys(checkout.paymentMethods)
				.filter((key) => checkout.paymentMethods[key].id === order.paymentId)
				.toString();
			setCurrentPaymentMethod(checkout.paymentMethods[paymentIndex]);
			setIsHideElencoPagamenti(true);
		}
	}, [
		currentPage.pageName,
		user,
		order,
		checkout,
		currentPaymentMethod,
		currentPaymentMethodId,
		currentAddress,
		isHideElencoIndirizzo,
		isHideElencoPagamenti,
	]);

	// controllo di avere nello store i dati che mi servono (user.indirizzi, checkout.provinces, checkout.paymentMethods)
	if (!user.indirizzi || !checkout.provinces || !checkout.paymentMethods) {
		return <div></div>;
	}

	const onChangeMetodoPagamento = (
		paymentMethodId,
		paymentMethodName,
		event
	) => {
		// chiedo conferma
		ModalConfirm({
			titleToShow:
				spedizionePagamentoTranslation.modalTitoloSelezionaPagamento.itemName,
			messageToShow: `<b>${paymentMethodName}</b>`,
			isShowConfirmButton: true,
			isShowCanceButton: true,
			isTimerModal: false,
			textConfirmButton: genericheTranslation.conferma.itemName,
			textCancelButton: genericheTranslation.annulla.itemName,
			onClickHandler: () => {
				event.preventDefault();

				setIsSelectingPaymentMethod(true);

				// imposto metodo di pagamento corrente selezionato
				const paymentIndex = Object.keys(checkout.paymentMethods)
					.filter((key) => checkout.paymentMethods[key].id === paymentMethodId)
					.toString();
				setCurrentPaymentMethodId(paymentIndex);
				setCurrentPaymentMethod(checkout.paymentMethods[paymentIndex]);

				dispatch(
					allActions.paymentDetailActions.selectPaymentMethod(
						currentPage.currentLang,
						user.token_id,
						user.userData.id,
						paymentMethodId
					)
				);

				/***
				 * GTM CHECKOUT SPEDIZIONE / PAGAMENTO - STEP 2
				 */
				const gtmSuffix = `gtm_code${currentPage.currentLang}`;
				const currentGTMTAG = siteParams[gtmSuffix];
				gtmCheckoutSpedizionePagamento({
					cartData: cart,
					paymentMethod: checkout.paymentMethods[paymentIndex].name,
					isGtmEnabled: currentGTMTAG !== null ? true : false,
				});
			},
			onCancelHandler: () => {},
		});
	};

	// funzione chiamata da GestioneIndirizzi
	const onChangeAddress = (currentAddressId) => {
		setCurrentAddressId(currentAddressId);
		const addressIndex = Object.keys(user.indirizzi)
			.filter(
				(key) => parseInt(user.indirizzi[key].id) === parseInt(currentAddressId)
			)
			.toString();
		setCurrentAddress(user.indirizzi[addressIndex]);
		setIsHideElencoIndirizzo(true);
	};

	const onClickAddressShow = () => {
		setIsHideElencoIndirizzo(!isHideElencoIndirizzo);
	};

	const onClickPaymentShow = () => {
		setIsHideElencoPagamenti(!isHideElencoPagamenti);
	};

	const elencoMetodiPagamento = checkout.paymentMethods.map((item) => {
		// salto pagamento con RiBa se non abilitato lato utente
		if (item.name === 'RiBa' && user.userData.riba_enabled !== 1) {
			return;
		}

		// salto contrassegno se la nazione di spedizione è estera
		if (item.name === 'Contrassegno' && order.selectedCountryRealId !== 36) {
			return;
		}

		const isSelectedPayment =
			parseInt(item.id) === currentPaymentMethodId ||
			parseInt(item.id) === order.paymentId
				? true
				: false;

		const elencoImmagini = item.immagini.map((immagineMetodo) => {
			return [BASENAME + '/images/' + immagineMetodo];
		});

		return (
			<div
				key={item.id}
				className='col-span-60 md:col-span-30 flex items-center mb-6'>
				<CheckboxField
					checkboxType='radio'
					checkboxName='cambiaMetodoPagamento'
					checkboxValue={item.name}
					checkboxHandlerFunction={(event) => {
						if (!isSelectingPaymentMethod) {
							// chiamo action creator
							onChangeMetodoPagamento(item.id, item.name, event);

							/* event.stopPropagation();
							event.preventDefault(); */
						}
					}}
					checkboxLabelStyle={`flex items-center ${
						isSelectedPayment ? 'pointer-events-none' : 'cursor-pointer'
					}`}
					isChecked={isSelectedPayment}
					checkboxStyle='border-2 text-ribola-congress-blue border-ribola-nobel mr-6 w-6 h-6'
					direction='right'
					checkboxImg={elencoImmagini}
					checkboxImgWidth='112'
					checkboxImgHeight='79'
					checkboxImgAlt={[item.name]}
					checkboxImgStyle='flex-start mr-6 w-20 md:w-auto'
				/>
			</div>
		);
	});

	// indirizzo di consegna selezionato
	const indirizzoConsegnaSelezionatoDiv =
		order.addressId !== null &&
		order.addressId !== 0 &&
		currentAddress !== null &&
		currentAddress !== undefined ? (
			<div
				className='col-span-60 flex justify-between items-center bg-ribola-gallery-gray text-ribola-congress-blue cursor-pointer px-6 py-3 mb-6'
				onClick={onClickAddressShow}>
				<span>
					<span className='text-lg font-opensansbold mr-3'>
						{spedizionePagamentoTranslation.indirizzodiconsegnaselezionato.itemName.toUpperCase()}
						:
					</span>
					<span className='text-lg text-black font-opensansbold'>
						{currentAddress.meta_data.nomes} {currentAddress.meta_data.cognomes}
						&nbsp;
					</span>
					<span className='text-black'>
						{currentAddress.meta_data.indirizzos} -&nbsp;
						{currentAddress.meta_data.cittas}&nbsp; (
						{currentAddress.meta_data.provinciaText ||
							currentAddress.meta_data.provinciaesteras}
						) &nbsp;
						{currentAddress.meta_data.caps}&nbsp;
						{currentAddress.meta_data.nazioneText}&nbsp;-&nbsp;
						{genericheTranslation.telefono.itemName}:&nbsp;
						{currentAddress.meta_data.telefonos}
					</span>
				</span>
				<i className='fas fa-chevron-down text-2xl'></i>
			</div>
		) : (
			<div className='col-span-60 hidden'></div>
		);

	const hideElencoIndirizziStyles =
		isHideElencoIndirizzo === true
			? 'max-h-36 sm:max-h-16 overflow-hidden'
			: 'max-h-full';

	const hideElencoHr = isHideElencoIndirizzo && 'hidden';

	// pagamento selezionato
	const pagamentoSelezionatoDiv =
		order.paymentId !== null &&
		order.paymentId !== 0 &&
		currentPaymentMethod !== null ? (
			<div
				className='col-span-60 flex justify-between items-center bg-ribola-gallery-gray text-ribola-congress-blue px-6 py-3 mb-6'
				onClick={onClickPaymentShow}>
				<span>
					<span className='text-lg font-opensansbold mr-3'>
						{spedizionePagamentoTranslation.metododipagamentoselezionato.itemName.toUpperCase()}
						:
					</span>
					<span className='text-lg text-black font-opensansbold'>
						{currentPaymentMethod.name}
						&nbsp;
					</span>
				</span>
			</div>
		) : (
			<div className='col-span-60 hidden'></div>
		);

	const hideElencoPagamentiStyles = isHideElencoPagamenti
		? 'max-h-24 sm:max-h-16 overflow-hidden'
		: 'max-h-full';

	// mostro o nascondo gli indirizzi di consegna (se ho selezionato ritiro in sede li nascondo)
	const showOrHideIndirizziDiConsegna = order.isNoShipping === false && (
		<Fragment>
			<div className='col-span-60 flex flex-col justify-center items-center mb-4 sm:mb-7'>
				<span className='text-3xl md:text-4xl text-ribola-congress-blue text-center font-opensansbold'>
					{spedizionePagamentoTranslation.titoloindirizzodiconsegna.itemName.toUpperCase()}
				</span>
				<span className='flex text-lg md:text-xl italic text-ribola-dove-gray mt-4'>
					{
						spedizionePagamentoTranslation.sottotitoloindirizzodiconsegna
							.itemName
					}
				</span>
			</div>
			<div className='col-span-60 relative'>
				<div
					className={`grid grid-cols-60 sm:gap-2 2xl:gap-4 ${hideElencoIndirizziStyles}`}>
					{indirizzoConsegnaSelezionatoDiv}
					<GestioneIndirizzi onChangeAddressFunction={onChangeAddress} />
				</div>
			</div>
			<div className={`col-span-60 mt-16 ${hideElencoHr}`}>
				<hr className='w-full h-1 border-gray-400' />
			</div>
		</Fragment>
	);

	return (
		<div className='col-start-3 4xl:col-start-5 col-end-59 4xl:col-end-57 pb-6 sm:pb-12'>
			<Briciole />
			<BarraCheckout currentCheckoutPage={currentPage.routeName} />
			<div className='grid grid-cols-60 mt-12 sm:mt-20'>
				<div className='col-start-1 col-end-61 xl:col-end-45'>
					<div className='grid grid-cols-60'>
						{showOrHideIndirizziDiConsegna}
						<div className='col-span-60 flex flex-col justify-center items-center mt-6 mb-7'>
							<span className='text-3xl md:text-4xl text-ribola-congress-blue font-opensansbold'>
								{spedizionePagamentoTranslation.titolometododipagamento.itemName.toUpperCase()}
							</span>
							<span className='sm:text-lg md:text-xl italic text-ribola-dove-gray mt-4'>
								{
									spedizionePagamentoTranslation.sottotitolometododipagamento
										.itemName
								}
							</span>
							<div
								className={`grid grid-cols-60 sm:gap-2 2xl:gap-4 w-full mt-6 sm:mt-12 relative`}>
								{pagamentoSelezionatoDiv}
								{elencoMetodiPagamento}
							</div>
						</div>
					</div>
				</div>
				<div className='col-start-1 col-end-61 xl:col-start-46 xl:col-end-61 bg-ribola-gallery-gray py-8 3xl:py-12 px-6 3xl:px-8'>
					<RiepilogoCarrello />
				</div>
			</div>
		</div>
	);
};

export default PaymentDetail;
