import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import allActions from '../../../state/actions';
import TabellaDatiOutlet from './tabellaDatiOutlet/TabellaDatiOutlet';
import QuantitaInput from '../quantitaInput/QuantitaInput';
import TotaleCostoProdotto from '../totaleCostoProdotto/TotaleCostoProdotto';
import PulsanteAggiungiCarrello from '../pulsanteAggiungiCarrello/PulsanteAggiungiCarrello';
import ModalAddedToCart from '../modalAddedToCart/ModalAddedToCart';
import { BASESTORAGE } from '../../../utils/basestorage';
import { gtmAddToCart } from '../../../gtm/gtm';

const ProductConfigurationOutlet = () => {
	// store data
	const dispatch = useDispatch();
	const history = useHistory();
	const token = useSelector((state) => state.user.token_id);
	const cartLastItem = useSelector((state) => state.cartLastItem);
	const order = useSelector((state) => state.order);
	const user = useSelector((state) => state.user);
	const currentLang = useSelector((state) => state.currentPage.currentLang);
	const siteParams = useSelector((state) => state.siteParams);
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const fasiCarrelloTranslation = useSelector(
		(state) => state.translations.fasiCarrello
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);
	const prezzoProdotto =
		pageContent.detail.prezzoScontato > 0
			? pageContent.detail.prezzoScontato
			: pageContent.detail.imponibile;
	const prezzoProdottoOriginale =
		pageContent.detail.prezzoScontato > 0
			? pageContent.detail.imponibile
			: null;

	// local state
	const [quantita, setQuantita] = useState(1);
	const [totaleImponibile, setTotaleImponibile] = useState(0);
	const [totaleImponibileOriginale, setTotaleImponibileOriginale] = useState(0);
	const [totalePrezzo, setTotalePrezzo] = useState(0);
	const [totalePrezzoOriginale, setTotalePrezzoOriginale] = useState(0);
	const [isAddToCart, setIsAddToCart] = useState(false);

	const onClickGoToCart = () => {
		//console.log('aaaa');
		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		// vado al carrello
		dispatch(
			allActions.changePageActions.changePage(
				'',
				fasiCarrelloTranslation.carrello.routeName,
				fasiCarrelloTranslation.carrello.linkName,
				history
			)
		);
	};

	useEffect(() => {
		console.log(quantita);
		changeCostoOutlet();

		// mostro modal per item aggiunto a carrello e reimposto isAddToCart a false
		if (isAddToCart === true && cartLastItem?.id !== undefined) {
			setIsAddToCart(false);

			let lunghezza = pageContent.detail.lunghezza;
			let larghezza = pageContent.detail.larghezza;
			let profondita = pageContent.detail.profondita;
			let metrilineari = pageContent.detail.metrilineari;
			let diametro = pageContent.detail.diametro;
			let dimensione = 0;

			if (profondita) {
				dimensione = parseFloat(lunghezza * lunghezza * profondita).toFixed(2);
			} else if (lunghezza && larghezza) {
				dimensione = parseFloat(lunghezza * lunghezza).toFixed(2);
			}

			let dimensioneCartItem = `${parseFloat(larghezza)
				.toFixed(2)
				.toString()
				.replace('.', ',')}x${parseFloat(lunghezza)
				.toFixed(2)
				.toString()
				.replace('.', ',')} (${parseFloat(cartLastItem.attributes.dimensione)
				.toFixed(2)
				.toString()
				.replace('.', ',')} ${cartLastItem.attributes.unitaMisura})`;

			ModalAddedToCart({
				cartLastItem: cartLastItem,
				dimensione: dimensioneCartItem,
				subtotalCart: order.subtotalCart,
				onClickHandler: onClickGoToCart,
				genericheTranslation: genericheTranslation,
			});

			// gtm add to cart
			const productData = {
				titoloBreve: pageContent.name,
				codice: pageContent.detail.codice,
			};
			const categoryName = pageContent.detail.titoloCategoria;
			const prezzoProdotto = parseFloat(totalePrezzo / quantita).toFixed(2);

			const gtmSuffix = `gtm_code${currentLang}`;
			const currentGTMTAG = siteParams[gtmSuffix];
			gtmAddToCart({
				productData,
				categoryName,
				prezzoCarrello: prezzoProdotto,
				quantitaCarrello: quantita,
				isGtmEnabled: currentGTMTAG !== null ? true : false,
			});
		}
	}, [quantita, totalePrezzo, totalePrezzoOriginale, cartLastItem, order]);

	const pezziRimanenti =
		pageContent.detail.giacenza - pageContent.detail.venduti;

	const onQuantitaChange = (event) => {
		//console.log(event, quantita);
		if (event === 'sottrai' && quantita > 1) {
			setQuantita(quantita - 1);
		} else if (event === 'somma' && quantita < pezziRimanenti) {
			setQuantita(quantita * 1 + 1);
		} else {
			// mi arriva da digitazione diretta
			if (event?.target !== undefined && event?.target?.value !== '') {
				if (event?.target?.value === '0') {
					setQuantita(1);
				} else if (parseInt(event.target.value) > pezziRimanenti) {
					setQuantita(pezziRimanenti);
				} else {
					setQuantita(parseInt(event.target.value));
				}
			} else {
				if (!isNaN(event)) {
					setQuantita(event);
				} else {
					setQuantita('');
				}
			}
		}
	};

	const onAddToCartHandler = () => {
		// mostro preloader add to cart
		setIsAddToCart(true);

		let lunghezza = pageContent.detail.lunghezza;
		let larghezza = pageContent.detail.larghezza;
		let profondita = pageContent.detail.profondita;
		let metrilineari = pageContent.detail.metrilineari;
		let diametro = pageContent.detail.diametro;
		let dimensione = 0;

		if (profondita) {
			dimensione = parseFloat(lunghezza * lunghezza * profondita).toFixed(2);
		} else if (lunghezza && larghezza) {
			dimensione = parseFloat(lunghezza * lunghezza).toFixed(2);
		}

		// preparo oggetto prodotto per add to cart
		const productCart = {
			tokenCarrello: token,
			product_id: pageContent.id,
			name: pageContent.name,
			codice: pageContent.detail.codice,
			titoloCategoria: pageContent.detail.titoloCategoria,
			larghezza: larghezza,
			lunghezza: lunghezza,
			profondita: profondita,
			metrilineari: metrilineari,
			diametro: diametro,
			dimensione: dimensione,
			quantita: quantita.toString(),
			totalePrezzo: totaleImponibile,
			totalePrezzoOriginale: totaleImponibileOriginale,
			sconto: pageContent.detail.sconto,
			tipologia_sconto: pageContent.detail.tipologia_sconto,
			tipologia_sconto_msg: 'Sconto prontaconsegna',
			pesoCollo: pageContent.detail.peso.toString(),
			prontaConsegna: true,
			disponibilita: pageContent.detail.giacenza,
		};

		//console.log(productCart);

		// chiamo action creator postCartAdd
		dispatch(
			allActions.cartDetailActions.postCartAdd(
				currentLang,
				user?.userData?.id,
				order.selectedCountryRealId,
				productCart
			)
		);
	};

	const changeCostoOutlet = () => {
		const totaleImponibile = prezzoProdotto;
		const totaleImponibileOriginale = prezzoProdottoOriginale;
		const totalePrezzo = totaleImponibile * quantita;
		const totalePrezzoOriginale = totaleImponibileOriginale * quantita;

		setTotaleImponibile(parseFloat(totaleImponibile).toFixed(4));
		setTotaleImponibileOriginale(
			parseFloat(totaleImponibileOriginale).toFixed(4)
		);
		setTotalePrezzo(parseFloat(totalePrezzo).toFixed(4));
		setTotalePrezzoOriginale(parseFloat(totalePrezzoOriginale).toFixed(4));
	};

	const boxTotale =
		prezzoProdottoOriginale !== null ? (
			<TotaleCostoProdotto
				prezzoProdotto={parseFloat(totalePrezzo).toFixed(2)}
				prezzoOriginale={parseFloat(totalePrezzoOriginale).toFixed(2)}
			/>
		) : (
			<TotaleCostoProdotto
				prezzoProdotto={parseFloat(totalePrezzo).toFixed(2)}
			/>
		);

	return (
		<Fragment>
			<div
				className={`flex justify-between items-center w-full bg-${pageContent.hoverColor} text-white font-opensanssemibold text-2xl px-6 py-3`}>
				<img
					src={
						BASESTORAGE +
						'/' +
						pageContent.icona.folder +
						'/' +
						pageContent.icona.file_name
					}
					width='60'
					height='60'
					className='flex-start w-12 sm:w-16 filter brightness-0 invert'
					alt={pageContent.icona.file_name}
				/>
				<span>
					{genericheTranslation.prodottoprontaconsegna.itemName.toUpperCase()}
				</span>
			</div>
			<TabellaDatiOutlet
				giacenza={pageContent.detail.giacenza}
				venduti={pageContent.detail.venduti}
				codice={pageContent.detail.codice}
				pesocollo={pageContent.detail.pesoCollo}
				larghezza={pageContent.detail.larghezza}
				lunghezza={pageContent.detail.lunghezza}
				profondita={pageContent.detail.profondita}
				metrilineari={pageContent.detail.metrilineari}
				colore={pageContent.hoverColor}
				unitamisura={pageContent.measure_unit_id}
			/>
			<div className='grid grid-cols-60'>
				<div className='col-span-60 sm:col-span-30 xl:col-span-60 3xl:col-span-30'>
					<QuantitaInput
						quantitaValue={quantita}
						onQuantitaChange={onQuantitaChange}
						customStyle='px-8'
					/>
				</div>
			</div>
			{boxTotale}
			<div className='w-full flex justify-center'>
				<PulsanteAggiungiCarrello
					onAddToCartHandler={onAddToCartHandler}
					isDisabled={pezziRimanenti > 0 && totalePrezzo > 0 ? false : true}
					isSending={isAddToCart}
					isNotAvailable={pezziRimanenti > 0 ? false : true}
				/>
			</div>
		</Fragment>
	);
};

export default ProductConfigurationOutlet;
