import { BASENAME } from '../../../utils/basename';

const PreloaderBg = ({ showPreloader }) => {
	let showPr = showPreloader ? 'opacity-100' : 'pointer-events-none opacity-0';

	return (
		<div
			id='preloadRibola'
			className={`fixed top-0 left-0 z-50 w-full h-full flex flex-col justify-center items-center text-ribola-congress-blue bg-ribola-alto transition ${showPr}`}>
			<img
				src={BASENAME + '/images/logo.svg'}
				width='275'
				height='188'
				className='flex-start'
				alt='Ribola Retificio Srl'
			/>
			<i className='fas fa-spinner fa-spin text-6xl mt-6 mr-6'></i>
		</div>
	);
};

export default PreloaderBg;
