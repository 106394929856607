import { Route, Redirect } from 'react-router-dom';
import Checkout from '../pages/checkout/Checkout';

const CheckoutRoute = ({
	isLogged,
	addressId,
	paymentId,
	userId,
	orderItems,
	isNoShipping,
	...rest
}) => (
	<Route
		{...rest}
		render={(props) =>
			isLogged &&
			(addressId > 0 || isNoShipping === true) &&
			paymentId > 0 &&
			userId > 0 &&
			orderItems > 0 ? (
				<Checkout />
			) : (
				// se non sono loggato e l'ordine non ha associato un addressId, un paymentId, un userId e più di un prodotto a carrello, lo mando in homepage
				<Redirect
					exact
					to={{
						pathname: '/',
						state: {
							from: props.location,
						},
					}}
				/>
			)
		}
	/>
);

export default CheckoutRoute;
