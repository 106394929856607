import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import _uniqueId from 'lodash/uniqueId';
import allActions from '../../state/actions';
import Briciole from '../shared/briciole/Briciole';
import CampiContatti from './campiContatti/CampiContatti';

const ContattiDetail = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm({
		mode: 'onChange',
	});
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const currentPage = useSelector((state) => state.currentPage);
	const siteParams = useSelector((state) => state.siteParams);
	const paginestaticheTranslation = useSelector(
		(state) => state.translations.paginestatiche
	);

	// local state
	const [isFormSending, setIsFormSending] = useState(false);

	useEffect(() => {
		/* console.log(isFormSending);
		console.log(isFormSending === true); */

		// mando pagina contatti-ok solo dopo la risposta di sendContatti()
		if (
			isFormSending === true &&
			currentPage?.status === 'OK' &&
			currentPage?.message === 'Messaggio inviato'
		) {
			setIsFormSending(false);

			// mando alla pagina contatti-ok che ho già recuperato da sendContatti()
			// mostro spinner
			dispatch(allActions.isFetchingAction.loadSpinner());

			// vado alla pagina contatti-ok
			dispatch(
				allActions.changePageActions.changePage(
					'',
					paginestaticheTranslation.contattiok.itemName,
					paginestaticheTranslation.contattiok.itemLink,
					history
				)
			);
		}
	}, [isFormSending, currentPage]);

	// controllo che esista la proprietà detail (altrimenti vuol dire che sto ancora recuperando i dati)
	if (!pageContent.detail) {
		return <div></div>;
	}

	const onSubmitFormContatti = (data) => {
		console.log(data);

		// chiamo action creator
		if (
			data.nome !== '' &&
			data.cognome !== '' &&
			data.telefono !== '' &&
			data.email !== '' &&
			data.messaggio !== ''
		) {
			// mostro preloader
			setIsFormSending(true);

			const dataForm = {
				nome: data.nome,
				cognome: data.cognome,
				telefono: data.telefono,
				email: data.email,
				messaggio: data.messaggio,
			};

			dispatch(
				allActions.staticDetailActions.sendContatti(
					currentPage.currentLang,
					dataForm
				)
			);
		}
	};

	const isDisabledClick = isFormSending ? 'pointer-events-none' : '';
	const isButtonSending = isFormSending ? true : false;

	return (
		<div className='col-start-1 lg:col-start-15 4xl:col-start-16 col-end-61 3xl:col-end-59 4xl:col-end-57 sm:pb-12'>
			<Briciole />
			<div className='grid grid-cols-60 mt-0 sm:mt-3 xl:mt-6'>
				<div className='col-span-60 flex justify-between items-center px-8 xl:px-12 3xl:px-20 xl:pt-8 pb-6 sm:pb-8 xl:pb-12 3xl:py-14'>
					<h1 className='text-ribola-congress-blue text-3xl md:text-4xl xl:text-5xl font-opensansbold'>
						<small className='leading-tight flex'>
							{paginestaticheTranslation.titolocontatti.itemName.toUpperCase()}
						</small>
					</h1>
				</div>
				<div className='col-span-60 px-8 xl:px-12 3xl:px-20 pb-8 xl:pb-12'>
					<p
						className='text-lg text-ribola-congress-blue font-opensanssemibold'
						dangerouslySetInnerHTML={{
							__html:
								paginestaticheTranslation.testocontatti.itemName.toUpperCase(),
						}}></p>
				</div>
				<div className='col-span-60 px-8 xl:px-12 3xl:px-20'>
					<div className='grid grid-cols-60 w-full'>
						<div className='col-span-60 xl:col-span-40'>
							<form onSubmit={handleSubmit(onSubmitFormContatti)} noValidate>
								<CampiContatti
									key='campiContatti'
									errors={errors}
									register={register}
									isDisabledClick={isDisabledClick}
									isButtonSending={isButtonSending}
								/>
							</form>
						</div>
						<div className='col-span-60 xl:col-span-20 mt-8 xl:mt-0'>
							<div className='grid grid-cols-60 w-full xl:pl-12 3xl:pl-20'>
								<div className='col-span-10 text-ribola-astral flex justify-center'>
									<i className='far fa-map-marker-alt text-3xl'></i>
								</div>
								<div className='col-span-50'>
									<span
										className='text-sm'
										dangerouslySetInnerHTML={{
											__html: siteParams.sedeLegaleIndirizzo,
										}}
									/>
								</div>
							</div>
							<div className='grid grid-cols-60 w-full xl:pl-12 3xl:pl-20 mt-6'>
								<div className='col-span-10 text-ribola-astral flex justify-center'>
									<i className='far fa-phone-volume text-3xl'></i>
								</div>
								<div className='col-span-50'>
									<span
										className='text-sm'
										dangerouslySetInnerHTML={{
											__html: 'Tel. ' + siteParams.telefono,
										}}
									/>
									<br />
									<span
										className='text-sm'
										dangerouslySetInnerHTML={{
											__html: 'Fax ' + siteParams.fax,
										}}
									/>
								</div>
							</div>
							<div className='grid grid-cols-60 w-full xl:pl-12 3xl:pl-20 mt-6'>
								<div className='col-span-10 text-ribola-astral flex justify-center'>
									<i className='far fa-envelope text-3xl'></i>
								</div>
								<div className='col-span-50'>
									<span
										className='text-sm'
										dangerouslySetInnerHTML={{
											__html: siteParams.email,
										}}
									/>
								</div>
							</div>
							<div className='grid grid-cols-60 w-full xl:pl-12 3xl:pl-20 mt-6'>
								<div className='col-span-10'></div>
								<div className='col-span-50'>
									<span
										className='text-sm'
										dangerouslySetInnerHTML={{
											__html: 'C.F. - P.IVA ' + siteParams.piva,
										}}
									/>
									<br />
									<span
										className='text-sm'
										dangerouslySetInnerHTML={{
											__html:
												'N. iscrizione reg. imprese (rea)<br> ' +
												siteParams.rea,
										}}
									/>
									<br />
									<span
										className='text-sm'
										dangerouslySetInnerHTML={{
											__html: 'Capitale sociale ' + siteParams.capitaleSociale,
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='col-span-60 px-8 xl:px-12 3xl:px-20 mt-8 sm:mt-20'>
					<iframe
						src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2789.328889043373!2d10.005914416199623!3d45.644216629433174!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478165c54ac898b7%3A0x16e42db7e639ca0b!2sRibola%20Retificio%20S.r.l.!5e0!3m2!1sen!2sit!4v1634311727638!5m2!1sen!2sit'
						width='100%'
						height='450'
						allowFullScreen=''
						loading='lazy'></iframe>
				</div>
			</div>
		</div>
	);
};

export default ContattiDetail;
