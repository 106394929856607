import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import MenuLeftMagazine from '../../components/shared/menuLeftMagazine/MenuLeftMagazine';
import MagazineArticleDetail from '../../components/magazineArticleDetail/MagazineArticleDetail';
import Error404Detail from '../../components/error404Detail/Error404Detail';

const MagazineArticle = () => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const magazine = useSelector((state) => state.magazine);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// recupero struttura percorso da url (per via del back dal browser, devo sempre recuperare i dati via url)
		const pathNodes = location.pathname.split('/').filter((val) => val !== '');

		const magazine_urlseo = pathNodes[0];
		const magazine_category_urlseo = pathNodes[1];
		const magazine_article_urlseo = pathNodes[2].replace('.html', '');
		let magazineArticle = null;

		// recupero id categoria magazine da store (magazine)
		const magazine_category_id = Object.keys(magazine)
			.filter((key) => magazine[key].urlSeo === magazine_category_urlseo)
			.toString();

		//console.log(magazine_category_id);

		if (magazine_category_id) {
			// recupero id magazine da store (magazine)
			const magazine_article_id = Object.keys(
				magazine[magazine_category_id]?.childMagazines
			)
				.filter(
					(key) =>
						magazine[magazine_category_id]?.childMagazines[key]?.urlSeo ===
						magazine_article_urlseo
				)
				.toString();

			// controllo nello store se ho già il dettaglio della magazineArticle (isFetched = true), altrimenti eseguo action creator
			magazineArticle =
				magazine[magazine_category_id]?.childMagazines[magazine_article_id];
		}

		if (magazineArticle) {
			// setto i metatags
			document.title = magazineArticle.metaTitle;
			document.getElementsByTagName('META')[2].content =
				magazineArticle.metaDesc;
		}

		console.log(magazineArticle, history.action);

		// controllo per pagina 404, se currentPage.routeName === 'error404Detail' vuol dire che ho inserito un url sbagliato, restituisco la pagina 404
		if (
			currentPage.routeName === 'error404Detail' ||
			(magazineArticle === null && history.action === 'POP') ||
			(magazineArticle === undefined && history.action === 'POP')
		) {
			// recupero pagina 404
			const wrongPage404 =
				magazine_urlseo +
				'/' +
				magazine_category_urlseo +
				'/' +
				magazine_article_urlseo +
				'.html';
			setTimeout(
				() =>
					dispatch(
						allActions.staticDetailActions.fetchError404Detail(
							currentPage.currentLang,
							user?.userData?.id,
							wrongPage404
						)
					),
				50
			);
		} else {
			// recupero dati articolo magazine
			if (
				magazineArticle === null ||
				magazineArticle?.isFetched === undefined ||
				magazineArticle?.isFetched === false
			) {
				// non ho i dati del prodotto nello store, chiamo action creator fetchMagazineArticleDetail
				// se non ho ancora recuperato l'array di magazine devo passare l'urlseo del magazine piuttosto che l'id
				const urlSeoOrId =
					magazineArticle?.id === undefined
						? magazine_article_urlseo
						: magazineArticle.id;
				setTimeout(
					() =>
						dispatch(
							allActions.magazineDetailActions.fetchMagazineArticleDetail(
								currentPage.currentLang,
								user?.userData?.id,
								urlSeoOrId
							)
						),
					50
				);
			} else {
				// loading...
				dispatch(allActions.isFetchingAction.loadStoredData());

				// recupero dati da store
				dispatch(
					allActions.magazineDetailActions.loadMagazineArticleDetail(
						magazineArticle
					)
				);
			}
		}
	}, [location]);

	const isPage404 =
		currentPage.routeName === 'error404Detail' ? (
			<Error404Detail />
		) : (
			<MagazineArticleDetail />
		);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<MenuLeftMagazine />
					{isPage404}
				</div>
			</div>
		</Layout>
	);
};

export default MagazineArticle;
