import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SlideshowHeader from '../shared/slideshowHeader/SlideshowHeader';
import ImageHeader from '../shared/imageHeader/ImageHeader';

const Layout = ({ children }) => {
	const location = useLocation();
	const currentPage = useSelector((state) => state.currentPage);

	// se sono nel carrello non mostro la testata
	const showHeaderImage =
		currentPage.routeName === 'cartDetail' ||
		currentPage.routeName === 'registrationDetail' ||
		currentPage.routeName === 'paymentDetail' ||
		currentPage.routeName === 'checkoutDetail' ||
		currentPage.routeName === 'orderClosedDetail' ? (
			''
		) : location.pathname === '/' ? (
			<SlideshowHeader />
		) : (
			<ImageHeader />
		);

	return (
		<Fragment>
			{showHeaderImage}
			<main>{children}</main>
		</Fragment>
	);
};

export default Layout;
