import { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import MenuLeft from '../../components/shared/menuLeft/MenuLeft';
import ProductDetail from '../../components/productDetail/ProductDetail';
import Error404Detail from '../../components/error404Detail/Error404Detail';
import { gtmProductDetail } from '../../gtm/gtm';

const Product = () => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const currentLang = useSelector((state) => state.currentPage.currentLang);
	const siteParams = useSelector((state) => state.siteParams);
	const user = useSelector((state) => state.user);
	const catalogue = useSelector((state) => state.catalogue);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		//console.log('sono in product');
		// recupero struttura percorso da url (per via del back dal browser, devo sempre recuperare i dati via url)
		const pathNodes = location.pathname.split('/').filter((val) => val !== '');

		const reti_urlseo = pathNodes[0];
		const parent_product_category_urlseo = pathNodes[1];
		const product_category_urlseo = pathNodes[2];
		const product_urlseo = pathNodes[3].replace('.html', '');

		/* console.log(
			reti_urlseo,
			parent_product_category_urlseo,
			product_category_urlseo,
			product_urlseo
		); */

		const parent_product_category_id = Object.keys(catalogue)
			.filter((key) => catalogue[key].urlSeo === parent_product_category_urlseo)
			.toString();
		const parent_product_category = catalogue[parent_product_category_id];

		//console.log(parent_product_category_id, parent_product_category);

		const product_category_id = Object.keys(
			catalogue[parent_product_category_id].childCategories
		)
			.filter(
				(key) =>
					catalogue[parent_product_category_id].childCategories[key].urlSeo ===
					product_category_urlseo
			)
			.toString();

		console.log(parent_product_category);

		let product_id = null;
		if (product_category_id) {
			product_id = Object.keys(
				catalogue[parent_product_category_id].childCategories[
					product_category_id
				].childProducts
			)
				.filter(
					(key) =>
						catalogue[parent_product_category_id].childCategories[
							product_category_id
						].childProducts[key].urlSeo === product_urlseo
				)
				.toString();
		}

		let product = null;
		if (product_id) {
			// controllo nello store se ho già recuperato tutti i dati del prodotto (isFetched = true), altrimenti eseguo action creator
			product =
				catalogue[parent_product_category_id].childCategories[
					product_category_id
				].childProducts[product_id];
		}

		console.log(product);

		// ho il prodotto, setto i metatags ed invio dati a GTM
		if (product) {
			// setto i metatags
			document.title = product.metaTitle;
			document.getElementsByTagName('META')[2].content = product.metaDesc;

			const productCategory =
				catalogue[parent_product_category_id].childCategories[
					product_category_id
				];

			// gtm detail prodotto
			const nomeProdotto = product.detail.titolo_breve;
			const codiceProdotto = product.detail.codice;
			const imponibile = parseFloat(product.detail.imponibile).toFixed(2);
			const sconto = product.detail.sconto;
			const prezzoScontato = parseFloat(product.detail.prezzoScontato).toFixed(
				2
			);
			const categoria = productCategory.detail.titolo;

			const productData = {
				nomeProdotto,
				codiceProdotto,
				imponibile,
				sconto,
				prezzoScontato,
			};

			// gtm productDetail
			const gtmSuffix = `gtm_code${currentLang}`;
			const currentGTMTAG = siteParams[gtmSuffix];
			gtmProductDetail({
				productData: productData,
				categoryName: categoria,
				isGtmEnabled: currentGTMTAG !== null ? true : false,
			});
		}

		// controllo per pagina 404, se currentPage.routeName === 'error404Detail' vuol dire che ho inserito un url sbagliato, restituisco la pagina 404
		if (currentPage.routeName === 'error404Detail') {
			// recupero pagina 404
			const wrongPage404 =
				reti_urlseo +
				'/' +
				parent_product_category_urlseo +
				'/' +
				product_category_urlseo +
				'/' +
				product_urlseo +
				'.html';
			setTimeout(
				() =>
					dispatch(
						allActions.staticDetailActions.fetchError404Detail(
							currentPage.currentLang,
							user?.userData?.id,
							wrongPage404
						)
					),
				50
			);
		} else {
			// recupero dati prodotto (se currentPage.isLogging è true o false, vuole dire che ho eseguito un login o logout di recente e quindi devo riscaricare i dati del prodotto per eventuali sconti)
			if (
				product === null ||
				product?.isFetched === undefined ||
				product?.isFetched === false ||
				currentPage.isLogging === true ||
				currentPage.isLogging === false
			) {
				// non ho i dati del prodotto nello store, chiamo action creator fetchProductDetail passando product_urlseo (se sono in pronta consegna cambio fetch e passo id 2 (pronta Consegna))
				if (parent_product_category.id === '2') {
					setTimeout(
						() =>
							dispatch(
								allActions.productDetailActions.fetchProductDetail(
									currentPage.currentLang,
									user?.userData?.id,
									product_urlseo,
									2
								)
							),
						50
					);
				} else {
					setTimeout(
						() =>
							dispatch(
								allActions.productDetailActions.fetchProductDetail(
									currentPage.currentLang,
									user?.userData?.id,
									product_urlseo
								)
							),
						50
					);
				}
			} else {
				// loading...
				dispatch(allActions.isFetchingAction.loadStoredData());

				// recupero dati da store
				dispatch(allActions.productDetailActions.loadProductDetail(product));
			}
		}
	}, [location]);

	const isPage404 =
		currentPage.routeName === 'error404Detail' ? (
			<Error404Detail />
		) : (
			<ProductDetail />
		);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<MenuLeft />
					{isPage404}
				</div>
			</div>
		</Layout>
	);
};

export default Product;
