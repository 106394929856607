import { Route } from 'react-router-dom';
import Cart from '../pages/cart/Cart';

export default (cartsRoute) => {
	return [
		<Route
			exact
			path={`/${cartsRoute}.html`}
			component={Cart}
			key={`${cartsRoute}Route`}
		/>,
	];
};
