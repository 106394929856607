import { useEffect, useState } from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import allActions from './state/actions';
import { STRIPE_PUBLISHABLE_KEY } from './utils/stripeKeys';
import { PAYPAL_CLIENT_ID } from './utils/paypalKeys';
import ScrollToTop from './hoc/ScrollToTop';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import HomepageRoute from './routes/homepageRoute';
import NetsRoute from './routes/netsRoute';
import ParentCategoryRoute from './routes/parentCategoryRoute';
import CategoryRoute from './routes/categoryRoute';
import ProductRoute from './routes/productRoute';
import CartRoute from './routes/cartRoute';
import RegistrationRoute from './routes/registrationRoute';
import RegistrationFormRoute from './routes/registrationFormRoute';
import PaymentRoute from './routes/paymentRoute';
import CheckoutRoute from './routes/checkoutRoute';
import ProfileRoute from './routes/profileRoute';
import AddressesRoute from './routes/addressesRoute';
import OrdersRoute from './routes/ordersRoute';
import OrderClosedRoute from './routes/orderClosedRoute';
import ChisiamoRoute from './routes/chisiamoRoute';
import LenostreretiRoute from './routes/lenostreretiRoute';
import ContattiRoute from './routes/contattiRoute';
import ContattiokRoute from './routes/contattiokRoute';
import ContattikoRoute from './routes/contattikoRoute';
import CondizioniVenditaRoute from './routes/condizioniVenditaRoute';
import MagazineRoute from './routes/magazineRoute';
import MagazineCategoryRoute from './routes/magazineCategoryRoute';
import MagazineArticleRoute from './routes/magazineArticleRoute';
import RisultatiricercaRoute from './routes/risultatiricercaRoute';
import PrivacyPolicyRoute from './routes/privacyPolicyRoute';
import CookiesPolicyRoute from './routes/cookiesPolicyRoute';
import Error404 from './pages/error404/Error404';

// inizializzazione Stripejs
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const App = () => {
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const order = useSelector((state) => state.order);
	const routeTranslation = useSelector(
		(state) => state.translations.routesTranslated
	);

	// local state
	const [isAuthenticating, setIsAuthenticating] = useState(false);

	// lingua corrente
	const suffissoLang = currentPage.currentLang.replace('_', '');

	useEffect(() => {
		//console.log(currentPage, currentPage.isAuthenticated, user.isLogged);

		// authenticate user (recupero X-XSRF-TOKEN per il carrello e le chiamate successive autenticate)
		if (!currentPage.isAuthenticated && isAuthenticating === false) {
			setIsAuthenticating(true);

			dispatch(allActions.userActions.authenticateUser());
		}
	}, [currentPage]);

	// opzione configurazione Stripe
	const CustomFontSourceObj = {
		family: 'OpenSansRegular',
		src: 'url(/webfonts/OpenSans-Regular.woff2)',
		weight: '400',
		display: 'swap',
	};

	// opzione configurazione Paypal (passo anche lingua)
	let langPaypal = `${suffissoLang}_${suffissoLang.toUpperCase()}`;
	langPaypal = langPaypal === 'en_EN' ? 'en_GB' : langPaypal;

	const initialOptions = {
		'client-id': PAYPAL_CLIENT_ID,
		currency: 'EUR',
		locale: langPaypal,
	};

	return (
		<Elements
			options={{ locale: suffissoLang }}
			fonts={CustomFontSourceObj}
			stripe={stripePromise}>
			<PayPalScriptProvider options={initialOptions}>
				<ScrollToTop>
					<Header />
					<Switch>
						{HomepageRoute}
						{ContattiRoute(routeTranslation.contattiroute[suffissoLang])}
						{ContattiokRoute(routeTranslation.contattiokroute[suffissoLang])}
						{ContattikoRoute(routeTranslation.contattikoroute[suffissoLang])}
						{ChisiamoRoute(routeTranslation.chisiamoroute[suffissoLang])}
						{LenostreretiRoute(
							routeTranslation.lenostreretiroute[suffissoLang]
						)}
						{CondizioniVenditaRoute(
							routeTranslation.condizionivenditaroute[suffissoLang]
						)}
						{PrivacyPolicyRoute(
							routeTranslation.privacypolicyroute[suffissoLang]
						)}
						{CookiesPolicyRoute(
							routeTranslation.cookiespolicyroute[suffissoLang]
						)}
						{RisultatiricercaRoute(routeTranslation.searchroute[suffissoLang])}
						{MagazineRoute(routeTranslation.magazineroute[suffissoLang])}
						{MagazineCategoryRoute(
							routeTranslation.magazineroute[suffissoLang]
						)}
						{MagazineArticleRoute(routeTranslation.magazineroute[suffissoLang])}
						{CartRoute(routeTranslation.carrelloroute[suffissoLang])}
						<OrderClosedRoute
							exact
							path='/stripe-ok.html'
							key='orderStripeRoute'
						/>
						<OrderClosedRoute
							exact
							path='/paypal-ok.html'
							key='orderStripeRoute'
						/>
						<OrderClosedRoute
							exact
							path='/bonifico-ok.html'
							key='orderBonificoRoute'
						/>
						<OrderClosedRoute exact path='/riba-ok.html' key='orderRibaRoute' />
						<OrderClosedRoute
							exact
							path='/contrassegno-ok.html'
							key='orderContrassegnoRoute'
						/>
						<PaymentRoute
							exact
							path={`/${routeTranslation.pagamentoroute[suffissoLang]}.html`}
							key={`${routeTranslation.pagamentoroute[suffissoLang]}Route`}
							isLogged={user.isLogged}
							orderItems={order.totalItems}
						/>
						<CheckoutRoute
							exact
							path={`/${routeTranslation.concludiordineroute[suffissoLang]}.html`}
							key={`${routeTranslation.concludiordineroute[suffissoLang]}Route`}
							isLogged={user.isLogged}
							addressId={order.addressId}
							paymentId={order.paymentId}
							userId={order.userId}
							orderItems={order.totalItems}
							isNoShipping={order.isNoShipping}
						/>
						{NetsRoute(routeTranslation.retiroute[suffissoLang])}
						{ParentCategoryRoute(routeTranslation.retiroute[suffissoLang])}
						{CategoryRoute(routeTranslation.retiroute[suffissoLang])}
						{ProductRoute(routeTranslation.retiroute[suffissoLang])}
						<ProfileRoute
							exact
							path={`/${routeTranslation.profiloroute[suffissoLang]}.html`}
							key={`${routeTranslation.profiloroute[suffissoLang]}Route`}
							isLogged={user.isLogged}
						/>
						<AddressesRoute
							exact
							path={`/${routeTranslation.indirizziroute[suffissoLang]}.html`}
							key={`${routeTranslation.indirizziroute[suffissoLang]}Route`}
							isLogged={user.isLogged}
						/>
						<OrdersRoute
							exact
							path={`/${routeTranslation.ordiniroute[suffissoLang]}.html`}
							key={`${routeTranslation.ordiniroute[suffissoLang]}Route`}
							isLogged={user.isLogged}
						/>
						<RegistrationRoute
							exact
							path={`/${routeTranslation.registrazioneroute[suffissoLang]}.html`}
							key={`${routeTranslation.registrazioneroute[suffissoLang]}Route`}
							isLogging={currentPage.isLogging}
							isLogged={user.isLogged}
						/>
						<RegistrationFormRoute
							exact
							path={`/${routeTranslation.registrazioneformroute[suffissoLang]}.html`}
							key={`${routeTranslation.registrazioneformroute[suffissoLang]}Route`}
							isLogged={user.isLogged}
							redirectPage={
								order.totalItems > 0
									? routeTranslation.pagamentoroute[suffissoLang]
									: routeTranslation.profiloroute[suffissoLang]
							}
						/>
						<Route exact path='/*' component={Error404} key='404Route' />
					</Switch>
					<Footer />
				</ScrollToTop>
			</PayPalScriptProvider>
		</Elements>
	);
};

export default withRouter(App);
