import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const SelectLanguage = (props) => {
	const siteParams = useSelector((state) => state.siteParams);
	const currentPage = useSelector((state) => state.currentPage);

	useEffect(() => {
		//console.log(siteParams);
	}, []);

	const array_languages = {
		IT: siteParams.sitoDominio_it,
		EN: siteParams.sitoDominio_en,
		FR: siteParams.sitoDominio_fr,
		DE: siteParams.sitoDominio_de,
	};

	const langsList = Object.keys(array_languages).map((language, key) => {
		return (
			language !== siteParams.current_language.toUpperCase() && (
				<option
					key={language}
					value={language + '_' + array_languages[language] + '/'}>
					{language}
				</option>
			)
		);
	});

	const langSelected = Object.keys(array_languages).map((language, key) => {
		return (
			language === siteParams.current_language.toUpperCase() && (
				<option key={language}>{language}</option>
			)
		);
	});

	const onChangeLanguage = (event) => {
		console.log(event.target.value);
		// se ho l'alternate della pagina punto a quello altrimenti alla homepage
		const alternate_it = currentPage?.pageLinkSeo_it;
		const alternate_en = currentPage?.pageLinkSeo_en;
		const alternate_fr = currentPage?.pageLinkSeo_fr;
		const alternate_de = currentPage?.pageLinkSeo_de;
		const langSelected = event.target.value.split('_')[0];

		if (langSelected === 'IT') {
			if (alternate_it) {
				window.location = array_languages['IT'] + alternate_it;
			} else {
				window.location = array_languages['IT'];
			}
		}

		if (langSelected === 'EN') {
			if (alternate_en) {
				window.location = array_languages['EN'] + alternate_en;
			} else {
				window.location = array_languages['EN'];
			}
		}

		if (langSelected === 'FR') {
			if (alternate_fr) {
				window.location = array_languages['FR'] + alternate_fr;
			} else {
				window.location = array_languages['FR'];
			}
		}

		if (langSelected === 'DE') {
			if (alternate_de) {
				window.location = array_languages['DE'] + alternate_de;
			} else {
				window.location = array_languages['DE'];
			}
		}
	};

	return (
		<div className='customSelect'>
			<select onChange={onChangeLanguage}>
				{langSelected}
				{langsList}
			</select>
		</div>
	);
};

export default SelectLanguage;
