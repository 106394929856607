import axios from '../../utils/axiosBase';
import { FETCH_PRODUCT_DETAIL, LOAD_PRODUCT_DETAIL } from './types';

const fetchProductDetail =
	(currentLang, userId, product_id, pronta_consegna_id = null, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
		};

		// chiamo web service
		let response;
		if (pronta_consegna_id) {
			response = await axios.post(
				`api/productdetail/${product_id}/${pronta_consegna_id}/`,
				payload,
				{
					withCredentials: true,
				}
			);
		} else {
			response = await axios.post(`api/productdetail/${product_id}/`, payload, {
				withCredentials: true,
			});
		}

		dispatch({
			type: FETCH_PRODUCT_DETAIL,
			payload: response.data,
		});
	};

const loadProductDetail =
	(productLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_PRODUCT_DETAIL,
			payload: {
				productLoaded,
			},
		});
	};

export default {
	fetchProductDetail,
	loadProductDetail,
};
