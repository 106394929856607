import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import NavigationItem from '../navigationItem/NavigationItem';

const Briciole = (props) => {
	const briciole = useSelector(
		(state) => state.currentPage.pageContent.briciole
	);

	/* useEffect(() => {
		console.log(briciole);
	}, [briciole]); */

	if (briciole === undefined || briciole === null) return <div></div>;

	const elencoBriciole = briciole.map((briciola) => {
		return (
			<NavigationItem
				key={_uniqueId('briciola_')}
				itemLink={briciola.linkSeo}
				itemName={briciola.name}
				itemRouteName={briciola.routeName}
				itemStyle='mr-2'
			/>
		);
	});

	return (
		<div className='flex justify-end items-center w-full'>
			<nav className='flex-wrap font-poppinsregular py-3 px-3 text-sm sm:text-base sm:text-right briciole'>
				{elencoBriciole}
			</nav>
		</div>
	);
};

export default Briciole;
