import { Route, Redirect } from 'react-router-dom';
import Orders from '../pages/orders/Orders';

const OrdersRoute = ({ isLogged, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			isLogged ? (
				<Orders />
			) : (
				<Redirect
					exact
					to={{
						pathname: '/',
						state: {
							from: props.location,
						},
					}}
				/>
			)
		}
	/>
);

export default OrdersRoute;
