import { Route } from 'react-router-dom';
import Chisiamo from '../pages/chisiamo/Chisiamo';

export default (chisiamoRoute) => {
	return [
		<Route
			exact
			path={`/${chisiamoRoute}.html`}
			component={Chisiamo}
			key={`${chisiamoRoute}Route`}
		/>,
	];
};
