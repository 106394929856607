export const IS_FETCHING = 'IS_FETCHING';
export const IS_LOADING = 'IS_LOADING';
export const CHANGE_PAGE = 'CHANGE_PAGE';
export const FETCH_HOMEPAGE = 'FETCH_HOMEPAGE';
export const LOAD_HOMEPAGE = 'LOAD_HOMEPAGE';
export const FETCH_NETS_DETAIL = 'FETCH_NETS_DETAIL';
export const LOAD_NETS_DETAIL = 'LOAD_NETS_DETAIL';
export const FETCH_PARENT_CATEGORY_DETAIL = 'FETCH_PARENT_CATEGORY_DETAIL';
export const LOAD_PARENT_CATEGORY_DETAIL = 'LOAD_PARENT_CATEGORY_DETAIL';
export const FETCH_CATEGORY_DETAIL = 'FETCH_CATEGORY_DETAIL';
export const LOAD_CATEGORY_DETAIL = 'LOAD_CATEGORY_DETAIL';
export const FETCH_PRODUCT_DETAIL = 'FETCH_PRODUCT_DETAIL';
export const LOAD_PRODUCT_DETAIL = 'LOAD_PRODUCT_DETAIL';
export const FETCH_CART_DETAIL = 'FETCH_CART_DETAIL';
export const LOAD_CART_DETAIL = 'LOAD_CART_DETAIL';
export const FETCH_REGISTRATION_DETAIL = 'FETCH_REGISTRATION_DETAIL';
export const LOAD_REGISTRATION_DETAIL = 'LOAD_REGISTRATION_DETAIL';
export const FETCH_REGISTRATION_FORM_DETAIL = 'FETCH_REGISTRATION_FORM_DETAIL';
export const LOAD_REGISTRATION_FORM_DETAIL = 'LOAD_REGISTRATION_FORM_DETAIL';
export const FETCH_PAYMENT_DETAIL = 'FETCH_PAYMENT_DETAIL';
export const LOAD_PAYMENT_DETAIL = 'LOAD_PAYMENT_DETAIL';
export const FETCH_CHECKOUT_DETAIL = 'FETCH_CHECKOUT_DETAIL';
export const LOAD_CHECKOUT_DETAIL = 'LOAD_CHECKOUT_DETAIL';
export const FETCH_ORDER_CLOSED_DETAIL = 'FETCH_ORDER_CLOSED_DETAIL';
export const LOAD_ORDER_CLOSED_DETAIL = 'LOAD_ORDER_CLOSED_DETAIL';
export const FETCH_PROFILE_DETAIL = 'FETCH_PROFILE_DETAIL';
export const LOAD_PROFILE_DETAIL = 'LOAD_PROFILE_DETAIL';
export const FETCH_ADDRESSES_DETAIL = 'FETCH_ADDRESSES_DETAIL';
export const LOAD_ADDRESSES_DETAIL = 'LOAD_ADDRESSES_DETAIL';
export const FETCH_ORDERS_DETAIL = 'FETCH_ORDERS_DETAIL';
export const LOAD_ORDERS_DETAIL = 'LOAD_ORDERS_DETAIL';
export const FETCH_CHISIAMO_DETAIL = 'FETCH_CHISIAMO_DETAIL';
export const LOAD_CHISIAMO_DETAIL = 'LOAD_CHISIAMO_DETAIL';
export const FETCH_LENOSTRERETI_DETAIL = 'FETCH_LENOSTRERETI_DETAIL';
export const LOAD_LENOSTRERETI_DETAIL = 'LOAD_LENOSTRERETI_DETAIL';
export const FETCH_CONTATTI_DETAIL = 'FETCH_CONTATTI_DETAIL';
export const LOAD_CONTATTI_DETAIL = 'LOAD_CONTATTI_DETAIL';
export const FETCH_CONTATTI_OK_DETAIL = 'FETCH_CONTATTI_OK_DETAIL';
export const LOAD_CONTATTI_OK_DETAIL = 'LOAD_CONTATTI_OK_DETAIL';
export const FETCH_CONTATTI_KO_DETAIL = 'FETCH_CONTATTI_KO_DETAIL';
export const LOAD_CONTATTI_KO_DETAIL = 'LOAD_CONTATTI_KO_DETAIL';
export const FETCH_CONDIZIONI_VENDITA_DETAIL =
	'FETCH_CONDIZIONI_VENDITA_DETAIL';
export const LOAD_CONDIZIONI_VENDITA_DETAIL = 'LOAD_CONDIZIONI_VENDITA_DETAIL';
export const FETCH_MAGAZINE_DETAIL = 'FETCH_MAGAZINE_DETAIL';
export const LOAD_MAGAZINE_DETAIL = 'LOAD_MAGAZINE_DETAIL';
export const FETCH_MAGAZINE_CATEGORY_DETAIL = 'FETCH_MAGAZINE_CATEGORY_DETAIL';
export const LOAD_MAGAZINE_CATEGORY_DETAIL = 'LOAD_MAGAZINE_CATEGORY_DETAIL';
export const FETCH_MAGAZINE_ARTICLE_DETAIL = 'FETCH_MAGAZINE_ARTICLE_DETAIL';
export const LOAD_MAGAZINE_ARTICLE_DETAIL = 'LOAD_MAGAZINE_ARTICLE_DETAIL';
export const FETCH_PRIVACY_POLICY_DETAIL = 'FETCH_PRIVACY_POLICY_DETAIL';
export const LOAD_PRIVACY_POLICY_DETAIL = 'LOAD_PRIVACY_POLICY_DETAIL';
export const FETCH_COOKIES_POLICY_DETAIL = 'FETCH_COOKIES_POLICY_DETAIL';
export const LOAD_COOKIES_POLICY_DETAIL = 'LOAD_COOKIES_POLICY_DETAIL';
export const FETCH_SEARCH_DETAIL = 'FETCH_SEARCH_DETAIL';
export const FETCH_404_DETAIL = 'FETCH_404_DETAIL';
export const LOAD_404_DETAIL = 'LOAD_404_DETAIL';
export const AUTHENTICATE_USER = 'AUTHENTICATE_USER';
export const ADD_TO_CART = 'ADD_TO_CART';
export const UPDATE_CART_QUANTITY = 'UPDATE_CART_QUANTITY';
export const DELETE_CART_ITEM = 'DELETE_CART_ITEM';
export const DELETE_CART_ITEMS = 'DELETE_CART_ITEMS';
export const NO_SHIPPING = 'NO_SHIPPING';
export const SHIPPING_FROM_CAP = 'SHIPPING_FROM_CAP';
export const UPDATE_NOTES = 'UPDATE_NOTES';
export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';
export const REGISTER_USER = 'REGISTER_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const CREATE_ADDRESS = 'CREATE_ADDRESS';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const SELECT_ADDRESS = 'SELECT_ADDRESS';
export const SELECT_PAYMENT = 'SELECT_PAYMENT';
export const STRIPE_PAYMENT_INTENT_CREATE = 'STRIPE_PAYMENT_INTENT_CREATE';
export const STRIPE_OK = 'STRIPE_OK';
export const STRIPE_KO = 'STRIPE_KO';
export const PAYPAL_OK = 'PAYPAL_OK';
export const PAYPAL_KO = 'PAYPAL_KO';
export const BONIFICO_OK = 'BONIFICO_OK';
export const RIBA_OK = 'RIBA_OK';
export const CONTRASSEGNO_OK = 'CONTRASSEGNO_OK';
export const ORDER_RESET = 'ORDER_RESET';
export const OPEN_MENU = 'OPEN_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';
export const SEND_CONTATTI = 'SEND_CONTATTI';
export const SEND_RICHIEDI_INFO_PRODOTTO = 'SEND_RICHIEDI_INFO_PRODOTTO';
export const ADD_COUPON = 'ADD_COUPON';
