import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../state/actions';
import LazyLoadImg from '../lazyLoadImg/LazyLoadImg';
import CustomButton from '../customButton/CustomButton';
import InfoTooltipElement from '../infoTooltipElement/InfoTooltipElement';
import { BASENAME } from '../../../utils/basename';
import { URLASSOLUTO } from '../../../utils/urlassoluto';
import { gtmProductClick } from '../../../gtm/gtm';

const ProductBoxSmallItem = ({
	id,
	index,
	immagine,
	titoloBreve,
	codice,
	imponibile,
	sconto,
	tipologiaScontoPromozione,
	prezzoScontato,
	unitaMisura,
	measure_unit_id,
	iconaTagBestseller = '',
	iconaTagNovita = '',
	richiediInfo = '',
	isProntaConsegna = false,
	lunghezza = null,
	larghezza = null,
	profondita = null,
	metrilineari = null,
	prodotto3x2 = false,
	prodottoAbbinatoObj = null,
	prezzoFisso = null,
	colore,
	routeName,
	linkSeo,
	categoriaTitolo,
	scrollPosition,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const currentLang = useSelector((state) => state.currentPage.currentLang);
	const siteParams = useSelector((state) => state.siteParams);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	const onProductClick = (event) => {
		event.preventDefault();

		const productData = {
			titoloBreve,
			codice,
			imponibile,
			sconto,
			prezzoScontato,
		};

		// gtm productClick
		const gtmSuffix = `gtm_code${currentLang}`;
		const currentGTMTAG = siteParams[gtmSuffix];
		gtmProductClick({
			productData,
			categoryName: categoriaTitolo,
			position: index,
			callbackFunction: onGoToPage,
			isGtmEnabled: currentGTMTAG !== null ? true : false,
		});
	};

	const onGoToPage = () => {
		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(id, routeName, linkSeo, history)
		);
	};

	const tagBestseller = iconaTagBestseller ? (
		<span
			className={`iconaTag inline-block bg-ribola-shakespeare px-2 py-1 mt-2 mb-3 mr-2`}>
			{genericheTranslation.bestsellerflag.itemName.toUpperCase()}
		</span>
	) : (
		<span className='iconaTag hidden sm:inline-block p-0 m-0 w-0'>&nbsp;</span>
	);

	const tagNovita = iconaTagNovita ? (
		<span
			className={`iconaTag inline-block bg-ribola-congress-blue-variant px-2 py-1 mt-2 mb-3`}>
			{genericheTranslation.novitaflag.itemName.toUpperCase()}
		</span>
	) : (
		<span className='iconaTag hidden sm:inline-block p-0 m-0 w-0'>&nbsp;</span>
	);

	const tagInfo =
		richiediInfo === 1 ? (
			<span
				className={`iconaTag inline-block bg-ribola-chelsea-cucumber px-2 py-1 mt-2 mb-3`}>
				{genericheTranslation.informazioni.itemName.toUpperCase()}
			</span>
		) : (
			<span className='iconaTag hidden sm:inline-block mt-2 mb-3'>&nbsp;</span>
		);

	// prezzo fisso da tendina?
	// preparo i valori di prezzo ed eventuale prezzo scontato
	const prezzoDaMenuATendinaFisso = prezzoFisso ? prezzoFisso : null;
	const prezzoImponibileDaTendina =
		sconto > 0
			? prezzoDaMenuATendinaFisso - (prezzoDaMenuATendinaFisso / 100) * sconto
			: prezzoDaMenuATendinaFisso;
	const prezzoImponibileOriginale = sconto > 0 ? prezzoDaMenuATendinaFisso : 0;

	// se c'è uno sconto prodotto mostro il prezzo originale da presso fisso (se abilitato) oppure l'imponibile originale (non scontato e barrato)
	const prezzoOriginale = prezzoDaMenuATendinaFisso
		? prezzoImponibileOriginale
		: imponibile;
	// il prezzo finale può essere quello da prezzo fisso (menu a tendina) oppure da prezzo prodotto (eventualmente scontato)
	const prezzo = prezzoDaMenuATendinaFisso
		? prezzoImponibileDaTendina
		: sconto > 0
		? prezzoScontato
		: imponibile;

	const prezzoProdottoOriginale =
		sconto && sconto > 0 ? (
			<span className='prezzoScontato flex'>
				{parseFloat(prezzoOriginale).toFixed(2).toString().replace('.', ',')} €
			</span>
		) : (
			<span className='prezzoScontato flex opacity-0'>&nbsp;</span>
		);

	const prezzoProdotto =
		sconto && sconto > 0 ? (
			<span className='prezzoProdotto flex nomt text-ribola-web-orange text-2xl'>
				{parseFloat(prezzo).toFixed(2).toString().replace('.', ',')} €
				<small>{isProntaConsegna === false ? unitaMisura : ''}</small>
			</span>
		) : (
			<span className='prezzoProdotto flex nomt text-ribola-congress-blue text-2xl'>
				{parseFloat(prezzo).toFixed(2).toString().replace('.', ',')} €
				<small>{isProntaConsegna === false ? unitaMisura : ''}</small>
			</span>
		);

	const scontoFasciaArancione =
		sconto && sconto > 0 ? (
			<div className='scontoFasciaArancione small pointer-events-none z-20'>
				<span className='scontoFasciaInterna small'>
					<span className='titoloFasciaInterna'>
						<span className='leading-none'>
							{sconto}
							{tipologiaScontoPromozione === 'Percentuale' ? '%' : '€'}
						</span>
					</span>
					<span className='leading-none'>
						{genericheTranslation.sconto.itemName}
					</span>
				</span>
				<img
					src={`${BASENAME}/images/sconto_fascia_arancione.svg`}
					width='106'
					height='106'
					className='flex-start'
					alt='Sconto prodotto'
				/>
			</div>
		) : (
			''
		);

	const immagineElenco =
		immagine === null ||
		immagine === undefined ||
		immagine.file_name === undefined ? (
			<LazyLoadImg
				imgName='placeholder_elenco_prodotto.jpg'
				imgPath='statiche'
				imgWidth='147'
				imgHeight='147'
				imgStyle='flex-start mb-3 w-full object-fit cursor-pointer'
				imgAlt={`${titoloBreve} - cod.${codice}`}
			/>
		) : (
			<LazyLoadImg
				imgName={`147x147-${immagine.file_name.replace('.jpg', '.webp')}`}
				imgPath={`${immagine.folder}/thumbs`}
				imgWidth='147'
				imgHeight='147'
				imgStyle='flex-start mb-3 w-full object-fit cursor-pointer'
				imgAlt={`${titoloBreve} - cod.${codice}`}
			/>
		);

	const pulsanteLink =
		richiediInfo === 1 ? (
			<CustomButton
				buttonId={id}
				buttonLink={linkSeo}
				buttonRouteName={routeName}
				buttonOnClickFunction={onProductClick}
				buttonName={genericheTranslation.richiediinformazioni.itemName}
				buttonStyle='dettagliButton richiediInfo text-sm pl-4 pr-16 py-2 3xl:py-3 mt-2'
			/>
		) : (
			<CustomButton
				buttonId={id}
				buttonLink={linkSeo}
				buttonRouteName={routeName}
				buttonOnClickFunction={onProductClick}
				buttonName={genericheTranslation.dettagli.itemName}
				buttonStyle='dettagliButton text-sm pl-4 pr-16 py-2 3xl:py-3 mt-2'
			/>
		);

	// prodotto 3x2 promo?
	const promoProdottoTrePerDue = prodotto3x2 ? (
		<span className='offertatreperdue absolute right-0 bottom-0 h-6 mb-2 px-2 inline-flex justify-center items-center bg-ribola-chelsea-cucumber text-white text-xs font-opensanssemibold'>
			<span className='flex-shrink-0 mr-3'>
				{genericheTranslation.offerta32.itemName.toUpperCase()}
			</span>
			<InfoTooltipElement
				iconColor='white'
				fontSize='text-lg'
				tooltipColor='gray-800'
				tooltipText={genericheTranslation.offerta32.itemName}
			/>
		</span>
	) : (
		''
	);

	// prodotto abbinato promo?
	const immagineOmaggioUrl = prodottoAbbinatoObj?.immagineelenco;
	const altImmagine =
		prodottoAbbinatoObj !== null
			? prodottoAbbinatoObj.name + ' - cod.' + prodottoAbbinatoObj.codice
			: 'Prodotto abbinato';
	const immagineOmaggio =
		(prodottoAbbinatoObj && immagineOmaggioUrl === null) ||
		immagineOmaggioUrl === undefined ? (
			<LazyLoadImg
				imgName='placeholder_elenco_prodotto.jpg'
				imgPath='statiche'
				imgWidth='36'
				imgHeight='36'
				imgStyle='flex-start w-6 object-fit'
				imgAlt={altImmagine}
			/>
		) : (
			<LazyLoadImg
				imgName={`36x36-${immagineOmaggioUrl.file_name.replace(
					'.jpg',
					'.webp'
				)}`}
				imgPath={`${immagineOmaggioUrl.folder}/thumbs`}
				imgWidth='36'
				imgHeight='36'
				imgStyle='flex-start w-full object-fit'
				imgAlt={altImmagine}
			/>
		);

	const msgProdottoAbbinato =
		prodottoAbbinatoObj?.tipologiaScontoSingolo === 'Omaggio'
			? genericheTranslation.insiemeinregalo.itemName.toUpperCase()
			: genericheTranslation.acquistaconsconto.itemName.toUpperCase();
	const promoProdottoAbbinato = prodottoAbbinatoObj ? (
		<span className='prodottoInRegalo absolute -right-2 -bottom-4 h-11 pl-2 pr-1 inline-flex justify-center items-center border-2 border-white bg-ribola-chelsea-cucumber text-white text-xs font-opensanssemibold'>
			<span
				className='flex-shrink-0 mr-3'
				dangerouslySetInnerHTML={{
					__html: msgProdottoAbbinato,
				}}
			/>
			{immagineOmaggio}
		</span>
	) : (
		<span className='prodottoInRegalo hidden'>&nbsp;</span>
	);

	const dettaglioMetriCubi =
		measure_unit_id === 6 && lunghezza && larghezza && profondita ? (
			<span className='iconaTag inline-block bg-ribola-web-orange px-2 py-1 mt-2 mb-3 mr-2'>
				<strong>
					{parseFloat(lunghezza).toFixed(2).toString().replace('.', ',')}x
					{parseFloat(larghezza).toFixed(2).toString().replace('.', ',')}x
					{parseFloat(profondita).toFixed(2).toString().replace('.', ',')} mc
				</strong>
			</span>
		) : (
			''
		);

	const dettaglioMetriQuadri =
		(measure_unit_id === 1 || measure_unit_id === 5) &&
		lunghezza &&
		larghezza ? (
			<span className='iconaTag inline-block bg-ribola-web-orange px-2 py-1 mt-2 mb-3 mr-2'>
				<strong>
					{parseFloat(lunghezza).toFixed(2).toString().replace('.', ',')}x
					{parseFloat(larghezza).toFixed(2).toString().replace('.', ',')} mt
				</strong>
			</span>
		) : (
			''
		);

	const dettaglioMetriLineari =
		measure_unit_id === 3 && metrilineari ? (
			<span className='iconaTag inline-block bg-ribola-web-orange px-2 py-1 mt-2 mb-3 mr-2'>
				<strong>
					{parseFloat(metrilineari).toFixed(2).toString().replace('.', ',')} ml
				</strong>
			</span>
		) : (
			''
		);

	return (
		<div className='productBoxItem small border border-gray-200 mx-6 sm:mx-0 lg:mx-3 xl:mx-2 4xl:mx-3'>
			{scontoFasciaArancione}
			<div className='grid grid-cols-60'>
				<div className='col-span-25 flex flex-col relative justify-between items-start'>
					<a href={URLASSOLUTO + linkSeo} onClick={onProductClick}>
						{immagineElenco}
						{promoProdottoTrePerDue}
						{promoProdottoAbbinato}
					</a>
					{dettaglioMetriCubi}
					{dettaglioMetriQuadri}
					{dettaglioMetriLineari}
				</div>
				<div className='col-span-35 pl-5'>
					{tagInfo}
					{tagBestseller}
					{tagNovita}
					<span className='titolo block text-sm text-text-ribola-tundora'>
						{titoloBreve}
					</span>
					<span className='codice text-sm'>{codice}</span>
					{!richiediInfo ? prezzoProdottoOriginale : ''}
					{!richiediInfo ? prezzoProdotto : ''}
					{pulsanteLink}
				</div>
			</div>
		</div>
	);
};

export default ProductBoxSmallItem;
