import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import MenuLeft from '../../components/shared/menuLeft/MenuLeft';
import PrivacyPolicyDetail from '../../components/privacyPolicyDetail/PrivacyPolicyDetail';

const PrivacyPolicy = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const staticPages = useSelector((state) => state.staticPages);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// controllo nello store se ho già il dettaglio di privacy policy, altrimenti eseguo action creator
		const privacyPolicyPage = staticPages.privacypolicy;

		if (privacyPolicyPage) {
			// setto i metatags
			document.title = privacyPolicyPage.metaTitle;
			document.getElementsByTagName('META')[2].content =
				privacyPolicyPage.metaDesc;
		}

		if (
			privacyPolicyPage === undefined ||
			privacyPolicyPage.detail === undefined
		) {
			// non ho i dati del prodotto nello store, chiamo action creator fetchPrivacyPolicyDetail
			setTimeout(
				() =>
					dispatch(
						allActions.staticDetailActions.fetchPrivacyPolicyDetail(
							currentPage.currentLang,
							user?.userData?.id
						)
					),
				50
			);
		} else {
			// loading...
			dispatch(allActions.isFetchingAction.loadStoredData());

			// recupero dati da store
			dispatch(
				allActions.staticDetailActions.loadPrivacyPolicyDetail(
					privacyPolicyPage
				)
			);
		}

		//console.log(privacyPolicyPage);
	}, [location, staticPages]);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<MenuLeft />
					<PrivacyPolicyDetail />
				</div>
			</div>
		</Layout>
	);
};

export default PrivacyPolicy;
