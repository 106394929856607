import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const TotaleCostoProdotto = ({
	prezzoProdotto,
	prezzoOriginale = null,
	totalePrezzoKit = null,
}) => {
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	useEffect(() => {
		/* console.log(prezzoOriginale);
		console.log(prezzoProdotto); */
	}, [prezzoProdotto, prezzoOriginale]);

	const totalePrezzoOriginale = prezzoOriginale ? (
		<span className='text-ribola-congress-blue flex items-baseline'>
			<span className='mr-3 text-xl xl:text-lg 3xl:text-xl'>
				{genericheTranslation.totalestatic.itemName.toUpperCase()}
			</span>
			<span className='line-through text-3xl xl:text-2xl 3xl:text-3xl'>
				{prezzoOriginale.toString().replace('.', ',')} €
			</span>
		</span>
	) : (
		''
	);

	const totalePrezzo = prezzoOriginale ? (
		<span className='text-ribola-web-orange text-5xl xl:text-4xl 3xl:text-5xl'>
			{prezzoProdotto.toString().replace('.', ',')} €
		</span>
	) : (
		<span className='text-ribola-congress-blue text-5xl xl:text-4xl 3xl:text-5xl flex items-baseline'>
			<span className='mr-3 text-2xl xl:text-xl 3xl:text-2xl'>
				{genericheTranslation.totalestatic.itemName.toUpperCase()}
			</span>
			{prezzoProdotto.toString().replace('.', ',')} €
		</span>
	);

	const prezzoKit = totalePrezzoKit !== null && totalePrezzoKit > 0 && (
		<span className='text-ribola-congress-blue text-base self-center'>
			{genericheTranslation.prezzokit.itemName}&nbsp;
			{parseFloat(totalePrezzoKit).toFixed(2).toString().replace('.', ',')} €
		</span>
	);

	return (
		<div className='flex flex-col justify-center items-center font-opensanssemibold mt-3 3xl:mt-10 pt-3 3xl:pt-6 border-t border-gray-400'>
			{totalePrezzoOriginale}
			{totalePrezzo}
			{prezzoKit}
		</div>
	);
};

export default TotaleCostoProdotto;
