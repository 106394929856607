import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import MenuLeft from '../../components/shared/menuLeft/MenuLeft';
import ContattiDetail from '../../components/contattiDetail/ContattiDetail';

const Contatti = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const staticPages = useSelector((state) => state.staticPages);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// controllo nello store se ho già il dettaglio di contatti, altrimenti eseguo action creator
		const contattiPage = staticPages.contatti;

		if (contattiPage) {
			// setto i metatags
			document.title = contattiPage.metaTitle;
			document.getElementsByTagName('META')[2].content = contattiPage.metaDesc;
		}

		if (contattiPage === undefined || contattiPage.detail === undefined) {
			// non ho i dati del prodotto nello store, chiamo action creator fetchContattiDetail
			setTimeout(
				() =>
					dispatch(
						allActions.staticDetailActions.fetchContattiDetail(
							currentPage.currentLang,
							user?.userData?.id
						)
					),
				50
			);
		} else {
			// loading...
			dispatch(allActions.isFetchingAction.loadStoredData());

			// recupero dati da store
			dispatch(allActions.staticDetailActions.loadContattiDetail(contattiPage));
		}

		//console.log(contattiPage);
	}, [location, staticPages]);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<MenuLeft />
					<ContattiDetail />
				</div>
			</div>
		</Layout>
	);
};

export default Contatti;
