import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import allActions from '../../state/actions';
import Logo from '../shared/logo/Logo';
import BloccoIconeFooter from '../shared/bloccoIconeFooter/BloccoIconeFooter';
import FeedatyButton from '../shared/feedatyButton/FeedatyButton';
import { URLASSOLUTO } from '../../utils/urlassoluto';

const Footer = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const siteParams = useSelector((state) => state.siteParams);
	const isUserLogged = useSelector((state) => state.user.isLogged);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);
	const fasiCarrelloTranslation = useSelector(
		(state) => state.translations.fasiCarrello
	);
	const mainNavigation = useSelector(
		(state) => state.translations.mainNavigation
	);
	const profiloTranslation = useSelector(
		(state) => state.translations.menuLeftProfilo
	);
	const paginestaticheTranslation = useSelector(
		(state) => state.translations.paginestatiche
	);

	const onClickContatti = (event) => {
		event.preventDefault();

		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(
				'',
				'contattiDetail',
				mainNavigation.contatti.itemLink,
				history
			)
		);
	};

	const onClickPrivacy = (event) => {
		event.preventDefault();

		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(
				'',
				'privacyPolicyDetail',
				paginestaticheTranslation.privacypolicy.itemLink,
				history
			)
		);
	};

	const onClickCookies = (event) => {
		event.preventDefault();

		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(
				'',
				'cookiesPolicyDetail',
				paginestaticheTranslation.cookiespolicy.itemLink,
				history
			)
		);
	};

	const onClickCondizioniVendita = (event) => {
		event.preventDefault();

		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(
				'',
				'condizioniVenditaDetail',
				paginestaticheTranslation.condizionivendita.itemLink,
				history
			)
		);
	};

	const onClickRegistrazione = (event) => {
		event.preventDefault();

		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(
				'',
				fasiCarrelloTranslation.registrazione.routeName,
				fasiCarrelloTranslation.registrazione.linkName,
				history
			)
		);
	};

	const onClickProfilo = (event) => {
		event.preventDefault();

		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(
				'',
				profiloTranslation.profilo.itemRouteName,
				profiloTranslation.profilo.itemLink,
				history
			)
		);
	};

	const profiloOrRegistrazione = isUserLogged ? (
		<a
			href={`${URLASSOLUTO}${profiloTranslation.profilo.itemLink}`}
			onClick={onClickProfilo}
			className='block underline'>
			{profiloTranslation.profilo.itemName}
		</a>
	) : (
		<a
			href={`${URLASSOLUTO}${fasiCarrelloTranslation.registrazione.linkName}`}
			onClick={onClickRegistrazione}
			className='block underline'>
			{fasiCarrelloTranslation.registrazione.itemName}
		</a>
	);

	return (
		<div className='bg-ribola-alto mt-5'>
			<div className='containerFluido container mx-auto'>
				<div className='grid grid-cols-60 py-12 sm:py-20 text-ribola-dove-gray'>
					<div className='col-start-5 lg:col-start-3 xl:col-start-4 3xl:col-start-5 col-end-56 lg:col-end-58 xl:col-end-60 3xl:col-end-56'>
						<div className='grid grid-cols-60'>
							<div className='col-start-1 col-end-33 sm:col-end-33 md:col-end-26 lg:col-end-16 xl:col-end-14 3xl:col-end-11 flex flex-col justify-between'>
								<div className='w-40'>
									<Logo />
								</div>
								<div className='text-sm mt-6 sm:mt-0'>
									{siteParams.ragioneSociale}
									<br />
									<span
										dangerouslySetInnerHTML={{
											__html: siteParams.sedeLegaleIndirizzo,
										}}
									/>
								</div>
							</div>
							<div className='col-start-33 col-end-61 flex sm:hidden items-start'>
								<FeedatyButton isFooter={true} />
							</div>
							<div className='col-start-1 sm:col-start-33 md:col-start-26 lg:col-start-16 xl:col-start-14 3xl:col-start-11 col-end-61 md:col-end-46 lg:col-end-32 xl:col-end-28 3xl:col-end-25 flex flex-col justify-between mt-6 sm:mt-0'>
								<div className='text-sm'>
									<span className='footerTitle'>
										{mainNavigation.contatti.itemName.toUpperCase()}
									</span>
									<span className='block'>
										{genericheTranslation.telefono.itemName}:
										{siteParams.telefono}
									</span>
									<span className='block'>Fax: {siteParams.fax}</span>
									<span className='block'>{siteParams.email}</span>
									<br />
									<span className='block'>
										{genericheTranslation.cfpiva.itemName} {siteParams.piva}
									</span>
									<span className='block'>
										{genericheTranslation.rea.itemName} (rea):
										<br />
										{siteParams.rea}
									</span>
									<span className='block'>
										{genericheTranslation.capitalesociale.itemName}:
										{siteParams.capitaleSociale}
									</span>
									<br />
									<br />
								</div>
								<div className='text-sm'>
									<span className='block text-ribola-congress-blue font-opensanssemibold'>
										{siteParams.sitoDominio_istituzionale_it}
									</span>
								</div>
							</div>
							<div className='col-start-1 md:col-start-46 lg:col-start-32 xl:col-start-28 3xl:col-start-25 col-end-61 lg:col-end-43 xl:col-end-39 3xl:col-end-35 flex flex-col justify-between mt-10 md:mt-0'>
								<div className='grid grid-cols-60 w-full h-full justify-between items-end'>
									<div className='col-span-32 md:col-span-60 text-sm h-full'>
										<span className='footerTitle'>
											{genericheTranslation.account.itemName.toUpperCase()}
										</span>
										{profiloOrRegistrazione}
										<a
											href={`${URLASSOLUTO}${paginestaticheTranslation.privacypolicy.itemLink}`}
											onClick={onClickPrivacy}
											className='block underline'>
											{paginestaticheTranslation.privacypolicy.itemName}
										</a>
										<a
											href={`${URLASSOLUTO}${paginestaticheTranslation.cookiespolicy.itemLink}`}
											onClick={onClickCookies}
											className='block underline'>
											{paginestaticheTranslation.cookiespolicy.itemName}
										</a>
									</div>
									<div className='col-span-28 md:col-span-60 text-sm h-full'>
										<span className='footerTitle'>
											{genericheTranslation.servizioclienti.itemName.toUpperCase()}
										</span>
										<a
											href={`${URLASSOLUTO}${paginestaticheTranslation.condizionivendita.itemLink}`}
											onClick={onClickCondizioniVendita}
											className='block underline'>
											{paginestaticheTranslation.condizionivendita.itemName}
										</a>
										<a
											href={`${URLASSOLUTO}${mainNavigation.contatti.itemLink}`}
											onClick={onClickContatti}
											className='block underline'>
											{genericheTranslation.richiediassistenza.itemName}
										</a>
									</div>
								</div>
							</div>
							<div className='col-start-1 lg:col-start-45 xl:col-start-39 3xl:col-start-35 col-end-61 flex justify-between lg:flex-col lg:justify-between mt-10 lg:mt-0'>
								<div className='grid grid-cols-60 w-full h-full justify-between items-end'>
									<div className='col-span-60 sm:col-span-32 md:col-span-25 lg:col-span-60 text-sm h-full'>
										<span className='footerTitle'>
											{genericheTranslation.pagamentisicuricon.itemName.toUpperCase()}
										</span>
										<div className='text-ribola-astral'>
											<i
												className='fab fa-cc-visa text-4xl mr-4'
												title='VISA'></i>
											<i
												className='fab fa-cc-mastercard text-4xl mr-4'
												title='MASTERCARD'></i>
											<i
												className='fab fa-cc-stripe text-4xl mr-4'
												title='STRIPE'></i>
											<i
												className='fab fa-cc-paypal text-4xl mr-4'
												title='PAYPAL'></i>
											<i
												className='fal fa-university text-4xl mr-4'
												title={genericheTranslation.bonifico.itemName.toUpperCase()}></i>
											<i
												className='fal fa-money-bill-wave text-4xl mr-4'
												title={genericheTranslation.contrassegno.itemName.toUpperCase()}></i>
										</div>
									</div>
									<div className='col-span-60 sm:col-span-32 lg:col-span-60 text-sm flex flex-col flex-wrap mt-4 2xl:-mt-4'>
										<BloccoIconeFooter />
									</div>
									<div className='col-span-60 sm:col-span-28 md:col-span-35 lg:col-span-60 text-sm flex flex-col 3xl:flex-row justify-start items-start 3xl:justify-between 3xl:items-end mt-2 sm:mt-4 xl:mt-0'>
										<div className=''>
											<span className='footerTitle'>
												{genericheTranslation.spedizioniceleri.itemName.toUpperCase()}
												<i className='fal fa-shipping-fast text-2xl ml-2'></i>
											</span>
											<span className='block'>
												{
													genericheTranslation.spedizioniconcorriereespresso
														.itemName
												}
											</span>
										</div>
										<div className='mt-3 3xl:mt-0'>{siteParams.webAgency}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Footer;
