const BASENAME_IT = process.env.MIX_BASENAME_IT;
const BASENAME_EN = process.env.MIX_BASENAME_EN;
const BASENAME_FR = process.env.MIX_BASENAME_FR;
const BASENAME_DE = process.env.MIX_BASENAME_DE;

export let BASENAME;

export default (initialData) => {
	if (initialData[0]['siteParams']['current_language'] === 'it') {
		BASENAME = BASENAME_IT;
	}
	if (initialData[0]['siteParams']['current_language'] === 'en') {
		BASENAME = BASENAME_EN;
	}
	if (initialData[0]['siteParams']['current_language'] === 'fr') {
		BASENAME = BASENAME_FR;
	}
	if (initialData[0]['siteParams']['current_language'] === 'de') {
		BASENAME = BASENAME_DE;
	}

	return BASENAME;
};
