import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import Briciole from '../shared/briciole/Briciole';
import LazyLoadPicture from '../shared/lazyLoadPicture/LazyLoadPicture';

const LenostreretiDetail = () => {
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const siteParams = useSelector((state) => state.siteParams);
	const paginestaticheTranslation = useSelector(
		(state) => state.translations.paginestatiche
	);

	// local state

	useEffect(() => {}, []);

	// controllo che esista la proprietà detail (altrimenti vuol dire che sto ancora recuperando i dati)
	if (!pageContent.detail) {
		return <div></div>;
	}

	const imgWidth = {
		xl4: '1320',
		xl3: '1188',
		xl2: '1188',
		xl: '981',
		lg: '785',
		md: '768',
		sm: '640',
		xs: '480',
		xxs: '480',
	};

	const imgHeight = {
		xl4: '531',
		xl3: '478',
		xl2: '478',
		xl: '395',
		lg: '316',
		md: '309',
		sm: '257',
		xs: '193',
		xxs: '193',
	};

	return (
		<div className='col-start-1 lg:col-start-15 4xl:col-start-16 col-end-61 3xl:col-end-59 4xl:col-end-57 sm:pb-12'>
			<Briciole />
			<div className='grid grid-cols-60 mt-0 sm:mt-3 xl:mt-6'>
				<div className='col-span-60 flex justify-between items-center px-8 xl:px-12 3xl:px-20 xl:pt-8 pb-6 sm:pb-8 xl:pb-12 3xl:py-14'>
					<h1 className='text-ribola-congress-blue text-3xl md:text-4xl xl:text-5xl font-opensansbold'>
						<small className='leading-tight flex'>
							{paginestaticheTranslation.titololenostrereti.itemName.toUpperCase()}
						</small>
					</h1>
				</div>
				<div className='col-span-60 px-8 xl:px-12 3xl:px-20 pb-8 xl:pb-12'>
					<p
						className='text-sm'
						dangerouslySetInnerHTML={{
							__html: paginestaticheTranslation.testolenostrereti.itemName,
						}}></p>
				</div>
				<div className='col-span-60 grid justify-items-center items-center sm:mb-8 xl:mb-12'>
					<LazyLoadPicture
						imgName='lenostrereti.webp'
						imgPath='statiche'
						imgRoute='immagini-responsive-type'
						imgType='fitnoresizeToWebp'
						imgWidth={imgWidth}
						imgHeight={imgHeight}
						imgStyle='flex-start max-w-none lg:max-w-full w-full lg:w-auto'
						pictureStyle='gridArea11'
						imgAlt='Le nostre reti'
					/>
				</div>
			</div>
		</div>
	);
};

/***
 * 
 * 						imgRoute='immagini-responsive-type'
						imgType='fitnoresizeToWebp'
 */

export default LenostreretiDetail;
