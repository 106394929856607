import {
	IS_FETCHING,
	IS_LOADING,
	CHANGE_PAGE,
	AUTHENTICATE_USER,
	FETCH_HOMEPAGE,
	LOAD_HOMEPAGE,
	FETCH_NETS_DETAIL,
	LOAD_NETS_DETAIL,
	FETCH_PARENT_CATEGORY_DETAIL,
	LOAD_PARENT_CATEGORY_DETAIL,
	FETCH_CATEGORY_DETAIL,
	LOAD_CATEGORY_DETAIL,
	FETCH_PRODUCT_DETAIL,
	LOAD_PRODUCT_DETAIL,
	FETCH_CART_DETAIL,
	LOAD_CART_DETAIL,
	FETCH_REGISTRATION_DETAIL,
	LOAD_REGISTRATION_DETAIL,
	FETCH_REGISTRATION_FORM_DETAIL,
	LOAD_REGISTRATION_FORM_DETAIL,
	FETCH_PAYMENT_DETAIL,
	LOAD_PAYMENT_DETAIL,
	FETCH_CHECKOUT_DETAIL,
	LOAD_CHECKOUT_DETAIL,
	FETCH_ORDER_CLOSED_DETAIL,
	LOAD_ORDER_CLOSED_DETAIL,
	FETCH_PROFILE_DETAIL,
	LOAD_PROFILE_DETAIL,
	FETCH_ADDRESSES_DETAIL,
	LOAD_ADDRESSES_DETAIL,
	FETCH_ORDERS_DETAIL,
	LOAD_ORDERS_DETAIL,
	FETCH_CHISIAMO_DETAIL,
	LOAD_CHISIAMO_DETAIL,
	FETCH_LENOSTRERETI_DETAIL,
	LOAD_LENOSTRERETI_DETAIL,
	FETCH_CONTATTI_DETAIL,
	LOAD_CONTATTI_DETAIL,
	FETCH_CONTATTI_OK_DETAIL,
	LOAD_CONTATTI_OK_DETAIL,
	FETCH_CONTATTI_KO_DETAIL,
	LOAD_CONTATTI_KO_DETAIL,
	FETCH_CONDIZIONI_VENDITA_DETAIL,
	LOAD_CONDIZIONI_VENDITA_DETAIL,
	FETCH_PRIVACY_POLICY_DETAIL,
	LOAD_PRIVACY_POLICY_DETAIL,
	FETCH_COOKIES_POLICY_DETAIL,
	LOAD_COOKIES_POLICY_DETAIL,
	FETCH_MAGAZINE_DETAIL,
	LOAD_MAGAZINE_DETAIL,
	FETCH_MAGAZINE_CATEGORY_DETAIL,
	LOAD_MAGAZINE_CATEGORY_DETAIL,
	FETCH_MAGAZINE_ARTICLE_DETAIL,
	LOAD_MAGAZINE_ARTICLE_DETAIL,
	FETCH_SEARCH_DETAIL,
	FETCH_404_DETAIL,
	LOAD_404_DETAIL,
	REGISTER_USER,
	LOGIN_USER,
	LOGOUT_USER,
	SEND_CONTATTI,
	SEND_RICHIEDI_INFO_PRODOTTO,
} from '../actions/types';

const INITIAL_STATE = {
	device: '',
	pageName: '',
	routeName: '',
	pageUrlSeo: '',
	pageLinkSeo: '',
	pageLinkSeo_it: '',
	pageLinkSeo_en: '',
	pageLinkSeo_fr: '',
	pageLinkSeo_de: '',
	pageHeader: {
		type: '',
		images: {},
	},
	pageContent: {
		metaTitle: '',
		metaDesc: '',
	},
	isFetching: false,
	isLoading: false,
	isAuthenticated: false,
	isLogging: null,
	status: '',
	message: '',
};

export const currentPageReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case IS_FETCHING:
			state.isFetching = true;
			return state;

		case IS_LOADING:
			state.isLoading = true;
			return state;

		case AUTHENTICATE_USER:
			state.isAuthenticated = true;
			return state;

		case LOGIN_USER:
		case REGISTER_USER:
			state.isLogging = true;
			return state;

		case LOGOUT_USER:
			state.isLogging = false;
			return state;

		case CHANGE_PAGE:
			const { routeName, pageLinkSeo } = action.payload;

			state.pageName = '';
			state.routeName = routeName;
			state.pageUrlSeo = '';
			state.pageLinkSeo = pageLinkSeo;
			state.pageHeader.type = '';
			state.pageHeader.images = {};
			state.pageContent = {};
			return state;

		case FETCH_HOMEPAGE:
			const { homepage } = action.payload;

			state.pageName = 'Homepage';
			state.routeName = 'homepage';
			state.pageUrlSeo = '/';
			state.pageLinkSeo = '/';
			state.pageLinkSeo_it = '/';
			state.pageLinkSeo_en = '/';
			state.pageLinkSeo_fr = '/';
			state.pageLinkSeo_de = '/';
			state.pageContent.metaTitle = homepage?.metaTitle;
			state.pageContent.metaDesc = homepage?.metaDesc;
			state.pageHeader.type = 'slideshow';
			state.pageHeader.images = homepage.headerStatic[0].images;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_HOMEPAGE:
			const { pageHeader, metaTitle, metaDesc } = action.payload;

			state.pageName = 'Homepage';
			state.routeName = 'homepage';
			state.pageUrlSeo = '/';
			state.pageLinkSeo = '/';
			state.pageLinkSeo_it = '/';
			state.pageLinkSeo_en = '/';
			state.pageLinkSeo_fr = '/';
			state.pageLinkSeo_de = '/';
			state.pageHeader = pageHeader;
			state.pageContent.metaTitle = metaTitle;
			state.pageContent.metaDesc = metaDesc;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_NETS_DETAIL:
			const { nets } = action.payload;

			state.pageName = nets.name;
			state.routeName = nets.routeName;
			state.pageUrlSeo = nets.urlSeo;
			state.pageLinkSeo = nets.linkSeo;
			state.pageLinkSeo_it = nets.linkSeo_it;
			state.pageLinkSeo_en = nets.linkSeo_en;
			state.pageLinkSeo_fr = nets.linkSeo_fr;
			state.pageLinkSeo_de = nets.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = nets.imageHeader;
			state.pageContent = nets;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_NETS_DETAIL:
			const { netsLoaded } = action.payload;

			state.pageName = netsLoaded.name;
			state.routeName = netsLoaded.routeName;
			state.pageUrlSeo = netsLoaded.urlSeo;
			state.pageLinkSeo = netsLoaded.linkSeo;
			state.pageLinkSeo_it = netsLoaded.linkSeo_it;
			state.pageLinkSeo_en = netsLoaded.linkSeo_en;
			state.pageLinkSeo_fr = netsLoaded.linkSeo_fr;
			state.pageLinkSeo_de = netsLoaded.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = netsLoaded.imageHeader;
			state.pageContent = netsLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_PARENT_CATEGORY_DETAIL:
			const { parentCategory } = action.payload;

			state.pageName = parentCategory.name;
			state.routeName = parentCategory.routeName;
			state.pageUrlSeo = parentCategory.urlSeo;
			state.pageLinkSeo = parentCategory.linkSeo;
			state.pageLinkSeo_it = parentCategory.linkSeo_it;
			state.pageLinkSeo_en = parentCategory.linkSeo_en;
			state.pageLinkSeo_fr = parentCategory.linkSeo_fr;
			state.pageLinkSeo_de = parentCategory.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = parentCategory.imageHeader;
			state.pageContent = parentCategory;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_PARENT_CATEGORY_DETAIL:
			const { parentCategoryLoaded } = action.payload;

			state.pageName = parentCategoryLoaded.name;
			state.routeName = parentCategoryLoaded.routeName;
			state.pageUrlSeo = parentCategoryLoaded.urlSeo;
			state.pageLinkSeo = parentCategoryLoaded.linkSeo;
			state.pageLinkSeo_it = parentCategoryLoaded.linkSeo_it;
			state.pageLinkSeo_en = parentCategoryLoaded.linkSeo_en;
			state.pageLinkSeo_fr = parentCategoryLoaded.linkSeo_fr;
			state.pageLinkSeo_de = parentCategoryLoaded.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = parentCategoryLoaded.imageHeader;
			state.pageContent = parentCategoryLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_CATEGORY_DETAIL:
			const { category } = action.payload;

			state.pageName = category.name;
			state.routeName = category.routeName;
			state.pageUrlSeo = category.urlSeo;
			state.pageLinkSeo = category.linkSeo;
			state.pageLinkSeo_it = category.linkSeo_it;
			state.pageLinkSeo_en = category.linkSeo_en;
			state.pageLinkSeo_fr = category.linkSeo_fr;
			state.pageLinkSeo_de = category.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = category.imageHeader;
			state.pageContent = category;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_CATEGORY_DETAIL:
			const { categoryLoaded } = action.payload;

			state.pageName = categoryLoaded.name;
			state.routeName = categoryLoaded.routeName;
			state.pageUrlSeo = categoryLoaded.urlSeo;
			state.pageLinkSeo = categoryLoaded.linkSeo;
			state.pageLinkSeo_it = categoryLoaded.linkSeo_it;
			state.pageLinkSeo_en = categoryLoaded.linkSeo_en;
			state.pageLinkSeo_fr = categoryLoaded.linkSeo_fr;
			state.pageLinkSeo_de = categoryLoaded.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = categoryLoaded.imageHeader;
			state.pageContent = categoryLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_PRODUCT_DETAIL:
			const { product } = action.payload;

			state.pageName = product.name;
			state.routeName = product.routeName;
			state.pageUrlSeo = product.urlSeo;
			state.pageLinkSeo = product.linkSeo;
			state.pageLinkSeo_it = product.linkSeo_it;
			state.pageLinkSeo_en = product.linkSeo_en;
			state.pageLinkSeo_fr = product.linkSeo_fr;
			state.pageLinkSeo_de = product.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = product.imageHeader;
			state.pageContent = product;
			state.isFetching = false;
			state.isLoading = false;
			state.isLogging = null;
			return state;

		case LOAD_PRODUCT_DETAIL:
			const { productLoaded } = action.payload;

			state.pageName = productLoaded.name;
			state.routeName = productLoaded.routeName;
			state.pageUrlSeo = productLoaded.urlSeo;
			state.pageLinkSeo = productLoaded.linkSeo;
			state.pageLinkSeo_it = productLoaded.linkSeo_it;
			state.pageLinkSeo_en = productLoaded.linkSeo_en;
			state.pageLinkSeo_fr = productLoaded.linkSeo_fr;
			state.pageLinkSeo_de = productLoaded.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = productLoaded.imageHeader;
			state.pageContent = productLoaded;
			state.isFetching = false;
			state.isLoading = false;
			state.isLogging = null;
			return state;

		case FETCH_CART_DETAIL:
			const { cart } = action.payload;

			state.pageName = cart.name;
			state.routeName = cart.routeName;
			state.pageUrlSeo = cart.urlSeo;
			state.pageLinkSeo = cart.linkSeo;
			state.pageLinkSeo_it = '/';
			state.pageLinkSeo_en = '/';
			state.pageLinkSeo_fr = '/';
			state.pageLinkSeo_de = '/';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = cart.imageHeader;
			state.pageContent = cart;
			state.isFetching = false;
			state.isLoading = false;
			state.isLogging = null;
			return state;

		case LOAD_CART_DETAIL:
			const { cartLoaded } = action.payload;

			state.pageName = cartLoaded.name;
			state.routeName = cartLoaded.routeName;
			state.pageUrlSeo = cartLoaded.urlSeo;
			state.pageLinkSeo = cartLoaded.linkSeo;
			state.pageLinkSeo_it = '/';
			state.pageLinkSeo_en = '/';
			state.pageLinkSeo_fr = '/';
			state.pageLinkSeo_de = '/';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = cartLoaded.imageHeader;
			state.pageContent = cartLoaded;
			state.isFetching = false;
			state.isLoading = false;
			state.isLogging = null;
			return state;

		case FETCH_REGISTRATION_DETAIL:
			const { registration } = action.payload;

			state.pageName = registration.name;
			state.routeName = registration.routeName;
			state.pageUrlSeo = registration.urlSeo;
			state.pageLinkSeo = registration.linkSeo;
			state.pageLinkSeo_it = '/';
			state.pageLinkSeo_en = '/';
			state.pageLinkSeo_fr = '/';
			state.pageLinkSeo_de = '/';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = registration.imageHeader;
			state.pageContent = registration;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_REGISTRATION_DETAIL:
			const { registrationLoaded } = action.payload;

			state.pageName = registrationLoaded.name;
			state.routeName = registrationLoaded.routeName;
			state.pageUrlSeo = registrationLoaded.urlSeo;
			state.pageLinkSeo = registrationLoaded.linkSeo;
			state.pageLinkSeo_it = '/';
			state.pageLinkSeo_en = '/';
			state.pageLinkSeo_fr = '/';
			state.pageLinkSeo_de = '/';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = registrationLoaded.imageHeader;
			state.pageContent = registrationLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_REGISTRATION_FORM_DETAIL:
			const { registrationForm } = action.payload;

			state.pageName = registrationForm.name;
			state.routeName = registrationForm.routeName;
			state.pageUrlSeo = registrationForm.urlSeo;
			state.pageLinkSeo = registrationForm.linkSeo;
			state.pageLinkSeo_it = '/';
			state.pageLinkSeo_en = '/';
			state.pageLinkSeo_fr = '/';
			state.pageLinkSeo_de = '/';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = registrationForm.imageHeader;
			state.pageContent = registrationForm;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_REGISTRATION_FORM_DETAIL:
			const { registrationFormLoaded } = action.payload;

			state.pageName = registrationFormLoaded.name;
			state.routeName = registrationFormLoaded.routeName;
			state.pageUrlSeo = registrationFormLoaded.urlSeo;
			state.pageLinkSeo = registrationFormLoaded.linkSeo;
			state.pageLinkSeo_it = '/';
			state.pageLinkSeo_en = '/';
			state.pageLinkSeo_fr = '/';
			state.pageLinkSeo_de = '/';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = registrationFormLoaded.imageHeader;
			state.pageContent = registrationFormLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_PAYMENT_DETAIL:
			const { payment } = action.payload;

			state.pageName = payment.name;
			state.routeName = payment.routeName;
			state.pageUrlSeo = payment.urlSeo;
			state.pageLinkSeo = payment.linkSeo;
			state.pageLinkSeo_it = '/';
			state.pageLinkSeo_en = '/';
			state.pageLinkSeo_fr = '/';
			state.pageLinkSeo_de = '/';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = payment.imageHeader;
			state.pageContent = payment;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_PAYMENT_DETAIL:
			const { paymentLoaded } = action.payload;

			state.pageName = paymentLoaded.name;
			state.routeName = paymentLoaded.routeName;
			state.pageUrlSeo = paymentLoaded.urlSeo;
			state.pageLinkSeo = paymentLoaded.linkSeo;
			state.pageLinkSeo_it = '/';
			state.pageLinkSeo_en = '/';
			state.pageLinkSeo_fr = '/';
			state.pageLinkSeo_de = '/';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = paymentLoaded.imageHeader;
			state.pageContent = paymentLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_CHECKOUT_DETAIL:
			const { checkout } = action.payload;

			state.pageName = checkout.name;
			state.routeName = checkout.routeName;
			state.pageUrlSeo = checkout.urlSeo;
			state.pageLinkSeo = checkout.linkSeo;
			state.pageLinkSeo_it = '/';
			state.pageLinkSeo_en = '/';
			state.pageLinkSeo_fr = '/';
			state.pageLinkSeo_de = '/';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = checkout.imageHeader;
			state.pageContent = checkout;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_CHECKOUT_DETAIL:
			const { checkoutLoaded } = action.payload;

			state.pageName = checkoutLoaded.name;
			state.routeName = checkoutLoaded.routeName;
			state.pageUrlSeo = checkoutLoaded.urlSeo;
			state.pageLinkSeo = checkoutLoaded.linkSeo;
			state.pageLinkSeo_it = '/';
			state.pageLinkSeo_en = '/';
			state.pageLinkSeo_fr = '/';
			state.pageLinkSeo_de = '/';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = checkoutLoaded.imageHeader;
			state.pageContent = checkoutLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_ORDER_CLOSED_DETAIL:
			const { orderClosed } = action.payload;

			state.pageName = orderClosed.name;
			state.routeName = orderClosed.routeName;
			state.pageUrlSeo = orderClosed.urlSeo;
			state.pageLinkSeo = orderClosed.linkSeo;
			state.pageLinkSeo_it = '/';
			state.pageLinkSeo_en = '/';
			state.pageLinkSeo_fr = '/';
			state.pageLinkSeo_de = '/';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = orderClosed.imageHeader;
			state.pageContent = orderClosed;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_ORDER_CLOSED_DETAIL:
			const { orderClosedLoaded } = action.payload;

			state.pageName = orderClosedLoaded.name;
			state.routeName = orderClosedLoaded.routeName;
			state.pageUrlSeo = orderClosedLoaded.urlSeo;
			state.pageLinkSeo = orderClosedLoaded.linkSeo;
			state.pageLinkSeo_it = '/';
			state.pageLinkSeo_en = '/';
			state.pageLinkSeo_fr = '/';
			state.pageLinkSeo_de = '/';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = orderClosedLoaded.imageHeader;
			state.pageContent = orderClosedLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_PROFILE_DETAIL:
			const { profile } = action.payload;

			state.pageName = profile.name;
			state.routeName = profile.routeName;
			state.pageUrlSeo = profile.urlSeo;
			state.pageLinkSeo = profile.linkSeo;
			state.pageLinkSeo_it = '/';
			state.pageLinkSeo_en = '/';
			state.pageLinkSeo_fr = '/';
			state.pageLinkSeo_de = '/';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = profile.imageHeader;
			state.pageContent = profile;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_PROFILE_DETAIL:
			const { profileLoaded } = action.payload;

			state.pageName = profileLoaded.name;
			state.routeName = profileLoaded.routeName;
			state.pageUrlSeo = profileLoaded.urlSeo;
			state.pageLinkSeo = profileLoaded.linkSeo;
			state.pageLinkSeo_it = '/';
			state.pageLinkSeo_en = '/';
			state.pageLinkSeo_fr = '/';
			state.pageLinkSeo_de = '/';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = profileLoaded.imageHeader;
			state.pageContent = profileLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_ADDRESSES_DETAIL:
			const { addresses } = action.payload;

			state.pageName = addresses.name;
			state.routeName = addresses.routeName;
			state.pageUrlSeo = addresses.urlSeo;
			state.pageLinkSeo = addresses.linkSeo;
			state.pageLinkSeo_it = '/';
			state.pageLinkSeo_en = '/';
			state.pageLinkSeo_fr = '/';
			state.pageLinkSeo_de = '/';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = addresses.imageHeader;
			state.pageContent = addresses;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_ADDRESSES_DETAIL:
			const { addressesLoaded } = action.payload;

			state.pageName = addressesLoaded.name;
			state.routeName = addressesLoaded.routeName;
			state.pageUrlSeo = addressesLoaded.urlSeo;
			state.pageLinkSeo = addressesLoaded.linkSeo;
			state.pageLinkSeo_it = '/';
			state.pageLinkSeo_en = '/';
			state.pageLinkSeo_fr = '/';
			state.pageLinkSeo_de = '/';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = addressesLoaded.imageHeader;
			state.pageContent = addressesLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_ORDERS_DETAIL:
			const { orders } = action.payload;

			state.pageName = orders.name;
			state.routeName = orders.routeName;
			state.pageUrlSeo = orders.urlSeo;
			state.pageLinkSeo = orders.linkSeo;
			state.pageLinkSeo_it = '/';
			state.pageLinkSeo_en = '/';
			state.pageLinkSeo_fr = '/';
			state.pageLinkSeo_de = '/';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = orders.imageHeader;
			state.pageContent = orders;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_ORDERS_DETAIL:
			const { ordersLoaded } = action.payload;

			state.pageName = ordersLoaded.name;
			state.routeName = ordersLoaded.routeName;
			state.pageUrlSeo = ordersLoaded.urlSeo;
			state.pageLinkSeo = ordersLoaded.linkSeo;
			state.pageLinkSeo_it = '/';
			state.pageLinkSeo_en = '/';
			state.pageLinkSeo_fr = '/';
			state.pageLinkSeo_de = '/';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = ordersLoaded.imageHeader;
			state.pageContent = ordersLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_CHISIAMO_DETAIL:
			const { chisiamo } = action.payload;

			state.pageName = chisiamo.name;
			state.routeName = chisiamo.routeName;
			state.pageUrlSeo = chisiamo.urlSeo;
			state.pageLinkSeo = chisiamo.linkSeo;
			state.pageLinkSeo_it = chisiamo.linkSeo_it;
			state.pageLinkSeo_en = chisiamo.linkSeo_en;
			state.pageLinkSeo_fr = chisiamo.linkSeo_fr;
			state.pageLinkSeo_de = chisiamo.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = chisiamo.imageHeader;
			state.pageContent = chisiamo;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_CHISIAMO_DETAIL:
			const { chisiamoLoaded } = action.payload;

			state.pageName = chisiamoLoaded.name;
			state.routeName = chisiamoLoaded.routeName;
			state.pageUrlSeo = chisiamoLoaded.urlSeo;
			state.pageLinkSeo = chisiamoLoaded.linkSeo;
			state.pageLinkSeo_it = chisiamoLoaded.linkSeo_it;
			state.pageLinkSeo_en = chisiamoLoaded.linkSeo_en;
			state.pageLinkSeo_fr = chisiamoLoaded.linkSeo_fr;
			state.pageLinkSeo_de = chisiamoLoaded.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = chisiamoLoaded.imageHeader;
			state.pageContent = chisiamoLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_LENOSTRERETI_DETAIL:
			const { lenostrereti } = action.payload;

			state.pageName = lenostrereti.name;
			state.routeName = lenostrereti.routeName;
			state.pageUrlSeo = lenostrereti.urlSeo;
			state.pageLinkSeo = lenostrereti.linkSeo;
			state.pageLinkSeo_it = lenostrereti.linkSeo_it;
			state.pageLinkSeo_en = lenostrereti.linkSeo_en;
			state.pageLinkSeo_fr = lenostrereti.linkSeo_fr;
			state.pageLinkSeo_de = lenostrereti.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = lenostrereti.imageHeader;
			state.pageContent = lenostrereti;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_LENOSTRERETI_DETAIL:
			const { lenostreretiLoaded } = action.payload;

			state.pageName = lenostreretiLoaded.name;
			state.routeName = lenostreretiLoaded.routeName;
			state.pageUrlSeo = lenostreretiLoaded.urlSeo;
			state.pageLinkSeo = lenostreretiLoaded.linkSeo;
			state.pageLinkSeo_it = lenostreretiLoaded.linkSeo_it;
			state.pageLinkSeo_en = lenostreretiLoaded.linkSeo_en;
			state.pageLinkSeo_fr = lenostreretiLoaded.linkSeo_fr;
			state.pageLinkSeo_de = lenostreretiLoaded.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = lenostreretiLoaded.imageHeader;
			state.pageContent = lenostreretiLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_CONTATTI_DETAIL:
			const { contatti } = action.payload;

			state.pageName = contatti.name;
			state.routeName = contatti.routeName;
			state.pageUrlSeo = contatti.urlSeo;
			state.pageLinkSeo = contatti.linkSeo;
			state.pageLinkSeo_it = contatti.linkSeo_it;
			state.pageLinkSeo_en = contatti.linkSeo_en;
			state.pageLinkSeo_fr = contatti.linkSeo_fr;
			state.pageLinkSeo_de = contatti.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = contatti.imageHeader;
			state.pageContent = contatti;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_CONTATTI_DETAIL:
			const { contattiLoaded } = action.payload;

			state.pageName = contattiLoaded.name;
			state.routeName = contattiLoaded.routeName;
			state.pageUrlSeo = contattiLoaded.urlSeo;
			state.pageLinkSeo = contattiLoaded.linkSeo;
			state.pageLinkSeo_it = contattiLoaded.linkSeo_it;
			state.pageLinkSeo_en = contattiLoaded.linkSeo_en;
			state.pageLinkSeo_fr = contattiLoaded.linkSeo_fr;
			state.pageLinkSeo_de = contattiLoaded.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = contattiLoaded.imageHeader;
			state.pageContent = contattiLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_CONTATTI_OK_DETAIL:
			const { contattiok } = action.payload;

			state.pageName = contattiok.name;
			state.routeName = contattiok.routeName;
			state.pageUrlSeo = contattiok.urlSeo;
			state.pageLinkSeo = contattiok.linkSeo;
			state.pageLinkSeo_it = contattiok.linkSeo_it;
			state.pageLinkSeo_en = contattiok.linkSeo_en;
			state.pageLinkSeo_fr = contattiok.linkSeo_fr;
			state.pageLinkSeo_de = contattiok.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = contattiok.imageHeader;
			state.pageContent = contattiok;
			state.isFetching = false;
			state.isLoading = false;
			state.status = '';
			state.message = '';
			return state;

		case LOAD_CONTATTI_OK_DETAIL:
			const { contattiokLoaded } = action.payload;

			state.pageName = contattiokLoaded.name;
			state.routeName = contattiokLoaded.routeName;
			state.pageUrlSeo = contattiokLoaded.urlSeo;
			state.pageLinkSeo = contattiokLoaded.linkSeo;
			state.pageLinkSeo_it = contattiokLoaded.linkSeo_it;
			state.pageLinkSeo_en = contattiokLoaded.linkSeo_en;
			state.pageLinkSeo_fr = contattiokLoaded.linkSeo_fr;
			state.pageLinkSeo_de = contattiokLoaded.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = contattiokLoaded.imageHeader;
			state.pageContent = contattiokLoaded;
			state.isFetching = false;
			state.isLoading = false;
			state.status = '';
			state.message = '';
			return state;

		case FETCH_CONTATTI_KO_DETAIL:
			const { contattiko } = action.payload;

			state.pageName = contattiko.name;
			state.routeName = contattiko.routeName;
			state.pageUrlSeo = contattiko.urlSeo;
			state.pageLinkSeo = contattiko.linkSeo;
			state.pageLinkSeo_it = contattiko.linkSeo_it;
			state.pageLinkSeo_en = contattiko.linkSeo_en;
			state.pageLinkSeo_fr = contattiko.linkSeo_fr;
			state.pageLinkSeo_de = contattiko.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = contattiko.imageHeader;
			state.pageContent = contattiko;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_CONTATTI_KO_DETAIL:
			const { contattikoLoaded } = action.payload;

			state.pageName = contattikoLoaded.name;
			state.routeName = contattikoLoaded.routeName;
			state.pageUrlSeo = contattikoLoaded.urlSeo;
			state.pageLinkSeo = contattikoLoaded.linkSeo;
			state.pageLinkSeo_it = contattikoLoaded.linkSeo_it;
			state.pageLinkSeo_en = contattikoLoaded.linkSeo_en;
			state.pageLinkSeo_fr = contattikoLoaded.linkSeo_fr;
			state.pageLinkSeo_de = contattikoLoaded.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = contattikoLoaded.imageHeader;
			state.pageContent = contattikoLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_CONDIZIONI_VENDITA_DETAIL:
			const { condizionivendita } = action.payload;

			state.pageName = condizionivendita.name;
			state.routeName = condizionivendita.routeName;
			state.pageUrlSeo = condizionivendita.urlSeo;
			state.pageLinkSeo = condizionivendita.linkSeo;
			state.pageLinkSeo_it = condizionivendita.linkSeo_it;
			state.pageLinkSeo_en = condizionivendita.linkSeo_en;
			state.pageLinkSeo_fr = condizionivendita.linkSeo_fr;
			state.pageLinkSeo_de = condizionivendita.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = condizionivendita.imageHeader;
			state.pageContent = condizionivendita;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_CONDIZIONI_VENDITA_DETAIL:
			const { condizionivenditaLoaded } = action.payload;

			state.pageName = condizionivenditaLoaded.name;
			state.routeName = condizionivenditaLoaded.routeName;
			state.pageUrlSeo = condizionivenditaLoaded.urlSeo;
			state.pageLinkSeo = condizionivenditaLoaded.linkSeo;
			state.pageLinkSeo_it = condizionivenditaLoaded.linkSeo_it;
			state.pageLinkSeo_en = condizionivenditaLoaded.linkSeo_en;
			state.pageLinkSeo_fr = condizionivenditaLoaded.linkSeo_fr;
			state.pageLinkSeo_de = condizionivenditaLoaded.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = condizionivenditaLoaded.imageHeader;
			state.pageContent = condizionivenditaLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_PRIVACY_POLICY_DETAIL:
			const { privacypolicy } = action.payload;

			state.pageName = privacypolicy.name;
			state.routeName = privacypolicy.routeName;
			state.pageUrlSeo = privacypolicy.urlSeo;
			state.pageLinkSeo = privacypolicy.linkSeo;
			state.pageLinkSeo_it = privacypolicy.linkSeo_it;
			state.pageLinkSeo_en = privacypolicy.linkSeo_en;
			state.pageLinkSeo_fr = privacypolicy.linkSeo_fr;
			state.pageLinkSeo_de = privacypolicy.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = privacypolicy.imageHeader;
			state.pageContent = privacypolicy;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_PRIVACY_POLICY_DETAIL:
			const { privacypolicyLoaded } = action.payload;

			state.pageName = privacypolicyLoaded.name;
			state.routeName = privacypolicyLoaded.routeName;
			state.pageUrlSeo = privacypolicyLoaded.urlSeo;
			state.pageLinkSeo = privacypolicyLoaded.linkSeo;
			state.pageLinkSeo_it = privacypolicyLoaded.linkSeo_it;
			state.pageLinkSeo_en = privacypolicyLoaded.linkSeo_en;
			state.pageLinkSeo_fr = privacypolicyLoaded.linkSeo_fr;
			state.pageLinkSeo_de = privacypolicyLoaded.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = privacypolicyLoaded.imageHeader;
			state.pageContent = privacypolicyLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_COOKIES_POLICY_DETAIL:
			const { cookiespolicy } = action.payload;

			state.pageName = cookiespolicy.name;
			state.routeName = cookiespolicy.routeName;
			state.pageUrlSeo = cookiespolicy.urlSeo;
			state.pageLinkSeo = cookiespolicy.linkSeo;
			state.pageLinkSeo_it = cookiespolicy.linkSeo_it;
			state.pageLinkSeo_en = cookiespolicy.linkSeo_en;
			state.pageLinkSeo_fr = cookiespolicy.linkSeo_fr;
			state.pageLinkSeo_de = cookiespolicy.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = cookiespolicy.imageHeader;
			state.pageContent = cookiespolicy;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_COOKIES_POLICY_DETAIL:
			const { cookiespolicyLoaded } = action.payload;

			state.pageName = cookiespolicyLoaded.name;
			state.routeName = cookiespolicyLoaded.routeName;
			state.pageUrlSeo = cookiespolicyLoaded.urlSeo;
			state.pageLinkSeo = cookiespolicyLoaded.linkSeo;
			state.pageLinkSeo_it = cookiespolicyLoaded.linkSeo_it;
			state.pageLinkSeo_en = cookiespolicyLoaded.linkSeo_en;
			state.pageLinkSeo_fr = cookiespolicyLoaded.linkSeo_fr;
			state.pageLinkSeo_de = cookiespolicyLoaded.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = cookiespolicyLoaded.imageHeader;
			state.pageContent = cookiespolicyLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_MAGAZINE_DETAIL:
			const { magazine } = action.payload;

			state.pageName = magazine.name;
			state.routeName = magazine.routeName;
			state.pageUrlSeo = magazine.urlSeo;
			state.pageLinkSeo = magazine.linkSeo;
			state.pageLinkSeo_it = magazine.linkSeo_it;
			state.pageLinkSeo_en = magazine.linkSeo_en;
			state.pageLinkSeo_fr = magazine.linkSeo_fr;
			state.pageLinkSeo_de = magazine.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = magazine.imageHeader;
			state.pageContent = magazine;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_MAGAZINE_DETAIL:
			const { magazineLoaded } = action.payload;

			state.pageName = magazineLoaded.name;
			state.routeName = magazineLoaded.routeName;
			state.pageUrlSeo = magazineLoaded.urlSeo;
			state.pageLinkSeo = magazineLoaded.linkSeo;
			state.pageLinkSeo_it = magazineLoaded.linkSeo_it;
			state.pageLinkSeo_en = magazineLoaded.linkSeo_en;
			state.pageLinkSeo_fr = magazineLoaded.linkSeo_fr;
			state.pageLinkSeo_de = magazineLoaded.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = magazineLoaded.imageHeader;
			state.pageContent = magazineLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_MAGAZINE_CATEGORY_DETAIL:
			const { magazineCategory } = action.payload;

			state.pageName = magazineCategory.name;
			state.routeName = magazineCategory.routeName;
			state.pageUrlSeo = magazineCategory.urlSeo;
			state.pageLinkSeo = magazineCategory.linkSeo;
			state.pageLinkSeo_it = magazineCategory.linkSeo_it;
			state.pageLinkSeo_en = magazineCategory.linkSeo_en;
			state.pageLinkSeo_fr = magazineCategory.linkSeo_fr;
			state.pageLinkSeo_de = magazineCategory.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = magazineCategory.imageHeader;
			state.pageContent = magazineCategory;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_MAGAZINE_CATEGORY_DETAIL:
			const { magazineCategoryLoaded } = action.payload;

			state.pageName = magazineCategoryLoaded.name;
			state.routeName = magazineCategoryLoaded.routeName;
			state.pageUrlSeo = magazineCategoryLoaded.urlSeo;
			state.pageLinkSeo = magazineCategoryLoaded.linkSeo;
			state.pageLinkSeo_it = magazineCategoryLoaded.linkSeo_it;
			state.pageLinkSeo_en = magazineCategoryLoaded.linkSeo_en;
			state.pageLinkSeo_fr = magazineCategoryLoaded.linkSeo_fr;
			state.pageLinkSeo_de = magazineCategoryLoaded.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = magazineCategoryLoaded.imageHeader;
			state.pageContent = magazineCategoryLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_MAGAZINE_ARTICLE_DETAIL:
			const { magazineArticle } = action.payload;

			state.pageName = magazineArticle.name;
			state.routeName = magazineArticle.routeName;
			state.pageUrlSeo = magazineArticle.urlSeo;
			state.pageLinkSeo = magazineArticle.linkSeo;
			state.pageLinkSeo_it = magazineArticle.linkSeo_it;
			state.pageLinkSeo_en = magazineArticle.linkSeo_en;
			state.pageLinkSeo_fr = magazineArticle.linkSeo_fr;
			state.pageLinkSeo_de = magazineArticle.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = magazineArticle.imageHeader;
			state.pageContent = magazineArticle;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_MAGAZINE_ARTICLE_DETAIL:
			const { magazineArticleLoaded } = action.payload;

			state.pageName = magazineArticleLoaded.name;
			state.routeName = magazineArticleLoaded.routeName;
			state.pageUrlSeo = magazineArticleLoaded.urlSeo;
			state.pageLinkSeo = magazineArticleLoaded.linkSeo;
			state.pageLinkSeo_it = magazineArticleLoaded.linkSeo_it;
			state.pageLinkSeo_en = magazineArticleLoaded.linkSeo_en;
			state.pageLinkSeo_fr = magazineArticleLoaded.linkSeo_fr;
			state.pageLinkSeo_de = magazineArticleLoaded.linkSeo_de;
			state.pageHeader.type = 'interna';
			state.pageHeader.images = magazineArticleLoaded.imageHeader;
			state.pageContent = magazineArticleLoaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_SEARCH_DETAIL:
			const { search } = action.payload;

			state.pageName = search.name;
			state.routeName = search.routeName;
			state.pageUrlSeo = search.urlSeo;
			state.pageLinkSeo = search.linkSeo;
			state.pageLinkSeo_it = '';
			state.pageLinkSeo_en = '';
			state.pageLinkSeo_fr = '';
			state.pageLinkSeo_de = '';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = search.imageHeader;
			state.pageContent = search;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case FETCH_404_DETAIL:
			const { error404 } = action.payload;

			state.pageName = error404.name;
			state.routeName = error404.routeName;
			state.pageUrlSeo = error404.urlSeo;
			state.pageLinkSeo = error404.linkSeo;
			state.pageLinkSeo_it = '';
			state.pageLinkSeo_en = '';
			state.pageLinkSeo_fr = '';
			state.pageLinkSeo_de = '';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = error404.imageHeader;
			state.pageContent = error404;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case LOAD_404_DETAIL:
			const { error404Loaded } = action.payload;

			state.pageName = error404Loaded.name;
			state.routeName = error404Loaded.routeName;
			state.pageUrlSeo = error404Loaded.urlSeo;
			state.pageLinkSeo = error404Loaded.linkSeo;
			state.pageLinkSeo_it = '';
			state.pageLinkSeo_en = '';
			state.pageLinkSeo_fr = '';
			state.pageLinkSeo_de = '';
			state.pageHeader.type = 'interna';
			state.pageHeader.images = error404Loaded.imageHeader;
			state.pageContent = error404Loaded;
			state.isFetching = false;
			state.isLoading = false;
			return state;

		case SEND_CONTATTI:
		case SEND_RICHIEDI_INFO_PRODOTTO:
			const { status, message } = action.payload;

			state.status = status;
			state.message = message;
			state.pageLinkSeo_it = '';
			state.pageLinkSeo_en = '';
			state.pageLinkSeo_fr = '';
			state.pageLinkSeo_de = '';
			return state;

		default:
			return state;
	}
};
