import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Briciole from '../shared/briciole/Briciole';
import ProductMainData from './productMainData/ProductMainData';
import ProductGallery from './productGallery/ProductGallery';
import ProductTabs from './productTabs/ProductTabs';
import ProductConfigurationMetriQuadri from './productConfigurationMetriQuadri/ProductConfigurationMetriQuadri';
import ProductConfigurationMetriCubi from './productConfigurationMetriCubi/ProductConfigurationMetriCubi';
import ProductConfigurationUnita from './productConfigurationUnita/ProductConfigurationUnita';
import ProductConfigurationCoppia from './productConfigurationCoppia/ProductConfigurationCoppia';
import ProductConfigurationMetriLineari from './productConfigurationMetriLineari/ProductConfigurationMetriLineari';
import ProductConfigurationTeloTondo from './productConfigurationTeloTondo/ProductConfigurationTeloTondo';
import ProductConfigurationTeloPiscina from './productConfigurationTeloPiscina/ProductConfigurationTeloPiscina';
import ProductConfigurationOutlet from './productConfigurationOutlet/ProductConfigurationOutlet';
import ProductRichiediInformazioni from './productRichiediInformazioni/ProductRichiediInformazioni';
import ProductSlideshowOtherProducts from './productSlideshowOtherProducts/ProductSlideshowOtherProducts';
import ProductSlideshowOutlet from './productSlideshowOutlet/ProductSlideshowOutlet';
import ProductSlideshowAccessories from './productSlideshowAccessories/ProductSlideshowAccessories';

const ProductDetail = () => {
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const promotion = useSelector((state) => state.promotion);

	// local state
	const [fixedPriceId, setFixedPriceId] = useState(0);

	useEffect(() => {
		return () => {
			//console.log('cleaned up prodotto');
		};
	}, [fixedPriceId]);

	// controllo che siano stati recuperati i dati del prodotto (altrimenti vuol dire che sto ancora recuperando i dati)
	if (!pageContent.isFetched || pageContent.isFetched === undefined) {
		return <div></div>;
	}

	const onUpdateMainData = (productFixedPriceId) => {
		// aggiorno prezzo fisso (se presente)
		setFixedPriceId(productFixedPriceId);
	};

	// controllo tipologia prodotto in base all'unità di misura e ad eventuali flags attivi
	let productTypeFields = '';

	// metri quadri
	if (pageContent.measure_unit_id === 1) {
		productTypeFields = (
			<ProductConfigurationMetriQuadri updateMainData={onUpdateMainData} />
		);
	}
	// prodotto venduto per quantità
	if (pageContent.measure_unit_id === 2) {
		productTypeFields = (
			<ProductConfigurationUnita updateMainData={onUpdateMainData} />
		);
	}
	// prodotto venduto a metri lineari
	if (pageContent.measure_unit_id === 3) {
		productTypeFields = (
			<ProductConfigurationMetriLineari updateMainData={onUpdateMainData} />
		);
	}
	// prodotto venduto a coppia
	if (pageContent.measure_unit_id === 4) {
		productTypeFields = (
			<ProductConfigurationCoppia updateMainData={onUpdateMainData} />
		);
	}
	// metri quadri + configuratore telo piscina
	if (
		pageContent.measure_unit_id === 1 &&
		pageContent.detail.flags?.flag_abilita_configuratore_telo_piscina === 1
	) {
		productTypeFields = (
			<ProductConfigurationTeloPiscina updateMainData={onUpdateMainData} />
		);
	}
	// diametro + configuratore telo tondo
	if (
		pageContent.measure_unit_id === 5 &&
		pageContent.detail.flags?.flag_abilita_configuratore_telo_tondo === 1
	) {
		productTypeFields = (
			<ProductConfigurationTeloTondo updateMainData={onUpdateMainData} />
		);
	}
	// metri cubi
	if (pageContent.measure_unit_id === 6) {
		productTypeFields = (
			<ProductConfigurationMetriCubi updateMainData={onUpdateMainData} />
		);
	}
	// prodotto non acquistabile (richiedi informazioni)
	if (pageContent.detail.flags?.flag_abilita_informazioni_prodotto === 1) {
		productTypeFields = (
			<ProductRichiediInformazioni key='richiediInformazioniProdotto' />
		);
	}
	// prodotto Pronta consegna (outlet)
	if (pageContent.isProntaConsegna) {
		productTypeFields = <ProductConfigurationOutlet />;
	}

	// stile diverso per la scheda outlet (senza padding)
	const detailStyle = pageContent.isProntaConsegna
		? ''
		: 'px-8 py-10 mt-0 sm:mt-4 xl:mt-0';

	return (
		<div className='col-start-1 lg:col-start-15 4xl:col-start-16 col-end-61 3xl:col-end-59 4xl:col-end-57 pb-12'>
			<Briciole />
			<div className='grid grid-cols-60 mt-6'>
				<div className='col-start-4 sm:col-start-1 col-end-58 sm:col-end-61 xl:col-end-35'>
					<ProductMainData prezzoFissoIndex={fixedPriceId} />
					<ProductGallery promotion={promotion} />
					<div className='grid grid-cols-60'>
						<div className='col-span-60 mt-3 mb-12 block xl:hidden'>
							<ProductTabs />
						</div>
					</div>
				</div>
				<div
					className={`col-start-1 xl:col-start-37 col-end-61 bg-ribola-gallery-gray ${detailStyle}`}>
					{productTypeFields}
				</div>
			</div>
			<div className='grid grid-cols-60'>
				<div className='col-span-60 mt-10 mb-12 hidden xl:block'>
					<ProductTabs />
				</div>
			</div>
			<div className='grid grid-cols-60'>
				<div className='col-span-60'>
					<ProductSlideshowOtherProducts />
				</div>
			</div>
			<div className='grid grid-cols-60'>
				<div className='col-span-60'>
					<ProductSlideshowOutlet />
				</div>
			</div>
			<div className='grid grid-cols-60'>
				<div className='col-span-60'>
					<ProductSlideshowAccessories />
				</div>
			</div>
		</div>
	);
};

export default ProductDetail;
