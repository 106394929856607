import { Route, Redirect } from 'react-router-dom';
import Registration from '../pages/registration/Registration';

const RegistrationRoute = ({ isLogging, isLogged, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			!isLogged || isLogging ? (
				<Registration />
			) : (
				<Redirect
					exact
					to={{
						pathname: '/',
						state: {
							from: props.location,
						},
					}}
				/>
			)
		}
	/>
);

export default RegistrationRoute;
