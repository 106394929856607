import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import RegistrationFormDetail from '../../components/cart/registrationFormDetail/RegistrationFormDetail';

const RegistrationForm = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const staticPages = useSelector((state) => state.staticPages);
	const tokenCarrello = useSelector((state) => state.user.token_id);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// controllo nello store se ho già il dettaglio di registrazione form e che sia per l'utente giusto (privato o azienda), altrimenti eseguo action creator
		const registrationFormPage = staticPages.registrationForm;

		if (registrationFormPage) {
			// setto i metatags
			document.title = registrationFormPage.metaTitle;
			document.getElementsByTagName('META')[2].content =
				registrationFormPage.metaDesc;
		}

		if (
			registrationFormPage === undefined ||
			registrationFormPage.detail === undefined
		) {
			// non ho i dati del prodotto nello store, chiamo action creator fetchRegistrationFormDetail
			setTimeout(
				() =>
					dispatch(
						allActions.registrationDetailActions.fetchRegistrationFormDetail(
							currentPage.currentLang,
							user?.userData?.id,
							tokenCarrello
						)
					),
				50
			);
		} else {
			// loading...
			dispatch(allActions.isFetchingAction.loadStoredData());

			// recupero dati da store
			dispatch(
				allActions.registrationDetailActions.loadRegistrationDetail(
					registrationFormPage
				)
			);
		}

		//console.log(registrationFormPage);
	}, [location, staticPages]);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<RegistrationFormDetail />
				</div>
			</div>
		</Layout>
	);
};

export default RegistrationForm;
