const URLASSOLUTO_IT = process.env.MIX_URLASSOLUTO_IT;
const URLASSOLUTO_EN = process.env.MIX_URLASSOLUTO_EN;
const URLASSOLUTO_FR = process.env.MIX_URLASSOLUTO_FR;
const URLASSOLUTO_DE = process.env.MIX_URLASSOLUTO_DE;

export let URLASSOLUTO;

export default (initialData) => {
	if (initialData[0]['siteParams']['current_language'] === 'it') {
		URLASSOLUTO = URLASSOLUTO_IT;
	}
	if (initialData[0]['siteParams']['current_language'] === 'en') {
		URLASSOLUTO = URLASSOLUTO_EN;
	}
	if (initialData[0]['siteParams']['current_language'] === 'fr') {
		URLASSOLUTO = URLASSOLUTO_FR;
	}
	if (initialData[0]['siteParams']['current_language'] === 'de') {
		URLASSOLUTO = URLASSOLUTO_DE;
	}

	return URLASSOLUTO;
};
