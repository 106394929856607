import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import PaymentDetail from '../../components/cart/paymentDetail/PaymentDetail';

const Payment = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const staticPages = useSelector((state) => state.staticPages);
	const user = useSelector((state) => state.user);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// controllo nello store se ho già il dettaglio della pagina di pagamento, altrimenti eseguo action creator (NB se mi sono registrato come guest devo SEMPRE eseguire il fetch della pagina, così scarico i dati aggiornati dell'utente guest)
		const paymentPage = staticPages.payment;

		// 03/11/2021 modificato --> eseguo sempre l'action creator della pagina pagamento (per evitare bug di cambio utente con regole particolari IVA)
		setTimeout(
			() =>
				dispatch(
					allActions.paymentDetailActions.fetchPaymentDetail(
						currentPage.currentLang,
						user.token_id,
						user.userData.id
					)
				),
			50
		);

		//console.log(paymentPage);
	}, [location]);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<PaymentDetail />
				</div>
			</div>
		</Layout>
	);
};

export default Payment;
