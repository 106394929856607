import { Route } from 'react-router-dom';
import Nets from '../pages/nets/Nets';

export default (netsRoute) => {
	return [
		<Route
			exact
			path={`/${netsRoute}/`}
			component={Nets}
			key={`${netsRoute}Route`}
		/>,
	];
};
