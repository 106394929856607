import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import allActions from '../../../state/actions';
import { URLASSOLUTO } from '../../../utils/urlassoluto';
import { BASESTORAGE } from '../../../utils/basestorage';

const CategoryItem = ({
	id,
	categoryName,
	categoryIcon,
	categoryHoverColor,
	routeName,
	linkSeo,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const onCategoryClick = (event) => {
		event.preventDefault();

		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(id, routeName, linkSeo, history)
		);
	};

	return (
		<div
			className={`categoryItemSub border-${categoryHoverColor} hover:bg-${categoryHoverColor} hover:text-white`}>
			<a
				href={URLASSOLUTO + linkSeo}
				onClick={onCategoryClick}
				className='py-3 px-3 3xl:py-6 3xl:px-4'>
				<img
					src={
						BASESTORAGE +
						'/' +
						categoryIcon?.folder +
						'/' +
						categoryIcon?.file_name
					}
					width='60'
					height='60'
					className='flex-start'
					alt={categoryName}
				/>
				<span
					className='categoryItemName line-clamp-2 pl-4 text-base sm:text-sm lg:text-base xl:text-xs 2xl:text-sm 4xl:text-base'
					dangerouslySetInnerHTML={{ __html: categoryName?.toUpperCase() }}
				/>
				<i className='far fa-chevron-right'></i>
			</a>
		</div>
	);
};

export default CategoryItem;
