import {
	FETCH_HOMEPAGE,
	FETCH_NETS_DETAIL,
	FETCH_CART_DETAIL,
	FETCH_REGISTRATION_DETAIL,
	FETCH_REGISTRATION_FORM_DETAIL,
	FETCH_PAYMENT_DETAIL,
	FETCH_CHECKOUT_DETAIL,
	FETCH_ORDER_CLOSED_DETAIL,
	FETCH_PROFILE_DETAIL,
	FETCH_ADDRESSES_DETAIL,
	FETCH_ORDERS_DETAIL,
	FETCH_CHISIAMO_DETAIL,
	FETCH_LENOSTRERETI_DETAIL,
	FETCH_CONTATTI_DETAIL,
	FETCH_CONTATTI_OK_DETAIL,
	FETCH_CONTATTI_KO_DETAIL,
	FETCH_CONDIZIONI_VENDITA_DETAIL,
	FETCH_PRIVACY_POLICY_DETAIL,
	FETCH_MAGAZINE_DETAIL,
	FETCH_SEARCH_DETAIL,
	FETCH_404_DETAIL,
} from '../actions/types';
import { current } from 'immer';

const INITIAL_STATE = {
	homepage: {
		metaTitle: '',
		metaDesc: '',
	},
	nets: {},
	cart: {},
	registration: {},
	registrationForm: {},
	payment: {},
	checkout: {},
	profile: {},
	addresses: {},
	orders: {},
	orderClosed: {},
	chisiamo: {},
	lenostrereti: {},
	contatti: {},
	contattiok: {},
	contattiko: {},
	condizionivendita: {},
	privacypolicy: {},
	magazine: {},
	search: {},
	error404: {},
};

export const staticPagesReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_HOMEPAGE:
			const { homepage } = action.payload;

			//console.log(action, current(state));

			state = { ...state, homepage };

			return state;

		case FETCH_NETS_DETAIL:
			const { nets } = action.payload;

			state = { ...state, nets };

			return state;

		case FETCH_CART_DETAIL:
			const { cart } = action.payload;

			state = { ...state, cart };

			return state;

		case FETCH_REGISTRATION_DETAIL:
			const { registration } = action.payload;

			state = { ...state, registration };

			return state;

		case FETCH_REGISTRATION_FORM_DETAIL:
			const { registrationForm } = action.payload;

			state = { ...state, registrationForm };

			return state;

		case FETCH_PAYMENT_DETAIL:
			const { payment } = action.payload;

			state = { ...state, payment };

			return state;

		case FETCH_CHECKOUT_DETAIL:
			const { checkout } = action.payload;

			state = { ...state, checkout };

			return state;

		case FETCH_ORDER_CLOSED_DETAIL:
			const { orderClosed } = action.payload;

			state = { ...state, orderClosed };

			return state;

		case FETCH_PROFILE_DETAIL:
			const { profile } = action.payload;

			state = { ...state, profile };

			return state;

		case FETCH_ADDRESSES_DETAIL:
			const { addresses } = action.payload;

			state = { ...state, addresses };

			return state;

		case FETCH_ORDERS_DETAIL:
			const { orders } = action.payload;

			state = { ...state, orders };

			return state;

		case FETCH_CHISIAMO_DETAIL:
			const { chisiamo } = action.payload;

			state = { ...state, chisiamo };

			return state;

		case FETCH_LENOSTRERETI_DETAIL:
			const { lenostrereti } = action.payload;

			state = { ...state, lenostrereti };

			return state;

		case FETCH_CONTATTI_DETAIL:
			const { contatti } = action.payload;

			state = { ...state, contatti };

			return state;

		case FETCH_CONTATTI_OK_DETAIL:
			const { contattiok } = action.payload;

			state = { ...state, contattiok };

			return state;

		case FETCH_CONTATTI_KO_DETAIL:
			const { contattiko } = action.payload;

			state = { ...state, contattiko };

			return state;

		case FETCH_CONDIZIONI_VENDITA_DETAIL:
			const { condizionivendita } = action.payload;

			state = { ...state, condizionivendita };

			return state;

		case FETCH_PRIVACY_POLICY_DETAIL:
			const { privacypolicy } = action.payload;

			state = { ...state, privacypolicy };

			return state;

		case FETCH_MAGAZINE_DETAIL:
			const { magazine } = action.payload;

			state = { ...state, magazine };

			return state;

		case FETCH_SEARCH_DETAIL:
			const { search } = action.payload;

			state = { ...state, search };

			return state;

		case FETCH_404_DETAIL:
			const { error404 } = action.payload;

			state = { ...state, error404 };

			return state;

		default:
			return state;
	}
};
