import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import AOS from 'aos';
import { trackWindowScroll } from 'react-lazy-load-image-component';
import TitoloBlocco from '../../shared/titoloBlocco/TitoloBlocco';
import ProductBoxItem from '../../shared/productBoxItem/ProductBoxItem';
import Slideshow from '../../shared/slideshow/Slideshow';

const BloccoPiuVendutiHome = ({ scrollPosition }) => {
	const deviceType = useSelector((state) => state.device);
	const promotion = useSelector((state) => state.promotion);
	const productsBestSoldHome = useSelector(
		(state) => state.productsBestSoldHome
	);
	const homepageTranslation = useSelector(
		(state) => state.translations.homepage
	);

	useEffect(() => {
		// inizializzo AOS
		AOS.init();
		AOS.refresh();
	}, []);

	const elencoPiuVenduti = productsBestSoldHome.map(
		({ id, image, hoverColor, detail, routeName, linkSeo }, index) => {
			const titolo_breve = detail.titolo_breve;
			const sottotitolo_elenco = detail.sottotitolo_elenco;
			const codice = detail.codice;
			const imponibile = detail.imponibile;
			const sconto = detail.sconto;
			const prezzoScontato = detail.prezzoScontato;
			const measure_unit = detail.measure_unit;
			const flag_abilita_tag_bestseller =
				detail.flags.flag_abilita_tag_bestseller;
			const flag_abilita_tag_novita = detail.flags.flag_abilita_tag_novita;
			const flag_abilita_informazioni_prodotto =
				detail.flags.flag_abilita_informazioni_prodotto;
			const flag_abilita_prezzi_fissi = detail.flags.flag_abilita_prezzi_fissi;
			const colore = hoverColor;

			let tipologiaScontoP = null;
			// recupero eventuale sconto prodotto
			if (sconto > 0) {
				tipologiaScontoP = detail.tipologia_sconto;
			}

			// promozione 3x2?
			let isProdotto3x2 = false;
			if (promotion?.tipologiaPromozione === 'Promozione 3x2') {
				const prodotto3x2 = promotion?.elencoProdotti?.filter((item) => {
					return item.id === id;
				})[0];
				if (prodotto3x2) {
					isProdotto3x2 = true;
				}
			}

			// promozione prodotto abbinato?
			let promoProdottoAbbinato = null;
			if (
				promotion &&
				promotion?.tipologiaPromozione === 'Promozione Prodotto abbinato'
			) {
				promoProdottoAbbinato = promotion?.elencoProdotti?.filter((item) => {
					return item.id === id;
				})[0];
			}

			// prezzo fisso?
			const prezzoFisso =
				flag_abilita_prezzi_fissi === 1 ? detail?.prezzoFisso?.prezzo : null;

			return (
				<ProductBoxItem
					key={_uniqueId('productPiuVendutoItem_')}
					id={id}
					index={index}
					immagine={image}
					titoloBreve={titolo_breve}
					sottotitoloElenco={sottotitolo_elenco}
					codice={codice}
					imponibile={imponibile}
					sconto={sconto}
					tipologiaScontoPromozione={tipologiaScontoP}
					prezzoScontato={prezzoScontato}
					unitaMisura={measure_unit}
					iconaTagBestseller={flag_abilita_tag_bestseller}
					iconaTagNovita={flag_abilita_tag_novita}
					richiediInfo={flag_abilita_informazioni_prodotto}
					prodotto3x2={isProdotto3x2}
					prodottoAbbinatoObj={promoProdottoAbbinato}
					prezzoFisso={prezzoFisso}
					colore={colore}
					routeName={routeName}
					linkSeo={linkSeo}
					categoriaTitolo={detail?.titoloCategoria}
					itemClass='mx-1 sm:mx-0 md:mx-3 lg:mx-2 3xl:mx-4 4xl:mx-3'
				/>
			);
		}
	);

	const responsive = {
		bigdesktop: {
			breakpoint: { max: 4000, min: 1920 },
			items: 5,
		},
		desktop: {
			breakpoint: { max: 1920, min: 1536 },
			items: 4,
		},
		minipc: {
			breakpoint: { max: 1536, min: 1280 },
			items: 4,
		},
		bigtablet: {
			breakpoint: { max: 1280, min: 1024 },
			items: 3,
		},
		tablet: {
			breakpoint: { max: 1024, min: 768 },
			items: 2,
		},
		bigmobile: {
			breakpoint: { max: 768, min: 640 },
			items: 2,
		},
		middlemobile: {
			breakpoint: { max: 640, min: 480 },
			items: 1,
		},
		mobile: {
			breakpoint: { max: 480, min: 0 },
			items: 1,
			partialVisibilityGutter: 80,
		},
	};

	/* const removeArrowsOnDeviceType = ['mobile']; */
	const removeArrowsOnDeviceType = [];

	return (
		<div className='bg-ribola-gallery-gray'>
			<div className='container mx-auto'>
				<div className='grid grid-cols-60'>
					<div className='col-span-60 flex flex-col justify-center items-center pt-10 md:pt-16 lg:pb-4'>
						<TitoloBlocco
							titoloStyle='titoloBlocco text-ribola-astral text-3xl sm:text-4xl lg:text-5xl 4xl:text-6xl'
							titolo={homepageTranslation.titoloIPiuVenduti.itemName.toUpperCase()}
						/>
					</div>
					<div
						className='col-start-1 xl:col-start-1 3xl:col-start-2 col-end-61 xl:col-end-61 3xl:col-end-60 pb-8'
						data-aos='fade-right'
						data-aos-delay='200'
						data-aos-once={false}>
						<Slideshow
							slideList={elencoPiuVenduti}
							swipeableProp={true}
							draggableProp={false}
							showDotsProp={false}
							infiniteProp={false}
							autoPlayProp={false}
							autoPlaySpeedProp={3000}
							arrowsProp={true}
							partialVisibleProp={true}
							responsiveProp={responsive}
							deviceTypeProp={deviceType}
							removeArrowOnDeviceTypeProp={removeArrowsOnDeviceType}
							containerClass='slideshowPiuVenduti sm:pl-3 pt-3 xs:pt-8 xl:pt-16 xs:pb-8 xl:pb-16'
							itemClass='transition-all itemPiuVenduti flex justify-center py-4 px-0 sm:p-4'
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

// containerClass='slideshowPiuVenduti sm:pl-6 pt-8 md:pt-16 pb-8 md:pb-16'

export default trackWindowScroll(BloccoPiuVendutiHome);
