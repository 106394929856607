import {
	ADD_TO_CART,
	STRIPE_OK,
	PAYPAL_OK,
	BONIFICO_OK,
	RIBA_OK,
	CONTRASSEGNO_OK,
} from '../actions/types';

const INITIAL_STATE = {
	cartLastItem: {
		status: '',
		title: '',
		message: '',
		cartItem: {},
	},
};

export const cartLastItemReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case ADD_TO_CART:
			const { cartLastItem, status, title, message } = action.payload;

			const cartItemKey = Object.keys(cartLastItem).reduce((item) => item);

			state = { ...state.cartItem, ...cartLastItem[cartItemKey] };
			state.status = status;
			state.title = title;
			state.message = message;

			return state;

		case STRIPE_OK:
		case PAYPAL_OK:
		case BONIFICO_OK:
		case RIBA_OK:
		case CONTRASSEGNO_OK:
			state = {};

			return state;

		default:
			return state;
	}
};
