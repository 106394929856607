import BoxRichiedInfo from '../boxRichiediInfo/BoxRichiediInfo';
import BloccoProfiloMenuLeft from '../bloccoProfiloMenuLeft/BloccoProfiloMenuLeft';
import BoxIconeMenuLeft from '../boxIconeMenuLeft/BoxIconeMenuLeft';

const MenuLeftProfilo = () => {
	return (
		<div className='col-start-2 2xl:col-start-3 4xl:col-start-5 col-end-13 4xl:col-end-14 pt-20 hidden lg:inline-block'>
			<BoxRichiedInfo />
			<BloccoProfiloMenuLeft />
			<BoxIconeMenuLeft />
		</div>
	);
};

export default MenuLeftProfilo;
