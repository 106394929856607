import { useRef, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AOS from 'aos';
import _uniqueId from 'lodash/uniqueId';
import TitoloBlocco from '../../shared/titoloBlocco/TitoloBlocco';
import CategoryItem from '../../shared/categoryItem/CategoryItem';

const BloccoCategorieHome = () => {
	const [handLeftVisible, setHandLeftVisible] = useState(false);
	const [handRightVisible, setHandRightVisible] = useState(true);
	const trackFirstElement = useRef(null);
	const trackLastElement = useRef(null);
	const categorieDivRef = useRef(null);
	const productCategories = useSelector((state) => state.catalogue);
	const homepageTranslation = useSelector(
		(state) => state.translations.homepage
	);

	useEffect(() => {
		// inizializzo AOS
		AOS.init();
		AOS.refresh();
	}, []);

	const totalCategories = Object.keys(productCategories).length;

	const elencoCategories = Object.keys(productCategories).map((key, index) => {
		const { id, detail, icona, hoverColor, routeName, urlSeo, linkSeo } =
			productCategories[key];

		let staggerTime = index * 10 + 100;

		if (index === 0) {
			return (
				<div
					key={_uniqueId('category_')}
					ref={trackFirstElement}
					className='categoryItem'
					data-aos='fade-right'
					data-aos-delay={staggerTime}
					data-aos-once={false}>
					<CategoryItem
						id={id}
						categoryName={detail.titolo}
						categoryIcon={icona}
						categoryHoverColor={hoverColor}
						routeName={routeName}
						urlSeo={urlSeo}
						linkSeo={linkSeo}
					/>
				</div>
			);
		} else if (index === totalCategories - 1) {
			return (
				<div
					key={_uniqueId('category_')}
					ref={trackLastElement}
					className='categoryItem'
					data-aos='fade-right'
					data-aos-delay={staggerTime}
					data-aos-once={false}>
					<CategoryItem
						id={id}
						categoryName={detail.titolo}
						categoryIcon={icona}
						categoryHoverColor={hoverColor}
						routeName={routeName}
						urlSeo={urlSeo}
						linkSeo={linkSeo}
					/>
				</div>
			);
		} else {
			return (
				<div
					key={_uniqueId('category_')}
					className='categoryItem'
					data-aos='fade-right'
					data-aos-delay={staggerTime}
					data-aos-once={false}>
					<CategoryItem
						id={id}
						categoryName={detail.titolo}
						categoryIcon={icona}
						categoryHoverColor={hoverColor}
						routeName={routeName}
						urlSeo={urlSeo}
						linkSeo={linkSeo}
					/>
				</div>
			);
		}
	});

	// commentato la comparsa della mano sinistra perchè crea un effetto scattoso allo snap delle categorie
	const onScrollHandler = () => {
		/* const myFirstElement = trackFirstElement.current;
		const firstElementX = myFirstElement.getBoundingClientRect().x;
		if (firstElementX < 0) {
			setHandLeftVisible(true);
		} else {
			setHandLeftVisible(false);
		}

		const myLastElement = trackLastElement.current;
		const lastElementX = myLastElement.getBoundingClientRect().x;
		if (lastElementX < 100) {
			setHandRightVisible(false);
		} else {
			setHandRightVisible(true);
		} */
	};

	const onShowCategorieClick = () => {
		const categorieDiv = categorieDivRef.current;
		/* console.log(categorieDiv.offsetWidth, categorieDiv.offsetHeight);
		console.log(window.screen.width); */
		if (
			categorieDiv.offsetWidth > 0 &&
			categorieDiv.offsetHeight > 0 &&
			window.screen.width < 640
		) {
			categorieDiv.style.display = 'none';
		} else if (
			categorieDiv.offsetWidth === 0 &&
			categorieDiv.offsetHeight === 0 &&
			window.screen.width < 640
		) {
			categorieDiv.style.display = 'block';
		}
	};

	const handLeft = handLeftVisible ? 'opacity-100' : 'opacity-0';
	const handRight = handRightVisible ? 'opacity-100' : 'opacity-0';

	return (
		<div className='container mx-auto'>
			<div className='grid grid-cols-60'>
				<div className='col-span-60 py-6 lg:pt-12 lg:pb-6 xl:py-12'>
					<span
						className='flex flex-col justify-center items-center rounded-md border border-ribola-nobel sm:border-0 mx-3 sm:mx-0 py-2 sm:py-0 transition-all hover:bg-blue-200 sm:hover:bg-transparent'
						onClick={onShowCategorieClick}>
						<TitoloBlocco
							titoloStyle='titoloBlocco text-ribola-astral text-3xl lg:text-5xl 4xl:text-6xl'
							titolo={homepageTranslation.titoloCategorie.itemName.toUpperCase()}
						/>
						<span>
							<TitoloBlocco
								titoloStyle='sottoTitoloBlocco text-base sm:text-xl text-ribola-astral'
								titolo={homepageTranslation.sfoglianostrocatalogo.itemName}
							/>
							<i className='far fa-chevron-down text-ribola-astral sm:hidden ml-2'></i>
						</span>
					</span>
				</div>
				<div
					className='col-start-4 sm:col-start-2 md:col-start-6 lg:col-start-2 3xl:col-start-5 col-end-57 sm:col-end-60 md:col-end-56 lg:col-end-60 3xl:col-end-57 mb-8 xl:border-b xl:border-gray-300 hidden sm:block'
					ref={categorieDivRef}>
					<div className='flex justify-between text-ribola-astral xl:hidden'>
						<span
							className={`text-sm flex items-center transition ${handLeft}`}>
							{/* <i className='fal fa-hand-point-left text-lg mr-1'></i> */}
							{homepageTranslation.scorri.itemName}
						</span>
						<span
							className={`text-sm flex items-center transition ${handRight}`}>
							{homepageTranslation.scorri.itemName}
							{/* <i className='fal fa-hand-point-right text-lg ml-1'></i> */}
						</span>
					</div>
					<div className='grid grid-cols-60 xl:border-t border-gray-300'>
						<div className='col-span-60'>
							<nav
								onScroll={onScrollHandler}
								className='containerCategorie flex flex-wrap'>
								{elencoCategories}
							</nav>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default BloccoCategorieHome;
