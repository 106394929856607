import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import MenuLeftMagazine from '../../components/shared/menuLeftMagazine/MenuLeftMagazine';
import MagazineDetail from '../../components/magazineDetail/MagazineDetail';

const Magazine = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const staticPages = useSelector((state) => state.staticPages);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// controllo nello store se ho già il dettaglio di magazine, altrimenti eseguo action creator
		const magazinePage = staticPages.magazine;

		if (magazinePage) {
			// setto i metatags
			document.title = magazinePage.metaTitle;
			document.getElementsByTagName('META')[2].content = magazinePage.metaDesc;
		}

		if (magazinePage === undefined || magazinePage.detail === undefined) {
			// non ho i dati del prodotto nello store, chiamo action creator fetchMagazineDetail
			setTimeout(
				() =>
					dispatch(
						allActions.magazineDetailActions.fetchMagazineDetail(
							currentPage.currentLang,
							user?.userData?.id
						)
					),
				50
			);
		} else {
			// loading...
			dispatch(allActions.isFetchingAction.loadStoredData());

			// recupero dati da store
			dispatch(
				allActions.magazineDetailActions.loadMagazineDetail(magazinePage)
			);
		}

		//console.log(magazinePage);
	}, [location, staticPages]);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<MenuLeftMagazine />
					<MagazineDetail />
				</div>
			</div>
		</Layout>
	);
};

export default Magazine;
