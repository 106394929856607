import { useSelector } from 'react-redux';
import InputField from '../../../components/shared/inputField/InputField';
import SelectField from '../../../components/shared/selectField/SelectField';

const CampiMetriCubi = ({
	lunghezzaValue,
	larghezzaValue,
	profonditaValue,
	altezzeFisseList = [],
	onLunghezzaChange,
	onLarghezzaChange,
}) => {
	const flags = useSelector(
		(state) => state.currentPage.pageContent.detail.flags
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	// menu a tendina di altezze fisse?
	let altezzeFisse = '';
	if (altezzeFisseList.length > 0) {
		altezzeFisse = altezzeFisseList.map((altezza) => {
			return { id: altezza, value: altezza + ' m' };
		});
	}

	const isLarghezzaOrAltezza =
		flags.flag_abilita_misura_altezza === 1
			? genericheTranslation.altezza.itemName.toUpperCase()
			: genericheTranslation.larghezza.itemName.toUpperCase();

	const isLarghezzaOrAltezzeFisse =
		altezzeFisseList.length > 0 ? (
			<SelectField
				selectName='larghezza'
				selectListValue={altezzeFisse}
				selectLabel={genericheTranslation.altezzafissa.itemName.toUpperCase()}
				selectHandlerFunction={onLarghezzaChange}
				selectLabelStyle='text-sm'
				selectClassStyle='bg-transparent w-full border border-ribola-dove-gray border-opacity-20 focus:border-opacity-100 py-1.5 mt-px text-sm'
			/>
		) : (
			<InputField
				inputType='number'
				inputLabel={isLarghezzaOrAltezza}
				inputName='larghezza'
				inputValue={larghezzaValue}
				inputHandlerFunction={onLarghezzaChange}
				inputPlaceholder='0,0'
				inputSuffix={` ${genericheTranslation.metri.itemName}`}
				inputStyle='border'
				isNumberFormat={true}
			/>
		);

	return (
		<div className='grid grid-cols-60 mt-5'>
			<div className='col-start-1 col-end-29'>
				<InputField
					inputType='number'
					inputLabel={genericheTranslation.lunghezza.itemName.toUpperCase()}
					inputName='lunghezza'
					inputValue={lunghezzaValue}
					inputHandlerFunction={onLunghezzaChange}
					inputPlaceholder='0,0'
					inputSuffix={` ${genericheTranslation.metri.itemName}`}
					inputStyle='border'
					isNumberFormat={true}
				/>
			</div>
			<div className='col-start-31 col-end-61'>{isLarghezzaOrAltezzeFisse}</div>
			<div className='col-start-1 col-end-29 mt-3'>
				<InputField
					inputType='number'
					inputLabel={genericheTranslation.profondita.itemName.toUpperCase()}
					inputName='profondita'
					inputValue={profonditaValue}
					inputHandlerFunction={onProfonditaChange}
					inputPlaceholder='0,0'
					inputSuffix={` ${genericheTranslation.metri.itemName}`}
					inputStyle='border'
					isNumberFormat={true}
				/>
			</div>
		</div>
	);
};

export default CampiMetriCubi;
