import { Route, Redirect } from 'react-router-dom';
import Profile from '../pages/profile/Profile';

const ProfileRoute = ({ isLogged, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			isLogged ? (
				<Profile />
			) : (
				<Redirect
					exact
					to={{
						pathname: '/',
						state: {
							from: props.location,
						},
					}}
				/>
			)
		}
	/>
);

export default ProfileRoute;
