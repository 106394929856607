import axios from '../../utils/axiosBase';
import {
	FETCH_PARENT_CATEGORY_DETAIL,
	LOAD_PARENT_CATEGORY_DETAIL,
} from './types';

const fetchParentCategoryDetail =
	(currentLang, userId, parent_category_id, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
		};

		// chiamo web service
		const response = await axios.post(
			`api/parentcategorydetail/${parent_category_id}/`,
			payload,
			{
				withCredentials: true,
			}
		);

		dispatch({
			type: FETCH_PARENT_CATEGORY_DETAIL,
			payload: response.data,
		});
	};

const loadParentCategoryDetail =
	(parentCategoryLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_PARENT_CATEGORY_DETAIL,
			payload: {
				parentCategoryLoaded,
			},
		});
	};

export default {
	fetchParentCategoryDetail,
	loadParentCategoryDetail,
};
