import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import Slideshow from '../slideshow/Slideshow';
import ImgPicture from '../imgPicture/ImgPicture';

const SlideshowHeader = () => {
	const deviceType = useSelector((state) => state.currentPage.device);
	const pageHeader = useSelector((state) => state.currentPage.pageHeader);
	const images = pageHeader?.images;
	const typeHeader = pageHeader?.type;

	useEffect(() => {
		//console.log(pageHeader);
	}, []);

	const imgWidth = {
		xl4: '1920',
		xl3: '1620',
		xl: '1536',
		lg: '1024',
		md: '768',
		sm: '640',
		xxs: '640',
	};

	const imgHeight = {
		xl4: '642',
		xl3: '594',
		xl: '563',
		lg: '375',
		md: '312',
		sm: '312',
		xxs: '540',
	};

	// non ho ancora le immagini, mostro un placeholder
	if (!images || typeHeader !== 'slideshow') {
		return <div className='container mx-auto h-80 bg-gray-300'></div>;
	}

	const slideContent = images.map((image) => {
		//console.log(image);
		return (
			<div key={_uniqueId('imageHeader_')}>
				<ImgPicture imgWidth={imgWidth} imgHeight={imgHeight} {...image} />
			</div>
		);
	});

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 1,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1024 },
			items: 1,
		},
		tablet: {
			breakpoint: { max: 1024, min: 464 },
			items: 1,
		},
		mobile: {
			breakpoint: { max: 464, min: 0 },
			items: 1,
		},
	};

	const removeArrowsOnDeviceType = ['tablet', 'mobile'];

	return (
		<div className='container mx-auto'>
			<Slideshow
				slideList={slideContent}
				swipeableProp={true}
				draggableProp={false}
				showDotsProp={true}
				infiniteProp={true}
				autoPlayProp={true}
				autoPlaySpeedProp={6000}
				arrowsProp={false}
				responsiveProp={responsive}
				deviceTypeProp={deviceType}
				transitionDurationProp='1000'
				containerClass='headerHomepageContainer'
				dotListClass='headerHomepageDots'
			/>
		</div>
	);
};

export default SlideshowHeader;
