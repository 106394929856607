import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import ImgPicture from '../imgPicture/ImgPicture';

const ImageHeader = () => {
	const pageHeader = useSelector((state) => state.currentPage.pageHeader);
	const image = pageHeader.images[0];
	const typeHeader = pageHeader.type;

	useEffect(() => {
		//console.log(pageHeader);
	}, [pageHeader]);

	const imgWidth = {
		xl4: '1920',
		xl3: '1403',
		xl2: '1330',
		xl: '1280',
		lg: '1024',
		md: '1024',
		sm: '768',
		xs: '640',
		xxs: '480',
	};

	const imgHeight = {
		xl4: '317',
		xl3: '317',
		xl2: '317',
		xl: '317',
		lg: '317',
		md: '317',
		sm: '317',
		xs: '212',
		xxs: '212',
	};

	if (!image || typeHeader !== 'interna') {
		return <div className='container mx-auto h-80 bg-gray-300'></div>;
	}

	const imageWebp = image.imgName.replace('.jpg', '.webp');

	return (
		<div className='container mx-auto h-44 sm:h-56 lg:h-80 imageHeader overflow-hidden'>
			<ImgPicture
				imgName={imageWebp}
				imgPath={image.imgPath}
				imgWidth={imgWidth}
				imgHeight={imgHeight}
				imgStyle={image.imgStyle}
				imgAlt={image.imgAlt}
			/>
		</div>
	);
};

/**
 * 
 * imgRoute={image.imgRoute}
				imgType={image.imgType}
 */

// imgRoute='immagini-responsive-type'
// imgType='fitnoresizeToWebp'

export default ImageHeader;
