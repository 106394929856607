import { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import TitoloBlocco from '../../shared/titoloBlocco/TitoloBlocco';
import ProductBoxSmallItem from '../../shared/productBoxSmallItem/ProductBoxSmallItem';
import Slideshow from '../../shared/slideshow/Slideshow';

const ProductSlideshowOtherProducts = () => {
	const deviceType = useSelector((state) => state.device);
	const promotion = useSelector((state) => state.promotion);
	const prodottiCorrelati = useSelector(
		(state) => state.currentPage.pageContent.detail.prodottiCorrelati
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	useEffect(() => {
		//console.log(prodottiCorrelati);
	}, []);

	// controllo che lo store sia aggiornato e che ci siano i prodottiCorrelati
	if (prodottiCorrelati === undefined) {
		return <div></div>;
	}

	const elencoProdottiCorrelati = prodottiCorrelati.map(
		({ id, image, detail, routeName, linkSeo }, index) => {
			const titolo_breve = detail.titolo_breve;
			const codice = detail.codice;
			const imponibile = detail.imponibile;
			const sconto = detail.sconto;
			const prezzoScontato = detail.prezzoScontato;
			const measure_unit = detail.measure_unit;
			const flag_abilita_tag_bestseller =
				detail.flags.flag_abilita_tag_bestseller;
			const flag_abilita_tag_novita = detail.flags.flag_abilita_tag_novita;
			const flag_abilita_prezzi_fissi = detail.flags.flag_abilita_prezzi_fissi;
			const flag_abilita_informazioni_prodotto =
				detail.flags.flag_abilita_informazioni_prodotto;

			// recupero eventuale promozione a tempo di categorie
			const promozioneCategoria = promotion?.elencoCategorie?.filter(
				(item) => item.id === id
			);

			// sconto promozione categoria?
			let tipologiaScontoP = null;
			if (promozioneCategoria !== undefined) {
				tipologiaScontoP = promotion.tipologiaSconto;
			}

			// recupero eventuale sconto prodotto
			if (sconto > 0) {
				tipologiaScontoP = detail.tipologia_sconto;
			}
			// fine promozione prodotto

			// promozione 3x2?
			let isProdotto3x2 = false;
			if (promotion?.tipologiaPromozione === 'Promozione 3x2') {
				const prodotto3x2 = promotion?.elencoProdotti?.filter((item) => {
					return item.id === id;
				})[0];
				if (prodotto3x2) {
					isProdotto3x2 = true;
				}
			}

			// promozione prodotto abbinato?
			let promoProdottoAbbinato = null;
			if (
				promotion &&
				promotion?.tipologiaPromozione === 'Promozione Prodotto abbinato'
			) {
				promoProdottoAbbinato = promotion?.elencoProdotti?.filter((item) => {
					return item.id === id;
				})[0];
			}

			// prezzo fisso?
			const prezzoFisso =
				flag_abilita_prezzi_fissi === 1 ? detail?.prezzoFisso?.prezzo : null;

			return (
				<ProductBoxSmallItem
					key={_uniqueId('productCorrelatoItem_')}
					id={id}
					index={index}
					immagine={image}
					titoloBreve={titolo_breve}
					codice={codice}
					imponibile={imponibile}
					sconto={sconto}
					tipologiaScontoPromozione={tipologiaScontoP}
					prezzoScontato={prezzoScontato}
					unitaMisura={measure_unit}
					prodotto3x2={isProdotto3x2}
					prodottoAbbinatoObj={promoProdottoAbbinato?.prodottoAbbinato}
					prezzoFisso={prezzoFisso}
					iconaTagBestseller={flag_abilita_tag_bestseller}
					iconaTagNovita={flag_abilita_tag_novita}
					richiediInfo={flag_abilita_informazioni_prodotto}
					routeName={routeName}
					linkSeo={linkSeo}
					categoriaTitolo={detail?.titoloCategoria}
				/>
			);
		}
	);

	const responsive = {
		superLargeDesktop: {
			breakpoint: { max: 4000, min: 3000 },
			items: 3,
		},
		desktop: {
			breakpoint: { max: 3000, min: 1540 },
			items: 3,
			partialVisibilityGutter: 0,
		},
		minipc: {
			breakpoint: { max: 1540, min: 1280 },
			items: 2,
		},
		bigtablet: {
			breakpoint: { max: 1280, min: 1024 },
			items: 2,
		},
		tablet: {
			breakpoint: { max: 1024, min: 768 },
			items: 2,
		},
		mobile: {
			breakpoint: { max: 768, min: 0 },
			items: 1,
		},
	};

	const removeArrowsOnDeviceType = [];

	// nessun prodotto correlato?
	if (elencoProdottiCorrelati.length === 0) {
		return <div></div>;
	}

	return (
		<Fragment>
			<TitoloBlocco
				titoloStyle='titoloBlocco flex text-ribola-alto text-2xl sm:text-4xl px-6 xl:px-0 mt-12 xl:mt-8'
				titolo={genericheTranslation.gliutentiacquistanoanche.itemName.toUpperCase()}
			/>
			<Slideshow
				slideList={elencoProdottiCorrelati}
				swipeableProp={true}
				draggableProp={false}
				showDotsProp={false}
				infiniteProp={false}
				autoPlayProp={false}
				autoPlaySpeedProp={3000}
				arrowsProp={true}
				partialVisible={false}
				responsiveProp={responsive}
				deviceTypeProp={deviceType}
				removeArrowOnDeviceTypeProp={removeArrowsOnDeviceType}
				containerClass='slideshowOtherProducts pt-3'
				itemClass='transition-all itemProductSmallItem flex justify-center p-4'
			/>
		</Fragment>
	);
};

export default ProductSlideshowOtherProducts;
