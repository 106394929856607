import SocialItem from './socialItem/SocialItem';

const Socials = (props) => {
	return (
		<div className='col-span-10 flex justify-center'>
			<SocialItem
				socialLink='https://www.facebook.com/retificioribola/'
				socialIcon='fab fa-facebook-f'
			/>
			<SocialItem
				socialLink='https://www.instagram.com/retificioribola/'
				socialIcon='fab fa-instagram'
			/>
			<SocialItem
				socialLink='https://www.pinterest.it/0ghmmgi15wukg9djrxqkz6m46zyeoi/'
				socialIcon='fab fa-pinterest-p'
			/>
		</div>
	);
};

export default Socials;
