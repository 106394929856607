import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const TabellaDatiTeloTondo = ({ diametro, dimensione }) => {
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	useEffect(() => {}, []);

	if (diametro === '') diametro = 0;

	return (
		<div className='flex flex-col mt-10 mb-4'>
			<span className='text-xl font-opensanssemibold block mb-2'>
				{genericheTranslation.dimensionitelo.itemName.toUpperCase()}
			</span>
			<div className='flex justify-between items-center border-b border-gray-300 py-2 text-sm text-ribola-heavy-metal'>
				<span>
					{genericheTranslation.diametrocopertura.itemName.toUpperCase()}
				</span>
				<span>
					{parseFloat(diametro).toFixed(2).toString().replace('.', ',')}&nbsp;
					{genericheTranslation.metri.itemName}
				</span>
			</div>
			<div className='flex justify-between items-center border-b border-gray-300 py-2 text-sm text-ribola-heavy-metal'>
				<span>{genericheTranslation.area.itemName.toUpperCase()}</span>
				<span>
					{parseFloat(dimensione).toFixed(2).toString().replace('.', ',')}&nbsp;
					{genericheTranslation.mq.itemName}
				</span>
			</div>
		</div>
	);
};

export default TabellaDatiTeloTondo;
