import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import ReactImageVideoLightbox from 'react-image-video-lightbox';
import Briciole from '../shared/briciole/Briciole';
import LazyLoadPicture from '../shared/lazyLoadPicture/LazyLoadPicture';
import CustomButton from '../shared/customButton/CustomButton';

const ChisiamoDetail = () => {
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const siteParams = useSelector((state) => state.siteParams);
	const paginestaticheTranslation = useSelector(
		(state) => state.translations.paginestatiche
	);

	// local state
	const [openVideo, setOpenVideo] = useState(false);

	useEffect(() => {
		console.log('video', openVideo);
	}, [openVideo]);

	// controllo che esista la proprietà detail (altrimenti vuol dire che sto ancora recuperando i dati)
	if (!pageContent.detail) {
		return <div></div>;
	}

	const onVideoClick = () => {
		console.log('ho cliccato', openVideo);
		setOpenVideo(true);
	};

	const onVideoCallBack = () => {
		console.log('ho chiuso', openVideo);
		setOpenVideo(false);
	};

	const imgWidth = {
		xl4: '1320',
		xl3: '1188',
		xl2: '1188',
		xl: '981',
		lg: '785',
		md: '768',
		sm: '640',
		xs: '480',
		xxs: '480',
	};

	const imgHeight = {
		xl4: '531',
		xl3: '478',
		xl2: '478',
		xl: '395',
		lg: '316',
		md: '309',
		sm: '257',
		xs: '193',
		xxs: '193',
	};

	const imgWidth2 = {
		xl4: '616',
		xl3: '554',
		xl2: '554',
		xl: '450',
		lg: '785',
		md: '768',
		sm: '640',
		xs: '480',
		xxs: '480',
	};

	const imgHeight2 = {
		xl4: '535',
		xl3: '481',
		xl2: '481',
		xl: '391',
		lg: '316',
		md: '309',
		sm: '257',
		xs: '193',
		xxs: '193',
	};

	const showVideo = openVideo && (
		<div className='videoTube'>
			<ReactImageVideoLightbox
				data={[
					{
						url: 'https://www.youtube.com/embed/AHwpDgXq0T8?rel=0',
						type: 'video',
						title: 'Video',
					},
				]}
				startIndex={0}
				showResourceCount={false}
				onCloseCallback={onVideoCallBack}
			/>
		</div>
	);

	return (
		<div className='col-start-1 lg:col-start-15 4xl:col-start-16 col-end-61 3xl:col-end-59 4xl:col-end-57 sm:pb-12'>
			<Briciole />
			<div className='grid grid-cols-60 mt-0 sm:mt-3 xl:mt-6'>
				<div className='col-span-60 flex justify-between items-center px-8 xl:px-12 3xl:px-20 xl:pt-8 pb-6 sm:pb-8 xl:pb-12 3xl:py-14'>
					<h1 className='text-ribola-congress-blue text-3xl md:text-4xl xl:text-5xl font-opensansbold'>
						<small className='leading-tight flex'>
							{paginestaticheTranslation.titolochisiamo.itemName.toUpperCase()}
						</small>
					</h1>
				</div>
				<div className='col-span-60 px-8 xl:px-12 3xl:px-20 pb-8 xl:pb-12'>
					<p
						className='text-sm'
						dangerouslySetInnerHTML={{
							__html: paginestaticheTranslation.testochisiamo.itemName,
						}}></p>
				</div>
				<div
					className='col-span-60 grid justify-items-center items-center text-white cursor-pointer mb-8 xl:mb-12'
					onClick={onVideoClick}>
					<LazyLoadPicture
						imgName='video_ribola.webp'
						imgPath='statiche'
						imgWidth={imgWidth}
						imgHeight={imgHeight}
						imgStyle='flex-start max-w-none lg:max-w-full w-full lg:w-auto'
						pictureStyle='gridArea11'
						imgAlt='Video Ribola Retificio Srl'
					/>
					<i className='fal fa-play-circle gridArea11 text-7xl'></i>
				</div>
				<div className='col-span-60 xl:pl-12 3xl:pl-20 xl:pb-12'>
					<div className='grid grid-cols-60 w-full xl:mt-6'>
						<div className='col-span-60 xl:col-span-30 flex items-center px-8 xl:pl-0 xl:pr-20 pb-8 xl:pb-0'>
							<p
								className='text-sm'
								dangerouslySetInnerHTML={{
									__html: paginestaticheTranslation.testochisiamo2.itemName,
								}}></p>
						</div>
						<div className='col-span-60 xl:col-span-30'>
							<LazyLoadPicture
								imgName='chisiamo3.webp'
								imgPath='statiche'
								imgWidth={imgWidth2}
								imgHeight={imgHeight2}
								imgStyle='flex-start max-w-none lg:max-w-full w-full lg:w-auto'
								imgAlt='Chi siamo'
							/>
						</div>
					</div>
				</div>
				<div className='col-span-60 grid sm:mt-6 xl:pb-12'>
					<LazyLoadPicture
						imgName='chisiamo4.webp'
						imgPath='statiche'
						imgWidth={imgWidth}
						imgHeight={imgHeight}
						imgStyle='flex-start max-w-none lg:max-w-full w-full lg:w-auto'
						pictureStyle='gridArea11'
						imgAlt='Produzione reti'
					/>
					<div className='grid grid-cols-60 gridArea11 w-full xl:h-2/4 bg-ribola-congress-blue bg-opacity-40 text-white'>
						<div className='col-span-60 xl:col-span-30 flex items-center justify-center px-8 xl:px-20 py-12 xl:py-0 text-sm'>
							<p
								className='leading-normal'
								dangerouslySetInnerHTML={{
									__html: paginestaticheTranslation.testochisiamo3.itemName,
								}}></p>
						</div>
						<div className='col-span-60 xl:col-span-30 flex items-center justify-center px-8 xl:px-20 pb-8 xl:pb-0'>
							<CustomButton
								buttonLink={`https://${siteParams.sitoDominio_istituzionale_it}`}
								buttonName={paginestaticheTranslation.visitasitoistituzionale.itemName.toUpperCase()}
								buttonTarget='_blank'
								buttonOnClickFunction={() => {}}
								buttonStyle='text-base bg-ribola-gallery-gray text-ribola-congress-blue sm:text-xl py-4 px-6'
							/>
						</div>
					</div>
				</div>
				{showVideo}
			</div>
		</div>
	);
};

/***
 * 
 * 						imgRoute='immagini-responsive-type'
						imgType='fitnoresizeToWebp'
 */

export default ChisiamoDetail;
