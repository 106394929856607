import axios from '../../utils/axiosBase';
import {
	AUTHENTICATE_USER,
	UPDATE_USER,
	LOGIN_USER,
	LOGOUT_USER,
} from './types';

const authenticateUser = (history) => async (dispatch, getState) => {
	// chiamo web service
	const response = await axios.get(`sanctum/csrf-cookie/`);

	dispatch({
		type: AUTHENTICATE_USER,
		payload: response.headers,
	});
};

const loginUser =
	(currentLang, email, password, tokenCarrello, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			email: email,
			password: password,
			tokenCarrello: tokenCarrello,
		};

		// chiamo web service
		const response = await axios.post(`login/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: LOGIN_USER,
			payload: response.data,
		});
	};

const logoutUser =
	(currentLang, tokenCarrello, history) => async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			tokenCarrello: tokenCarrello,
		};

		// chiamo web service
		const response = await axios.post(`logout/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: LOGOUT_USER,
			payload: response.data,
		});
	};

const updateUser =
	(currentLang, tokenCarrello, userData, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			tokenCarrello: tokenCarrello,
			user: userData,
		};

		// chiamo web service
		const response = await axios.post(`api/updateuser/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: UPDATE_USER,
			payload: response.data,
		});
	};

export default {
	authenticateUser,
	loginUser,
	logoutUser,
	updateUser,
};
