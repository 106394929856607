import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import TitoloBlocco from '../titoloBlocco/TitoloBlocco';
import CategoryMagazineLeftItem from '../categoryMagazineLeftItem/CategoryMagazineLeftItem';

const BloccoCategorieMagazineMenuLeft = () => {
	const magazineCategories = useSelector((state) => state.magazine);
	const mainNavigation = useSelector(
		(state) => state.translations.mainNavigation
	);

	const elencoMagazineCategories = Object.keys(magazineCategories).map(
		(key) => {
			const { id, name, routeName, linkSeo } = magazineCategories[key];
			//console.log(magazineCategories[key]);
			return (
				<CategoryMagazineLeftItem
					key={_uniqueId('magazinecategory_')}
					categoryId={id}
					categoryName={name}
					routeName={routeName}
					linkSeo={linkSeo}
				/>
			);
		}
	);

	return (
		<div className='menuLeft border-r border-gray-300'>
			<TitoloBlocco
				titoloStyle='text-xl xl:text-2xl block pt-4 pl-3 3xl:pt-6 3xl:pl-6 mb-6'
				titolo={mainNavigation.magazine.itemName.toUpperCase()}
			/>
			<nav>{elencoMagazineCategories}</nav>
		</div>
	);
};

export default BloccoCategorieMagazineMenuLeft;
