import { useEffect, useState, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import allActions from '../../../state/actions';
import Briciole from '../../shared/briciole/Briciole';
import BarraCheckout from '../barraCheckout/BarraCheckout';
import InputField from '../../shared/inputField/InputField';
import CheckboxField from '../../shared/checkboxField/CheckboxField';
import ModalConfirm from '../../shared/modalConfirm/ModalConfirm';
import BoxFormIndirizzo from '../gestioneIndirizzi/boxFormIndirizzo/BoxFormIndirizzo';
import RegistrationFormFields from './registrationFormFields/RegistrationFormFields';
import { gtmCheckoutLoginRegistrazione } from '../../../gtm/gtm';
import { URLASSOLUTO } from '../../../utils/urlassoluto';

const RegistrationFormDetail = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	const {
		register,
		unregister,
		formState: { errors },
		handleSubmit,
		control,
		setValue,
		getValues,
	} = useForm({
		mode: 'onChange',
		defaultValues: {
			targetUser: currentUserTarget,
			copiaDatiRegistrazioneSpedizione: 'si',
		},
	});
	const user = useSelector((state) => state.user);
	const currentPage = useSelector((state) => state.currentPage);
	const siteParams = useSelector((state) => state.siteParams);
	const order = useSelector((state) => state.order);
	const cart = useSelector((state) => state.cart);
	const campiFormTranslation = useSelector(
		(state) => state.translations.campiFormRegistrazione
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);
	const erroriFormTranslation = useSelector(
		(state) => state.translations.erroriForm
	);
	const paginestaticheTranslation = useSelector(
		(state) => state.translations.paginestatiche
	);

	// local state
	const [currentUserTarget, setCurrentUserTarget] = useState('');
	const [nazione, setNazione] = useState('');
	const [nazioneS, setNazioneS] = useState('');
	const [
		isStessiDatiRegistrazioneSpedizione,
		setStessiDatiRegistrazioneSpedizione,
	] = useState('si');
	const [isRegistering, setIsRegistering] = useState(false);
	const [isSDIRequired, setIsSDIRequired] = useState(true);
	const [isFullNameRequired, setIsFullNameRequired] = useState(true);
	const [disableCampi, setDisableCampi] = useState(true);
	const [disableCampiSpedizione, setDisableCampiSpedizione] = useState(true);

	useEffect(() => {
		//console.log(history.location.state?.propsToSend);
		console.log('currentUserTarget', currentUserTarget);

		if (
			history.location.state?.propsToSend !== undefined &&
			currentUserTarget === ''
		) {
			console.log('eseguo', history, history.location.state?.propsToSend);
			setCurrentUserTarget(history.location.state?.propsToSend);
		}

		if (isRegistering === true && user.status === 'KO') {
			setIsRegistering(false);
			ModalConfirm({
				titleToShow: genericheTranslation.modaltitoloregistrazione.itemName,
				messageToShow:
					genericheTranslation.modalmessaggioregistrazioneko.itemName,
				isShowConfirmButton: false,
				isShowCanceButton: false,
				isTimerModal: true,
			});

			/**
			 * GTM CHECKOUT LOGIN REGISTRAZIONE - STEP 1
			 */
			const gtmSuffix = `gtm_code${currentPage.currentLang}`;
			const currentGTMTAG = siteParams[gtmSuffix];
			gtmCheckoutLoginRegistrazione({
				cartData: cart,
				isGtmEnabled: currentGTMTAG !== null ? true : false,
			});
		}

		// rimuovo controlli validazione a campi provincia, quando nascosti
		nazione === '36' || nazione === ''
			? unregister('provincia')
			: unregister('provinciaEstera');

		// svuoto i valori di provincia
		nazione === '36' || nazione === ''
			? setValue('provinciaEstera', '')
			: setValue('provincia', '');

		// se passo da azienda a privato, svuoto valori azienda
		if (currentUserTarget !== 'azienda') {
			setValue('ragioneSociale', '');
			setValue('partitaIva', '');
			setValue('codiceFiscale', '');
			setValue('pec', '');
			setValue('sdi', '');
			setValue('tipoAttivita', '');
		}

		// fix per targetUser
		if (currentUserTarget === 'azienda') {
			setValue('targetUser', 'azienda');
		} else if (currentUserTarget === 'privato') {
			setValue('targetUser', 'privato');
		} else if (currentUserTarget === 'ospite') {
			setValue('targetUser', 'ospite');
		}

		// se passo ad azienda tolgo l'obblitatorietà di Nome e cognome
		if (currentUserTarget === 'azienda') {
			unregister('nome');
			unregister('cognome');
			setIsFullNameRequired(false);
		} else {
			register('nome');
			register('cognome');
			setIsFullNameRequired(true);
		}

		// se passo a ospite, svuoto valori di spedizione (uso quelli principali) e dati login e faccio unregister
		if (currentUserTarget === 'ospite') {
			unregister('password');
			unregister('confermaPassword');
			unregister('capS');
			unregister('cognomeS');
			unregister('emailS');
			unregister('indirizzoS');
			unregister('nazioneS');
			unregister('nomeS');
			unregister('provinciaEsteraS');
			unregister('provinciaS');
			unregister('telefonoS');

			setValue('password', '');
			setValue('confermaPassword', '');
			setValue('capS', '');
			setValue('cognomeS', '');
			setValue('emailS', '');
			setValue('indirizzoS', '');
			setValue('nazioneS', '');
			setValue('nomeS', '');
			setValue('provinciaEsteraS', '');
			setValue('provinciaS', '');
			setValue('telefonoS', '');

			setValue('targetUser', 'ospite');
		}

		// svuoto i valori di provinciaS
		nazioneS === '36' || nazioneS === ''
			? setValue('provinciaEsteraS', '')
			: setValue('provinciaS', '');
	}, [currentUserTarget, isFullNameRequired, nazione, nazioneS, user]);

	const onChangeTargetUser = (targetUserValue) => {
		console.log(targetUserValue);

		setCurrentUserTarget(targetUserValue);
	};

	const onChangeNazione = (event) => {
		// rimuovo controlli validazione sdi o rimetto (se nazione estera sdi non è obbligatorio)
		if (event.target.value !== '36') {
			unregister('sdi');
			setIsSDIRequired(false);
		} else {
			register('sdi');
			setIsSDIRequired(true);
		}

		setValue('citta', ''); // resetto città
		setDisableCampi(false); // abilito i campi città e provincia
		setNazione(event.target.value);
	};

	const onChangeNazioneS = (event) => {
		setDisableCampiSpedizione(false); // abilito i campi città e provincia
		setNazioneS(event.target.value);
	};

	const onChangeStessiDatiRegistrazioneSpedizione = (event) => {
		setStessiDatiRegistrazioneSpedizione(event.target.value);
	};

	const onSubmitFormRegistrazione = (data) => {
		console.log(data);

		// chiamo action creator
		setIsRegistering(true);

		dispatch(
			allActions.registrationDetailActions.registerUser(
				currentPage.currentLang,
				user.token_id,
				data
			)
		);
	};

	const labelStylePrivato =
		currentUserTarget === 'privato' ||
		(currentUserTarget === undefined && targetUser === 'privato')
			? 'font-opensansbold text-ribola-congress-blue'
			: 'text-black cursor-pointer';
	const labelStyleAzienda =
		currentUserTarget === 'azienda' ||
		(currentUserTarget === undefined && targetUser === 'azienda')
			? 'font-opensansbold text-ribola-congress-blue'
			: 'text-black cursor-pointer';
	const labelStyleOspite =
		currentUserTarget === 'ospite' ||
		(currentUserTarget === undefined && targetUser === 'ospite')
			? 'font-opensansbold text-ribola-congress-blue'
			: 'text-black cursor-pointer';

	const isInputDisabled = currentUserTarget !== 'azienda' ? true : false;
	const inputDisabledStyle =
		currentUserTarget !== 'azienda' ? 'bg-gray-300' : '';
	const inputDisabledStyleAzienda =
		currentUserTarget === 'azienda' ? 'bg-gray-300' : '';
	const divDisabledStyle =
		currentUserTarget !== 'azienda' ? 'pointer-events-none opacity-20' : '';
	const divDisabledStyleAzienda =
		currentUserTarget === 'azienda' ? 'pointer-events-none opacity-20' : '';

	const isInputDisabledOspite = currentUserTarget === 'ospite' ? true : false;
	const divDisabledStyleOspite =
		currentUserTarget === 'ospite' ? 'hidden' : 'flex';

	const isRegistrazioneOSpedizione =
		currentUserTarget === 'ospite'
			? campiFormTranslation.datispedizione.itemName
			: campiFormTranslation.datiregistrazione.itemName;

	const isBoxSpedizioneVisible =
		isStessiDatiRegistrazioneSpedizione === 'si' ||
		currentUserTarget === 'ospite'
			? 'hidden'
			: 'flex';
	const isInputDisabledSpedizione =
		isStessiDatiRegistrazioneSpedizione === 'si' ? true : false;

	const nazioneEsteraCampiVisibili =
		nazione !== '36' && nazione !== '' ? true : false;

	const nazioneEsteraSpedizioneCampiVisibili =
		nazioneS !== '36' && nazioneS !== '' ? true : false;

	const isUserOspite = currentUserTarget === 'ospite' ? true : false;

	const ospiteCheckbox =
		order.totalItems > 0 ? (
			<div className='md:mx-6 w-auto md:w-40'>
				<CheckboxField
					checkboxType='radio'
					checkboxLabel={campiFormTranslation.ospite.itemName}
					checkboxLabelStyle={`text-lg sm:text-xl md:text-2xl ${labelStyleOspite}`}
					checkboxName='targetUser'
					checkboxValue='ospite'
					checkboxHandlerFunction={() => onChangeTargetUser('ospite')}
					isChecked={currentUserTarget === 'ospite' ? true : false}
					isFocus={currentUserTarget === 'ospite' ? true : false}
					checkboxStyle='border-2 text-xl md:text-2xl text-ribola-congress-blue border-ribola-congress-blue mr-3 cursor-pointer'
					direction='right'
					register={register('targetUser')}
				/>
			</div>
		) : (
			''
		);

	const divPerSpedizione =
		order.totalItems > 0 && isStessiDatiRegistrazioneSpedizione == 'no' ? (
			<Fragment>
				<div className='col-span-60'>
					<BoxFormIndirizzo
						onChangeNazioneSFunction={onChangeNazioneS}
						isInputDisabledSpedizione={isInputDisabledSpedizione}
						isBoxSpedizioneVisible={isBoxSpedizioneVisible}
						isUserOspite={isUserOspite}
						nazioneEsteraCampiVisibili={nazioneEsteraSpedizioneCampiVisibili}
						disableCampiProvinciaCitta={disableCampiSpedizione}
						errors={errors}
						register={register}
						Controller={Controller}
						control={control}
					/>
				</div>
			</Fragment>
		) : (
			''
		);

	// ERRORI VARI VALIDAZIONE
	const passwordError = errors.password ? (
		<p className='text-sm text-red-600'>
			<i className='fal fa-exclamation-triangle mr-1'></i>
			{errors.password.message}
		</p>
	) : (
		''
	);

	const confermaPasswordError = errors.confermaPassword ? (
		<p className='text-sm text-red-600'>
			<i className='fal fa-exclamation-triangle mr-1'></i>
			{errors.confermaPassword.message}
		</p>
	) : (
		''
	);

	const privacyError = errors.privacy ? (
		<p className='text-sm text-red-600 ml-3 self-end'>
			<i className='fal fa-exclamation-triangle mr-1'></i>
			{errors.privacy.message}
		</p>
	) : (
		''
	);

	const condizioniVenditaError = errors.condizionivendita ? (
		<p className='text-sm text-red-600 ml-3 self-end'>
			<i className='fal fa-exclamation-triangle mr-1'></i>
			{errors.condizionivendita.message}
		</p>
	) : (
		''
	);

	// FINE ERRORI VARI VALIDAZIONE

	const isDisabledClick = isRegistering ? 'pointer-events-none' : '';
	const isButtonSending = isRegistering ? true : false;

	return (
		<div
			className={`col-start-3 4xl:col-start-5 col-end-59 4xl:col-end-57 sm:pb-12 ${isDisabledClick}`}>
			<Briciole />
			<BarraCheckout currentCheckoutPage={currentPage.routeName} />
			<form onSubmit={handleSubmit(onSubmitFormRegistrazione)}>
				<div className='grid grid-cols-60 mt-12 md:mt-20'>
					<div className='col-span-60 flex flex-col justify-center items-center mb-4 sm:mb-8'>
						<span
							className='text-3xl md:text-4xl text-ribola-congress-blue font-opensansbold'
							dangerouslySetInnerHTML={{
								__html: isRegistrazioneOSpedizione,
							}}
						/>
						<div className='flex justify-center items-center mt-6 sm:mt-12'>
							<div className='md:mx-6 w-auto md:w-60'>
								<CheckboxField
									checkboxType='radio'
									checkboxLabel={campiFormTranslation.privato.itemName}
									checkboxLabelStyle={`text-lg sm:text-xl md:text-2xl ${labelStylePrivato}`}
									checkboxName='targetUser'
									checkboxValue='privato'
									checkboxHandlerFunction={() => onChangeTargetUser('privato')}
									isChecked={currentUserTarget === 'privato' ? true : false}
									isFocus={currentUserTarget === 'privato' ? true : false}
									checkboxStyle='border-2 text-xl md:text-2xl text-ribola-congress-blue border-ribola-congress-blue mr-3 cursor-pointer'
									direction='right'
									register={register('targetUser')}
								/>
							</div>
							<div className='md:mx-6 w-auto md:w-60'>
								<CheckboxField
									checkboxType='radio'
									checkboxLabel={campiFormTranslation.azienda.itemName}
									checkboxLabelStyle={`text-lg sm:text-xl md:text-2xl ${labelStyleAzienda}`}
									checkboxName='targetUser'
									checkboxValue='azienda'
									checkboxHandlerFunction={() => onChangeTargetUser('azienda')}
									isChecked={currentUserTarget === 'azienda' ? true : false}
									isFocus={currentUserTarget === 'azienda' ? true : false}
									checkboxStyle='border-2 text-xl md:text-2xl text-ribola-congress-blue border-ribola-congress-blue mr-3 cursor-pointer'
									direction='right'
									register={register('targetUser')}
								/>
							</div>
							{ospiteCheckbox}
						</div>
					</div>
					<RegistrationFormFields
						onChangeNazioneFunction={onChangeNazione}
						isDivDisabledStyle={divDisabledStyle}
						isDivDisabledStyleAzienda={divDisabledStyleAzienda}
						isInputDisabled={isInputDisabled}
						isinputDisabledStyle={inputDisabledStyle}
						isinputDisabledStyleAzienda={inputDisabledStyleAzienda}
						isSDIRequired={isSDIRequired}
						isFullNameRequired={isFullNameRequired}
						nazioneEsteraCampiVisibili={nazioneEsteraCampiVisibili}
						disableCampiProvinciaCitta={disableCampi}
						errors={errors}
						register={register}
						Controller={Controller}
						control={control}
					/>
					<div
						className={`col-span-60 flex flex-col bg-ribola-gallery-gray text-ribola-heavy-metal py-8 px-8 mt-8 ${divDisabledStyleOspite}`}>
						<span className='text-3xl md:text-4xl text-ribola-congress-blue font-opensansbold w-full text-center'>
							{campiFormTranslation.datilogin.itemName.toUpperCase()}
						</span>
						<span
							className='sm:text-lg md:text-xl block text-center mt-6'
							dangerouslySetInnerHTML={{
								__html: campiFormTranslation.sottotitolosceglipassword.itemName,
							}}
						/>
						<div className='grid grid-cols-60 sm:gap-2 4xl:gap-4 mt-3'>
							<div className='col-start-1 lg:col-start-11 col-end-61 lg:col-end-31'>
								<InputField
									inputType='password'
									inputLabel='<b>Password: *</b>'
									inputLabelStyle='flex justify-between items-center w-full sm:w-11/12 sm:text-lg 4xl:text-xl text-black mt-4 sm:mt-8'
									inputName='password'
									isDisabled={isInputDisabledOspite}
									inputPlaceholder='password'
									inputStyle='border mt-3 py-3 px-8 text-xl bg-white placeholder-opacity-50 w-full sm:w-11/12'
									inputError={passwordError}
									register={
										!isUserOspite &&
										register('password', {
											required: `${erroriFormTranslation.erroreinserirecampo.itemName} password`,
										})
									}
								/>
							</div>
							<div className='col-start-1 lg:col-start-34 col-end-61 lg:col-end-54'>
								<InputField
									inputType='password'
									inputLabel={`<b>${campiFormTranslation.confermapassword.itemName}: *</b>`}
									inputLabelStyle='flex justify-between items-center w-full sm:w-11/12 sm:text-lg 4xl:text-xl text-black mt-4 sm:mt-8'
									inputName='confermaPassword'
									isDisabled={isInputDisabledOspite}
									inputPlaceholder={
										campiFormTranslation.confermapassword.itemName
									}
									inputStyle='border mt-3 py-3 px-8 text-xl bg-white placeholder-opacity-50 w-full sm:w-11/12'
									inputError={confermaPasswordError}
									register={
										!isUserOspite &&
										register('confermaPassword', {
											required: `${erroriFormTranslation.erroreinserirecampo.itemName} ${campiFormTranslation.confermapassword.itemName}`,
											validate: {
												matchesPreviousPassword: (value) => {
													const { password } = getValues();
													return (
														password === value ||
														campiFormTranslation.passworddiverse.itemName
													);
												},
											},
										})
									}
								/>
							</div>
						</div>
					</div>
					<div
						className={`col-span-60 flex-col py-3 sm:py-8 px-0 lg:px-8 mt-6 ${divDisabledStyleOspite}`}>
						<CheckboxField
							checkboxType='radio'
							checkboxLabel={campiFormTranslation.stessidatispedizione.itemName}
							checkboxLabelStyle='md:text-lg 2xl:text-xl flex'
							checkboxName='copiaDatiRegistrazioneSpedizione'
							checkboxValue='si'
							checkboxHandlerFunction={
								onChangeStessiDatiRegistrazioneSpedizione
							}
							isChecked={
								isStessiDatiRegistrazioneSpedizione === 'si' ? true : false
							}
							isDisabled={isInputDisabledOspite}
							checkboxStyle='border-2 text-ribola-congress-blue border-ribola-nobel mr-3 w-6 h-6 cursor-pointer'
							direction='right'
							register={register('copiaDatiRegistrazioneSpedizione')}
						/>
						<CheckboxField
							checkboxType='radio'
							checkboxLabel={campiFormTranslation.cambiadatispedizione.itemName}
							checkboxLabelStyle='md:text-lg 2xl:text-xl flex mt-8'
							checkboxName='copiaDatiRegistrazioneSpedizione'
							checkboxValue='no'
							checkboxHandlerFunction={
								onChangeStessiDatiRegistrazioneSpedizione
							}
							isChecked={
								isStessiDatiRegistrazioneSpedizione === 'no' ? true : false
							}
							isDisabled={isInputDisabledOspite}
							checkboxStyle='border-2 text-ribola-congress-blue border-ribola-nobel mr-3 w-6 h-6 cursor-pointer'
							direction='right'
							register={register('copiaDatiRegistrazioneSpedizione')}
						/>
					</div>
					{divPerSpedizione}
					<div className='col-span-60 flex-col py-8 xl:pl-8 mt-3'>
						<div className='flex flex-col md:flex-row justify-between'>
							<div className='flex flex-col'>
								<div className='flex'>
									<CheckboxField
										checkboxType='checkbox'
										checkboxLabel={`<a href='${URLASSOLUTO}${paginestaticheTranslation.privacypolicy.itemLink}' target='_blank'>${campiFormTranslation.holettolaprivacy.itemName}</a>`}
										checkboxLabelStyle='lg:text-lg italic flex underline mt-8'
										checkboxName='privacy'
										checkboxStyle='border-2 text-ribola-congress-blue border-ribola-nobel mr-3 w-6 h-6 cursor-pointer'
										direction='right'
										checkboxError={privacyError}
										register={register('privacy', {
											required:
												erroriFormTranslation.erroraccettacondizioni.itemName,
										})}
									/>
								</div>
								<div className='flex'>
									<CheckboxField
										checkboxType='checkbox'
										checkboxLabel={`<a href='${URLASSOLUTO}${paginestaticheTranslation.condizionivendita.itemLink}' target='_blank'>${campiFormTranslation.holettolecondizionidivendita.itemName}</a>`}
										checkboxLabelStyle='lg:text-lg italic flex underline mt-8'
										checkboxName='condizionivendita'
										checkboxStyle='border-2 text-ribola-congress-blue border-ribola-nobel mr-3 w-6 h-6 cursor-pointer'
										direction='right'
										checkboxError={condizioniVenditaError}
										register={register('condizionivendita', {
											required:
												erroriFormTranslation.erroraccettacondizioni.itemName,
										})}
									/>
								</div>
								<span className='italic xl:text-lg inline-block mt-8'>
									{campiFormTranslation.campiobbligatori.itemName}
								</span>
							</div>
							<div className='flex items-end'>
								<InputField
									inputType='submit'
									inputValue={genericheTranslation.invia.itemName.toUpperCase()}
									inputStyle='w-full md:w-max flex justify-center items-center text-white bg-ribola-congress-blue sm:text-lg 4xl:text-xl font-opensanssemibold py-4 px-24 border-r-2 border-ribola-gallery-gray cursor-pointer mt-8 md:mt-0'
									showLabel={false}
									isSpinnerLoading={isButtonSending}
								/>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default RegistrationFormDetail;
