import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import Briciole from '../shared/briciole/Briciole';
import ProductBoxItem from '../shared/productBoxItem/ProductBoxItem';
import ProductOutletBoxItem from '../shared/productOutletBoxItem/ProductOutletBoxItem';
import { BASESTORAGE } from '../../utils/basestorage';
import { gtmImpressions } from '../../gtm/gtm';

const CategoryDetail = () => {
	const currentLang = useSelector((state) => state.currentPage.currentLang);
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const promotion = useSelector((state) => state.promotion);
	const siteParams = useSelector((state) => state.siteParams);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	// local state
	const [showFullDescription, setShowFullDescription] = useState(false);

	useEffect(() => {
		//console.log(prezzoFisso);
	}, []);

	// controllo che esista la proprietà detail e che pageContent abbia la proprietà childProducts (altrimenti vuol dire che sto ancora recuperando i dati)
	if (
		!pageContent.detail ||
		!pageContent.detail.descrizione ||
		pageContent.childProducts === undefined
	) {
		return <div></div>;
	}

	// gtm impressions prodotti
	const gtmSuffix = `gtm_code${currentLang}`;
	const currentGTMTAG = siteParams[gtmSuffix];
	if (
		pageContent.childProducts !== null &&
		pageContent.childProducts !== undefined &&
		currentGTMTAG !== null
	) {
		gtmImpressions({
			productList: pageContent.childProducts,
			categoryName: pageContent.detail.titolo,
		});
	}

	// recupero eventuale promozione a tempo di categorie
	const promozioneCategoria = promotion?.elencoCategorie?.filter(
		(item) => item.id === pageContent.id
	);

	const onScopriClickHandler = () => {
		setShowFullDescription(!showFullDescription);
	};

	const showDesc = showFullDescription ? 'line-clamp-none' : 'line-clamp-6';
	const rotateArrow = showFullDescription
		? 'transform -rotate-180'
		: 'transform rotate-0';

	const elencoProducts = Object.keys(pageContent.childProducts).map(
		(key, index) => {
			const {
				id,
				image,
				imageAlt,
				detail,
				isProntaConsegna,
				routeName,
				linkSeo,
			} = pageContent.childProducts[key];

			const titolo_breve = detail.titolo_breve;
			const sottotitolo_elenco = detail.sottotitolo_elenco;
			const codice = detail.codice;
			const imponibile = detail.imponibile;
			const sconto = detail.sconto;
			const prezzoScontato = detail.prezzoScontato;
			const measure_unit = detail.measure_unit;
			const flag_abilita_tag_bestseller =
				detail.flags.flag_abilita_tag_bestseller;
			const flag_abilita_tag_novita = detail.flags.flag_abilita_tag_novita;
			const flag_abilita_informazioni_prodotto =
				detail.flags.flag_abilita_informazioni_prodotto;
			const flag_abilita_prezzi_fissi = detail.flags.flag_abilita_prezzi_fissi;
			const colore = pageContent.hoverColor;

			// pronta consegna?
			const prontaConsegna = isProntaConsegna;
			const lunghezza = detail.lunghezza;
			const larghezza = detail.larghezza;
			const profondita = detail.profondita;
			const metrilineari = detail.metrilineari;
			const giacenza = detail.giacenza;
			const venduti = detail.venduti;

			// sconto promozione categoria?
			let tipologiaScontoP = null;
			if (promozioneCategoria !== undefined) {
				tipologiaScontoP = promotion.tipologiaSconto;
			}
			// fine sconto promozione categoria

			// recupero eventuale sconto prodotto
			if (sconto > 0) {
				tipologiaScontoP = detail.tipologia_sconto;
			}
			// fine promozione prodotto

			// promozione 3x2?
			let isProdotto3x2 = false;
			if (promotion?.tipologiaPromozione === 'Promozione 3x2') {
				const prodotto3x2 = promotion?.elencoProdotti?.filter((item) => {
					return item.id === id;
				})[0];
				if (prodotto3x2) {
					isProdotto3x2 = true;
				}
			}

			// promozione prodotto abbinato?
			let promoProdottoAbbinato = null;
			if (
				promotion &&
				promotion?.tipologiaPromozione === 'Promozione Prodotto abbinato'
			) {
				promoProdottoAbbinato = promotion?.elencoProdotti?.filter((item) => {
					return item.id === id;
				})[0];
			}

			// prezzo fisso?
			const prezzoFisso =
				flag_abilita_prezzi_fissi === 1 ? detail?.prezzoFisso?.prezzo : null;

			const prodottoToShow = prontaConsegna ? (
				<ProductOutletBoxItem
					id={id}
					index={index}
					immagine={image}
					titoloBreve={titolo_breve}
					sottotitoloElenco={sottotitolo_elenco}
					codice={codice}
					imponibile={imponibile}
					sconto={sconto}
					prezzoScontato={prezzoScontato}
					lunghezza={lunghezza}
					larghezza={larghezza}
					profondita={profondita}
					metrilineari={metrilineari}
					giacenza={giacenza}
					venduti={venduti}
					colore={colore}
					routeName={routeName}
					linkSeo={linkSeo}
					categoriaTitolo={pageContent.detail.titolo}
				/>
			) : (
				<ProductBoxItem
					id={id}
					index={index}
					immagine={image}
					immagineAlternativa={imageAlt}
					titoloBreve={titolo_breve}
					sottotitoloElenco={sottotitolo_elenco}
					codice={codice}
					imponibile={imponibile}
					sconto={sconto}
					tipologiaScontoPromozione={tipologiaScontoP}
					prezzoScontato={prezzoScontato}
					unitaMisura={measure_unit}
					iconaTagBestseller={flag_abilita_tag_bestseller}
					iconaTagNovita={flag_abilita_tag_novita}
					richiediInfo={flag_abilita_informazioni_prodotto}
					prodotto3x2={isProdotto3x2}
					prodottoAbbinatoObj={promoProdottoAbbinato}
					prezzoFisso={prezzoFisso}
					colore={colore}
					routeName={routeName}
					linkSeo={linkSeo}
					categoriaTitolo={pageContent.detail.titolo}
				/>
			);

			return (
				<div
					key={_uniqueId('productItem_')}
					className='col-span-60 sm:col-span-30 md:col-span-20 2xl:col-span-15 flex justify-center elencoProdotti mb-10 mx-8 sm:mx-0'>
					{prodottoToShow}
				</div>
			);
		}
	);

	const sottotitolo =
		pageContent.detail.sottotitolo !== null ? (
			<h2 className='text-xl xl:text-2xl font-opensansbold mb-6'>
				{pageContent.detail.sottotitolo.toUpperCase()}
			</h2>
		) : (
			''
		);

	const descrizioneToShow = pageContent.isProntaConsegna
		? pageContent.detail.descrizione_outlet
		: pageContent.detail.descrizione;

	return (
		<div className='col-start-1 lg:col-start-15 4xl:col-start-16 col-end-61 3xl:col-end-59 4xl:col-end-57 sm:pb-12'>
			<Briciole />
			<div className='grid grid-cols-60 mt-0 sm:mt-3 xl:mt-6'>
				<div className='col-span-60 flex justify-between items-center px-8 xl:px-12 3xl:px-20 pt-3 pb-6 sm:py-3'>
					<h1
						className={`text-${pageContent.hoverColor} text-3xl md:text-4xl xl:text-5xl font-opensansbold`}>
						<small className='leading-tight flex'>
							{pageContent.detail.titolo.toUpperCase()}
						</small>
					</h1>
					<img
						src={
							BASESTORAGE +
							'/' +
							pageContent.icona.folder +
							'/' +
							pageContent.icona.file_name
						}
						width='140'
						height='140'
						className='flex-start w-12 sm:w-32'
						alt={pageContent.detail.titolo}
					/>
				</div>
				<div className='col-span-60 flex flex-col bg-ribola-gallery-gray px-8 xl:px-12 3xl:px-20 py-8 sm:py-12'>
					{sottotitolo}
					<p
						className={`text-sm ${showDesc} md:line-clamp-none`}
						dangerouslySetInnerHTML={{
							__html: descrizioneToShow,
						}}></p>
					<span
						className='md:hidden font-opensanssemibold italic text-sm text-ribola-heavy-metal flex justify-center py-3 cursor-pointer'
						onClick={onScopriClickHandler}>
						<span className='underline'>
							{genericheTranslation.scopristatic.itemName}
						</span>
						<i className={`fal fa-chevron-down ml-2 ${rotateArrow}`}></i>
					</span>
				</div>
			</div>
			<div className='grid grid-cols-60 mt-12'>{elencoProducts}</div>
		</div>
	);
};

export default CategoryDetail;
