import axios from '../../utils/axiosBase';
import {
	FETCH_PROFILE_DETAIL,
	LOAD_PROFILE_DETAIL,
	FETCH_ADDRESSES_DETAIL,
	LOAD_ADDRESSES_DETAIL,
	FETCH_ORDERS_DETAIL,
	LOAD_ORDERS_DETAIL,
	UPDATE_ADDRESS,
	CREATE_ADDRESS,
	DELETE_ADDRESS,
} from './types';

const fetchProfileDetail =
	(currentLang, userId, tokenCarrello, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			tokenCarrello: tokenCarrello,
		};

		// chiamo web service
		const response = await axios.post(`api/profiledetail/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_PROFILE_DETAIL,
			payload: response.data,
		});
	};

const loadProfileDetail =
	(profileLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_PROFILE_DETAIL,
			payload: {
				profileLoaded,
			},
		});
	};

const fetchAddressesDetail =
	(currentLang, tokenCarrello, userId, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			tokenCarrello: tokenCarrello,
			userId: userId,
		};

		// chiamo web service
		const response = await axios.post(`api/addressesdetail/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_ADDRESSES_DETAIL,
			payload: response.data,
		});
	};

const loadAddressesDetail =
	(addressesLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_ADDRESSES_DETAIL,
			payload: {
				addressesLoaded,
			},
		});
	};

const fetchOrdersDetail =
	(currentLang, tokenCarrello, userId, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			tokenCarrello: tokenCarrello,
			userId: userId,
		};

		// chiamo web service
		const response = await axios.post(`api/ordersdetail/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_ORDERS_DETAIL,
			payload: response.data,
		});
	};

const loadOrdersDetail =
	(ordersLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_ORDERS_DETAIL,
			payload: {
				ordersLoaded,
			},
		});
	};

const updateAddress =
	(currentLang, userId, tokenCarrello, addressData, history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			tokenCarrello: tokenCarrello,
			addressData: addressData,
		};

		// chiamo web service
		const response = await axios.post(`api/updateaddress/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: UPDATE_ADDRESS,
			payload: response.data,
		});
	};

const createAddress =
	(currentLang, userId, addressData, history) => async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			addressData: addressData,
		};

		// chiamo web service
		const response = await axios.post(`api/createaddress/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: CREATE_ADDRESS,
			payload: response.data,
		});
	};

const deleteAddress =
	(currentLang, userId, addressId, history) => async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			addressId: addressId,
		};

		// chiamo web service
		const response = await axios.post(`api/deleteaddress/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: DELETE_ADDRESS,
			payload: response.data,
		});
	};

export default {
	fetchProfileDetail,
	fetchAddressesDetail,
	fetchOrdersDetail,
	loadProfileDetail,
	loadAddressesDetail,
	loadOrdersDetail,
	updateAddress,
	createAddress,
	deleteAddress,
};
