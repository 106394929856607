import { useSelector } from 'react-redux';
import { BASENAME } from '../../../utils/basename';

const BoxIconeMenuLeft = () => {
	const vociIconeTranslation = useSelector(
		(state) => state.translations.iconeMenuLeft
	);

	return (
		<div className='bg-ribola-gallery-gray flex flex-col justify-center mt-6 px-4 pt-8'>
			<div className='grid grid-cols-60 mb-6'>
				<div className='col-span-25 xl:col-span-30 flex justify-center items-center mr-4 xl:mx-4'>
					<img
						src={BASENAME + '/images/reso_gratuito.svg'}
						width='59'
						height='58'
						className='flex-start w-8 xl:w-12 p-1 sm:p-0'
						alt={vociIconeTranslation.resoGratuito.itemName}
					/>
				</div>
				<div className='col-span-30 flex items-center font-opensanssemibold text-ribola-dust-gray text-sm mr-4'>
					{vociIconeTranslation.resoGratuito.itemName.toUpperCase()}
				</div>
			</div>
			<div className='grid grid-cols-60 mb-6'>
				<div className='col-span-25 xl:col-span-30 flex justify-center items-center mr-4 xl:mx-4'>
					<img
						src={BASENAME + '/images/spedizione_espressa.svg'}
						width='80'
						height='42'
						className='flex-start w-8 xl:w-12'
						alt={vociIconeTranslation.spedizioniRapide.itemName}
					/>
				</div>
				<div className='col-span-30 flex items-center font-opensanssemibold text-ribola-dust-gray text-sm mr-4'>
					{vociIconeTranslation.spedizioniRapide.itemName.toUpperCase()}
				</div>
			</div>
			<div className='grid grid-cols-60 mb-6'>
				<div className='col-span-25 xl:col-span-30 flex justify-center items-center mr-4 xl:mx-4'>
					<img
						src={BASENAME + '/images/pagamenti.svg'}
						width='58'
						height='50'
						className='flex-start w-8 xl:w-12 p-1 sm:p-0'
						alt={vociIconeTranslation.pagamentiSicuri.itemName}
					/>
				</div>
				<div className='col-span-30 flex items-center font-opensanssemibold text-ribola-dust-gray text-sm mr-4'>
					{vociIconeTranslation.pagamentiSicuri.itemName.toUpperCase()}
				</div>
			</div>
			<div className='grid grid-cols-60 mb-4'>
				<div className='col-span-25 xl:col-span-30 flex justify-center items-center mr-4 xl:mx-4'>
					<img
						src={BASENAME + '/images/assistenza.svg'}
						width='54'
						height='54'
						className='flex-start w-8 xl:w-12 p-1 sm:p-0'
						alt={vociIconeTranslation.assistenza.itemName}
					/>
				</div>
				<div className='col-span-30 flex items-center font-opensanssemibold text-ribola-dust-gray text-sm mr-4'>
					{vociIconeTranslation.assistenza.itemName.toUpperCase()}
				</div>
			</div>
			<img
				src={BASENAME + '/images/sagoma_ribola.svg'}
				width='183'
				height='70'
				className='self-end opacity-50'
				alt='sagoma ribola'
			/>
		</div>
	);
};

export default BoxIconeMenuLeft;
