import { Fragment, useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import TabLink from './tabLink/TabLink';
import TabContent from './tabContent/TabContent';

const ProductTabs = () => {
	const { descrizione, noteTecniche, download, video } = useSelector(
		(state) => state.currentPage.pageContent.detail
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);
	const [openTab, setOpenTab] = useState(1);

	useEffect(() => {
		console.log(noteTecniche, download, video);
	}, [openTab]);

	const onTabHandler = (tabId) => {
		setOpenTab(tabId);
	};

	const descrizioneLungaTab =
		descrizione !== '' ? (
			<TabLink
				idTab={1}
				label={genericheTranslation.descrizione.itemName}
				openTab={openTab}
				onTabClick={onTabHandler}
			/>
		) : (
			''
		);

	const descrizioneLungaContent =
		descrizione !== '' ? (
			<TabContent idContent={1} openTab={openTab} showScopri={true}>
				{descrizione}
			</TabContent>
		) : (
			''
		);

	const noteTecnicheTab =
		noteTecniche !== null && noteTecniche !== '' ? (
			<TabLink
				idTab={2}
				label={genericheTranslation.notetecniche.itemName}
				openTab={openTab}
				onTabClick={onTabHandler}
			/>
		) : (
			''
		);

	const noteTecnicheContent =
		noteTecniche !== null && noteTecniche !== '' ? (
			<TabContent idContent={2} openTab={openTab}>
				{noteTecniche}
			</TabContent>
		) : (
			''
		);

	const downloadTab =
		download !== null && download !== '' ? (
			<TabLink
				idTab={3}
				label='Download'
				openTab={openTab}
				onTabClick={onTabHandler}
			/>
		) : (
			''
		);

	const downloadContent =
		download !== null && download !== '' ? (
			<TabContent idContent={3} openTab={openTab}>
				{download}
			</TabContent>
		) : (
			''
		);

	const videoTab =
		video !== null && video !== '' ? (
			<TabLink
				idTab={4}
				label={genericheTranslation.video.itemName}
				openTab={openTab}
				onTabClick={onTabHandler}
			/>
		) : (
			''
		);

	const videoContent =
		video !== null && video !== '' ? (
			<TabContent idContent={4} openTab={openTab}>
				{video}
			</TabContent>
		) : (
			''
		);

	return (
		<Fragment>
			<ul
				className='flex mb-0 list-none flex-wrap bg-ribola-nobel flex-row'
				role='tablist'>
				{descrizioneLungaTab}
				{noteTecnicheTab}
				{downloadTab}
				{videoTab}
			</ul>
			<div className='relative flex flex-col min-w-0 break-words w-full bg-ribola-gallery-gray'>
				<div className='px-6 sm:px-10 2xl:px-16 py-6 sm:py-8 flex-auto'>
					<div className='tab-content tab-space text-sm'>
						{descrizioneLungaContent}
						{noteTecnicheContent}
						{downloadContent}
						{videoContent}
					</div>
				</div>
			</div>
		</Fragment>
	);
};

export default ProductTabs;
