import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../state/actions';
import CheckboxField from '../../shared/checkboxField/CheckboxField';
import CustomButton from '../../shared/customButton/CustomButton';
import InputField from '../../shared/inputField/InputField';
import PaypalButtons from '../checkoutDetail/paypalButtons/PaypalButtons';
import ModalConfirm from '../../shared/modalConfirm/ModalConfirm';
import debounce from '../../../utils/debounce';

const RiepilogoCarrello = ({
	isButtonSending,
	isPaypal = false,
	onPaypalSubmit = () => {},
}) => {
	const dispatch = useDispatch();
	const tokenCarrello = useSelector((state) => state.user.token_id);
	const currentPage = useSelector((state) => state.currentPage);
	const order = useSelector((state) => state.order);
	const shippingCountries = useSelector(
		(state) => state.checkout.shippingCountries
	);
	const user = useSelector((state) => state.user);
	const checkout = useSelector((state) => state.checkout);
	const routeName = useSelector((state) => state.currentPage.routeName);
	const promotion = useSelector((state) => state.promotion);
	const fasiCarrelloTranslation = useSelector(
		(state) => state.translations.fasiCarrello
	);
	const riepilogoCarrelloTranslation = useSelector(
		(state) => state.translations.riepilogoCarrello
	);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);
	const erroriFormTranslation = useSelector(
		(state) => state.translations.erroriForm
	);

	// local state
	const [calcolaspedizione, setCalcolaSpedizione] = useState('');
	const [selectedCountry, setSelectedCountry] = useState(
		order.selectedCountryId
	);
	const [selectedCountryName, setSelectedCountryName] = useState('Italia');
	const [aliquotaIVA, setAliquotaIVA] = useState(order.aliquotaIVA);
	const [aliquotaIVADesc, setAliquotaIVADesc] = useState(
		order.descrizioneAliquotaIVA
	);
	const [subtotalCartVAT, setSubtotalCartVAT] = useState(order.subtotalCartVAT);
	const [shippingCost, setShippingCost] = useState(0);
	const [shippingVATCost, setShippingVATCost] = useState(0);
	const [totalCart, setTotalCart] = useState(order.totalCart);
	const [totalCartByCap, setTotalCartByCap] = useState(order.totalCart);
	const [totalToShow, setTotalToShow] = useState(0);
	const [couponSconto, setCouponSconto] = useState('');
	const [isCouponSending, setIsCouponSending] = useState(false);
	const [selectedCAP, setSelectedCAP] = useState(null);
	const [isNoShipping, setIsNoShipping] = useState(null);

	useEffect(() => {
		console.log('selectedCountry', selectedCountry);
		console.log('order.isNoShipping', order.isNoShipping);
		console.log('order.totalCart', order.totalCart);
		setSubtotalCartVAT(order.subtotalCartVAT);
		setShippingCost(order.totalShipping);
		setShippingVATCost(order.totalShippingVAT);
		setTotalCart(order.totalCart);
		setSelectedCountry(order.selectedCountryId);
		setAliquotaIVA(order.aliquotaIVA);
		setAliquotaIVADesc(order.descrizioneAliquotaIVA);

		// se cambio da shipping a noshipping aggiorno stato locale
		if (isNoShipping !== order.isNoShipping) {
			// fix anteprima totale carrello quando passo da ritiro in sede a spedizione 03/03/2022
			isNoShipping !== order.isNoShipping
				? setTotalToShow(order.totalCart)
				: setTotalToShow(totalCart);
			setTotalCart(totalToShow);

			console.log('totalToShow', totalToShow);
			console.log('totalCart', totalCart);
		}

		order.isNoShipping === true
			? setCalcolaSpedizione('ritiroinsede')
			: setCalcolaSpedizione('calcolaspedizione');
		setIsNoShipping(order.isNoShipping);

		// messaggio inserimento coupon?
		if (isCouponSending) {
			ModalConfirm({
				titleToShow: erroriFormTranslation.coupontitolo.itemName,
				messageToShow: order.message,
				isShowConfirmButton: false,
				isShowCanceButton: false,
				isTimerModal: true,
			});
			setIsCouponSending(false);
		}

		// se il calcolo spedizione da CAP è cambiato mostro i nuovi valori all'utente (solo anteprima)
		if (
			order?.capSelected !== undefined &&
			selectedCAP !== null &&
			order?.capSelected === selectedCAP
		) {
			showShippingFromCap();
		}
	}, [
		order.totalCart,
		order.isNoShipping,
		order.dataUpdate,
		order?.shippingCostCAP,
		totalToShow,
	]);

	// passa da spedizione a ritiro in sede
	const onCalcolaSpedizioneChange = (event) => {
		//console.log(event);
		setCalcolaSpedizione(event);

		// chiamo action creator per aggiornare ordine con no shipping (ritiro in sede) o con costo spedizione
		if (event !== calcolaspedizione) {
			dispatch(
				allActions.cartDetailActions.sendNoShipping(
					currentPage.currentLang,
					user?.userData?.id,
					order.selectedCountryRealId,
					tokenCarrello,
					event
				)
			);
		}

		console.log('onCalcolaSpedizioneChange order.totalCart', order.totalCart);
	};

	// recupero cap e nazione per le spedizioni all'estero
	const onSpedizioneCapChange = debounce(function (event) {
		const selectedCountryName =
			shippingCountries[selectedCountry]?.shippingCountry;
		const selectedCountryRealId = shippingCountries[selectedCountry]?.id;
		const capValue = event.floatValue;
		console.log(selectedCountryName, capValue, selectedCountryRealId);

		// salvo localmente il cap
		setSelectedCAP(capValue);

		if (
			selectedCountryName === 'Italia' ||
			selectedCountryName === 'France' ||
			selectedCountryName === 'Greece'
		) {
			dispatch(
				allActions.cartDetailActions.shippingFromCap(
					currentPage.currentLang,
					user?.userData?.id,
					selectedCountryRealId,
					tokenCarrello,
					capValue
				)
			);
		}
	}, 1000);

	// questa funzione viene chiamata in risposta alla ricerca per CAP
	const showShippingFromCap = () => {
		//setSelectedCountry(event.target.value);
		// resetto nazione locale
		const nazioneId = Object.keys(checkout.shippingCountries).filter(
			(itemId) => {
				const countryItem = checkout.shippingCountries[itemId];
				return countryItem.id === order.shippingCountryCAP.id;
			}
		)[0];
		setSelectedCountry(nazioneId);

		// recupero nazione italia nel caso di utente loggato come azienda italiana
		const italiaId = Object.keys(checkout.shippingCountries).filter(
			(itemId) => {
				const countryItem = checkout.shippingCountries[itemId];
				return countryItem.id === 36;
			}
		)[0];
		const countryITA = checkout.shippingCountries[italiaId];

		// anteprima costo spedizione senza iva
		setShippingCost(order.shippingCostCAP);

		// calcolo iva imponibile in base a nazione di spedizione (N.B se sono loggato come azienda italiana, l'iva è sempre al 22%!) --> nel caso di azienda estera non ricalcolo l'iva perchè è a 0!!!
		let aliquotaIVANuova = 0;
		let aliquotaIVADescNuova = '';
		let newShippingCostVAT = 0;
		let ivaPerSpedizione = 0;

		// utente loggato come privato o azienda
		if (user?.userData?.vat_disabled === 0) {
			aliquotaIVANuova =
				user.userData?.meta_data?.partitaiva &&
				user.userData?.meta_data.nazione === '36'
					? countryITA.aliquotaIVA
					: order.shippingCountryCAP.aliquotaIVA;
			setAliquotaIVA(aliquotaIVANuova);

			aliquotaIVADescNuova =
				user.userData?.meta_data?.partitaiva &&
				user.userData?.meta_data.nazione === '36'
					? 'IVA ' + countryITA.descrizioneEstesa
					: 'IVA ' + order.shippingCountryCAP.descrizioneEstesa;
			setAliquotaIVA(aliquotaIVANuova);
			setAliquotaIVADesc(aliquotaIVADescNuova);
			newShippingCostVAT = (order.subtotalCart / 100) * aliquotaIVANuova;
			setSubtotalCartVAT(newShippingCostVAT);

			// iva spedizione
			ivaPerSpedizione =
				(order.shippingCostCAP / 100) * order.shippingCountryCAP.aliquotaIVA;
			setShippingVATCost(ivaPerSpedizione);
		} else {
			// utente non ancora loggato oppure utente loggato con vat_disabled = 1
			console.log('non loggato oppure utente loggato con vat_disabled = 1');
			aliquotaIVANuova = order.shippingCountryCAP.aliquotaIVA;
			aliquotaIVADescNuova = order.shippingCountryCAP.descrizioneEstesa;
			setAliquotaIVA(aliquotaIVANuova);
			setAliquotaIVADesc(aliquotaIVADescNuova);
			newShippingCostVAT = (order.subtotalCart / 100) * aliquotaIVANuova;
			setSubtotalCartVAT(newShippingCostVAT);

			// iva spedizione
			ivaPerSpedizione =
				(order.shippingCostCAP / 100) * order.shippingCountryCAP.aliquotaIVA;
			setShippingVATCost(ivaPerSpedizione);
		}

		// totale in base a IVA spedizione
		const newTotal =
			order.subtotalCart +
			newShippingCostVAT +
			parseFloat(order.shippingCostCAP) +
			parseFloat(ivaPerSpedizione);

		setTotalCart(newTotal);
		setTotalCartByCap(newTotal);
	};

	// cambio paese da select Nazione con anteprima calcolo spedizione
	const onCountryChange = (event) => {
		const newSelectedCountry = checkout.shippingCountries[event.target.value];
		const nazioneId = Object.keys(checkout.shippingCountries).filter(
			(itemId) => {
				const countryItem = checkout.shippingCountries[itemId];
				return countryItem.id === 36;
			}
		)[0];
		const countryITA = checkout.shippingCountries[nazioneId];
		console.log(event.target.value);
		setSelectedCountry(event.target.value);
		setSelectedCountryName(newSelectedCountry.shippingCountry);
		setShippingCost(newSelectedCountry.shippingCostByWeight);

		// calcolo iva imponibile in base a nazione di spedizione (N.B se sono loggato come azienda italiana, l'iva è sempre al 22%!) --> nel caso di azienda estera non ricalcolo l'iva perchè è a 0!!!
		let aliquotaIVANuova = 0;
		let aliquotaIVADescNuova = '';
		let newShippingCostVAT = 0;
		let ivaPerSpedizione = 0;

		// utente loggato come privato o azienda
		if (user?.userData?.vat_disabled === 0) {
			aliquotaIVANuova =
				user.userData?.meta_data?.partitaiva &&
				user.userData?.meta_data.nazione === '36'
					? countryITA.aliquotaIVA
					: newSelectedCountry.aliquotaIVA;

			aliquotaIVADescNuova =
				user.userData?.meta_data?.partitaiva &&
				user.userData?.meta_data.nazione === '36'
					? 'IVA ' + countryITA.descrizioneEstesa
					: 'IVA ' + newSelectedCountry.descrizioneEstesa;
			setAliquotaIVA(aliquotaIVANuova);
			setAliquotaIVADesc(aliquotaIVADescNuova);
			newShippingCostVAT = (order.subtotalCart / 100) * aliquotaIVANuova;
			setSubtotalCartVAT(newShippingCostVAT);

			// iva spedizione
			ivaPerSpedizione =
				user.userData?.meta_data?.partitaiva &&
				user.userData?.meta_data.nazione === '36'
					? countryITA.shippingCostVATByWeight
					: newSelectedCountry.shippingCostVATByWeight;
			setShippingVATCost(ivaPerSpedizione);
		} else {
			// utente non ancora loggato oppure utente loggato con vat_disabled = 1
			console.log(
				'non loggato oppure utente loggato con vat_disabled = 1',
				newSelectedCountry
			);
			aliquotaIVANuova = newSelectedCountry.aliquotaIVA;

			aliquotaIVADescNuova = newSelectedCountry.descrizioneEstesa;
			setAliquotaIVA(aliquotaIVANuova);
			setAliquotaIVADesc(aliquotaIVADescNuova);
			newShippingCostVAT = (order.subtotalCart / 100) * aliquotaIVANuova;
			setSubtotalCartVAT(newShippingCostVAT);

			// iva spedizione
			ivaPerSpedizione = newSelectedCountry.shippingCostVATByWeight;
			setShippingVATCost(ivaPerSpedizione);
		}

		// totale in base a IVA spedizione
		const newTotal =
			order.subtotalCart +
			newShippingCostVAT +
			parseFloat(newSelectedCountry.shippingCostByWeight) +
			parseFloat(ivaPerSpedizione);

		setTotalCart(newTotal);
	};

	// cambio stato locale coupon
	const onCouponScontoChange = (event) => {
		setCouponSconto(event.target.value);
	};

	// aggiunta coupon
	const onCouponSend = () => {
		console.log(couponSconto);
		if (couponSconto !== '') {
			setIsCouponSending(true);

			// invio coupon a API
			dispatch(
				allActions.cartDetailActions.postCouponAdd(
					currentPage.currentLang,
					user?.userData?.id,
					tokenCarrello,
					couponSconto
				)
			);
		}
	};

	if (checkout?.shippingCountries === undefined) {
		return <div></div>;
	}

	const shippingCostByWeight =
		shippingCost > 0 && order.isNoShipping === false
			? shippingCost
			: order.totalShipping;
	const shippingCostVATByWeight =
		shippingVATCost > 0 && order.isNoShipping === false
			? shippingVATCost
			: order.totalShippingVAT;

	const elencoPaesiSpedizione = checkout.shippingCountries.map(
		(item, index) => {
			return (
				<option key={item.id} value={index}>
					{item.shippingCountry}
				</option>
			);
		}
	);

	// disabilito i label del calcolo spedizione o ritiro in sede se il carrello è vuoto
	const isLabelDisabled =
		order.isNoShipping === null &&
		'pointer-events-none opacity-60 cursor-default';
	const messageRitiroInSede =
		order.isNoShipping === true &&
		riepilogoCarrelloTranslation.ritiroinsede.itemName;

	// possabilità di selezionare ritiro in sede (è possibile solo nella sezione carrello)
	const selezionaSpedizioneORitiroInSede = routeName === 'cartDetail' && (
		<div className='text-ribola-congress-blue flex xl:justify-between items-center mt-8'>
			<i className='fal fa-shipping-fast text-4xl xl:text-3xl mr-2'></i>
			<div
				className={`flex flex-col sm:flex-row xl:flex-col 3xl:flex-row ${isLabelDisabled}`}>
				<CheckboxField
					checkboxType='radio'
					checkboxLabel={
						riepilogoCarrelloTranslation.spedizionicalcola.itemName
					}
					checkboxLabelStyle='flex items-center text-sm mb-3 sm:mb-0'
					checkboxName='calcolaspedizione'
					checkboxHandlerFunction={() =>
						onCalcolaSpedizioneChange('calcolaspedizione')
					}
					isChecked={order.isNoShipping === false ? true : false}
					isDisabled={
						order.isNoShipping === null || order.totalItems === 0 ? true : false
					}
					checkboxStyle='border-2 text-ribola-congress-blue border-ribola-congress-blue mr-3 cursor-pointer'
					direction='right'
				/>
				<CheckboxField
					checkboxType='radio'
					checkboxLabel={riepilogoCarrelloTranslation.ritiroinsede.itemName}
					checkboxLabelStyle='flex items-center text-sm'
					checkboxName='calcolaspedizione'
					checkboxHandlerFunction={() =>
						onCalcolaSpedizioneChange('ritiroinsede')
					}
					isChecked={order.isNoShipping === true ? true : false}
					isDisabled={
						order.isNoShipping === null || order.totalItems === 0 ? true : false
					}
					checkboxStyle='border-2 text-ribola-congress-blue border-ribola-congress-blue mr-3 cursor-pointer'
					direction='right'
				/>
			</div>
		</div>
	);

	const isButtonDisabled = order.totalItems === 0 ? true : false;
	const isButtonConcludiDisabled =
		order.totalItems === 0 ||
		order.paymentId === null ||
		order.paymentId === 0 ||
		(order.addressId === 0 &&
			(order.addressId === null || order.isNoShipping === false))
			? true
			: false;
	const isSelectShippingDisabled =
		order.isNoShipping === true || order.totalItems === 0 ? true : false;

	// mostro calcolo spedizione per paese solo nella pagina carrello (nella pagina pagamento posso solamente scegliere ritiro in sede o spedizione)
	const calcoloSpedizione = routeName === 'cartDetail' && (
		<Fragment>
			<span
				dangerouslySetInnerHTML={{
					__html: riepilogoCarrelloTranslation.calcolaspedizioniestero.itemName,
				}}
			/>
			<div className='relative mt-3'>
				<select
					onChange={onCountryChange}
					value={selectedCountry}
					disabled={isSelectShippingDisabled}
					className='form-select appearance-none w-full bg-transparent border border-ribola-dove-gray focus:outline-none cursor-pointer font-opensanssemibold text-xl'
					style={{ textAlignLast: 'center' }}>
					{elencoPaesiSpedizione}
				</select>
			</div>
		</Fragment>
	);

	// anteprima costo spedizione con CAP (al momento solo Italia, Francia e Grecia)
	const calcoloSpedizionePerCap = routeName === 'cartDetail' &&
		!isSelectShippingDisabled &&
		(selectedCountryName === 'Italia' ||
			selectedCountryName === 'France' ||
			selectedCountryName === 'Greece') && (
			<Fragment>
				<span
					className='block mt-3'
					dangerouslySetInnerHTML={{
						__html: riepilogoCarrelloTranslation.calcolaspedizionicap.itemName,
					}}
				/>
				<div className='relative mt-3'>
					<InputField
						inputType='number'
						inputName='capSpedizione'
						inputPlaceholder={
							riepilogoCarrelloTranslation.inseriscicap.itemName
						}
						inputMaxValue={99999}
						isNumberFormat={true}
						inputDecimalScale='none'
						inputThousandSeparator=''
						inputHandlerFunction={onSpedizioneCapChange}
						inputStyle='border border-ribola-dove-gray w-1/2 text-sm'
					/>
				</div>
			</Fragment>
		);

	// tipo pulsante da mostrare per proseguire
	let buttonProsegui = '';
	if (routeName === 'cartDetail') {
		if (user.isLogged) {
			buttonProsegui = (
				<CustomButton
					buttonLink={fasiCarrelloTranslation.spedizionePagamento.linkName}
					buttonRouteName={
						fasiCarrelloTranslation.spedizionePagamento.routeName
					}
					buttonName={riepilogoCarrelloTranslation.procediconpagamento.itemName.toUpperCase()}
					buttonStyle='w-full flex justify-center items-center text-white text-center bg-ribola-amber hover:bg-ribola-web-orange transition duration-300 text-base 2xl:text-lg 3xl:text-xl font-opensanssemibold py-4 px-2 2xl:px-6 border-r-2 border-ribola-gallery-gray'
					isButtonDisabled={isButtonDisabled}
				/>
			);
		} else {
			buttonProsegui = (
				<CustomButton
					buttonLink={fasiCarrelloTranslation.registrazione.linkName}
					buttonRouteName={fasiCarrelloTranslation.registrazione.routeName}
					buttonName={riepilogoCarrelloTranslation.procediconpagamento.itemName.toUpperCase()}
					buttonStyle='w-full flex justify-center items-center text-white text-center bg-ribola-amber hover:bg-ribola-web-orange transition duration-300 text-base 2xl:text-lg 3xl:text-xl font-opensanssemibold py-4 px-2 2xl:px-6 border-r-2 border-ribola-gallery-gray'
					isButtonDisabled={isButtonDisabled}
				/>
			);
		}
	} else if (routeName === 'paymentDetail') {
		buttonProsegui = (
			<CustomButton
				buttonLink={fasiCarrelloTranslation.concludiOrdineFase.linkName}
				buttonRouteName={fasiCarrelloTranslation.concludiOrdineFase.routeName}
				buttonName={riepilogoCarrelloTranslation.procediconpagamento.itemName.toUpperCase()}
				buttonStyle='w-full flex justify-center items-center text-white text-center bg-ribola-amber hover:bg-ribola-web-orange transition duration-300 text-base 2xl:text-lg 3xl:text-xl font-opensanssemibold py-4 px-2 2xl:px-6 border-r-2 border-ribola-gallery-gray'
				isButtonDisabled={isButtonConcludiDisabled}
			/>
		);
	} else if (routeName === 'checkoutDetail') {
		if (isPaypal) {
			buttonProsegui = <PaypalButtons onPaypalSubmit={onPaypalSubmit} />;
		} else {
			buttonProsegui = (
				<InputField
					inputType='submit'
					inputValue={riepilogoCarrelloTranslation.pagaordine.itemName.toUpperCase()}
					inputStyle='w-full flex justify-center items-center text-white text-center bg-ribola-amber hover:bg-ribola-web-orange transition duration-300 text-lg 3xl:text-xl font-opensanssemibold py-4 px-2 2xl:px-6 border-r-2 border-ribola-gallery-gray cursor-pointer'
					showLabel={false}
					isSpinnerLoading={isButtonSending}
				/>
			);
		}
	}
	// fine tipo pulsante da mostrare per proseguire

	// parte coupon
	let divCoupon = '';
	if (
		order?.totalItems > 0 &&
		order?.codiceCoupon === null &&
		routeName === 'cartDetail'
	) {
		divCoupon = (
			<Fragment>
				<InputField
					inputType='text'
					inputLabel={riepilogoCarrelloTranslation.codicesconto.itemName.toUpperCase()}
					inputLabelStyle='text-ribola-congress-blue font-opensansbold mt-5'
					inputName='couponSconto'
					inputValue={couponSconto}
					inputHandlerFunction={onCouponScontoChange}
					inputStyle='border border-ribola-congress-blue border-opacity-100 mt-1 py-2 px-6 text-xl w-full'
				/>
				<div className='flex justify-end'>
					<span
						onClick={onCouponSend}
						className='buttonBorderedTransparent bg-ribola-congress-blue-variant text-white text-sm text-center w-30 px-5 focus:outline-none'>
						{genericheTranslation.invia.itemName}
					</span>
				</div>
			</Fragment>
		);
	} else if (order?.totalItems > 0 && order?.codiceCoupon !== null) {
		divCoupon = (
			<span className='text-ribola-astral text-lg block mt-4'>
				{riepilogoCarrelloTranslation.codicesconto.itemName}:
				<strong className='ml-1'>
					{order.codiceCoupon}
					<small className='ml-1'>(-{order.scontoApplicatoCoupon}€)</small>
				</strong>
			</span>
		);
	}
	// fine parte coupon

	// disabilito eventi su pulsante quando sto pagando?
	const disablePointerEvents =
		isButtonSending === true || isCouponSending === true
			? 'pointer-events-none'
			: '';

	const aliquotaIVADESC2 =
		aliquotaIVADesc !== '' && '(' + aliquotaIVADesc + ')';

	return (
		<Fragment>
			<span className='text-black text-xl block'>
				{riepilogoCarrelloTranslation.totalepeso.itemName}:&nbsp;
				{parseFloat(order.totalWeight).toFixed(2).toString().replace('.', ',')}
				kg
			</span>
			<span className='text-black text-xl block mt-6'>
				{riepilogoCarrelloTranslation.imponibile.itemName}:&nbsp;
				<strong>
					{parseFloat(order.subtotalCart)
						.toFixed(2)
						.toString()
						.replace('.', ',')}
					€
				</strong>
				<br />
				<span className='text-sm'>
					{riepilogoCarrelloTranslation.ivaimponibile.itemName}&nbsp;
					{parseFloat(subtotalCartVAT).toFixed(2).toString().replace('.', ',')}€
					<small className='ml-2 font-opensanssemibold text-ribola-astral'>
						{aliquotaIVADESC2}
					</small>
				</span>
			</span>
			{selezionaSpedizioneORitiroInSede}
			<div className='mt-6'>
				{calcoloSpedizione}
				{calcoloSpedizionePerCap}
				<span className='text-black text-xl block mt-4'>
					{riepilogoCarrelloTranslation.spedizione.itemName}:&nbsp;
					<strong>
						{parseFloat(shippingCostByWeight)
							.toFixed(2)
							.toString()
							.replace('.', ',')}
						€
					</strong>
					<small className='ml-2 text-xs'>{messageRitiroInSede}</small>
					<br />
					<span className='text-sm'>
						{riepilogoCarrelloTranslation.ivaspedizione.itemName}&nbsp;
						{parseFloat(shippingCostVATByWeight)
							.toFixed(2)
							.toString()
							.replace('.', ',')}
						€
						<small className='ml-2 font-opensanssemibold text-ribola-astral'>
							{aliquotaIVADESC2}
						</small>
					</span>
				</span>
			</div>
			<div className={disablePointerEvents}>
				{divCoupon}
				<span className='text-ribola-congress-blue font-opensansbold text-2xl 3xl:text-3xl block mt-3 mb-6 2xl:mb-8'>
					{riepilogoCarrelloTranslation.totale.itemName.toUpperCase()}:&nbsp;
					{parseFloat(totalCart).toFixed(2).toString().replace('.', ',')} €
				</span>
				{buttonProsegui}
			</div>
		</Fragment>
	);
};

export default RiepilogoCarrello;
