import {
	FETCH_PARENT_CATEGORY_DETAIL,
	FETCH_CATEGORY_DETAIL,
	FETCH_PRODUCT_DETAIL,
	FETCH_CART_DETAIL,
} from '../actions/types';

const INITIAL_STATE = [];

export const promotionReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case FETCH_PARENT_CATEGORY_DETAIL:
			const { promotion } = action.payload.parentCategory;

			state = promotion;

			return state;

		case FETCH_CART_DETAIL:

			state = action.payload.promotion;

			return state;

		default:
			return state;
	}
};
