const INITIAL_STATE = {
	mainNavigation: {},
	iconeMenuLeft: {},
	menuLeftProfilo: {},
	fasiCarrello: {},
	carrello: {},
};

export const translationReducer = (state = INITIAL_STATE, action) => {
	return state;
};
