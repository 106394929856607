import { useSelector } from 'react-redux';
import { BASENAME } from '../../../utils/basename';

const MessaggioBordatura = () => {
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	return (
		<div className='w-full relative h-14 flex justify-center items-center bg-ribola-chelsea-cucumber text-white text-sm font-opensanssemibold mt-8 px-3 py-2'>
			<img
				src={BASENAME + '/images/free.svg'}
				width='39'
				height='39'
				className='flex-start self-start sm:absolute sm:left-4 mr-3 sm:mr-0'
				alt='Bordatura'
			/>
			<span>{genericheTranslation.messaggiobordatura.itemName}</span>
		</div>
	);
};

export default MessaggioBordatura;
