import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './state/store';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import BASENAME from './utils/basename';
import BASESTORAGE from './utils/basestorage';
import URLASSOLUTO from './utils/urlassoluto';
import { getCookieR, grepArrayR } from './utils/cookieConsent';

import App from './App';

// Grab the state from a global variable injected into the server-generated HTML
const { initialData } = window.__PRELOADED_STATE__;

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

const store = configureStore(initialData[0]);

// setto già le variabili globali per percorsi assoluti e relativi
BASESTORAGE(initialData);
URLASSOLUTO(initialData);

// lingua corrente
const suffissoLang = initialData[0]['currentPage']['currentLang'].replace(
	'_',
	''
);

// controllo cookie consent
/* const categorycookieoldR = getCookieR('categorycookie');
let categorycookieoldoR = [];
let includeGTM = false;
if (typeof categorycookieoldR !== 'undefined') {
	categorycookieoldoR = JSON.parse(categorycookieoldR);
	includeGTM =
		grepArrayR(categorycookieoldoR, function (obj) {
			return obj.name == 'analytics';
		}).length === 1;
}
 */
// inizializzazione google tag manager
const gtmSuffix = `gtm_code_${suffissoLang}`;
const currentGTMTAG = initialData[0]['siteParams'][gtmSuffix];
if (currentGTMTAG !== null) {
	const tagManagerArgs = {
		gtmId: currentGTMTAG,
		auth: 'TsdZJYookkP66Byl46Xczw',
		preview: 'env-1',
		dataLayer: {
			userId: 'ribola',
			userProject: 'sito2021',
		},
	};
	if (typeof window !== 'undefined') {
		console.log('inizializzo tagManager');
		TagManager.initialize(tagManagerArgs);
	}
}

// DOM element
if (document.getElementById('app')) {
	ReactDOM.hydrate(
		<Provider store={store}>
			<BrowserRouter basename={BASENAME(initialData)}>
				<App />
			</BrowserRouter>
		</Provider>,
		document.getElementById('app')
	);
}
