import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import TitoloBlocco from '../titoloBlocco/TitoloBlocco';
import CategoryLeftItem from '../categoryLeftItem/CategoryLeftItem';

const BloccoCategorieMenuLeft = () => {
	const productCategories = useSelector((state) => state.catalogue);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	const elencoCategories = Object.keys(productCategories).map((key) => {
		const { id, detail, icona, hoverColor, routeName, linkSeo } =
			productCategories[key];
		//console.log(productCategories[key]);
		return (
			<CategoryLeftItem
				key={_uniqueId('category_')}
				categoryId={id}
				categoryName={detail.titolo}
				categoryIcon={icona}
				categoryHoverColor={hoverColor}
				routeName={routeName}
				linkSeo={linkSeo}
			/>
		);
	});

	return (
		<div className='menuLeft border-r border-gray-300'>
			<TitoloBlocco
				titoloStyle='4xl:text-2xl block pt-4 pl-3 3xl:pt-6 3xl:pl-6 mb-3'
				titolo={genericheTranslation.prodottistatic.itemName.toUpperCase()}
			/>
			<nav>{elencoCategories}</nav>
		</div>
	);
};

export default BloccoCategorieMenuLeft;
