import axios from '../../utils/axiosBase';
import {
	FETCH_CHISIAMO_DETAIL,
	LOAD_CHISIAMO_DETAIL,
	FETCH_LENOSTRERETI_DETAIL,
	LOAD_LENOSTRERETI_DETAIL,
	FETCH_CONTATTI_DETAIL,
	LOAD_CONTATTI_DETAIL,
	FETCH_CONTATTI_OK_DETAIL,
	LOAD_CONTATTI_OK_DETAIL,
	FETCH_CONTATTI_KO_DETAIL,
	LOAD_CONTATTI_KO_DETAIL,
	FETCH_CONDIZIONI_VENDITA_DETAIL,
	LOAD_CONDIZIONI_VENDITA_DETAIL,
	FETCH_PRIVACY_POLICY_DETAIL,
	LOAD_PRIVACY_POLICY_DETAIL,
	FETCH_COOKIES_POLICY_DETAIL,
	LOAD_COOKIES_POLICY_DETAIL,
	FETCH_SEARCH_DETAIL,
	FETCH_404_DETAIL,
	LOAD_404_DETAIL,
	SEND_CONTATTI,
	SEND_RICHIEDI_INFO_PRODOTTO,
} from './types';

const fetchChisiamoDetail =
	(currentLang, userId, history) => async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			pageToLoad: 'chisiamo',
		};

		// chiamo web service
		const response = await axios.post(`api/staticdetail/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_CHISIAMO_DETAIL,
			payload: response.data,
		});
	};

const loadChisiamoDetail =
	(chisiamoLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_CHISIAMO_DETAIL,
			payload: {
				chisiamoLoaded,
			},
		});
	};

const fetchLenostreretiDetail =
	(currentLang, userId, history) => async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			pageToLoad: 'lenostrereti',
		};

		// chiamo web service
		const response = await axios.post(`api/staticdetail/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_LENOSTRERETI_DETAIL,
			payload: response.data,
		});
	};

const loadLenostreretiDetail =
	(lenostreretiLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_LENOSTRERETI_DETAIL,
			payload: {
				lenostreretiLoaded,
			},
		});
	};

const fetchContattiDetail =
	(currentLang, userId, history) => async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			pageToLoad: 'contatti',
		};

		// chiamo web service
		const response = await axios.post(`api/staticdetail/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_CONTATTI_DETAIL,
			payload: response.data,
		});
	};

const loadContattiDetail =
	(contattiLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_CONTATTI_DETAIL,
			payload: {
				contattiLoaded,
			},
		});
	};

const sendContatti =
	(currentLang, dataForm, history) => async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			dataForm: dataForm,
		};

		// chiamo web service
		const response = await axios.post(`api/sendcontatti/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: SEND_CONTATTI,
			payload: response.data,
		});
	};

const sendRichiediInfoProdotto =
	(currentLang, dataForm, history) => async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			dataForm: dataForm,
		};

		// chiamo web service
		const response = await axios.post(`api/sendinfoprodotto/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: SEND_RICHIEDI_INFO_PRODOTTO,
			payload: response.data,
		});
	};

const fetchContattiOKDetail =
	(currentLang, userId, history) => async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			pageToLoad: 'contattiok',
		};

		// chiamo web service
		const response = await axios.post(`api/staticdetail/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_CONTATTI_OK_DETAIL,
			payload: response.data,
		});
	};

const loadContattiOKDetail =
	(contattiokLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_CONTATTI_OK_DETAIL,
			payload: {
				contattiokLoaded,
			},
		});
	};

const fetchContattiKODetail =
	(currentLang, userId, history) => async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			pageToLoad: 'contattiko',
		};

		// chiamo web service
		const response = await axios.post(`api/staticdetail/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_CONTATTI_KO_DETAIL,
			payload: response.data,
		});
	};

const loadContattiKODetail =
	(contattikoLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_CONTATTI_KO_DETAIL,
			payload: {
				contattikoLoaded,
			},
		});
	};

const fetchCondizioniVenditaDetail =
	(currentLang, userId, history) => async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			pageToLoad: 'condizionivendita',
		};

		// chiamo web service
		const response = await axios.post(`api/staticdetail/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_CONDIZIONI_VENDITA_DETAIL,
			payload: response.data,
		});
	};

const loadCondizioniVenditaDetail =
	(condizionivenditaLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_CONDIZIONI_VENDITA_DETAIL,
			payload: {
				condizionivenditaLoaded,
			},
		});
	};

const fetchSearchDetail =
	(currentLang, userId, searchWords, history) => async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			pageToLoad: 'search',
			searchWords: searchWords,
		};

		// chiamo web service
		const response = await axios.post(`api/staticdetail/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_SEARCH_DETAIL,
			payload: response.data,
		});
	};

const fetchError404Detail =
	(currentLang, userId, pageToLoad = '404', history) =>
	async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			pageToLoad: pageToLoad,
		};

		// chiamo web service
		const response = await axios.post(`api/staticdetail/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_404_DETAIL,
			payload: response.data,
		});
	};

const loadError404Detail =
	(error404Loaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_404_DETAIL,
			payload: {
				error404Loaded,
			},
		});
	};

const fetchPrivacyPolicyDetail =
	(currentLang, userId, history) => async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			pageToLoad: 'privacypolicy',
		};

		// chiamo web service
		const response = await axios.post(`api/staticdetail/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_PRIVACY_POLICY_DETAIL,
			payload: response.data,
		});
	};

const loadPrivacyPolicyDetail =
	(privacypolicyLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_PRIVACY_POLICY_DETAIL,
			payload: {
				privacypolicyLoaded,
			},
		});
	};

const fetchCookiesPolicyDetail =
	(currentLang, userId, history) => async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
			pageToLoad: 'cookiespolicy',
		};

		// chiamo web service
		const response = await axios.post(`api/staticdetail/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_COOKIES_POLICY_DETAIL,
			payload: response.data,
		});
	};

const loadCookiesPolicyDetail =
	(cookiespolicyLoaded, history) => async (dispatch, getState) => {
		dispatch({
			type: LOAD_COOKIES_POLICY_DETAIL,
			payload: {
				cookiespolicyLoaded,
			},
		});
	};

export default {
	fetchChisiamoDetail,
	fetchLenostreretiDetail,
	fetchContattiDetail,
	fetchContattiOKDetail,
	fetchContattiKODetail,
	fetchCondizioniVenditaDetail,
	fetchPrivacyPolicyDetail,
	fetchCookiesPolicyDetail,
	fetchSearchDetail,
	fetchError404Detail,
	sendContatti,
	sendRichiediInfoProdotto,
	loadChisiamoDetail,
	loadLenostreretiDetail,
	loadContattiDetail,
	loadContattiOKDetail,
	loadContattiKODetail,
	loadCondizioniVenditaDetail,
	loadPrivacyPolicyDetail,
	loadCookiesPolicyDetail,
	loadError404Detail,
};
