const BASESTORAGE_IT = process.env.MIX_STORAGE_IT;
const BASESTORAGE_EN = process.env.MIX_STORAGE_EN;
const BASESTORAGE_FR = process.env.MIX_STORAGE_FR;
const BASESTORAGE_DE = process.env.MIX_STORAGE_DE;

export let BASESTORAGE;

export default (initialData) => {
	if (initialData[0]['siteParams']['current_language'] === 'it') {
		BASESTORAGE = BASESTORAGE_IT;
	}
	if (initialData[0]['siteParams']['current_language'] === 'en') {
		BASESTORAGE = BASESTORAGE_EN;
	}
	if (initialData[0]['siteParams']['current_language'] === 'fr') {
		BASESTORAGE = BASESTORAGE_FR;
	}
	if (initialData[0]['siteParams']['current_language'] === 'de') {
		BASESTORAGE = BASESTORAGE_DE;
	}

	return BASESTORAGE;
};
