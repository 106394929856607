import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const TabellaDatiTeloPiscina = ({ lunghezza, larghezza, dimensione }) => {
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	useEffect(() => {}, []);

	if (lunghezza === '') lunghezza = 0;
	if (larghezza === '') larghezza = 0;

	return (
		<div className='flex flex-col mt-6 2xl:mt-10 mb-4'>
			<span className='text-xl font-opensanssemibold block mb-2'>
				{genericheTranslation.dimensionitelo.itemName.toUpperCase()}
			</span>
			<div className='flex justify-between items-center border-b border-gray-300 py-2 text-sm text-ribola-heavy-metal'>
				<span>
					{genericheTranslation.lunghezzacopertura.itemName.toUpperCase()}
				</span>
				<span>
					{parseFloat(lunghezza).toFixed(2).toString().replace('.', ',')}{' '}
					{genericheTranslation.metri.itemName}
				</span>
			</div>
			<div className='flex justify-between items-center border-b border-gray-300 py-2 text-sm text-ribola-heavy-metal'>
				<span>
					{genericheTranslation.larghezzacopertura.itemName.toUpperCase()}
				</span>
				<span>
					{parseFloat(larghezza).toFixed(2).toString().replace('.', ',')}{' '}
					{genericheTranslation.metri.itemName}
				</span>
			</div>
			<div className='flex justify-between items-center border-b border-gray-300 py-2 text-sm text-ribola-heavy-metal'>
				<span>{genericheTranslation.dimensione.itemName.toUpperCase()}</span>
				<span>
					{parseFloat(dimensione).toFixed(2).toString().replace('.', ',')}{' '}
					{genericheTranslation.mq.itemName}
				</span>
			</div>
		</div>
	);
};

export default TabellaDatiTeloPiscina;
