import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';

const FeedatyButton = ({ isFooter = false }) => {
	const feedatyContainerRef = useRef();
	const feedatyContainerFooterRef = useRef();
	const currentLang = useSelector((state) => state.currentPage.currentLang);
	const [scriptLoaded, setScriptLoaded] = useState(false);

	useEffect(() => {
		scriptLoader();
		return () => {
			scriptUnloader();
		};
	}, []);

	useEffect(() => {
		if (!scriptLoaded) return;
	}, [scriptLoaded]);

	const scriptLoader = () => {
		window.addEventListener('load', scriptMover);
		const feedatyDiv = document.getElementById('feedatyScript');
		if (typeof window !== undefined && !feedatyDiv) {
			const script = document.createElement('script');
			script.src =
				'https://widget.feedaty.com/public/js/feedaty.js?merchant=10215774&style_ver=2021';
			script.id = 'feedatyScript';
			script.async = true;
			document.body.appendChild(script);
		}
	};

	const scriptUnloader = () => {
		//window.removeEventListener('load', scriptMover);
		const feedatyDiv = document.getElementById('feedatyScript');
		//document.body.removeChild(document.querySelector('#feedatyScript')); // Remove the script element on unmount
	};

	const scriptMover = () => {
		const feedatyWidgetFooterUpdated = document.getElementById(
			'feedatyWidgetFooter'
		);
		if (feedatyContainerFooterRef.current !== undefined) {
			feedatyContainerFooterRef.current.replaceWith(feedatyWidgetFooterUpdated);
		}

		const feedatyWidgetUpdated = document.getElementById('feedatyWidget');
		if (feedatyContainerRef.current !== undefined) {
			feedatyContainerRef.current.replaceWith(feedatyWidgetUpdated);
		}

		setScriptLoaded();
	};

	let currentWidgetLang = 'it-IT';
	if (currentLang === '_it') {
		currentWidgetLang = 'it-IT';
	} else if (currentLang === '_en') {
		currentWidgetLang = 'en-US';
	} else if (currentLang === '_fr') {
		currentWidgetLang = 'fr-FR';
	} else if (currentLang === '_de') {
		currentWidgetLang = 'de-DE';
	}

	const widgetToShow = isFooter ? (
		<div
			className='flex justify-end items-start -mt-3 -mr-4 xl:mr-0 2xl:mr-4'
			id='feedatyContainerFooter'
			ref={feedatyContainerFooterRef}>
			<div
				id='feedatyWidgetFooter'
				className='feedaty_widget'
				data-id='620f598b0be53'
				data-type='merchant'
				data-variant='150x100-1'
				data-lang={currentWidgetLang}
				data-gui={currentWidgetLang}></div>
		</div>
	) : (
		<div
			className='flex justify-end items-start -mt-3 -mr-4 xl:mr-0 2xl:mr-4'
			id='feedatyContainer'
			ref={feedatyContainerRef}>
			<div
				id='feedatyWidget'
				className='feedaty_widget'
				data-id='61fee7f06c9b7'
				data-type='merchant'
				data-variant='150x100-1'
				data-lang={currentWidgetLang}
				data-gui={currentWidgetLang}></div>
		</div>
	);

	return widgetToShow;
};

export default FeedatyButton;
