import React, { Fragment, useEffect } from 'react';

const CheckboxField = React.forwardRef(
	(
		{
			checkboxType = 'checkbox',
			checkboxLabel,
			checkboxLabelStyle = 'text-sm',
			checkboxName,
			checkboxHandlerFunction,
			checkboxStyle = '',
			checkboxValue,
			checkboxImg,
			checkboxImgWidth,
			checkboxImgHeight,
			checkboxImgAlt,
			checkboxImgStyle,
			checkboxError = '',
			isChecked = false,
			isDefaultChecked = true,
			isFocus = false,
			isReadOnly = false,
			isDisabled = false,
			direction = 'left',
			register = () => {},
		},
		ref
	) => {
		const isRounded = checkboxType === 'radio' ? 'rounded-full' : '';

		useEffect(() => {
			/* console.log('checkboxName', checkboxName);
			console.log('isDisabled', isDisabled); */
		}, [isChecked]);

		let elementImg = '';
		if (checkboxImg !== undefined && Array.isArray(checkboxImg) === false) {
			elementImg = (
				<span>
					<img
						src={checkboxImg}
						width={checkboxImgWidth}
						height={checkboxImgHeight}
						className={checkboxImgStyle}
						alt={checkboxImgAlt}
						title={checkboxImgAlt}
					/>
				</span>
			);
		}

		if (checkboxImg !== undefined && Array.isArray(checkboxImg) === true) {
			elementImg = checkboxImg.map((item, index) => {
				return (
					<span key={item}>
						<img
							src={item}
							width={checkboxImgWidth}
							height={checkboxImgHeight}
							className={checkboxImgStyle}
							alt={checkboxImgAlt[index]}
							title={checkboxImgAlt[index]}
						/>
					</span>
				);
			});
		}

		// disabilito i label quando il checkbox è disabled
		const labelDisabled = isDisabled && 'pointer-events-none';

		// bug fix per checked non visibile --> in alcuni casi defaultChecked non funziona e devo usare checked, controllo la variabile isDefaultChecked per impostare parametri diversi
		const checkboxVariation =
			isDefaultChecked === false ? (
				<input
					type={checkboxType}
					name={checkboxName}
					autoComplete='off'
					readOnly={isReadOnly}
					defaultValue={checkboxValue}
					disabled={isDisabled}
					autoFocus={isFocus}
					className={`${checkboxStyle} form-checkbox ${isRounded} bg-transparent border-opacity-80 focus:border-opacity-100 focus:outline-none checked:bg-ribola-congress-blue placeholder-gray-500 py-1`}
					ref={ref}
					checked={isChecked}
					onChange={(e) => {
						if (typeof onChange === 'function') {
							onChange(e);
						}
						checkboxHandlerFunction(e);
					}}
					{...register}
				/>
			) : (
				<input
					type={checkboxType}
					name={checkboxName}
					autoComplete='off'
					readOnly={isReadOnly}
					defaultValue={checkboxValue}
					disabled={isDisabled}
					autoFocus={isFocus}
					className={`${checkboxStyle} form-checkbox ${isRounded} bg-transparent border-opacity-80 focus:border-opacity-100 focus:outline-none checked:bg-ribola-congress-blue placeholder-gray-500 py-1`}
					ref={ref}
					defaultChecked={isChecked}
					{...register}
				/>
			);

		const checkboxDiv =
			direction === 'left' ? (
				<Fragment>
					<label
						className={`${checkboxLabelStyle} ${labelDisabled}`}
						onClick={checkboxHandlerFunction}>
						{elementImg}
						<span
							dangerouslySetInnerHTML={{
								__html: checkboxLabel,
							}}
						/>
						{checkboxVariation}
					</label>
					{checkboxError}
				</Fragment>
			) : (
				<Fragment>
					<label
						className={`${checkboxLabelStyle} ${labelDisabled}`}
						onClick={checkboxHandlerFunction}>
						{checkboxVariation}
						<span
							dangerouslySetInnerHTML={{
								__html: checkboxLabel,
							}}
						/>
						{elementImg}
					</label>
					{checkboxError}
				</Fragment>
			);

		return (
			<div className='flex items-center px-3 text-ribola-dove-gray'>
				{checkboxDiv}
			</div>
		);
	}
);

export default CheckboxField;
