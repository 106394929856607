import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../state/actions';
import Briciole from '../../shared/briciole/Briciole';
import BarraCheckout from '../barraCheckout/BarraCheckout';
import CustomButton from '../../shared/customButton/CustomButton';
import { gtmCheckoutOrdineConcluso, gtmTransaction } from '../../../gtm/gtm';

const OrderClosedDetail = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const siteParams = useSelector((state) => state.siteParams);
	const order = useSelector((state) => state.order);
	const cart = useSelector((state) => state.cart);
	const user = useSelector((state) => state.user);
	const checkout = useSelector((state) => state.checkout);
	const checkoutTranslation = useSelector(
		(state) => state.translations.checkout
	);
	const menuProfiloItems = useSelector(
		(state) => state.translations.menuLeftProfilo
	);

	// local state
	const [isGTM, setIsGTM] = useState(false);

	useEffect(() => {
		console.log(location);

		if (isGTM === false && order.paymentId !== 0) {
			/***
			 * GTM CHECKOUT ORDINE CONCLUSO - STEP 4
			 */
			const paymentMethodID = Object.keys(checkout.paymentMethods).filter(
				(itemId) => {
					const payment = checkout.paymentMethods[itemId];
					return payment.id === order.paymentId;
				}
			)[0];
			const paymentMethod = checkout.paymentMethods[paymentMethodID].name;
			console.log(paymentMethod);

			// fine checkout
			const gtmSuffix = `gtm_code${currentPage.currentLang}`;
			const currentGTMTAG = siteParams[gtmSuffix];
			gtmCheckoutOrdineConcluso({
				cartData: cart,
				paymentMethod: paymentMethod,
				callbackFunction: onOrderReset,
				isGtmEnabled: currentGTMTAG !== null ? true : false,
			});

			// transaction con dati ordine
			gtmTransaction({
				cartData: cart,
				orderData: order,
				tokenId: user.token_id,
				isGtmEnabled: currentGTMTAG !== null ? true : false,
			});

			setIsGTM(true);
		}
	}, []);

	const onOrderReset = () => {
		// resetto l'ordine
		dispatch(allActions.checkoutDetailActions.orderReset());
	};

	// configuro messaggio in base alla pagina in cui mi trovo
	let messaggioOrdineConcluso = '';
	let paymentMethod = '';
	if (location.pathname.includes('stripe')) {
		paymentMethod = 'stripe';
		messaggioOrdineConcluso =
			checkoutTranslation.stripemessaggioordineconcluso.itemName;
	}
	if (location.pathname.includes('paypal')) {
		paymentMethod = 'paypal';
		messaggioOrdineConcluso =
			checkoutTranslation.paypalmessaggioordineconcluso.itemName;
	}
	if (location.pathname.includes('bonifico')) {
		paymentMethod = 'bonifico';
		messaggioOrdineConcluso =
			checkoutTranslation.bonificomessaggioordineconcluso.itemName;
	}
	if (location.pathname.includes('riba')) {
		paymentMethod = 'riba';
		messaggioOrdineConcluso =
			checkoutTranslation.ribamessaggioordineconcluso.itemName;
	}
	if (location.pathname.includes('contrassegno')) {
		paymentMethod = 'contrassegno';
		messaggioOrdineConcluso =
			checkoutTranslation.contrassegnomessaggioordineconcluso.itemName;
	}

	// se è stato scelto ritiro in sede sovrascrivo il messaggio
	if (order.isNoShipping === true) {
		messaggioOrdineConcluso =
			checkoutTranslation.messaggioordineconclusoritiroinsede.itemName;
	}

	const msgBonifico =
		paymentMethod === 'bonifico' || paymentMethod === 'riba' ? (
			<div
				className='flex flex-col justify-center bg-ribola-astral text-white px-6 py-4 mt-10 mb-3'
				role='alert'>
				<span className='font-opensansbold text-lg 2xl:text-xl mb-2'>
					{siteParams.bancaNome}
				</span>
				<span className='sm:text-lg mb-2'>
					<span className='font-opensansbold'>IBAN:</span>&nbsp;
					{siteParams.bancaIban}
				</span>
				<span className='sm:text-lg'>
					<span className='font-opensansbold'>SWIFT:</span>&nbsp;
					{siteParams.bancaSwift}
				</span>
			</div>
		) : (
			''
		);

	// mostro il pulsante dei miei ordini solo se sono loggato (non lo sono se sono un guest)
	const showMieiOrdini = user.isLogged && (
		<CustomButton
			buttonLink={menuProfiloItems.ordiniProfilo.itemLink}
			buttonRouteName={menuProfiloItems.ordiniProfilo.itemRouteName}
			buttonName={menuProfiloItems.ordiniProfilo.itemName.toUpperCase()}
			buttonStyle='w-full max-w-xs flex justify-center items-center text-white bg-ribola-amber hover:bg-ribola-web-orange transition duration-300 text-lg 2xl:text-xl font-opensanssemibold py-4 px-6 mt-8'
		/>
	);

	return (
		<div className='col-start-3 4xl:col-start-5 col-end-59 4xl:col-end-57 pb-6 sm:pb-12'>
			<Briciole />
			<BarraCheckout currentCheckoutPage={currentPage.routeName} />
			<div className='grid grid-cols-60 mt-12 sm:mt-20'>
				<div className='col-span-60 flex flex-col justify-center items-center px-3 sm:px-0'>
					<span className='text-3xl md:text-4xl text-ribola-congress-blue font-opensansbold'>
						{checkoutTranslation.ordineconcluso.itemName}
					</span>
					<span
						className='sm:text-lg md:text-xl italic text-ribola-dove-gray sm:text-center mt-4'
						dangerouslySetInnerHTML={{
							__html: messaggioOrdineConcluso,
						}}
					/>
					{msgBonifico}
					{showMieiOrdini}
				</div>
			</div>
		</div>
	);
};

export default OrderClosedDetail;
