import { useSelector } from 'react-redux';
import InfoTooltipElement from '../../shared/infoTooltipElement/InfoTooltipElement';

const MessaggioOffertaTrePerDue = () => {
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	return (
		<div className='w-full relative h-14 flex 2xl:justify-center items-center bg-ribola-chelsea-cucumber text-white text-sm font-opensanssemibold mt-8 px-3 py-2'>
			<span className='flex-shrink-0 mr-2 sm:mr-3'>
				{genericheTranslation.offerta32.itemName.toUpperCase()}
			</span>
			<InfoTooltipElement
				iconColor='white'
				fontSize='text-2xl'
				tooltipColor='gray-800'
				tooltipText={genericheTranslation.offerta32.itemName}
			/>
			<span
				className='pl-2 sm:pl-4 text-xs sm:text-sm lg:text-xs 3xl:text-sm'
				dangerouslySetInnerHTML={{
					__html: genericheTranslation.offerta3x2info.itemName,
				}}
			/>
		</div>
	);
};

export default MessaggioOffertaTrePerDue;
