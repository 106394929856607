import { useState, useEffect, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import allActions from '../../../state/actions';
import InfoTooltipElement from '../../shared/infoTooltipElement/InfoTooltipElement';
import CampiRichiediInfo from '../campiRichiediInfo/CampiRichiediInfo';

const ProductRichiediInformazioni = () => {
	const history = useHistory();
	const dispatch = useDispatch();
	// store data
	const {
		register,
		formState: { errors },
		handleSubmit,
	} = useForm({
		mode: 'onChange',
	});
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const currentPage = useSelector((state) => state.currentPage);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);
	const paginestaticheTranslation = useSelector(
		(state) => state.translations.paginestatiche
	);

	// local state
	const [isFormSending, setIsFormSending] = useState(false);

	useEffect(() => {
		// mando pagina contatti-ok solo dopo la risposta di sendRichiediInfoProdotto()
		if (
			isFormSending === true &&
			currentPage?.status === 'OK' &&
			currentPage?.message === 'Messaggio inviato'
		) {
			setIsFormSending(false);

			// mando alla pagina contatti-ok che ho già recuperato da sendRichiediInfoProdotto()
			// mostro spinner
			dispatch(allActions.isFetchingAction.loadSpinner());

			// vado alla pagina contatti-ok
			dispatch(
				allActions.changePageActions.changePage(
					'',
					paginestaticheTranslation.contattiok.itemName,
					paginestaticheTranslation.contattiok.itemLink,
					history
				)
			);
		}

		return () => {
			//console.log('cleaned up');
		};
	}, [isFormSending, currentPage]);

	// controllo che esista la proprietà detail (altrimenti vuol dire che sto ancora recuperando i dati)
	if (!pageContent.detail) {
		return <div></div>;
	}

	const onSubmitFormRichiediInfo = (data) => {
		console.log(data);

		// chiamo action creator
		if (data.nome !== '' && data.email !== '' && data.messaggio !== '') {
			// mostro preloader
			setIsFormSending(true);

			const dataForm = {
				nome: data.nome,
				email: data.email,
				messaggio: data.messaggio,
				titoloProdotto: pageContent.detail.titolo,
				codiceProdotto: pageContent.detail.codice,
			};

			dispatch(
				allActions.staticDetailActions.sendRichiediInfoProdotto(
					currentPage.currentLang,
					dataForm
				)
			);
		}
	};

	const isDisabledClick = isFormSending ? 'pointer-events-none' : '';
	const isButtonSending = isFormSending ? true : false;

	return (
		<Fragment>
			<span className='text-xl font-opensanssemibold flex items-center justify-between'>
				{genericheTranslation.compilaformpermaggiorinfo.itemName.toUpperCase()}
				<InfoTooltipElement
					iconColor='ribola-astral'
					fontSize='text-3xl'
					tooltipColor='ribola-astral'
					tooltipText={
						genericheTranslation.questoprodottonondisponibile.itemName
					}
				/>
			</span>
			<form onSubmit={handleSubmit(onSubmitFormRichiediInfo)} noValidate>
				<CampiRichiediInfo
					key='campiRichiediInfo'
					errors={errors}
					register={register}
					isDisabledClick={isDisabledClick}
					isButtonSending={isButtonSending}
				/>
			</form>
			<div className='grid grid-cols-60 mt-8'></div>

			<div className='w-full flex justify-center'></div>
		</Fragment>
	);
};

export default ProductRichiediInformazioni;
