import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import _uniqueId from 'lodash/uniqueId';
import LazyLoadPicture from '../../../shared/lazyLoadPicture/LazyLoadPicture';
import TitoloBlocco from '../../../shared/titoloBlocco/TitoloBlocco';
import CustomButton from '../../../shared/customButton/CustomButton';
import ProductBoxItem from '../../../shared/productBoxItem/ProductBoxItem';
import LastSlideCategoryHome from '../lastSlideCategoryHome/LastSlideCategoryHome';
import Slideshow from '../../../shared/slideshow/Slideshow';
import { BASESTORAGE } from '../../../../utils/basestorage';

const CategoriaSlideItem = ({
	id: categoryId,
	category,
	icona,
	colore,
	linkSeo: linkSeoCategory,
	routeName: routeNameCategory,
	orientation,
	immagine: immagineCategory,
	elencoProdottiSlide,
}) => {
	const deviceType = useSelector((state) => state.device);
	const promotion = useSelector((state) => state.promotion);
	const homepageTranslation = useSelector(
		(state) => state.translations.homepage
	);

	//console.log(immagineCategory?.imgName);

	const elencoProdotti = elencoProdottiSlide.map(
		({ id, image, hoverColor, detail, routeName, linkSeo }, index) => {
			const titolo_breve = detail.titolo_breve;
			const sottotitolo_elenco = detail.sottotitolo_elenco;
			const codice = detail.codice;
			const imponibile = detail.imponibile;
			const sconto = detail.sconto;
			const prezzoScontato = detail.prezzoScontato;
			const measure_unit = detail.measure_unit;
			const flag_abilita_tag_bestseller =
				detail.flags.flag_abilita_tag_bestseller;
			const flag_abilita_tag_novita = detail.flags.flag_abilita_tag_novita;
			const flag_abilita_informazioni_prodotto =
				detail.flags.flag_abilita_informazioni_prodotto;
			const flag_abilita_prezzi_fissi = detail.flags.flag_abilita_prezzi_fissi;
			const colore = hoverColor;

			let tipologiaScontoP = null;
			// recupero eventuale sconto prodotto
			if (sconto > 0) {
				tipologiaScontoP = detail.tipologia_sconto;
			}
			// fine promozione prodotto

			// promozione 3x2?
			let isProdotto3x2 = false;
			if (promotion?.tipologiaPromozione === 'Promozione 3x2') {
				const prodotto3x2 = promotion?.elencoProdotti?.filter((item) => {
					return item.id === id;
				})[0];
				if (prodotto3x2) {
					isProdotto3x2 = true;
				}
			}

			// promozione prodotto abbinato?
			let promoProdottoAbbinato = null;
			if (
				promotion &&
				promotion?.tipologiaPromozione === 'Promozione Prodotto abbinato'
			) {
				promoProdottoAbbinato = promotion?.elencoProdotti?.filter((item) => {
					return item.id === id;
				})[0];
			}

			// prezzo fisso?
			const prezzoFisso =
				flag_abilita_prezzi_fissi === 1 ? detail?.prezzoFisso?.prezzo : null;

			return (
				<ProductBoxItem
					key={_uniqueId('productCategorySlideItem_')}
					id={id}
					index={index}
					immagine={image}
					titoloBreve={titolo_breve}
					sottotitoloElenco={sottotitolo_elenco}
					codice={codice}
					imponibile={imponibile}
					sconto={sconto}
					tipologiaScontoPromozione={tipologiaScontoP}
					prezzoScontato={prezzoScontato}
					unitaMisura={measure_unit}
					iconaTagBestseller={flag_abilita_tag_bestseller}
					iconaTagNovita={flag_abilita_tag_novita}
					richiediInfo={flag_abilita_informazioni_prodotto}
					prodotto3x2={isProdotto3x2}
					prodottoAbbinatoObj={promoProdottoAbbinato}
					prezzoFisso={prezzoFisso}
					colore={colore}
					routeName={routeName}
					linkSeo={linkSeo}
					categoriaTitolo={detail?.titoloCategoria}
				/>
			);
		}
	);

	// aggiungo ultima slide per andare a categoria
	elencoProdotti.push(
		<LastSlideCategoryHome
			key={_uniqueId('productCategorySlideItem_')}
			id={categoryId}
			category={category}
			icona={icona}
			colore={colore}
			linkCategory={linkSeoCategory}
			routeName={routeNameCategory}
		/>
	);

	const responsive = {
		bigdesktop: {
			breakpoint: { max: 4000, min: 1920 },
			items: 3,
		},
		desktop: {
			breakpoint: { max: 1920, min: 1536 },
			items: 3,
			partialVisibilityGutter: 0,
		},
		minipc: {
			breakpoint: { max: 1536, min: 1280 },
			items: 2,
		},
		bigtablet: {
			breakpoint: { max: 1280, min: 1024 },
			items: 3,
		},
		tablet: {
			breakpoint: { max: 1024, min: 860 },
			items: 2,
			partialVisibilityGutter: 120,
		},
		smalltablet: {
			breakpoint: { max: 860, min: 768 },
			items: 2,
			partialVisibilityGutter: 80,
		},
		bigmobile: {
			breakpoint: { max: 768, min: 640 },
			items: 2,
			partialVisibilityGutter: 40,
		},
		middlemobile: {
			breakpoint: { max: 640, min: 480 },
			items: 1,
			partialVisibilityGutter: 220,
		},
		mobile: {
			breakpoint: { max: 480, min: 0 },
			items: 1,
			partialVisibilityGutter: 80,
		},
	};

	/* const removeArrowsOnDeviceType = ['mobile']; */
	const removeArrowsOnDeviceType = [];

	const imgWidth = {
		xl4: '1800',
		xl3: '1500',
		xl: '1200',
		lg: '1024',
		md: '1024',
		sm: '768',
		xs: '640',
		xxs: '480',
	};

	const imgHeight = {
		xl4: '633',
		xl3: '633',
		xl: '633',
		lg: '312',
		md: '312',
		sm: '312',
		xs: '270',
		xxs: '240',
	};

	// orientamento slide
	const orientamentoSlide =
		orientation === 'left' ? (
			<div className='col-start-1 col-end-61 flex justify-center items-center relative overflow-hidden xl:pb-4'>
				<div className='grid grid-cols-60 w-full'>
					<div className='col-start-1 sm:col-start-2 2xl:col-start-4 col-end-61 xl:col-end-39 order-last xl:order-first z-10'>
						<Slideshow
							slideList={elencoProdotti}
							swipeableProp={true}
							draggableProp={false}
							showDotsProp={false}
							infiniteProp={false}
							autoPlayProp={false}
							autoPlaySpeedProp={3000}
							arrowsProp={true}
							partialVisibleProp={true}
							responsiveProp={responsive}
							deviceTypeProp={deviceType}
							removeArrowOnDeviceTypeProp={removeArrowsOnDeviceType}
							containerClass='slideshowCategoriaSlide sm:pl-3 pt-3 xs:pt-8 xl:pt-16 xs:pb-8 xl:pb-16'
							itemClass='transition-all itemCategoriaSlide flex justify-center pt-4 pb-8 sm:py-4 px-2 4xl:p-4'
						/>
					</div>
					<div className='col-start-1 xl:col-start-39 col-end-61 pt-6 sm:pt-6 xl:pt-0 spanServerSide'>
						<LazyLoadPicture
							imgName={immagineCategory?.imgName}
							imgPath={immagineCategory?.imgPath}
							imgWidth={imgWidth}
							imgHeight={imgHeight}
							imgFolder={immagineCategory?.imgFolder}
							imgStyle='flex-start max-w-none w-full mb-3 sm:mb-0 xl:absolute bottom-0 right-0'
							imgAlt={category}
						/>
					</div>
				</div>
			</div>
		) : (
			<div className='col-start-1 col-end-61 flex justify-center items-center relative overflow-hidden xl:pb-4'>
				<div className='grid grid-cols-60 w-full'>
					<div className='col-start-1 xl:col-end-23 col-end-61 pt-6 sm:pt-6 xl:pt-0'>
						<LazyLoadPicture
							imgName={immagineCategory?.imgName}
							imgPath={immagineCategory?.imgPath}
							imgWidth={imgWidth}
							imgHeight={imgHeight}
							imgFolder={immagineCategory?.imgFolder}
							imgStyle='flex-start max-w-none w-full mb-3 sm:mb-0 xl:absolute bottom-0 left-0'
							imgAlt={category}
						/>
					</div>
					<div className='col-start-1 sm:col-start-2 xl:col-start-22 col-end-61 2xl:col-end-57 z-10'>
						<Slideshow
							slideList={elencoProdotti}
							swipeableProp={true}
							draggableProp={false}
							showDotsProp={false}
							infiniteProp={false}
							autoPlayProp={false}
							autoPlaySpeedProp={3000}
							arrowsProp={true}
							partialVisibleProp={true}
							responsiveProp={responsive}
							deviceTypeProp={deviceType}
							removeArrowOnDeviceTypeProp={removeArrowsOnDeviceType}
							containerClass='slideshowCategoriaSlide sm:pr-3 pt-3 xs:pt-8 xl:pt-16 xs:pb-8 xl:pb-16'
							itemClass='transition-all itemCategoriaSlide flex justify-center pt-4 pb-8 sm:py-4 px-2 4xl:p-4'
						/>
					</div>
				</div>
			</div>
		);

	return (
		<Fragment>
			<div className='col-start-2 sm:col-start-4 xl:col-start-2 2xl:col-start-5 col-end-60 sm:col-end-58 xl:col-end-58 2xl:col-end-56 flex flex-col xl:flex-row justify-center xl:justify-between items-center xl:items-start 4xl:items-center py-4 xl:pt-12 z-10'>
				<div className='flex flex-col xl:flex-row items-center'>
					<img
						src={BASESTORAGE + '/' + icona?.folder + '/' + icona?.file_name}
						width='80'
						height='80'
						className='flex-start xl:mr-6'
						alt={category}
					/>
					<TitoloBlocco
						titoloStyle={`titoloBlocco text-center xl:text-left text-2xl sm:text-4xl lg:text-5xl px-3 sm:px-0 text-${colore}`}
						titolo={category.toUpperCase()}
					/>
				</div>
				<div className='flex-shrink-0 mt-2 sm:mt-0 z-10'>
					<CustomButton
						buttonId={categoryId}
						buttonLink={linkSeoCategory}
						buttonRouteName={routeNameCategory}
						buttonName={homepageTranslation.veditutti.itemName.toUpperCase()}
						buttonStyle={`buttonBorderedTransparent text-${colore} border-${colore} px-5`}
					/>
				</div>
			</div>
			{orientamentoSlide}
			<div className='col-start-1 col-end-61 bg-white h-4 z-10'></div>
		</Fragment>
	);
};

export default CategoriaSlideItem;
