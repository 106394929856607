import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Briciole from '../shared/briciole/Briciole';

const ContattiKODetail = () => {
	const pageContent = useSelector((state) => state.currentPage.pageContent);
	const siteParams = useSelector((state) => state.siteParams);
	const paginestaticheTranslation = useSelector(
		(state) => state.translations.paginestatiche
	);

	useEffect(() => {}, []);

	// controllo che esista la proprietà detail (altrimenti vuol dire che sto ancora recuperando i dati)
	if (!pageContent.detail) {
		return <div></div>;
	}

	return (
		<div className='col-start-1 lg:col-start-15 4xl:col-start-16 col-end-61 3xl:col-end-59 4xl:col-end-57 sm:pb-12'>
			<Briciole />
			<div className='grid grid-cols-60 mt-0 sm:mt-3 xl:mt-6'>
				<div className='col-span-60 flex justify-between items-center px-8 xl:px-12 3xl:px-20 xl:pt-8 pb-6 sm:pb-8 xl:pb-12 3xl:py-14'>
					<h1 className='text-ribola-congress-blue text-3xl md:text-4xl xl:text-5xl font-opensansbold'>
						<small className='leading-tight flex'>
							{paginestaticheTranslation.titolocontatti.itemName.toUpperCase()}
						</small>
					</h1>
				</div>
				<div className='col-span-60 px-8 xl:px-12 3xl:px-20 pb-8 xl:pb-12'>
					<p
						className='text-sm'
						dangerouslySetInnerHTML={{
							__html: paginestaticheTranslation.testocontattiko.itemName,
						}}></p>
				</div>
			</div>
		</div>
	);
};

export default ContattiKODetail;
