import { IS_FETCHING, IS_LOADING } from './types';

const loadSpinner = (history) => async (dispatch, getState) => {
	dispatch({
		type: IS_FETCHING,
	});
};

const loadStoredData = (history) => async (dispatch, getState) => {
	dispatch({
		type: IS_LOADING,
	});
};

export default {
	loadSpinner,
	loadStoredData,
};
