import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import _uniqueId from 'lodash/uniqueId';
import Img from '../../shared/img/Img';
import InputField from '../../shared/inputField/InputField';
import PulsanteAggiungiKit from '../pulsanteAggiungiKit/PulsanteAggiungiKit';
import { BASENAME } from '../../../utils/basename';

const ProductKitModal = ({
	kit = {},
	immagineProdotto = {},
	nomeProdotto = '',
	codiceProdotto = '',
	measure_unit_id = 0,
	lunghezzaValue = null,
	larghezzaValue = null,
	profonditaValue = null,
	metrilineariValue = null,
	diametroValue = null,
	dimensioneValue = null,
	quantitaValue = 1,
	disableChangeFields = false,
	genericheTranslation,
	flags,
	onKitAddHandler = () => {},
	onCloseModal = () => {},
}) => {
	// local state
	const [isModalOpened, setIsModalOpened] = useState(false);
	const [container, setContainer] = useState(null);
	const [larghezza, setLarghezza] = useState(parseFloat(larghezzaValue));
	const [lunghezza, setLunghezza] = useState(parseFloat(lunghezzaValue));
	const [profondita, setProfondita] = useState(parseFloat(profonditaValue));
	const [metrilineari, setMetrilineari] = useState(
		parseFloat(metrilineariValue)
	);
	const [diametro, setDiametro] = useState(parseFloat(diametroValue));
	const [dimensione, setDimensione] = useState(parseFloat(dimensioneValue));
	const [quantita, setQuantita] = useState(quantitaValue);
	const [accessorioManualeSelezionato, setAccessorioManualeSelezionato] =
		useState(null);
	const [idAccessorioManuale, setIdAccessorioManuale] = useState(null);
	const [qtaAccessorioManuale, setQtaAccessorioManuale] = useState(null);
	const [totaleKit, setTotaleKit] = useState(0);
	const [isCalculating, setIsCalculating] = useState(false);

	// modal sweetalert2
	const MySwal = withReactContent(Swal);

	const onAddKitFunction = (idKit) => {
		/**
		 * alla scheda prodotto devo passare questi dati del kit
		 * 1) idKit (per mostrare il nome del kit selezionato)
		 * 2) totale costo kit
		 * 3) array di IDProdotto degli accessori
		 * 4) array di quantità degli accessori
		 * 5) eventuali nuove misure e quantita selezionate
		 * 6) se si tratta di un accessorio manuale devo passare solamente l'id e la quantità di quell'accessorio (idAccessorioManuale e qtaAccessorioManuale)
		 */
		MySwal.close();

		if (idAccessorioManuale !== null) {
			onKitAddHandler(
				idKit,
				parseFloat(totaleKit).toFixed(2),
				[idAccessorioManuale],
				[qtaAccessorioManuale],
				lunghezza,
				larghezza,
				profondita,
				metrilineari,
				diametro,
				quantita
			);
		} else {
			onKitAddHandler(
				idKit,
				parseFloat(totaleKit).toFixed(2),
				elencoIdsAccessori,
				elencoQuantityAccessori,
				lunghezza,
				larghezza,
				profondita,
				metrilineari,
				diametro,
				quantita
			);
		}
	};

	const onCloseKitFunction = () => {
		console.log('sono in onCloseKitFunction');
		MySwal.close();
	};

	const onLunghezzaChange = (event) => {
		if (event.value === undefined) {
			setLunghezza(event.target.value);
		} else {
			setLunghezza(event.value);
		}
	};

	const onLarghezzaChange = (event) => {
		if (event.value === undefined) {
			setLarghezza(event.target.value);
		} else {
			setLarghezza(event.value);
		}
	};

	const onDiametroChange = (event) => {
		if (event.value === undefined) {
			setDiametro(event.target.value);
		} else {
			setDiametro(event.value);
		}
	};

	const onQuantitaChange = (event) => {
		// se i campi sono disabilitati, esco subito e non aggiorno la quantità
		if (disableChangeFields) {
			return;
		}

		if (event === 'sottrai' && quantita > 1) {
			setQuantita(quantita - 1);
		} else if (event === 'somma') {
			setQuantita(quantita * 1 + 1);
		} else {
			// mi arriva da digitazione diretta
			if (event?.target !== undefined && event?.target?.value !== '') {
				setQuantita(parseInt(event.target.value));
			} else {
				if (!isNaN(event)) {
					setQuantita(event);
				} else {
					setQuantita('');
				}
			}
		}
	};

	const changeDimensioneTotali = () => {
		let dimensioneValue = 0;
		let dimensioneValueQta = 0;

		// prodotto venduto a mq
		if (measure_unit_id === 1) {
			dimensioneValue = larghezza * lunghezza;
			dimensioneValueQta = dimensioneValue * quantita;
		}

		// prodotto venduto a diametro (rete tonda)
		if (measure_unit_id === 5) {
			dimensioneValue = dimensione;
			dimensioneValueQta = dimensioneValue * quantita;
		}

		setDimensione(parseFloat(dimensioneValueQta).toFixed(4));
		// setto totaleKit
		setTotaleKit(totaleKitCalcolato);
	};

	/**
	 *  FUNZIONI VARIE PER CALCOLARE I VALORI DEGLI ACCESSORI IN BASE A PARAMETRO E MOLTIPLICATORE
	 */
	const calcolaQuantitaAccessorio = ({
		accessorioId,
		parametro = '',
		moltiplicatore = 0,
		lunghezza = 0,
		larghezza = 0,
		diametro = 0,
		quantita = 0,
		isAccessorioManuale = 0,
		kit_steps = [],
	}) => {
		let qtaCalcolo = 0;

		// se l'accessorio è manuale ritorno subito 0 (verrà selezionato dall'utente)
		if (isAccessorioManuale === 1) {
			let qtaCalcolo = 0;
			return qtaCalcolo;
		}

		// calcoli per parametro
		if (parametro === 'PERIMETRO') {
			qtaCalcolo =
				moltiplicatore *
				((parseFloat(lunghezza) + parseFloat(larghezza)) * 2) *
				quantita;
		}
		if (parametro === 'PZ') {
			qtaCalcolo = moltiplicatore * quantita;
		}
		if (parametro === 'LUNGHEZZA') {
			qtaCalcolo = moltiplicatore * parseFloat(lunghezza) * quantita;
		}
		if (parametro === 'ALTEZZA') {
			qtaCalcolo = moltiplicatore * parseFloat(larghezza) * quantita;
		}
		if (parametro === 'PERIMETROTONDO') {
			qtaCalcolo = Math.round(
				moltiplicatore * (3.14 * 2 * (parseFloat(diametro) / 2) * quantita)
			);
		}

		/// accessorio venduto a scaglioni?
		if (kit_steps.length > 0) {
			let quantitaMancante = qtaCalcolo;
			let array_scaglioni = []; // elenco eventuali scaglioni per coprire la misura totale
			const numScaglioni = parseFloat(kit_steps.length);
			const ultimoScaglione = kit_steps[numScaglioni - 1];
			const totaleLoop = Math.ceil(
				parseFloat(quantitaMancante / ultimoScaglione['step'])
			);

			/* console.log('quantitaMancante', quantitaMancante);
			console.log('ultimoScaglione', ultimoScaglione);
			console.log('totaleLoop', totaleLoop);
			console.log('numScaglioni', numScaglioni);
 */
			// recupero array scaglioni
			for (let i = 0; i < totaleLoop; i++) {
				let tempArray;
				let arrayToObj;
				if (quantitaMancante > 0) {
					tempArray = kit_steps
						.map((item) => {
							if (item.step >= quantitaMancante && quantitaMancante > 0) {
								quantitaMancante = quantitaMancante - item.step;
								return {
									datiScaglione: item,
									codiceAccessorio: item.codice,
									imponibile: item.imponibile,
									imponibileScontato: item.imponibileScontato,
									sconto: item.sconto,
									product_id: item.product_id,
									qtaAccessorio: 1,
								};
							}
						})
						.filter((value) => value !== undefined);

					arrayToObj = { ...tempArray }[0];
					if (arrayToObj !== undefined) {
						array_scaglioni[i] = arrayToObj;
					}
				}

				// l'ultimo step non è sufficiente per la quantità totale, aggiungo a mano l'ultimo step (ultimoScaglione) e ricontrollo la misura restante
				if (quantitaMancante > 0) {
					quantitaMancante = quantitaMancante - ultimoScaglione.step;
					array_scaglioni[i] = {
						datiScaglione: ultimoScaglione,
						codiceAccessorio: ultimoScaglione.codice,
						imponibile: ultimoScaglione.imponibile,
						imponibileScontato: ultimoScaglione.imponibileScontato,
						sconto: ultimoScaglione.sconto,
						product_id: ultimoScaglione.product_id,
						qtaAccessorio: 1,
					};
				}
			}

			// faccio il merge degli scaglioni per codice accessorio
			let mergedSteps;
			if (quantitaMancante <= 0) {
				mergedSteps = Object.entries(array_scaglioni).reduce(
					(acc, [key, value]) => {
						let sumQuantita;
						if (acc[value.codiceAccessorio] === undefined) {
							acc[value.codiceAccessorio] = value;
							acc[value.codiceAccessorio].qtaAccessorio = 1;
						} else {
							//console.log('aggiungo quantita a codice già esistente');
							sumQuantita = acc[value.codiceAccessorio].qtaAccessorio + 1;
							acc[value.codiceAccessorio].qtaAccessorio = sumQuantita;
						}
						return acc;
					},
					{}
				);
			}

			// restituisco quantità totale da calcolo scaglioni
			//console.log(mergedSteps);
			return mergedSteps;
		}

		return parseFloat(qtaCalcolo).toFixed(0);
	};

	/// SELEZIONA ACCESSORIO (quando isAccessorioManuale === 1)
	const onSelezionaAccessorioClick = (accessorioIndex) => {
		console.log('ho cliccato', accessorioIndex);

		const accessorioSel = kit.elencoAccessori[accessorioIndex];

		// setto l'accessorio manuale
		setAccessorioManualeSelezionato(accessorioSel);
	};

	const calcolaAccessorioManuale = () => {
		// calcolo la quantità dell'accessorio selezionato
		const parametroAccessorioSelezionato =
			accessorioManualeSelezionato.parametro;
		const moltiplicatoreAccessorioSelezionato =
			accessorioManualeSelezionato.moltiplicatore;
		const accessorioId = accessorioManualeSelezionato.id;
		const quantitaAccessorio = calcolaQuantitaAccessorio({
			accessorioId,
			parametro: parametroAccessorioSelezionato,
			moltiplicatore: moltiplicatoreAccessorioSelezionato,
			lunghezza,
			larghezza,
			diametro,
			quantita,
		});

		//console.log('quantitaAccessorio selezionato', quantitaAccessorio);

		// calcolo il totale dell'accessorio selezionato
		const totaleAccessorio =
			accessorioManualeSelezionato.sconto > 0
				? calcolaTotaleAccessorio(
						'',
						accessorioManualeSelezionato.imponibileScontato,
						quantitaAccessorio
				  )
				: calcolaTotaleAccessorio(
						accessorioManualeSelezionato.imponibile,
						'',
						quantitaAccessorio
				  );

		//console.log('totaleAccessorio selezionato', totaleAccessorio);

		// imposto totaleKit, id accessorio e quantità
		setTotaleKit(totaleAccessorio);
		setIdAccessorioManuale(accessorioId);
		setQtaAccessorioManuale(quantitaAccessorio);
		setIsCalculating(true);
	};

	const calcolaTotaleAccessorio = (
		imponibile,
		imponibileScontato,
		quantitaAccessorio
	) => {
		let totaleCostoArticolo = 0;
		if (imponibileScontato) {
			totaleCostoArticolo = parseFloat(imponibileScontato) * quantitaAccessorio;
		} else {
			totaleCostoArticolo = parseFloat(imponibile) * quantitaAccessorio;
		}

		return totaleCostoArticolo;
	};
	/**
	 *  FINE FUNZIONI
	 */

	// campi disabilitati?
	const fieldDisabled = disableChangeFields ? true : false;
	const disabledStyle = disableChangeFields && 'opacity-30 pointer-events-none';

	const isLunghezzaOrAltezza =
		flags?.flag_abilita_misura_altezza === 1
			? genericheTranslation.altezza.itemName.toUpperCase()
			: genericheTranslation.lunghezza.itemName.toUpperCase();

	const isLunghezza = lunghezza >= 0 && lunghezza !== null && (
		<div className='w-full sm:w-6/12 2xl:w-full flex justify-between items-center border-b border-gray-300 py-4 sm:pr-6 2xl:pr-0 text-sm text-ribola-heavy-metal'>
			<span>{isLunghezzaOrAltezza}</span>
			<InputField
				inputType='number'
				inputName='lunghezza'
				inputValue={lunghezza}
				inputHandlerFunction={onLunghezzaChange}
				inputPlaceholder='0,0'
				inputSuffix={` ${genericheTranslation.metri.itemName}`}
				inputStyle='border'
				showLabel={false}
				isNumberFormat={true}
				isDisabled={fieldDisabled}
				mainDivStyle='w-6/12'
			/>
		</div>
	);

	const isLarghezza = larghezza >= 0 && larghezza !== null && (
		<div className='w-full sm:w-6/12 2xl:w-full flex justify-between items-center border-b border-gray-300 py-4 sm:pr-6 2xl:pr-0 text-sm text-ribola-heavy-metal'>
			<span>{genericheTranslation.larghezza.itemName.toUpperCase()}</span>
			<InputField
				inputType='number'
				inputName='larghezza'
				inputValue={larghezza}
				inputHandlerFunction={onLarghezzaChange}
				inputPlaceholder='0,0'
				inputSuffix={` ${genericheTranslation.metri.itemName}`}
				inputStyle='border'
				showLabel={false}
				isNumberFormat={true}
				isDisabled={fieldDisabled}
				mainDivStyle='w-6/12'
			/>
		</div>
	);

	const isDiametro = diametro >= 0 && diametro !== null && (
		<div className='w-full sm:w-6/12 2xl:w-full flex justify-between items-center border-b border-gray-300 py-4 sm:pr-6 2xl:pr-0 text-sm text-ribola-heavy-metal'>
			<span>{genericheTranslation.diametro.itemName.toUpperCase()}</span>
			<InputField
				inputType='number'
				inputName='diametro'
				inputValue={diametro}
				inputHandlerFunction={onDiametroChange}
				inputPlaceholder='0,0'
				inputSuffix={` ${genericheTranslation.metri.itemName}`}
				inputStyle='border'
				showLabel={false}
				isNumberFormat={true}
				isDisabled={fieldDisabled}
				mainDivStyle='w-6/12'
			/>
		</div>
	);

	const isDimensione = dimensioneValue > 0 && (
		<div className='w-full sm:w-6/12 2xl:w-full flex justify-between items-center border-b border-gray-300 py-4 sm:pr-6 2xl:pr-0 text-sm text-ribola-heavy-metal'>
			<span>{genericheTranslation.dimensione.itemName.toUpperCase()}</span>
			<span className='font-opensanssemibold'>
				{parseFloat(dimensione).toFixed(2).toString().replace('.', ',')}
				&nbsp;
				{genericheTranslation.mq.itemName}
			</span>
		</div>
	);

	/**
	 * HTML MODAL
	 */
	const elencoAccessoriKit = kit.elencoAccessori.map(
		(
			{
				id,
				name,
				codice,
				immagine,
				imponibile,
				imponibileScontato,
				sconto,
				parametro,
				moltiplicatore,
				measure_unit,
				isAccessorioManuale,
				isAccessorioPreferito,
				kit_steps = [],
			},
			index
		) => {
			let quantitaAccessorio = calcolaQuantitaAccessorio({
				accessorioId: id,
				parametro,
				moltiplicatore,
				lunghezza,
				larghezza,
				diametro,
				quantita,
				kit_steps,
			});

			// mostro singolo nome accessorio o più accessori se sono presenti gli scaglioni
			let nomiAccessori;
			if (typeof quantitaAccessorio === 'object') {
				nomiAccessori = Object.keys(quantitaAccessorio).map((index) => {
					return (
						<span
							key={_uniqueId('nomeAccessorio_')}
							className='font-opensansbold text-sm'>
							{quantitaAccessorio[index].datiScaglione.name}
						</span>
					);
				});
			} else {
				nomiAccessori = (
					<span className='font-opensansbold text-sm'>{name}</span>
				);
			}

			// mostro singolo codice o più codici se sono presenti gli scaglioni
			let codiciAccessori;
			if (typeof quantitaAccessorio === 'object') {
				codiciAccessori = Object.keys(quantitaAccessorio).map((index) => {
					return (
						<span key={_uniqueId('codiceAccessorio_')} className='text-sm'>
							{quantitaAccessorio[index].codiceAccessorio}
						</span>
					);
				});
			} else {
				codiciAccessori = <span className='text-sm'>{codice}</span>;
			}

			// mostro singola quantità o più quantità se sono presenti gli scaglioni
			let quantitaAccessori;
			if (typeof quantitaAccessorio === 'object') {
				quantitaAccessori = Object.keys(quantitaAccessorio).map((index) => {
					return (
						<span
							key={_uniqueId('qtaAccessorio_')}
							className='font-opensansbold text-sm'>
							{quantitaAccessorio[index].qtaAccessorio} {measure_unit}
						</span>
					);
				});
			} else {
				quantitaAccessori = (
					<span className='font-opensansbold text-sm'>
						{quantitaAccessorio} {measure_unit}
					</span>
				);
			}

			// mostro singolo imponibile o più imponibili se sono presenti gli scaglioni
			let totaleImponibili;
			if (typeof quantitaAccessorio === 'object') {
				totaleImponibili = Object.keys(quantitaAccessorio).map((index) => {
					return quantitaAccessorio[index].datiScaglione.sconto > 0 ? (
						<span
							key={_uniqueId('imponibileAccessorio_')}
							className='font-opensansbold text-ribola-astral text-base 3xl:text-xl'>
							<span>
								{parseFloat(
									quantitaAccessorio[index].datiScaglione.imponibileScontato
								)
									.toFixed(2)
									.toString()
									.replace('.', ',')}
								€
							</span>
							<span className='text-sm text-ribola-heavy-metal line-through ml-2'>
								{parseFloat(quantitaAccessorio[index].datiScaglione.imponibile)
									.toFixed(2)
									.toString()
									.replace('.', ',')}
								€
							</span>
						</span>
					) : (
						<span
							key={_uniqueId('imponibileAccessorio_')}
							className='font-opensansbold text-ribola-astral text-base 3xl:text-xl'>
							{parseFloat(quantitaAccessorio[index].datiScaglione.imponibile)
								.toFixed(2)
								.toString()
								.replace('.', ',')}
							€
						</span>
					);
				});
			} else {
				totaleImponibili =
					sconto > 0 ? (
						<span className='font-opensansbold text-ribola-astral text-base 3xl:text-xl flex items-center'>
							<span>
								{parseFloat(imponibileScontato)
									.toFixed(2)
									.toString()
									.replace('.', ',')}
								€
							</span>
							<span className='text-sm text-ribola-heavy-metal line-through ml-2'>
								{parseFloat(imponibile).toFixed(2).toString().replace('.', ',')}
								€
							</span>
						</span>
					) : (
						<span className='font-opensansbold text-ribola-astral text-base 3xl:text-xl'>
							{parseFloat(imponibile).toFixed(2).toString().replace('.', ',')}€
						</span>
					);
			}

			// mostro singolo totale o più totali se sono presenti gli scaglioni
			let totaleAccessori;
			let totaleAccessorio;
			if (typeof quantitaAccessorio === 'object') {
				totaleAccessori = Object.keys(quantitaAccessorio).map((index) => {
					totaleAccessorio = calcolaTotaleAccessorio(
						quantitaAccessorio[index].datiScaglione.imponibile,
						'',
						quantitaAccessorio[index].qtaAccessorio
					);
					let totaleAccessorioScontato = calcolaTotaleAccessorio(
						'',
						quantitaAccessorio[index].datiScaglione.imponibileScontato,
						quantitaAccessorio[index].qtaAccessorio
					);
					return quantitaAccessorio[index].datiScaglione.sconto > 0 ? (
						<span
							key={_uniqueId('totaleAccessorio_')}
							className='font-opensansbold text-ribola-astral text-base 3xl:text-xl'>
							<span>
								{parseFloat(totaleAccessorioScontato)
									.toFixed(2)
									.toString()
									.replace('.', ',')}
								€
							</span>
							<span className='text-sm text-ribola-heavy-metal line-through ml-2'>
								{parseFloat(totaleAccessorio)
									.toFixed(2)
									.toString()
									.replace('.', ',')}
								€
							</span>
						</span>
					) : (
						<span
							key={_uniqueId('totaleAccessorio_')}
							className='font-opensansbold text-ribola-astral text-base 3xl:text-xl'>
							{parseFloat(totaleAccessorio)
								.toFixed(2)
								.toString()
								.replace('.', ',')}
							€
						</span>
					);
				});
			} else {
				totaleAccessorio = calcolaTotaleAccessorio(
					imponibile,
					'',
					quantitaAccessorio
				);
				let totaleAccessorioScontato = calcolaTotaleAccessorio(
					'',
					imponibileScontato,
					quantitaAccessorio
				);
				totaleAccessori =
					sconto > 0 ? (
						<span className='font-opensansbold text-ribola-astral text-base 3xl:text-xl'>
							<span>
								{parseFloat(totaleAccessorioScontato)
									.toFixed(2)
									.toString()
									.replace('.', ',')}
								€
							</span>
							<span className='text-sm text-ribola-heavy-metal line-through ml-2'>
								{parseFloat(totaleAccessorio)
									.toFixed(2)
									.toString()
									.replace('.', ',')}
								€
							</span>
						</span>
					) : (
						<span className='font-opensansbold text-ribola-astral text-base 3xl:text-xl'>
							{parseFloat(totaleAccessorio)
								.toFixed(2)
								.toString()
								.replace('.', ',')}
							€
						</span>
					);
			}

			// mostro singolo sconto o più sconti se sono presenti gli scaglioni
			let totaleSconti;
			if (typeof quantitaAccessorio === 'object') {
				totaleSconti = Object.keys(quantitaAccessorio).map((index) => {
					return (
						<span
							key={_uniqueId('totaleSconto_')}
							className='font-opensanssemibold text-sm'>
							{quantitaAccessorio[index].datiScaglione.sconto > 0
								? quantitaAccessorio[index].datiScaglione.sconto + '%'
								: '-'}
						</span>
					);
				});
			} else {
				totaleSconti = (
					<span className='font-opensanssemibold text-sm'>
						{sconto > 0 ? sconto + '%' : '-'}
					</span>
				);
			}

			const accessorioManualeStyle =
				isAccessorioManuale === 1 &&
				accessorioManualeSelezionato?.id !== id &&
				'opacity-40 hover:opacity-100 cursor-pointer';

			const headerAccessorioManuale =
				isAccessorioManuale && index === 0 ? (
					<div className='grid grid-cols-60 w-auto xl:w-full'>
						<div className='col-span-60 flex py-2 text-sm font-opensanssemibold text-green-500'>
							{genericheTranslation.selezionaaccessorio.itemName}
						</div>
					</div>
				) : (
					''
				);

			const accessorioPreferito = isAccessorioPreferito === 1 && (
				<span className='pt-1'>
					<i className='far fa-heart mr-1'></i>
					<span className='text-sm'>
						{genericheTranslation.accessoriopreferito.itemName}
					</span>
				</span>
			);

			const accessorioPreferitoStyle =
				isAccessorioPreferito === 1 ? 'bg-green-100' : 'bg-ribola-gallery-gray';

			return (
				<React.Fragment key={_uniqueId('accessorioBoxItem_')}>
					{headerAccessorioManuale}
					<div
						className={`grid grid-cols-60 w-auto xl:w-full ${accessorioPreferitoStyle} text-sm text-left text-ribola-heavy-metal h-25 transition ${accessorioManualeStyle} mb-4 tableModalKit`}
						onClick={() =>
							isAccessorioManuale === 1
								? onSelezionaAccessorioClick(index)
								: false
						}>
						<div className='col-span-10 border-l-8 border-ribola-quicksand text-center flex flex-col items-center h-25 py-3'>
							<Img
								imgPath={`/storage/${
									immagine.folder
								}/thumbs/120x120-${immagine.file_name.replace(
									'.jpg',
									'.webp'
								)}`}
								imgWidth='75'
								imgHeight='75'
								imgStyle='flex-start'
								imgAlt={immagine.file_name}
							/>
						</div>
						<div className='col-span-16 flex flex-col justify-center py-3'>
							{nomiAccessori}
							{accessorioPreferito}
						</div>
						<div className='col-span-6 flex flex-col justify-center py-3'>
							{codiciAccessori}
						</div>
						<div className='col-span-6 flex flex-col justify-center py-3'>
							{quantitaAccessori}
						</div>
						<div className='col-span-8 flex flex-col justify-center py-3'>
							{totaleImponibili}
						</div>
						<div className='col-span-8 flex flex-col justify-center py-3'>
							{totaleAccessori}
						</div>
						<div className='col-span-6 flex flex-col justify-center py-3'>
							{totaleSconti}
						</div>
					</div>
				</React.Fragment>
			);
		}
	);

	/// TOTALE KIT CALCOLATO
	const totaleKitCalcolato = kit.elencoAccessori
		.map(
			({
				id,
				imponibile,
				imponibileScontato,
				sconto,
				parametro,
				moltiplicatore,
				isAccessorioManuale,
				kit_steps,
			}) => {
				let quantitaAccessorio = calcolaQuantitaAccessorio({
					accessorioId: id,
					parametro,
					moltiplicatore,
					lunghezza,
					larghezza,
					diametro,
					quantita,
					isAccessorioManuale,
					kit_steps,
				});

				let totaleAccessorio = 0;

				// se quantitaAccessorio è un oggetto devo calcolare i singoli importi degli steps
				if (typeof quantitaAccessorio === 'object') {
					Object.keys(quantitaAccessorio).map((itemKey) => {
						totaleAccessorio +=
							quantitaAccessorio[itemKey].sconto > 0
								? calcolaTotaleAccessorio(
										'',
										quantitaAccessorio[itemKey].imponibileScontato,
										quantitaAccessorio[itemKey].qtaAccessorio
								  )
								: calcolaTotaleAccessorio(
										quantitaAccessorio[itemKey].imponibile,
										'',
										quantitaAccessorio[itemKey].qtaAccessorio
								  );
						return;
					});
				} else {
					totaleAccessorio =
						sconto > 0
							? calcolaTotaleAccessorio(
									'',
									imponibileScontato,
									quantitaAccessorio
							  )
							: calcolaTotaleAccessorio(imponibile, '', quantitaAccessorio);
				}

				return totaleAccessorio;
			}
		)
		.reduce((previousValue, currentValue) => previousValue + currentValue);

	/// ELENCO IDS ACCESSORI
	const elencoIdsAccessori = kit.elencoAccessori.map(({ id }) => {
		return id;
	});

	/// ELENCO QTA ACCESSORI
	const elencoQuantityAccessori = kit.elencoAccessori.map(
		({ id, imponibile, sconto, parametro, moltiplicatore, kit_steps }) => {
			const quantitaAccessorio = calcolaQuantitaAccessorio({
				id,
				parametro,
				moltiplicatore,
				lunghezza,
				larghezza,
				diametro,
				quantita,
				kit_steps,
			});

			return quantitaAccessorio;
		}
	);

	const immagineProdottoModal =
		immagineProdotto?.imgName !== undefined
			? immagineProdotto
			: {
					imgName: 'placeholder_scheda_prodotto.jpg',
					imgPath: 'statiche',
			  };

	const htmlModal = (
		<div className='grid grid-cols-60'>
			<div className='col-span-60 flex mt-3 sm:mt-0 mb-6 sm:mb-12'>
				<span className='text-ribola-quicksand font-opensansbold text-left text-2xl sm:text-3xl'>
					{genericheTranslation.kitdimontaggio.itemName.toUpperCase()}&nbsp;
					{kit.categoriaKit}
				</span>
			</div>
			<div className='col-span-60 mb-3 sm:mb-8'>
				<div className='grid grid-cols-60 w-full'>
					<div className='col-span-60 md:col-span-15 flex flex-col items-center'>
						<Img
							imgPath={`/storage/${
								immagineProdottoModal.imgPath
							}/thumbs/743x580-${immagineProdottoModal.imgName.replace(
								'.jpg',
								'.webp'
							)}`}
							imgWidth='432'
							imgHeight='337'
							imgStyle='flex-start w-full max-w-lg object-fit'
							imgAlt={immagineProdottoModal.imgName}
						/>
					</div>
					<div className='col-span-60 md:col-span-45 flex flex-col items-start mt-8 md:pl-12 md:mt-0'>
						<span className='text-sm text-ribola-dove-gray'>
							{genericheTranslation.kitselezionato.itemName.toUpperCase()}
						</span>
						<span className='text-xl text-ribola-astral text-left font-opensansbold mb-4 lg:mb-8'>
							{kit.name}
						</span>
						<span className='text-sm text-ribola-dove-gray'>
							{genericheTranslation.prodotto.itemName.toUpperCase()}
						</span>
						<span className='text-xl text-ribola-astral text-left font-opensansbold mb-4 lg:mb-8'>
							{nomeProdotto}
						</span>
						<span className='text-sm text-ribola-dove-gray'>
							{genericheTranslation.codice.itemName.toUpperCase()}
						</span>
						<span className='text-xl text-ribola-astral text-left font-opensansbold'>
							{codiceProdotto}
						</span>
					</div>
				</div>
			</div>
			<div className='col-span-60'>
				<div className='grid grid-cols-60 w-full'>
					<div className='col-span-60 2xl:col-span-15 flex flex-wrap 2xl:flex-nowrap 2xl:flex-col items-start gruppoCampiInserimento'>
						{isLunghezza}
						{isLarghezza}
						{isDiametro}
						{isDimensione}
						<div className='w-full sm:w-6/12 2xl:w-full flex sm:py-3 2xl:py-0 mt-6 sm:mt-0 2xl:mt-6 sm:justify-end 2xl:justify-start text-ribola-heavy-metal'>
							<div className={`flex items-center ${disabledStyle}`}>
								<span className='w-28 3xl:w-auto text-xl font-opensanssemibold'>
									{genericheTranslation.quantitastatic.itemName.toUpperCase()}
								</span>
								<div className='flex items-center ml-3 4xl:ml-6'>
									<i
										className='far fa-minus text-xs mx-2 cursor-pointer'
										onClick={() => onQuantitaChange('sottrai')}></i>
									<InputField
										inputType='number'
										inputName='qta'
										inputValue={quantita}
										inputHandlerFunction={() => {}}
										inputStyle='border-2 text-center noArrows w-12'
										isReadOnly={true}
										isDisabled={fieldDisabled}
									/>
									<i
										className='far fa-plus text-xs mx-2 cursor-pointer'
										onClick={() => onQuantitaChange('somma')}></i>
								</div>
							</div>
						</div>
					</div>
					<div className='col-span-60 2xl:col-span-45 flex flex-col items-start overflow-x-scroll 2xl:overflow-x-visible mt-8 2xl:mt-0 2xl:pl-12'>
						<div className='grid grid-cols-60 w-auto xl:w-full text-ribola-heavy-metal text-xs 3xl:text-sm font-opensansbold mb-2 tableModalKit'>
							<div className='col-span-10'></div>
							<div className='col-span-16 flex'>
								<span>
									{genericheTranslation.accessorio.itemName.toUpperCase()}
								</span>
							</div>
							<div className='col-span-6 flex'>
								<span>
									{genericheTranslation.codice.itemName.toUpperCase()}
								</span>
							</div>
							<div className='col-span-6 flex'>
								<span>
									{genericheTranslation.quantitastatic.itemName.toUpperCase()}
								</span>
							</div>
							<div className='col-span-8 flex'>
								<span>
									{genericheTranslation.imponibilestatic.itemName.toUpperCase()}
								</span>
							</div>
							<div className='col-span-8 flex'>
								<span>
									{genericheTranslation.totalestatic.itemName.toUpperCase()}
								</span>
							</div>
							<div className='col-span-6 flex'>
								<span>
									{genericheTranslation.sconto.itemName.toUpperCase()}
								</span>
							</div>
						</div>
						{elencoAccessoriKit}
					</div>
				</div>
			</div>
			<div className='col-span-60 footerModalKit'>
				<div className='grid grid-cols-60 w-full'>
					<div className='col-span-12 flex-col items-start hidden xl:flex'>
						<div className='flex mt-20'>
							<img
								src={`${BASENAME}/images/logo.svg`}
								width='170'
								height='116'
								className='flex-start'
								alt='Ribola Retificio Srl'
							/>
						</div>
					</div>
					<div className='col-span-60 xl:col-span-45 flex flex-col lg:flex-row justify-center items-center lg:justify-end lg:items-end mt-8 2xl:mt-0 2xl:pl-12'>
						<span className='mr-3 2xl:mr-12'>
							<span className='text-lg text-ribola-astral font-opensanssemibold mr-3'>
								{genericheTranslation.totalestatic.itemName.toUpperCase()} KIT
							</span>
							<span className='text-4xl text-ribola-astral font-opensanssemibold'>
								{parseFloat(totaleKit).toFixed(2).toString().replace('.', ',')}€
							</span>
						</span>
						<PulsanteAggiungiKit
							idKit={kit.id}
							onKitHandler={onCloseKitFunction}
							isDisabled={false}
							isKitShown={true}
							isKitRemoved={true}
							buttonStyle='bg-ribola-nobel text-white active:bg-ribola-dove-gray hover:bg-ribola-dove-gray w-full lg:w-4/12 px-8 py-4 mt-3 lg:mt-0 lg:mr-6'
							spanStyle='text-xl'
						/>
						<PulsanteAggiungiKit
							idKit={kit.id}
							onKitHandler={onAddKitFunction}
							isDisabled={dimensione > 0 && totaleKit > 0 ? false : true}
							isKitShown={true}
							buttonStyle='bg-ribola-quicksand text-white active:bg-ribola-tundora hover:bg-ribola-tundora w-full lg:w-4/12 px-8 py-4 mt-3 lg:mt-0'
							spanStyle='text-xl'
						/>
					</div>
				</div>
			</div>
		</div>
	);

	/**
	 * FINE HTML MODAL
	 */

	const animatingOpen = isModalOpened
		? ''
		: 'animate__animated animate__slideInDown animate__faster';

	useEffect(() => {
		console.log('sono in ProductKitModal', flags);
		changeDimensioneTotali();

		//console.log(larghezza, diametro, dimensione);

		if (accessorioManualeSelezionato !== null && isCalculating === false) {
			console.log('accessorio manuale');
			calcolaAccessorioManuale();
			setIsCalculating(false);
		}

		if (!isModalOpened) {
			console.log('apro il modal', isModalOpened);
			MySwal.fire({
				html: <div ref={(el) => setContainer(el)} />,
				showCancelButton: false,
				showConfirmButton: false,
				showCloseButton: true,
				position: 'top-start',
				customClass: {
					container: 'modalKit',
					htmlContainer: 'htmlModalKit',
				},
				showClass: {
					popup: animatingOpen,
				},
				hideClass: {
					popup: 'animate__animated animate__slideOutUp animate__faster',
				},
				didOpen: () => {
					console.log('aperto', isModalOpened);
					!isModalOpened && setIsModalOpened(true);
				},
				didClose: () => {
					setIsModalOpened(false);
					setContainer(null);
					onCloseModal();
				},
			}).then((event) => {});
		}

		// cleanup function
		return () => {
			/// DA SISTEMARE NON RIESCO A SVUOTARE IL REFERER DEL PORTAL
			//console.log('aaa', isModalOpened, container);
			if (container) {
				//setContainer(null);
				//document.body.removeChild(container);
			}
		};
	}, [larghezza, lunghezza, diametro, quantita, accessorioManualeSelezionato]);

	if (container && isModalOpened) {
		console.log('container portal', container);
		return createPortal(htmlModal, container);
	}

	return null;
};

export default ProductKitModal;
