import TagManager from 'react-gtm-module';

const gtmImpressions = ({
	productList,
	categoryName,
	isGtmEnabled = false,
}) => {
	// gtm impressions prodotti
	/**
   * {
        id: 'codice',
        name: 'none prodotto + codice',
        price: 'imponibile',
        brand: 'Ribola',
        category: 'nome categoria',
        position: '0 --> incrementale',
        list: 'Elenco categorianome'
      }
    */

	const elencoProducts = Object.keys(productList).map((key, index) => {
		const { detail } = productList[key];
		const nomeProdotto = detail.titolo_breve;
		const codiceProdotto = detail.codice;
		const imponibile = parseFloat(detail.imponibile).toFixed(2);
		const sconto = detail.sconto;
		const prezzoScontato = parseFloat(detail.prezzoScontato).toFixed(2);
		const categoria = categoryName;

		return {
			id: codiceProdotto,
			name: nomeProdotto + ' cod.' + codiceProdotto,
			price: sconto > 0 ? prezzoScontato : imponibile,
			brand: 'Ribola',
			category: categoria,
			position: index,
			list: 'Elenco ' + categoria,
		};
	});
	const tagManagerArgs = {
		dataLayer: {
			event: 'impressions',
			ecommerce: {
				currencyCode: 'EUR',
				impressions: [elencoProducts],
			},
		},
	};

	// invio dati a GTM
	if (typeof window !== 'undefined' && isGtmEnabled) {
		TagManager.dataLayer(tagManagerArgs);
	}
};

const gtmProductClick = ({
	productData,
	categoryName,
	position,
	callbackFunction = () => {},
	isGtmEnabled = false,
}) => {
	// gtm product click
	/**
   * {
        id: 'codice',
        name: 'none prodotto + codice',
        price: 'imponibile',
        brand: 'Ribola',
        category: 'nome categoria',
        position: '0',
      }
    */

	const nomeProdotto = productData.titoloBreve;
	const codiceProdotto = productData.codice;
	const imponibile = parseFloat(productData.imponibile).toFixed(2);
	const sconto = productData.sconto;
	const prezzoScontato = parseFloat(productData.prezzoScontato).toFixed(2);

	console.log(productData, categoryName, position);

	const tagManagerArgs = {
		dataLayer: {
			event: 'productClick',
			ecommerce: {
				click: {
					actionField: {
						list: 'Elenco ' + categoryName,
					},
					products: [
						{
							id: codiceProdotto,
							name: nomeProdotto + ' cod.' + codiceProdotto,
							price: sconto > 0 ? prezzoScontato : imponibile,
							brand: 'Ribola',
							category: categoryName,
							position: position,
						},
					],
				},
			},
			eventCallback: function () {
				callbackFunction();
			},
		},
	};

	//console.log(tagManagerArgs);

	if (isGtmEnabled === false) {
		callbackFunction();
	}

	// invio dati a GTM
	if (typeof window !== 'undefined' && isGtmEnabled) {
		TagManager.dataLayer(tagManagerArgs);
	}
};

const gtmProductDetail = ({
	productData,
	categoryName,
	isGtmEnabled = false,
}) => {
	// gtm detail prodotto
	/**
       * {
            id: 'codice',
            name: 'none prodotto + codice',
            price: 'imponibile',
            brand: 'Ribola',
          }
       */

	const nomeProdotto = productData.nomeProdotto;
	const codiceProdotto = productData.codiceProdotto;
	const imponibile = parseFloat(productData.imponibile).toFixed(2);
	const sconto = productData.sconto;
	const prezzoScontato = parseFloat(productData.prezzoScontato).toFixed(2);

	//console.log(productData, codiceProdotto, nomeProdotto);

	const tagManagerArgs = {
		dataLayer: {
			event: 'detail',
			ecommerce: {
				detail: {
					actionField: {
						list: 'Elenco ' + categoryName,
					},
					products: [
						{
							id: codiceProdotto,
							name: nomeProdotto + ' cod.' + codiceProdotto,
							price: sconto > 0 ? prezzoScontato : imponibile,
							brand: 'Ribola',
						},
					],
				},
			},
		},
	};

	// invio dati a GTM
	if (typeof window !== 'undefined' && isGtmEnabled) {
		TagManager.dataLayer(tagManagerArgs);
	}
};

const gtmAddToCart = ({
	productData,
	categoryName,
	prezzoCarrello,
	quantitaCarrello,
	callbackFunction = () => {},
	isGtmEnabled = false,
}) => {
	// gtm add to cart
	/**
       * {
            id: 'codice',
            name: 'none prodotto + codice',
            price: 'imponibile',
            brand: 'Ribola',
            category: 'nome categoria'
            quantity: 'quantità aggiunta'
          }
       */

	const nomeProdotto = productData.titoloBreve;
	const codiceProdotto = productData.codice;

	const tagManagerArgs = {
		dataLayer: {
			event: 'addToCart',
			ecommerce: {
				currencyCode: 'EUR',
				add: {
					products: [
						{
							id: codiceProdotto,
							name: nomeProdotto + ' cod.' + codiceProdotto,
							price: prezzoCarrello,
							brand: 'Ribola',
							category: categoryName,
							quantity: quantitaCarrello,
						},
					],
				},
			},
			eventCallback: function () {
				callbackFunction();
			},
		},
	};

	if (isGtmEnabled === false) {
		callbackFunction();
	}

	// invio dati a GTM
	if (typeof window !== 'undefined' && isGtmEnabled) {
		TagManager.dataLayer(tagManagerArgs);
	}
};

const gtmRemoveFromCart = ({
	productData,
	categoryName,
	prezzoCarrello,
	quantitaCarrello,
	callbackFunction = () => {},
	isGtmEnabled = false,
}) => {
	// gtm remove from cart
	/**
       * {
            id: 'codice',
            name: 'nome prodotto + codice',
            price: 'imponibile',
            brand: 'Ribola',
            category: 'nome categoria'
            quantity: 'quantità rimossa'
          }
       */

	const nomeProdotto = productData.titoloBreve;
	const codiceProdotto = productData.codice;

	console.log(productData, categoryName, prezzoCarrello, quantitaCarrello);

	const tagManagerArgs = {
		dataLayer: {
			event: 'removeFromCart',
			ecommerce: {
				currencyCode: 'EUR',
				remove: {
					products: [
						{
							id: codiceProdotto,
							name: nomeProdotto + ' cod.' + codiceProdotto,
							price: prezzoCarrello,
							brand: 'Ribola',
							category: categoryName,
							quantity: quantitaCarrello,
						},
					],
				},
			},
			eventCallback: function () {
				callbackFunction();
			},
		},
	};

	if (isGtmEnabled === false) {
		callbackFunction();
	}

	// invio dati a GTM
	if (typeof window !== 'undefined' && isGtmEnabled) {
		TagManager.dataLayer(tagManagerArgs);
	}
};

// STEP 1 = LOGIN/REGISTRAZIONE, 2 = PAGINA SPEDIZIONE / PAGAMENTO, 3 = CONFERMA ORDINE (CHECKOUT), 4 = ORDINE CONCLUSO

const gtmCheckoutLoginRegistrazione = ({
	cartData,
	callbackFunction = () => {},
	isGtmEnabled = false,
}) => {
	const cartProducts = Object.keys(cartData).map((itemId, index) => {
		const cartElement = cartData[itemId];
		console.log(cartElement);

		return {
			id: cartElement.attributes.codiceProdotto,
			name: cartElement.name + ' cod.' + cartElement.attributes.codiceProdotto,
			price: parseFloat(cartElement.price).toFixed(2),
			brand: 'Ribola',
			category: cartElement.attributes.titoloCategoria,
			quantity: cartElement.quantity,
		};
	});

	console.log(cartProducts);

	const tagManagerArgs = {
		dataLayer: {
			event: 'checkout',
			ecommerce: {
				currencyCode: 'EUR',
				checkout: {
					actionField: {
						step: 1,
					},
					products: cartProducts,
				},
			},
			eventCallback: function () {
				callbackFunction();
			},
		},
	};

	console.log(tagManagerArgs);

	if (isGtmEnabled === false) {
		callbackFunction();
	}

	// invio dati a GTM
	if (typeof window !== 'undefined' && isGtmEnabled) {
		TagManager.dataLayer(tagManagerArgs);
	}
};

const gtmCheckoutSpedizionePagamento = ({
	cartData,
	paymentMethod,
	callbackFunction = () => {},
	isGtmEnabled = false,
}) => {
	const cartProducts = Object.keys(cartData).map((itemId, index) => {
		const cartElement = cartData[itemId];
		console.log(cartElement);

		return {
			id: cartElement.attributes.codiceProdotto,
			name: cartElement.name + ' cod.' + cartElement.attributes.codiceProdotto,
			price: parseFloat(cartElement.price).toFixed(2),
			brand: 'Ribola',
			category: cartElement.attributes.titoloCategoria,
			quantity: cartElement.quantity,
		};
	});

	console.log(cartProducts);

	const tagManagerArgs = {
		dataLayer: {
			event: 'checkout',
			ecommerce: {
				currencyCode: 'EUR',
				checkout: {
					actionField: {
						step: 2,
						option: paymentMethod,
					},
					products: cartProducts,
				},
			},
			eventCallback: function () {
				callbackFunction();
			},
		},
	};

	console.log(tagManagerArgs);

	if (isGtmEnabled === false) {
		callbackFunction();
	}

	// invio dati a GTM
	if (typeof window !== 'undefined' && isGtmEnabled) {
		TagManager.dataLayer(tagManagerArgs);
	}
};

const gtmCheckoutConfermaOrdine = ({
	cartData,
	paymentMethod,
	callbackFunction = () => {},
	isGtmEnabled = false,
}) => {
	const cartProducts = Object.keys(cartData).map((itemId, index) => {
		const cartElement = cartData[itemId];
		console.log(cartElement);

		return {
			id: cartElement.attributes.codiceProdotto,
			name: cartElement.name + ' cod.' + cartElement.attributes.codiceProdotto,
			price: parseFloat(cartElement.price).toFixed(2),
			brand: 'Ribola',
			category: cartElement.attributes.titoloCategoria,
			quantity: cartElement.quantity,
		};
	});

	console.log(cartProducts);

	const tagManagerArgs = {
		dataLayer: {
			event: 'checkout',
			ecommerce: {
				currencyCode: 'EUR',
				checkout: {
					actionField: {
						step: 3,
						option: paymentMethod,
					},
					products: cartProducts,
				},
			},
			eventCallback: function () {
				callbackFunction();
			},
		},
	};

	console.log(tagManagerArgs);

	if (isGtmEnabled === false) {
		callbackFunction();
	}

	// invio dati a GTM
	if (typeof window !== 'undefined' && isGtmEnabled) {
		TagManager.dataLayer(tagManagerArgs);
	}
};

const gtmCheckoutOrdineConcluso = ({
	cartData,
	paymentMethod,
	callbackFunction = () => {},
	isGtmEnabled = false,
}) => {
	const cartProducts = Object.keys(cartData).map((itemId, index) => {
		const cartElement = cartData[itemId];
		console.log(cartElement);

		return {
			id: cartElement.attributes.codiceProdotto,
			name: cartElement.name + ' cod.' + cartElement.attributes.codiceProdotto,
			price: parseFloat(cartElement.price).toFixed(2),
			brand: 'Ribola',
			category: cartElement.attributes.titoloCategoria,
			quantity: cartElement.quantity,
		};
	});

	console.log(cartProducts);

	const tagManagerArgs = {
		dataLayer: {
			event: 'checkout',
			ecommerce: {
				currencyCode: 'EUR',
				checkout: {
					actionField: {
						step: 4,
						option: paymentMethod,
					},
					products: cartProducts,
				},
			},
			eventCallback: function () {
				callbackFunction();
			},
		},
	};

	console.log(tagManagerArgs);

	if (isGtmEnabled === false) {
		callbackFunction();
	}

	// invio dati a GTM
	if (typeof window !== 'undefined' && isGtmEnabled) {
		TagManager.dataLayer(tagManagerArgs);
	}
};

const gtmTransaction = ({
	cartData,
	orderData,
	tokenId,
	callbackFunction = () => {},
	isGtmEnabled = false,
}) => {
	const cartProducts = Object.keys(cartData).map((itemId, index) => {
		const cartElement = cartData[itemId];
		console.log(cartElement);

		return {
			id: cartElement.attributes.codiceProdotto,
			name: cartElement.name + ' cod.' + cartElement.attributes.codiceProdotto,
			price: parseFloat(cartElement.price).toFixed(2),
			brand: 'Ribola',
			category: cartElement.attributes.titoloCategoria,
			quantity: cartElement.quantity,
		};
	});

	console.log(cartProducts);

	const tagManagerArgs = {
		dataLayer: {
			event: 'transaction',
			ecommerce: {
				currencyCode: 'EUR',
				purchase: {
					actionField: {
						id: tokenId,
						affiliation: 'Ribola Ecommerce',
						revenue: parseFloat(orderData.totalCart).toFixed(2), // totale ordine + iva imponibile + spedizione con iva
						tax: parseFloat(orderData.subtotalCartVAT).toFixed(2), // iva imponibile
						shipping: parseFloat(orderData.totalShipping).toFixed(2), // spedizione senza iva
					},
					products: cartProducts,
				},
			},
			eventCallback: function () {
				callbackFunction();
			},
		},
	};

	console.log(tagManagerArgs);

	if (isGtmEnabled === false) {
		callbackFunction();
	}

	// invio dati a GTM
	if (typeof window !== 'undefined' && isGtmEnabled) {
		TagManager.dataLayer(tagManagerArgs);
	}
};

export {
	gtmImpressions,
	gtmProductClick,
	gtmProductDetail,
	gtmAddToCart,
	gtmRemoveFromCart,
	gtmCheckoutLoginRegistrazione,
	gtmCheckoutSpedizionePagamento,
	gtmCheckoutConfermaOrdine,
	gtmCheckoutOrdineConcluso,
	gtmTransaction,
};
