import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../state/actions';
import LazyLoadImg from '../lazyLoadImg/LazyLoadImg';
import CustomButton from '../customButton/CustomButton';
import InfoTooltipElement from '../infoTooltipElement/InfoTooltipElement';
import { BASENAME } from '../../../utils/basename';
import { URLASSOLUTO } from '../../../utils/urlassoluto';
import { gtmProductClick } from '../../../gtm/gtm';

const ProductBoxItem = ({
	id,
	index,
	immagine,
	immagineAlternativa,
	titoloBreve,
	sottotitoloElenco = '',
	codice,
	imponibile,
	sconto,
	tipologiaScontoPromozione,
	prezzoScontato,
	unitaMisura,
	measure_unit_id,
	iconaTagBestseller = '',
	iconaTagNovita = '',
	richiediInfo = '',
	isProntaConsegna = false,
	lunghezza = null,
	larghezza = null,
	profondita = null,
	metrilineari = null,
	prodotto3x2 = false,
	prodottoAbbinatoObj = null,
	prezzoFisso = null,
	colore,
	routeName,
	linkSeo,
	categoriaTitolo,
	scrollPosition,
	itemClass = null,
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const currentLang = useSelector((state) => state.currentPage.currentLang);
	const siteParams = useSelector((state) => state.siteParams);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);

	useEffect(() => {
		//console.log(prezzoFisso, imponibile, prezzoScontato, sconto);
		/* console.log(titoloBreve);
		if (titoloBreve === 'Teli copertura invernale') {
			console.log(richiediInfo);
		} */
	}, []);

	const onProductClick = (event) => {
		event.preventDefault();

		const productData = {
			titoloBreve,
			codice,
			imponibile,
			sconto,
			prezzoScontato,
		};

		// gtm productClick
		const gtmSuffix = `gtm_code${currentLang}`;
		const currentGTMTAG = siteParams[gtmSuffix];
		gtmProductClick({
			productData,
			categoryName: categoriaTitolo,
			position: index,
			callbackFunction: onGoToPage,
			isGtmEnabled: currentGTMTAG !== null ? true : false,
		});
	};

	const onGoToPage = () => {
		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(id, routeName, linkSeo, history)
		);
	};

	const sottotitolo =
		sottotitoloElenco !== '' ? (
			<span className={`titolo text-xs my-1 inline-block text-${colore}`}>
				<span className='leading-tight block'>{sottotitoloElenco}</span>
			</span>
		) : (
			<span className='titolo text-xs my-1 hidden sm:inline-block'>&nbsp;</span>
		);

	const tagBestseller = iconaTagBestseller ? (
		<span
			className={`iconaTag inline-block bg-ribola-shakespeare px-2 py-1 mt-2 mb-3 mr-2`}>
			{genericheTranslation.bestsellerflag.itemName.toUpperCase()}
		</span>
	) : (
		<span className='iconaTag hidden sm:inline-block py-1 mt-2 mb-3'>
			&nbsp;
		</span>
	);

	const tagNovita = iconaTagNovita ? (
		<span
			className={`iconaTag inline-block bg-ribola-congress-blue-variant px-2 py-1 mt-2 mb-3`}>
			{genericheTranslation.novitaflag.itemName.toUpperCase()}
		</span>
	) : (
		<span className='iconaTag hidden sm:inline-block mt-2 mb-3'>&nbsp;</span>
	);

	const tagInfo =
		richiediInfo === 1 ? (
			<span
				className={`iconaTag inline-block bg-ribola-chelsea-cucumber px-2 py-1 mt-2 mb-3`}>
				{genericheTranslation.informazioni.itemName.toUpperCase()}
			</span>
		) : (
			<span className='iconaTag hidden sm:inline-block mt-2 mb-3'>&nbsp;</span>
		);

	// prezzo fisso da tendina?
	// preparo i valori di prezzo ed eventuale prezzo scontato
	const prezzoDaMenuATendinaFisso = prezzoFisso ? prezzoFisso : null;
	const prezzoImponibileDaTendina =
		sconto > 0
			? prezzoDaMenuATendinaFisso - (prezzoDaMenuATendinaFisso / 100) * sconto
			: prezzoDaMenuATendinaFisso;
	const prezzoImponibileOriginale = sconto > 0 ? prezzoDaMenuATendinaFisso : 0;

	// se c'è uno sconto prodotto mostro il prezzo originale da presso fisso (se abilitato) oppure l'imponibile originale (non scontato e barrato)
	const prezzoOriginale = prezzoDaMenuATendinaFisso
		? prezzoImponibileOriginale
		: imponibile;
	// il prezzo finale può essere quello da prezzo fisso (menu a tendina) oppure da prezzo prodotto (eventualmente scontato)
	const prezzo = prezzoDaMenuATendinaFisso
		? prezzoImponibileDaTendina
		: sconto > 0
		? prezzoScontato
		: imponibile;

	const prezzoProdottoOriginale =
		sconto && sconto > 0 ? (
			<span className='prezzoScontato flex'>
				{parseFloat(prezzoOriginale).toFixed(2).toString().replace('.', ',')}€
			</span>
		) : (
			<span className='prezzoScontato opacity-0 hidden sm:inline-block'>
				&nbsp;
			</span>
		);

	const prezzoProdotto =
		sconto && sconto > 0 ? (
			<span className='prezzoProdotto flex text-ribola-web-orange text-3xl'>
				{parseFloat(prezzo).toFixed(2).toString().replace('.', ',')}€
				<small>{isProntaConsegna === false ? unitaMisura : ''}</small>
			</span>
		) : (
			<span className='prezzoProdotto flex text-ribola-congress-blue text-3xl'>
				{parseFloat(prezzo).toFixed(2).toString().replace('.', ',')}€
				<small>{isProntaConsegna === false ? unitaMisura : ''}</small>
			</span>
		);

	const scontoFasciaArancione =
		sconto && sconto > 0 ? (
			<div className='scontoFasciaArancione pointer-events-none z-20'>
				<span className='scontoFasciaInterna'>
					<span className='titoloFasciaInterna'>
						<span className='leading-none'>
							{sconto}
							{tipologiaScontoPromozione === 'Percentuale' ? '%' : '€'}
						</span>
					</span>
					<span className='leading-none'>
						{genericheTranslation.sconto.itemName}
					</span>
				</span>
				<img
					src={`${BASENAME}/images/sconto_fascia_arancione.svg`}
					width='180'
					height='180'
					className='flex-start'
					alt='Sconto prodotto'
				/>
			</div>
		) : (
			''
		);

	const immagineElenco =
		immagine === null ||
		immagine === undefined ||
		immagine.file_name === undefined ? (
			<LazyLoadImg
				imgName='placeholder_elenco_prodotto.jpg'
				imgPath='statiche'
				imgWidth='300'
				imgHeight='232'
				imgStyle='flex-start mb-3 w-full object-fit cursor-pointer'
				imgAlt={`${titoloBreve} - cod.${codice}`}
			/>
		) : (
			<LazyLoadImg
				imgName={`300x232-${immagine.file_name.replace('.jpg', '.webp')}`}
				imgPath={`${immagine.folder}/thumbs`}
				imgWidth='300'
				imgHeight='232'
				imgStyle='flex-start mb-3 w-full object-fit cursor-pointer'
				imgAlt={`${titoloBreve} - cod.${codice}`}
			/>
		);

	const iconaDoppiaImmagine = immagineAlternativa !== null &&
		immagineAlternativa !== undefined && (
			<span className='rounded-full h-10 w-10 flex items-center justify-center absolute top-1 right-1 bg-ribola-amber text-black'>
				<i className='far fa-images text-xl'></i>
			</span>
		);

	const immagineAlternativaElenco = immagineAlternativa !== null &&
		immagineAlternativa !== undefined && (
			<div className='transform-gpu transition-all -translate-x-full -translate-y-full immagineAlternativa'>
				<LazyLoadImg
					imgName={`300x232-${immagineAlternativa?.file_name.replace(
						'.jpg',
						'.webp'
					)}`}
					imgPath={`${immagineAlternativa?.folder}/thumbs`}
					imgWidth='300'
					imgHeight='232'
					imgStyle='flex-start w-full inline object-fit cursor-pointer'
					imgAlt={`${titoloBreve} - cod.${codice} alternative`}
				/>
			</div>
		);

	const pulsanteLink =
		richiediInfo === 1 ? (
			<CustomButton
				buttonId={id}
				buttonLink={linkSeo}
				buttonRouteName={routeName}
				buttonOnClickFunction={onProductClick}
				buttonName={genericheTranslation.richiediinformazioni.itemName}
				buttonStyle='dettagliButton richiediInfo text-sm pl-4 pr-16 py-2 3xl:py-3 mt-2'
			/>
		) : (
			<CustomButton
				buttonId={id}
				buttonLink={linkSeo}
				buttonRouteName={routeName}
				buttonOnClickFunction={onProductClick}
				buttonName={genericheTranslation.dettagli.itemName}
				buttonStyle='dettagliButton text-sm pl-4 pr-16 py-2 3xl:py-3 mt-2'
			/>
		);

	// prodotto 3x2 promo?
	const promoProdottoTrePerDue = prodotto3x2 ? (
		<span className='offertatreperdue absolute right-0 bottom-0 h-6 mb-2 px-2 inline-flex justify-center items-center bg-ribola-chelsea-cucumber text-white text-xs font-opensanssemibold'>
			<span className='flex-shrink-0 mr-3'>
				{genericheTranslation.offerta32.itemName.toUpperCase()}
			</span>
			<InfoTooltipElement
				iconColor='white'
				fontSize='text-lg'
				tooltipColor='gray-800'
				tooltipText={genericheTranslation.offerta32.itemName}
			/>
		</span>
	) : (
		''
	);

	// prodotto abbinato promo?
	const immagineOmaggioUrl =
		prodottoAbbinatoObj?.prodottoAbbinato.immagineelenco;
	const altImmagine =
		prodottoAbbinatoObj?.prodottoAbbinato !== undefined
			? prodottoAbbinatoObj?.prodottoAbbinato.name +
			  ' - cod.' +
			  prodottoAbbinatoObj?.prodottoAbbinato?.codice
			: 'Prodotto abbinato';
	const immagineOmaggio =
		(prodottoAbbinatoObj && immagineOmaggioUrl === null) ||
		immagineOmaggioUrl === undefined ? (
			<LazyLoadImg
				imgName='placeholder_elenco_prodotto.jpg'
				imgPath='statiche'
				imgWidth='36'
				imgHeight='36'
				imgStyle='flex-start w-6 object-fit'
				imgAlt={altImmagine}
			/>
		) : (
			<LazyLoadImg
				imgName={`36x36-${immagineOmaggioUrl.file_name.replace(
					'.jpg',
					'.webp'
				)}`}
				imgPath={`${immagineOmaggioUrl.folder}/thumbs`}
				imgWidth='36'
				imgHeight='36'
				imgStyle='flex-start w-full object-fit'
				imgAlt={altImmagine}
			/>
		);
	const msgProdottoAbbinato =
		prodottoAbbinatoObj?.tipologiaScontoSingolo === 'Omaggio'
			? genericheTranslation.insiemeinregalo.itemName.toUpperCase()
			: genericheTranslation.acquistaconsconto.itemName.toUpperCase();
	const promoProdottoAbbinato = prodottoAbbinatoObj ? (
		<span className='prodottoInRegalo absolute -right-2 -bottom-4 h-11 pl-2 pr-1 inline-flex justify-center items-center border-2 border-white bg-ribola-chelsea-cucumber text-white text-xs font-opensanssemibold'>
			<span
				className='flex-shrink-0 mr-3'
				dangerouslySetInnerHTML={{
					__html: msgProdottoAbbinato,
				}}
			/>
			{immagineOmaggio}
		</span>
	) : (
		<span className='prodottoInRegalo hidden'>&nbsp;</span>
	);

	// metto uno stile di default (margini) oppure se mi viene passato itemClass metto quello
	const itemClassStyled = itemClass
		? itemClass
		: 'mx-1 sm:mx-0 lg:mx-3 xl:mx-2 4xl:mx-3';

	return (
		<div
			className={`productBoxItem flex flex-col justify-between max-w-xxs sm:max-w-none ${itemClassStyled}`}>
			{scontoFasciaArancione}
			<div>
				<a
					href={URLASSOLUTO + linkSeo}
					onClick={onProductClick}
					className='h-52 inline-block w-full overflow-hidden relative'>
					{immagineElenco}
					{immagineAlternativaElenco}
					{promoProdottoTrePerDue}
					{promoProdottoAbbinato}
					{iconaDoppiaImmagine}
				</a>
				{tagInfo}
				{tagBestseller}
				{tagNovita}
				<span className='text-sm 3xl:text-base block'>
					<span className='leading-snug block text-ribola-tundora'>
						{titoloBreve}
					</span>
				</span>
				{sottotitolo}
				<span className='codice text-sm 3xl:text-base'>{codice}</span>
			</div>
			<div>
				{!richiediInfo ? prezzoProdottoOriginale : ''}
				{!richiediInfo ? prezzoProdotto : ''}
				{pulsanteLink}
			</div>
		</div>
	);
};

export default ProductBoxItem;
