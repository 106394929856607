import React from 'react';

const TextareaField = React.forwardRef(
	(
		{
			textareaLabel,
			textareaLabelStyle,
			textareaName,
			textareaValue,
			textareaHandlerFunction,
			textareaPlaceholder = '',
			textareaStyle = '',
			textareaRows = 4,
			textareaError = '',
			isReadOnly = false,
			showLabel = true,
			register = () => {},
		},
		ref
	) => {
		const labelStyled =
			textareaLabelStyle !== '' ? textareaLabelStyle : 'text-sm';

		const textareaStyled =
			textareaStyle !== ''
				? textareaStyle
				: 'border-ribola-dove-gray border-opacity-20 focus:border-opacity-100 text-ribola-dove-gray py-1';

		const labelDiv = showLabel ? (
			<div className={labelStyled}>
				<span
					dangerouslySetInnerHTML={{
						__html: textareaLabel,
					}}
				/>
				{textareaError}
			</div>
		) : (
			''
		);

		return (
			<div className='w-full flex flex-col justify-center items-start text-ribola-dove-gray'>
				{labelDiv}
				<textarea
					placeholder={textareaPlaceholder}
					name={textareaName}
					autoComplete='off'
					readOnly={isReadOnly}
					rows={textareaRows}
					className={`${textareaStyled} bg-transparent focus:outline-none placeholder-gray-500`}
					defaultValue={textareaValue}
					ref={ref}
					onChange={(e) => {
						if (typeof onChange === 'function') {
							onChange(e);
						}
						textareaHandlerFunction(e);
					}}
					{...register}
				/>
			</div>
		);
	}
);

export default TextareaField;
