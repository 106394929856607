import axios from '../../utils/axiosBase';
import { FETCH_NETS_DETAIL, LOAD_NETS_DETAIL } from './types';

const fetchNetsDetail =
	(currentLang, userId, history) => async (dispatch, getState) => {
		const payload = {
			currentLang: currentLang,
			userId: userId,
		};

		// chiamo web service
		const response = await axios.post(`api/netsdetail/`, payload, {
			withCredentials: true,
		});

		dispatch({
			type: FETCH_NETS_DETAIL,
			payload: response.data,
		});
	};

const loadNetsDetail = (netsLoaded, history) => async (dispatch, getState) => {
	dispatch({
		type: LOAD_NETS_DETAIL,
		payload: {
			netsLoaded,
		},
	});
};

export default {
	fetchNetsDetail,
	loadNetsDetail,
};
