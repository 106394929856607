import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import allActions from '../../../state/actions';
import ModalConfirm from '../../shared/modalConfirm/ModalConfirm';
import BoxAddress from './boxAddress/BoxAddress';
import BoxFormIndirizzo from './boxFormIndirizzo/BoxFormIndirizzo';

const GestioneIndirizzi = ({ onChangeAddressFunction = () => {} }) => {
	const dispatch = useDispatch();
	const {
		register,
		unregister,
		reset,
		formState: { errors },
		handleSubmit,
		control,
		setValue,
	} = useForm({
		mode: 'onChange',
	});
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const order = useSelector((state) => state.order);
	const indirizzi = useSelector((state) => state.user.indirizzi);
	const tokenCarrello = useSelector((state) => state.user.token_id);
	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);
	const gestioneIndirizziTranslation = useSelector(
		(state) => state.translations.gestioneIndirizzi
	);

	// local state
	const [isHideAddAddress, setIsHideAddAddress] = useState(true);
	const [isEditAddress, setIsEditAddress] = useState(false);
	const [disableCampi, setDisableCampi] = useState(false);
	const [isSending, setIsSending] = useState(false);
	const [isSelectingAddress, setIsSelectingAddress] = useState(false);
	const [currentAddressId, setCurrentAddressId] = useState(null);
	const [currentAddress, setCurrentAddress] = useState(null);
	const [nazioneS, setNazioneS] = useState('');

	useEffect(() => {
		console.log(currentAddress, currentAddressId, nazioneS);

		// inviando nuovo indirizzo o modificando indirizzo
		if (isSending) {
			const messageToShowCreate =
				order.status === 'OK'
					? genericheTranslation.modalmessaggiocreazioneindirizzook.itemName
					: genericheTranslation.modalmessaggiocreazioneindirizzoko.itemName;

			ModalConfirm({
				titleToShow:
					gestioneIndirizziTranslation.modalTitoloAggiornaIndirizzo.itemName,
				messageToShow: messageToShowCreate,
				isShowConfirmButton: false,
				isShowCanceButton: false,
				isTimerModal: true,
				onClickHandler: () => {
					reset(); // resetto form
					setIsHideAddAddress(true); // nascondo box inserimento
					setCurrentAddress(indirizzi[currentAddressId]); // mostro indirizzi aggiornati
				},
			});
			setIsSending(false);

			// resetto currentAddressId e currentAddress
			setCurrentAddressId(null);
			setCurrentAddress(null);
		}

		// selezionando indirizzo nella pagina pagamento
		if (isSelectingAddress && order.message) {
			const messageToShow =
				order.status === 'OK'
					? genericheTranslation.modalmessaggioselezioneindirizzook.itemName
					: genericheTranslation.modalmessaggioselezioneindirizzoko.itemName;
			ModalConfirm({
				titleToShow:
					gestioneIndirizziTranslation.modalTitoloSelezionaIndirizzo.itemName,
				messageToShow: messageToShow,
				isShowConfirmButton: false,
				isShowCanceButton: false,
				isTimerModal: true,
				onClickHandler: () => {
					reset(); // resetto form
					setIsHideAddAddress(true); // nascondo box inserimento
					setCurrentAddress(indirizzi[currentAddressId]); // mostro indirizzi aggiornati
					onChangeAddressFunction(currentAddressId); // passo l'informazione al padre PaymentDetail (se mi trovo in pagina pagamento)
				},
			});
			setIsSelectingAddress(false);
		}

		// rimuovo controlli validazione a campi provincia
		unregister('provinciaS');
		unregister('provinciaEsteraS');

		// preimposto i valori dell'indirizzo da modificare
		if (currentAddress) {
			setValue('addressId', currentAddressId);
			setValue('nomeS', currentAddress.meta_data.nomes);
			setValue('cognomeS', currentAddress.meta_data.cognomes);
			setValue('indirizzoS', currentAddress.meta_data.indirizzos);
			setValue('cittaS', currentAddress.meta_data.cittas);
			setValue('capS', currentAddress.meta_data.caps);
			setValue(
				'shipping_country_idS',
				currentAddress.meta_data?.shipping_country_ids
			);
			setValue('provinciaS', currentAddress.meta_data.provincias);
			setValue('provinciaEsteraS', currentAddress.meta_data.provinciaesteras);
			setValue('emailS', currentAddress.meta_data.emails);
			setValue('telefonoS', currentAddress.meta_data.telefonos);
			setNazioneS(currentAddress.meta_data.shipping_country_ids);
		}

		// svuoto i valori di provincia e città
		nazioneS === '36' || nazioneS === ''
			? setValue('provinciaEsteraS', '')
			: setValue('provinciaS', '');
	}, [isHideAddAddress, currentAddress, nazioneS, user, order]);

	const onAddAddressClick = ({ addressId }) => {
		// aggiungo l'indirizzo all'ordine
		//console.log(addressId);

		// chiedo conferma
		ModalConfirm({
			titleToShow:
				gestioneIndirizziTranslation.modalTitoloSelezionaIndirizzo.itemName,
			messageToShow: `${gestioneIndirizziTranslation.modalTitoloSelezionaIndirizzo.itemName}: <b>${addressId}</b>`,
			isShowConfirmButton: true,
			isShowCanceButton: true,
			isTimerModal: false,
			textConfirmButton: genericheTranslation.conferma.itemName,
			textCancelButton: genericheTranslation.annulla.itemName,
			onClickHandler: () => {
				// chiamo action creator
				setIsSelectingAddress(true);

				// imposto indirizzo corrente selezionato
				setCurrentAddressId(addressId);
				const indirizzoIndex = Object.keys(indirizzi)
					.filter((key) => indirizzi[key].id === addressId)
					.toString();
				setCurrentAddress(indirizzi[indirizzoIndex]);

				dispatch(
					allActions.paymentDetailActions.selectAddress(
						currentPage.currentLang,
						user.token_id,
						user.userData.id,
						addressId
					)
				);
			},
		});
	};

	const onNewAddressClick = (event) => {
		reset(); // resetto form
		setDisableCampi(true);
		setIsEditAddress(false);
		setCurrentAddressId(null);
		setCurrentAddress(null);
		if (isEditAddress) {
			setIsHideAddAddress(false);
		} else {
			setIsHideAddAddress(!isHideAddAddress);
		}
	};

	const onEditAddressClick = ({ addressId }) => {
		if (addressId != currentAddressId) {
			reset(); // resetto form (solo se non mi trovo già in modifica)

			const indirizzoIndex = Object.keys(indirizzi)
				.filter((key) => indirizzi[key].id === addressId)
				.toString();
			setCurrentAddressId(addressId);
			setCurrentAddress(indirizzi[indirizzoIndex]);
			setIsEditAddress(true);
			setDisableCampi(false);
			setIsHideAddAddress(false);
		}
	};

	const onDeleteAddressClick = ({ addressId }) => {
		console.log(addressId);
		ModalConfirm({
			titleToShow:
				gestioneIndirizziTranslation.modalTitoloCancellaIndirizzo.itemName,
			messageToShow: null,
			isShowConfirmButton: true,
			isShowCanceButton: true,
			isTimerModal: false,
			textConfirmButton: genericheTranslation.conferma.itemName,
			textCancelButton: genericheTranslation.annulla.itemName,
			onClickHandler: () => {
				// chiamo action creator
				setIsSending(true);

				dispatch(
					allActions.profileDetailActions.deleteAddress(
						currentPage.currentLang,
						user.userData.id,
						addressId
					)
				);
			},
		});
	};

	const onChangeNazioneS = (event) => {
		console.log(typeof event.target.value, event.target.value);

		// se nazione estera resetto campo città estera
		if (event.target.value !== '36') {
			setValue('cittaS', '');
		}

		setDisableCampi(false); // abilito i campi città e provincia
		setNazioneS(event.target.value);
	};

	const onSubmitAddress = (data) => {
		console.log(data);

		// chiamo action creator
		setIsSending(true);

		// controllo se aggiornamento o creazione
		if (data['addressId']) {
			dispatch(
				allActions.profileDetailActions.updateAddress(
					currentPage.currentLang,
					user.userData.id,
					tokenCarrello,
					data
				)
			);
		} else {
			dispatch(
				allActions.profileDetailActions.createAddress(
					currentPage.currentLang,
					user.userData.id,
					data
				)
			);
		}
	};

	const elencoIndirizzi = indirizzi.map((indirizzo) => {
		const isSelectedAddress =
			parseInt(indirizzo.id) === order?.addressId &&
			currentPage.routeName !== 'indirizziDetail'
				? true
				: false;

		return (
			<BoxAddress
				key={indirizzo.id}
				idIndirizzo={indirizzo.id}
				isSelected={isSelectedAddress}
				nome={indirizzo.meta_data.nomes}
				cognome={indirizzo.meta_data.cognomes}
				indirizzo={indirizzo.meta_data.indirizzos}
				citta={indirizzo.meta_data.cittas}
				provinciaText={indirizzo.meta_data.provinciaText}
				cap={indirizzo.meta_data.caps}
				shipping_country_id={indirizzo.shipping_country_id}
				nazioneText={indirizzo.meta_data.nazioneText}
				telefono={indirizzo.meta_data.telefonos}
				onAddAddressClick={onAddAddressClick}
				onEditAddressClick={onEditAddressClick}
				onDeleteAddressClick={onDeleteAddressClick}
			/>
		);
	});

	const nazioneEsteraCampiVisibili =
		nazioneS !== '36' && nazioneS !== '' ? true : false;

	const isButtonSending = isSending ? true : false;
	const showBoxAddress = isHideAddAddress ? 'hidden' : 'flex';

	const showBoxIndirizzo = (currentAddress || isHideAddAddress === false) && (
		<BoxFormIndirizzo
			onChangeNazioneSFunction={onChangeNazioneS}
			isBoxSpedizioneVisible={showBoxAddress}
			isEdit={isEditAddress}
			isSending={isButtonSending}
			currentAddress={currentAddress}
			nazioneEsteraCampiVisibili={nazioneEsteraCampiVisibili}
			disableCampiProvinciaCitta={disableCampi}
			showSubmit={true}
			errors={errors}
			register={register}
			Controller={Controller}
			control={control}
		/>
	);

	return (
		<Fragment>
			{elencoIndirizzi}
			<BoxAddress onNewAddressClick={onNewAddressClick} />
			<form onSubmit={handleSubmit(onSubmitAddress)} className='col-span-60'>
				<div className='grid grid-cols-60'>
					<div className='col-span-60'>{showBoxIndirizzo}</div>
				</div>
			</form>
		</Fragment>
	);
};

export default GestioneIndirizzi;
