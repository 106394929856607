import { useRef, useState } from 'react';
import { createPopper } from '@popperjs/core';

const InfoTooltipElement = ({
	iconColor,
	fontSize,
	tooltipColor,
	tooltipText,
}) => {
	const [tooltipShow, setTooltipShow] = useState(false);
	const btnRef = useRef();
	const tooltipRef = useRef();
	const openLeftTooltip = () => {
		createPopper(btnRef.current, tooltipRef.current, {
			placement: 'top',
		});
		setTooltipShow(true);
	};
	const closeLeftTooltip = () => {
		setTooltipShow(false);
	};

	return (
		<span className={`text-${iconColor} `}>
			<i
				className={`fas fa-info-circle cursor-pointer ${fontSize}`}
				onMouseEnter={openLeftTooltip}
				onMouseLeave={closeLeftTooltip}
				ref={btnRef}></i>
			<div
				className={
					(tooltipShow ? '' : 'hidden ') +
					'bg-' +
					tooltipColor +
					' border-0 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-md'
				}
				ref={tooltipRef}>
				<div
					className='text-white p-3'
					dangerouslySetInnerHTML={{ __html: tooltipText }}></div>
			</div>
		</span>
	);
};

export default InfoTooltipElement;
