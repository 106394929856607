import { Route } from 'react-router-dom';
import Risultatiricerca from '../pages/risultatiricerca/Risultatiricerca';

export default (risultatiricercaRoute) => {
	return [
		<Route
			exact
			path={`/${risultatiricercaRoute}.html`}
			component={Risultatiricerca}
			key={`${risultatiricercaRoute}Route`}
		/>,
	];
};
