import { Fragment } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { BASENAME } from '../../../utils/basename';

const LazyLoadImg = ({
	imgName,
	imgPath,
	imgRoute,
	imgType,
	imgWidth,
	imgHeight,
	imgStyle,
	imgAlt,
	imgPlaceholder,
	scrollPosition,
	visibleByDefaultProp,
}) => {
	// source responsive
	const fullUrl = (stepKey) => {
		if (imgRoute) {
			return (
				BASENAME +
				'/' +
				imgRoute +
				'/' +
				imgType +
				'_' +
				imgWidth[stepKey] +
				'x' +
				imgHeight[stepKey] +
				'x' +
				imgPath +
				'_' +
				imgName
			);
		} else {
			if (imgWidth[stepKey] === undefined) {
				return BASENAME + '/storage/' + imgPath + '/' + imgName;
			} else {
				return (
					BASENAME +
					'/storage/' +
					imgPath +
					'/thumbs/' +
					imgWidth[stepKey] +
					'x' +
					imgHeight[stepKey] +
					'-' +
					imgName
				);
			}
		}
	};

	const imgServer = (
		<img
			src={fullUrl('xl4')}
			width={imgWidth['xl4'] ? imgWidth['xl4'] : imgWidth}
			height={imgHeight['xl4'] ? imgHeight['xl4'] : imgHeight}
			className={imgStyle}
			alt={imgAlt ? imgAlt : ''}
		/>
	);

	return (
		<Fragment>
			<LazyLoadImage
				src={fullUrl('xl4')}
				width={imgWidth['xl4'] ? imgWidth['xl4'] : imgWidth}
				height={imgHeight['xl4'] ? imgHeight['xl4'] : imgHeight}
				className={`${imgStyle} span-placeholder`}
				alt={imgAlt ? imgAlt : ''}
				effect='opacity'
				visibleByDefault={visibleByDefaultProp ? true : false}
			/>
			<noscript>
				<style>{`.span-placeholder { display: none !important; }`}</style>
				{imgServer}
			</noscript>
		</Fragment>
	);
};

export default LazyLoadImg;
