const TitoloBlocco = ({ titoloStyle, titolo }) => {
	return (
		<span
			className={titoloStyle}
			dangerouslySetInnerHTML={{ __html: titolo }}
		/>
	);
};

export default TitoloBlocco;
