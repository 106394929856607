import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../../state/actions';
import { URLASSOLUTO } from '../../../utils/urlassoluto';

const BoxRichiedInfo = () => {
	const history = useHistory();
	const dispatch = useDispatch();

	const genericheTranslation = useSelector(
		(state) => state.translations.generiche
	);
	const navigationItems = useSelector(
		(state) => state.translations.mainNavigation
	);

	const onClickEmail = (event) => {
		event.preventDefault();

		// mostro spinner
		dispatch(allActions.isFetchingAction.loadSpinner());

		dispatch(
			allActions.changePageActions.changePage(
				'',
				navigationItems.contatti.itemRouteName,
				navigationItems.contatti.itemLink,
				history
			)
		);
	};

	return (
		<div className='bg-ribola-amber rounded-md flex flex-col justify-center items-center px-3 pt-3 pb-4'>
			<span className='text-white text-sm 2xl:text-base 3xl:text-lg 4xl:text-xl'>
				{genericheTranslation.richiediinformazioni.itemName.toUpperCase()}
			</span>
			<a
				href={URLASSOLUTO + navigationItems.contatti.itemLink}
				className='bg-white rounded-md text-ribola-dove-gray flex items-center w-full 3xl:w-11/12 px-4 3xl:px-6 py-1 mt-3 cursor-pointer'
				onClick={onClickEmail}>
				<i className='far fa-envelope text-2xl inline-block mr-6'></i>
				<span className='text-sm 3xl:text-base'>E-MAIL</span>
			</a>
			<a
				href='https://wa.me/393334870763'
				target='_blank'
				className='bg-white rounded-md text-ribola-dove-gray flex items-center w-full 3xl:w-11/12 px-4 3xl:px-6 py-1 mt-3'>
				<i className='fab fa-whatsapp text-2xl inline-block mr-6'></i>
				<span className='text-sm 3xl:text-base'>3334870763</span>
			</a>
		</div>
	);
};

export default BoxRichiedInfo;
