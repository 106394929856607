import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import allActions from '../../state/actions';
import Layout from '../../components/layout/Layout';
import PreloaderBg from '../../components/shared/preloaderBg/PreloaderBg';
import MenuLeftMagazine from '../../components/shared/menuLeftMagazine/MenuLeftMagazine';
import MagazineCategoryDetail from '../../components/magazineCategoryDetail/MagazineCategoryDetail';
import Error404Detail from '../../components/error404Detail/Error404Detail';

const MagazineCategory = () => {
	const location = useLocation();
	const dispatch = useDispatch();
	const currentPage = useSelector((state) => state.currentPage);
	const user = useSelector((state) => state.user);
	const magazine = useSelector((state) => state.magazine);
	const isFetching = currentPage.isFetching;

	useEffect(() => {
		// recupero struttura percorso da url (per via del back dal browser, devo sempre recuperare i dati via url)
		const pathNodes = location.pathname.split('/').filter((val) => val !== '');

		const magazine_urlseo = pathNodes[0];
		const magazine_category_urlseo = pathNodes[1];
		const magazine_category_id = Object.keys(magazine)
			.filter((key) => magazine[key].urlSeo === magazine_category_urlseo)
			.toString();

		// controllo nello store se ho già il dettaglio della magazineCategory (isFetched = true), altrimenti eseguo action creator
		const magazineCategory = magazine[magazine_category_id];

		if (magazineCategory) {
			// setto i metatags
			document.title = magazineCategory.metaTitle;
			document.getElementsByTagName('META')[2].content =
				magazineCategory.metaDesc;
		}

		// controllo per pagina 404, se currentPage.routeName === 'error404Detail' vuol dire che ho inserito un url sbagliato, restituisco la pagina 404
		if (
			currentPage.routeName === 'error404Detail' ||
			magazineCategory === undefined ||
			magazineCategory === null
		) {
			// recupero pagina 404
			const wrongPage404 =
				magazine_urlseo + '/' + magazine_category_urlseo + '/';
			setTimeout(
				() =>
					dispatch(
						allActions.staticDetailActions.fetchError404Detail(
							currentPage.currentLang,
							user?.userData?.id,
							wrongPage404
						)
					),
				50
			);
		} else {
			// recupero dati categoria magazine
			if (
				magazineCategory === null ||
				magazineCategory?.isFetched === undefined ||
				magazineCategory?.isFetched === false
			) {
				// non ho i dati del prodotto nello store, chiamo action creator fetchMagazineCategoryDetail
				// se non ho ancora recuperato l'array di magazine devo passare l'urlseo della categoria piuttosto che l'id
				const urlSeoOrId =
					magazineCategory?.id === undefined
						? magazine_category_urlseo
						: magazineCategory.id;
				setTimeout(
					() =>
						dispatch(
							allActions.magazineDetailActions.fetchMagazineCategoryDetail(
								currentPage.currentLang,
								user?.userData?.id,
								urlSeoOrId
							)
						),
					50
				);
			} else {
				// loading...
				dispatch(allActions.isFetchingAction.loadStoredData());

				// recupero dati da store
				dispatch(
					allActions.magazineDetailActions.loadMagazineCategoryDetail(
						magazineCategory
					)
				);
			}
		}
	}, [location]);

	const isPage404 =
		currentPage.routeName === 'error404Detail' ? (
			<Error404Detail />
		) : (
			<MagazineCategoryDetail />
		);

	return (
		<Layout>
			<div className='container mx-auto'>
				<PreloaderBg showPreloader={isFetching} />
				<div className='grid grid-cols-60'>
					<MenuLeftMagazine />
					{isPage404}
				</div>
			</div>
		</Layout>
	);
};

export default MagazineCategory;
